#cali-privacy-policy {
  p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:0in;
	 margin-left:0in;
	 font-size:12.0pt;
	 font-family:"Arial",sans-serif;
	 color:black;}
  span.MsoCommentReference
	{color:black;}
  a:link, span.MsoHyperlink
	{color:black;
	 text-decoration:none none;}
  span.CustomizableHeadingChar
	{mso-style-name:"Customizable Heading Char";
	 mso-style-link:"Customizable Heading";
	 font-family:"Times New Roman",serif;
	 color:black;
	 font-weight:bold;}
  p.CustomizableHeading, li.CustomizableHeading, div.CustomizableHeading
	{mso-style-name:"Customizable Heading";
	 mso-style-link:"Customizable Heading Char";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:0in;
	 margin-left:0in;
	 text-align:center;
	 font-size:12.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;
	 font-weight:bold;}
  span.BulletList1Char
	{mso-style-name:"Bullet List 1 Char";
	 mso-style-link:"Bullet List 1";
	 font-family:"Times New Roman",serif;
	 color:black;}
  p.BulletList1, li.BulletList1, div.BulletList1
	{mso-style-name:"Bullet List 1";
	 mso-style-link:"Bullet List 1 Char";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:6.0pt;
	 margin-left:.5in;
	 text-indent:-.25in;
	 font-size:12.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;}
  span.BulletList2Char
	{mso-style-name:"Bullet List 2 Char";
	 mso-style-link:"Bullet List 2";
	 font-family:"Times New Roman",serif;
	 color:black;}
  p.BulletList2, li.BulletList2, div.BulletList2
	{mso-style-name:"Bullet List 2";
	 mso-style-link:"Bullet List 2 Char";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:6.0pt;
	 margin-left:.75in;
	 text-indent:-.25in;
	 font-size:12.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;}
  span.List-NumberedListLevel1Char
	{mso-style-name:"List - Numbered List Level 1 Char";
	 mso-style-link:"List - Numbered List Level 1";
	 font-family:"Times New Roman",serif;
	 color:black;}
  p.List-NumberedListLevel1, li.List-NumberedListLevel1, div.List-NumberedListLevel1
	{mso-style-name:"List - Numbered List Level 1";
	 mso-style-link:"List - Numbered List Level 1 Char";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:6.0pt;
	 margin-left:.5in;
	 text-indent:-.3in;
	 font-size:12.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;}
  span.ParaFirst-lineIndentChar
	{mso-style-name:"Para First-line Indent Char";
	 mso-style-link:"Para First-line Indent";
	 font-family:"Times New Roman",serif;
	 color:black;}
  p.ParaFirst-lineIndent, li.ParaFirst-lineIndent, div.ParaFirst-lineIndent
	{mso-style-name:"Para First-line Indent";
	 mso-style-link:"Para First-line Indent Char";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:0in;
	 margin-left:0in;
	 text-indent:.5in;
	 font-size:12.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;}
  span.ParagraphChar1
	{mso-style-name:"Paragraph Char1";
	 mso-style-link:Paragraph;
	 font-family:"Times New Roman",serif;
	 color:black;}
  p.Paragraph, li.Paragraph, div.Paragraph
	{mso-style-name:Paragraph;
	 mso-style-link:"Paragraph Char1";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:0in;
	 margin-left:0in;
	 font-size:12.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;}
  span.DocumentTitleChar
	{mso-style-name:"Document Title Char";
	 mso-style-link:"Document Title";
	 font-family:"Times New Roman",serif;
	 color:black;
	 font-weight:bold;}
  p.DocumentTitle, li.DocumentTitle, div.DocumentTitle
	{mso-style-name:"Document Title";
	 mso-style-link:"Document Title Char";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:12.0pt;
	 margin-left:0in;
	 text-align:center;
	 font-size:16.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;
	 font-weight:bold;}
  .MsoChpDefault
	{font-size:10.0pt;
	 font-family:"Arial",sans-serif;}
  @page WordSection1
	{size:8.5in 11.0in;
	 margin:.5in .5in .5in .5in;}
  div.WordSection1
	{page:WordSection1;}
}
