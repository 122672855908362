@import url("https://use.fontawesome.com/releases/v5.15.0/css/all.css");

body {
  /* background-color: #fff; */
  background-color: #FAF9F9;
  font-family: Barlow, "Helvetica Neue", Helvetica, sans-serif;
}

body:after {
    content:'';
    position: fixed;
    height: 150px;
    width: 100%;
    top: 0%;
    /* background: #fff; */
    /* background:--original--#fff...; */
    z-index: -999;
}

.app-container {
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.ui.grid > * {
  padding-left: 1.2rem;
  padding-right: 1.35rem;
}

.ui.grid > .row.youtip-header {
  margin-top: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #fff;
}

.row.youtip-header + .row {
  padding-top: 0.25rem;
}

.ui.rating .active.icon {
  color: #fff;
}

#blank-container-col {
  margin-top: 1rem;
}

#blank-container-col img {
  margin-right: 1rem;
}

#blank-container-segment {
  /* background-color: #fff; */
  border-radius: 12px;
}



#tip-container-row {
  /* padding: 1.25rem 0.1rem 2rem 0.1rem; */
}
#tip-container-segment {
  border-radius: 14px;
  padding: 0; /* 1rem 1rem 2.25rem 1rem; */
}
#tip-container-segment .ui.segment {
  padding: 1rem 1.1rem;
}
.optional-tip-input-field.ui.input {
  width: 70%;
  color: #666;
  margin-bottom: 0.25em;
}
.optional-tip-input-field.ui.input > input.input {
  border: 0;
  border-radius: 0;
  padding: 0.25em;
  width: 100%;
  text-align: center;
}
#tip-container-segment .ui.attached.segment {
  border: none;
  /* border-radius: 14px; */
}
#tip-container-segment .ui[class*="top attached"].segment {
  border-radius: 14px 14px 0 0;
}
#tip-container-segment .ui[class*="bottom attached "].segment {
  border-radius: 0 0 14px 14px;
}
#tip-presets {
  background-color: #f2f2f2;
  font-family: 'Helvetica Neueu', Helvetica, sans-serif;
}
#tip-presets.ui.segment {
  /* padding-top: 0.5em; */
  /* padding-bottom: 0.5em;*/
  padding: 0.5em;
}
#tip-presets.ui.segment span {
  margin-right: 0.5em;
  font-weight: 500;
  font-size: 1em;
  color: #666;
  /* font-style: italic; */
}
#tip-presets .ui.circular.label {
  min-height: 2.8em;
  min-width: 2.8em;
  max-height: 2.75em;
  max-width: 2.75em;
  line-height: 1.7em;
  font-weight: 500;
  padding: 0.5em !important;
  font-family: Barlow, 'Helvetica Neueu', Helvetica, sans-serif;
  /* .5833 .833 */
}
#tip-presets .ui.youtip.label {
  background-color: #fff;
  /* border: 1px solid #fff; */
  border: 1px solid #ccc;
  font-size: 1rem;
  margin: 0 0.4em;
}

#tip-presets .ui.youtip.label.preset-left-adjust {
  padding-left: 0.35em !important;
}
/* #tip-presets:not(.horizontal).segment:last-child { */
/*   border-radius: 0 0 14px 14px; */
/* } */



/* .bg-green, .bg-blue, */
.bg-blue {
  /* background-color: #fff; */
  background-color: #ddd;
}
.bg-green,
.ui.button.bg-green {
  background-color: #ddd;
}


/* #e22288 */
/* #051e5e */
/* #dd7a80 */
/* #f7f762 */
/* #2d87fc */
/* #0f6ba8 */

#pay-with-cc-form #card-number-element,
#pay-with-cc-form #card-expiry-element,
#pay-with-cc-form #card-cvc-element {
  /* height: auto; */
  /* max-height: 35px; */
  background-color: white;
}

.ui.progress.top {
  background: #ccc;
  /* background: #eebcbf; */
  /* background: rgba(219, 123, 130, .7); */

}

.ui.progress .bar {
  background: #999;
  /* background: #dd7a80; */
  /* background: #e3be32; */
  /* background: #f1d127; */
}

.ui.centered.grid .column .terms-and-conditions-link,
.ui.centered.grid .column .wallet-info-link {
  /* text-transform: uppercase; */
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.terms-and-conditions-link i.icon {
  color: #ccc;
}
.wallet-info-link i.icon {
  color: #999;
  font-style: italic;
}

#wallet-info-button,
#terms-and-conditions-button {
  font-size: 0.85em;
  font-style: italic;
  border: none;
  padding: 0.5em;
  /* text-transform: uppercase; */
}

#wallet-info-button:hover,
#terms-and-conditions-button:hover {
  text-decoration: underline;
  color: #444
}

#wallet-info-button {
  padding: 0.5em 0.5em 0.6em 0.5em;
}

.wallet-info-link button.ui.basic.button#wallet-info-button,
.wallet-info-link a,
a.wallet-info-link,
.terms-and-conditions-link button.ui.basic.button#terms-and-conditions-button,
.terms-and-conditions-link a,
a.terms-and-conditions-link {
  font-family: Barlow, 'Helvetica Neue', Helvetica, sans-serif;
  /* text-decoration: underline; */
  font-size: 0.8em;
  text-align: center;
  font-style: italic;
  /*color: rbga(0, 0, 0, .6);*/ /*#fff;*/ /*#999;*/
  color: #666;
}

.wallet-info-link button.ui.basic.button#wallet-info-button,
.wallet-info-link a,
a.wallet-info-link {
  color: #999;
}

.ui.mini.message {
  padding: 0.6em;
}

.divider.slim {
  margin: 0.75rem 0;
}
div.ui.horizontal.divider {
  color: #dd7a80;
  font-size: 0.9rem;
}

button.ui.button.applepay-button,
button.ui.button.googlepay-button{
  background-origin: content-box;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 0px;
  border-radius: 12px;
  cursor: pointer;
  height: 40px;
  min-height: 40px;
  background-color: #000;
  box-shadow: none;
  padding: 12px 24px 10px;
}

.ui.grid .column.applepay-col {
  padding-right: 0.5rem;
}

.ui.grid .column.googlepay-col {
  padding-left: 0.5rem;
}

#pay-with-room {
  margin: 1.25rem 0;
}

#pay-with-cc-button-toggle {
  color: #fff;
  border-radius: 12px;
}

#pay-with-cc-view {
  margin-top: 0.3em;
}

#pay-with-cc-form {
  border-radius: 12px 12px 0 0;
  border: 1px solid #eee;
  line-height: 24px;
  padding: 1em 1.3em 1em 1.3em;
  padding-bottom: 1.5em;
  font-family: Helvetica Neue, Helvetica, sans-serif;
  background-color: #f5f7fa;
}

#pay-with-cc-button {
  border-radius: 0 0 12px 12px;
  color: #fff;
}

/**************************************************/
/*     prefactor's RecipientInfo.css contents     */
/**************************************************/
#recipient-info {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  text-align: left;
  /* padding-bottom: 0.85rem; */
  padding-bottom: 0;
}

#recipient-info .timestamp {
  color: #999;
  padding-bottom: 0.25rem;
  font-size: 0.85rem;
  border-bottom: 1px dashed #e5e5e5;
}

#recipient-info .details {
  margin-top: 0.8rem;
  line-height: 1.3rem;
}

#recipient-info .details .name {
  font-weight: bold;
  font-size: 1.15rem;
}

#recipient-info .details .info {
  text-align: right;
  font-weight: normal;
  font-size: 1rem;
}

#recipient-info .details .service {
  text-align: left;
  font-style: italic;
  font-size: 0.85rem;
  color: #666;
}

/**************************************************/
/*    prefactor's TipAmountInput.css contents     */
/**************************************************/
.tip-amount-header {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  margin-top: 0;  /* 1.25rem; */
  margin-bottom: 0.65rem;
  font-size: 1em;
  color: #333;
}

.ui.input.tip-amount-input {
  font-family: Barlow, 'Helvetica Neue', Helvetica, sans-serif;
  border-color: #fff;
  color: #fff;
  text-align: center;
  min-width: 30%;
  max-width: 90%;
  width: 70%;
}

input.tip-amount-input-currency,
.ui.input.tip-amount-input > input {
  font-family: Barlow, 'Helvetica Neue', Helvetica, sans-serif;
  border-color: #fff;
  color: #fff;
  font-size: 2.75em;
  text-align: right;
  /* line-height: 1em; */
  font-weight: 300;
  padding: 0.55rem 0.95rem !important;
  /* padding-left: 1.15em !important; */
  /* padding-right: 0.5em !important; */
  /* max-width: 50%; */
}

/*
.ui.input.tip-amount-input > input:selected {
  color: #fff;
}
*/

.ui.input.tip-amount-input i.icon {
  color: #fff;

  /* color: #fff; */
  font-size: 1.25em;
  width: 1.5em;
  padding-left: 2em;
}

.tip-fee-info {
  margin-top: 1rem;
  font-size: 0.9em;
  color: #999;
  margin-bottom: 0.9rem;
}
.tip-fee-info .transaction-info-fee,
.tip-fee-info .transaction-info-total {
}

.tip-fee-info span {
  color: #fff;
}

.credit-card-logos {
  text-align: center;
  margin-top: 1.2rem;
}

.credit-card-logos img {
  max-width: 225px;
}

.credit-card-logos svg {
  margin: 0.25rem 0.5rem;
}

/**************************************************/
/*    prefactor's Receipt.css contents     */
/**************************************************/
#receipt-container .rate-service-row .rate-service-segment .comment-box-link {
  font-size: 0.85em;
  font-weight: bold;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
}

#receipt-container .rate-service-row .rate-service-segment .comment-box {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  background: none;
  border: none;
  border-bottom: 1px solid #fff;
  margin-top: 0;
}

#receipt-container .rate-service-row .rate-service-segment .comment-box > input {
  border: none !important;
  padding: 0.25em 0;
  background-color: #f9f9f9;
  font-size: 16px;
}

#receipt-container .receipt-email-header {
  padding-bottom: 8px;
}
#receipt-container .receipt-email-box {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  background: none;
  border: none;
  border-bottom: 1px solid #999;
  margin-top: 0;
}
#receipt-container .receipt-email-box .receipt-email-input > input {
  padding: 0.25em 0;
  border: none !important;
  background-color: #f9f9f9;
  font-size: 16px;
}
#receipt-container .receipt-email-submit {
  margin-top: 0.5em;
}
#receipt-container .receipt-message {
  font-size: 12px;
  font-style: italic;
}
#receipt-container .receipt-email-error {
  font-size: 11px;
  color: #cc0000;
}

#receipt-container .rate-service-row .rate-service-segment h5 {
  margin-top: 0.5em;
}

#receipt-container .rate-service-row .rate-service-segment .submitted-comment {
  font-size: 0.9em;
  font-style: italic;
  color: #666;
  padding-top: 0.7em;
}

#receipt-container .rate-service-row .rate-service-segment button {
  margin-top: 0.5em;
  color: #fff;
}

#receipt-container .receipt-image-row {
  padding-bottom: 0.8em;
}

#receipt-container .rate-service-row {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  padding-top: 0;
  padding-bottom: 1.5em;
}

#receipt-container .rate-service-row .rate-service-segment,
#receipt-container .receipt-content-row .receipt-content-segment {
  padding-top: 1.5em;
  padding-bottom: 2em;
  border-radius: 12px;
}

#receipt-container .rate-service-row .rate-service-segment .rating {
  margin: 0.25rem 0 1rem 0;
}

#receipt-container .rate-service-row .rate-service-segment .stars {
  margin-bottom: 0.75em;
}

#receipt-container .rate-service-row .rate-service-segment .stars .star-empty {
  color: #fff;
}

#receipt-container .rate-service-row .rate-service-segment .stars .star-full {
  color: #fff;
}


#receipt-container table.receipt-details {
  margin-top: 0.5em;
}

#receipt-container table.receipt-details .receipt-field-label {
  color: #666;
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: normal;
  text-align: left;
}

#receipt-container table.receipt-details .receipt-field-value {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  font-size: 1em;
  text-align: right;
}

#receipt-container table.receipt-details .receipt-field-value.transaction-id {
  font-size: 0.85em;
}

#receipt-container table.receipt-details .receipt-field-value .amount-details {
  font-size: 0.85em;
  color: #999;
}

#receipt-container .service-details {
  font-size: 1em;
  font-weight: normal;
  background-color: #eee;
}

#receipt-container #recipient-info {
  margin-top: 0;
  padding-bottom: 0.5em;
  border-bottom: 2px dotted #ccc;
}

#receipt-container #recipient-info .details {
  margin-top: 0;
}

#pay-with-cc-error-message {
  font-size: 0.9em;
  text-align: center;
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0.25em;
  margin-top: 0;
  font-family: Helvetica Neue, Helvetica, sans-serif;
  color: #e25950;
}


/**************************************************/
/*    prefactor's Payments.css contents     */
/**************************************************/

#pay-with-cc-form .field {
}

#pay-with-cc-form .field.no-border {
  border: none;
}

#pay-with-cc-form .field > label {
  font-weight: normal;
  color: #999;
  text-align: left;
}

#pay-with-cc-form .cc-form-cancel {
  margin-bottom: 0.5em;
}

#pay-with-cc-form form.ElementsApp,
#pay-with-cc-form input,
#pay-with-cc-form .ui.field input,
#pay-with-cc-form .CardNumberField input,
#pay-with-cc-form form.ElementsApp input,
#pay-with-cc-form .CardNumberField input,
#pay-with-cc-form .CardNumberField-input-wrapper,
.ElementsApp input,
.InputContainer input,
span.InputContainer input.InputElement,
#card-expiry-element,
#card-number-element,
#card-cvc-element,
.stripe-input-container {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  background: none;
  border-bottom: 1px solid #fff;
  padding: 0;
  font-size: 18px;
}

.pay-with-apple-dimmer {
  vertical-align: bottom;
}

.pay-with-apple-screenshot {
  vertical-align: bottom;
}

.ui.utton.pay-with-apple-button img {
  max-height: 30px;
  height: 25px;
  min-height: 15px;
}

.ui.menu.payment-options-menu {
  background-color: #565e77;
}

.ui.menu.payment-options-menu .item {
  color: #eee;
  border-color: #999;
}

.ui.menu.payment-options-menu .item:hover {
  color: #fff;
}

.ui.menu.payment-options-menu .item.active {
  background-color: #fff;
  border-color: #fff;
  color: #fff;
}
.ui.menu.payment-options-menu .item:after {
  border-color: #fff;
  background-color: #fff;
  color: #fff;
}

#pay-with-cc-form a.change-payment-method,
#pay-with-cc-form .change-payment-method {
  font-weight: bold;
  color: #555;
  background: none;
  border: none;
  cursor: pointer;
}

.optional-tip-input-field.ui.input {
  color: #666;
}

.optional-tip-input-field.ui.input > input.input {
  background-color: #fff;
  color: #666;
  font-style: italic;
  text-align: center;
  padding-bottom: 0.1em;
}

/***********************************************************/

.apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: donate; /* Use any supported button type. */
}
.apple-pay-button-black {
    -apple-pay-button-style: black;
}
.apple-pay-button-white {
    -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
}

@supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
    }
    .apple-pay-button-black {
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-color: black;
        -apple-pay-button-style: black;
    }
    .apple-pay-button-white {
        -apple-pay-button-style: white;
    }
    .apple-pay-button-white-with-line {
        -apple-pay-button-style: white-outline;
    }
}

@supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        background-size: 100% 60%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-radius: 5px;
        padding: 0px;
        box-sizing: border-box;
        min-width: 200px;
        min-height: 32px;
        max-height: 64px;
    }

    .apple-pay-button-black {
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-color: black;
    }
    .apple-pay-button-white {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
    }
    .apple-pay-button-white-with-line {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
        border: .5px solid black;
    }
}

#root > div > div > div.centered.row > div > div:last-child > div {
  border-top: 1px solid #ccc;
  padding-top: 0.75rem;
  text-align: center;
  margin-top: 0.6rem;
}

#receipt-container .receipt-content-segment.email-receipt-segment button:disabled {
  background-color: #666 !important;
}
