@import url(https://use.fontawesome.com/releases/v5.15.0/css/all.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#terms-of-use {
  ol.lst-kix_list_7-0 {
    list-style-type: none
  }

  .lst-kix_list_2-1>li {
    counter-increment: lst-ctn-kix_list_2-1
  }

  .lst-kix_list_21-8>li {
    counter-increment: lst-ctn-kix_list_21-8
  }

  ol.lst-kix_list_9-0.start {
    counter-reset: lst-ctn-kix_list_9-0 0
  }

  ol.lst-kix_list_30-0.start {
    counter-reset: lst-ctn-kix_list_30-0 0
  }

  ol.lst-kix_list_13-4.start {
    counter-reset: lst-ctn-kix_list_13-4 0
  }

  .lst-kix_list_30-6>li {
    counter-increment: lst-ctn-kix_list_30-6
  }

  .lst-kix_list_13-0>li {
    counter-increment: lst-ctn-kix_list_13-0
  }

  ol.lst-kix_list_20-2.start {
    counter-reset: lst-ctn-kix_list_20-2 0
  }

  .lst-kix_list_5-0>li {
    counter-increment: lst-ctn-kix_list_5-0
  }

  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0
  }

  ol.lst-kix_list_7-5 {
    list-style-type: none
  }

  ol.lst-kix_list_7-6 {
    list-style-type: none
  }

  ol.lst-kix_list_7-7 {
    list-style-type: none
  }

  ol.lst-kix_list_7-8 {
    list-style-type: none
  }

  ol.lst-kix_list_7-1 {
    list-style-type: none
  }

  ol.lst-kix_list_7-2 {
    list-style-type: none
  }

  ol.lst-kix_list_7-3 {
    list-style-type: none
  }

  ol.lst-kix_list_7-4 {
    list-style-type: none
  }

  ol.lst-kix_list_5-3.start {
    counter-reset: lst-ctn-kix_list_5-3 0
  }

  .lst-kix_list_4-3>li {
    counter-increment: lst-ctn-kix_list_4-3
  }

  ol.lst-kix_list_17-1.start {
    counter-reset: lst-ctn-kix_list_17-1 0
  }

  .lst-kix_list_24-7>li {
    counter-increment: lst-ctn-kix_list_24-7
  }

  .lst-kix_list_29-8>li {
    counter-increment: lst-ctn-kix_list_29-8
  }

  ol.lst-kix_list_30-5.start {
    counter-reset: lst-ctn-kix_list_30-5 0
  }

  .lst-kix_list_18-8>li {
    counter-increment: lst-ctn-kix_list_18-8
  }

  ol.lst-kix_list_8-8.start {
    counter-reset: lst-ctn-kix_list_8-8 0
  }

  ol.lst-kix_list_26-7.start {
    counter-reset: lst-ctn-kix_list_26-7 0
  }

  ol.lst-kix_list_10-4.start {
    counter-reset: lst-ctn-kix_list_10-4 0
  }

  .lst-kix_list_7-2>li {
    counter-increment: lst-ctn-kix_list_7-2
  }

  .lst-kix_list_32-8>li {
    counter-increment: lst-ctn-kix_list_32-8
  }

  .lst-kix_list_24-7>li:before {
    content: ""counter(lst-ctn-kix_list_24-7, lower-latin) ". "
  }

  ol.lst-kix_list_16-5 {
    list-style-type: none
  }

  ol.lst-kix_list_16-6 {
    list-style-type: none
  }

  .lst-kix_list_1-4>li {
    counter-increment: lst-ctn-kix_list_1-4
  }

  .lst-kix_list_24-8>li:before {
    content: ""counter(lst-ctn-kix_list_24-8, lower-roman) ". "
  }

  ol.lst-kix_list_16-7 {
    list-style-type: none
  }

  ol.lst-kix_list_16-8 {
    list-style-type: none
  }

  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0
  }

  ol.lst-kix_list_16-1 {
    list-style-type: none
  }

  ol.lst-kix_list_9-5.start {
    counter-reset: lst-ctn-kix_list_9-5 0
  }

  ol.lst-kix_list_16-2 {
    list-style-type: none
  }

  ol.lst-kix_list_16-3 {
    list-style-type: none
  }

  ol.lst-kix_list_16-4 {
    list-style-type: none
  }

  .lst-kix_list_24-2>li:before {
    content: ""counter(lst-ctn-kix_list_24-2, lower-roman) ". "
  }

  ol.lst-kix_list_16-0 {
    list-style-type: none
  }

  ol.lst-kix_list_20-7.start {
    counter-reset: lst-ctn-kix_list_20-7 0
  }

  .lst-kix_list_24-3>li:before {
    content: ""counter(lst-ctn-kix_list_24-3, decimal) ". "
  }

  .lst-kix_list_24-4>li:before {
    content: ""counter(lst-ctn-kix_list_24-4, lower-latin) ". "
  }

  .lst-kix_list_9-4>li {
    counter-increment: lst-ctn-kix_list_9-4
  }

  .lst-kix_list_24-5>li:before {
    content: ""counter(lst-ctn-kix_list_24-5, lower-roman) ". "
  }

  .lst-kix_list_24-6>li:before {
    content: ""counter(lst-ctn-kix_list_24-6, decimal) ". "
  }

  ol.lst-kix_list_16-4.start {
    counter-reset: lst-ctn-kix_list_16-4 0
  }

  ol.lst-kix_list_14-1.start {
    counter-reset: lst-ctn-kix_list_14-1 0
  }

  .lst-kix_list_23-6>li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_6-5>li {
    counter-increment: lst-ctn-kix_list_6-5
  }

  ol.lst-kix_list_27-6 {
    list-style-type: none
  }

  ol.lst-kix_list_27-5 {
    list-style-type: none
  }

  .lst-kix_list_23-3>li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_23-7>li:before {
    content: "o  "
  }

  ol.lst-kix_list_27-8 {
    list-style-type: none
  }

  ol.lst-kix_list_27-7 {
    list-style-type: none
  }

  .lst-kix_list_23-2>li:before {
    content: "\0025aa  "
  }

  ol.lst-kix_list_27-2 {
    list-style-type: none
  }

  ol.lst-kix_list_27-1 {
    list-style-type: none
  }

  ol.lst-kix_list_27-4 {
    list-style-type: none
  }

  ol.lst-kix_list_27-3 {
    list-style-type: none
  }

  .lst-kix_list_23-0>li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_23-8>li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_3-6>li {
    counter-increment: lst-ctn-kix_list_3-6
  }

  .lst-kix_list_23-1>li:before {
    content: "o  "
  }

  ol.lst-kix_list_27-0 {
    list-style-type: none
  }

  .lst-kix_list_24-1>li:before {
    content: ""counter(lst-ctn-kix_list_24-1, lower-latin) ". "
  }

  .lst-kix_list_2-8>li {
    counter-increment: lst-ctn-kix_list_2-8
  }

  .lst-kix_list_24-0>li:before {
    content: ""counter(lst-ctn-kix_list_24-0, decimal) ". "
  }

  ol.lst-kix_list_26-2.start {
    counter-reset: lst-ctn-kix_list_26-2 0
  }

  .lst-kix_list_23-4>li:before {
    content: "o  "
  }

  .lst-kix_list_23-5>li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_22-2>li:before {
    content: " "
  }

  .lst-kix_list_22-6>li:before {
    content: " "
  }

  ol.lst-kix_list_31-2.start {
    counter-reset: lst-ctn-kix_list_31-2 -1
  }

  .lst-kix_list_8-6>li {
    counter-increment: lst-ctn-kix_list_8-6
  }

  .lst-kix_list_22-0>li:before {
    content: "Schedule "counter(lst-ctn-kix_list_22-0, decimal) " "
  }

  .lst-kix_list_22-8>li:before {
    content: " "
  }

  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0
  }

  ol.lst-kix_list_9-7 {
    list-style-type: none
  }

  ol.lst-kix_list_9-8 {
    list-style-type: none
  }

  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0
  }

  ol.lst-kix_list_9-3 {
    list-style-type: none
  }

  ol.lst-kix_list_9-4 {
    list-style-type: none
  }

  .lst-kix_list_5-7>li {
    counter-increment: lst-ctn-kix_list_5-7
  }

  ol.lst-kix_list_9-5 {
    list-style-type: none
  }

  ol.lst-kix_list_9-6 {
    list-style-type: none
  }

  ol.lst-kix_list_9-0 {
    list-style-type: none
  }

  ol.lst-kix_list_9-1 {
    list-style-type: none
  }

  .lst-kix_list_22-4>li:before {
    content: " "
  }

  ol.lst-kix_list_9-2 {
    list-style-type: none
  }

  ol.lst-kix_list_32-8.start {
    counter-reset: lst-ctn-kix_list_32-8 0
  }

  ol.lst-kix_list_25-5.start {
    counter-reset: lst-ctn-kix_list_25-5 0
  }

  ol.lst-kix_list_29-2.start {
    counter-reset: lst-ctn-kix_list_29-2 0
  }

  .lst-kix_list_25-5>li:before {
    content: " "
  }

  .lst-kix_list_25-7>li:before {
    content: " "
  }

  .lst-kix_list_3-5>li {
    counter-increment: lst-ctn-kix_list_3-5
  }

  ol.lst-kix_list_18-3.start {
    counter-reset: lst-ctn-kix_list_18-3 0
  }

  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0
  }

  ol.lst-kix_list_24-4.start {
    counter-reset: lst-ctn-kix_list_24-4 0
  }

  ol.lst-kix_list_18-7 {
    list-style-type: none
  }

  ol.lst-kix_list_18-8 {
    list-style-type: none
  }

  ol.lst-kix_list_18-3 {
    list-style-type: none
  }

  ol.lst-kix_list_18-4 {
    list-style-type: none
  }

  ol.lst-kix_list_18-5 {
    list-style-type: none
  }

  .lst-kix_list_6-4>li {
    counter-increment: lst-ctn-kix_list_6-4
  }

  ol.lst-kix_list_18-6 {
    list-style-type: none
  }

  ol.lst-kix_list_18-0 {
    list-style-type: none
  }

  ol.lst-kix_list_27-4.start {
    counter-reset: lst-ctn-kix_list_27-4 0
  }

  ol.lst-kix_list_18-1 {
    list-style-type: none
  }

  ol.lst-kix_list_18-2 {
    list-style-type: none
  }

  .lst-kix_list_9-3>li {
    counter-increment: lst-ctn-kix_list_9-3
  }

  ol.lst-kix_list_15-2.start {
    counter-reset: lst-ctn-kix_list_15-2 0
  }

  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0
  }

  ol.lst-kix_list_7-6.start {
    counter-reset: lst-ctn-kix_list_7-6 0
  }

  ol.lst-kix_list_29-8 {
    list-style-type: none
  }

  ol.lst-kix_list_29-7 {
    list-style-type: none
  }

  ol.lst-kix_list_15-3.start {
    counter-reset: lst-ctn-kix_list_15-3 0
  }

  ol.lst-kix_list_29-4 {
    list-style-type: none
  }

  ol.lst-kix_list_29-3 {
    list-style-type: none
  }

  ol.lst-kix_list_29-6 {
    list-style-type: none
  }

  ol.lst-kix_list_29-5 {
    list-style-type: none
  }

  ol.lst-kix_list_29-0 {
    list-style-type: none
  }

  ol.lst-kix_list_5-8.start {
    counter-reset: lst-ctn-kix_list_5-8 0
  }

  ol.lst-kix_list_29-2 {
    list-style-type: none
  }

  ol.lst-kix_list_29-1 {
    list-style-type: none
  }

  .lst-kix_list_1-3>li {
    counter-increment: lst-ctn-kix_list_1-3
  }

  ol.lst-kix_list_12-2.start {
    counter-reset: lst-ctn-kix_list_12-2 0
  }

  ol.lst-kix_list_6-0.start {
    counter-reset: lst-ctn-kix_list_6-0 0
  }

  .lst-kix_list_4-2>li {
    counter-increment: lst-ctn-kix_list_4-2
  }

  ol.lst-kix_list_3-1 {
    list-style-type: none
  }

  .lst-kix_list_24-8>li {
    counter-increment: lst-ctn-kix_list_24-8
  }

  ol.lst-kix_list_3-2 {
    list-style-type: none
  }

  ol.lst-kix_list_3-3 {
    list-style-type: none
  }

  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0
  }

  .lst-kix_list_5-1>li {
    counter-increment: lst-ctn-kix_list_5-1
  }

  ol.lst-kix_list_3-4 {
    list-style-type: none
  }

  ol.lst-kix_list_21-3.start {
    counter-reset: lst-ctn-kix_list_21-3 0
  }

  ol.lst-kix_list_3-0 {
    list-style-type: none
  }

  .lst-kix_list_7-1>li {
    counter-increment: lst-ctn-kix_list_7-1
  }

  ol.lst-kix_list_25-6.start {
    counter-reset: lst-ctn-kix_list_25-6 0
  }

  .lst-kix_list_21-8>li:before {
    content: ""counter(lst-ctn-kix_list_21-8, lower-roman) ". "
  }

  ol.lst-kix_list_32-4.start {
    counter-reset: lst-ctn-kix_list_32-4 0
  }

  .lst-kix_list_16-0>li {
    counter-increment: lst-ctn-kix_list_16-0
  }

  .lst-kix_list_26-5>li:before {
    content: ""counter(lst-ctn-kix_list_26-5, lower-roman) ". "
  }

  .lst-kix_list_8-0>li {
    counter-increment: lst-ctn-kix_list_8-0
  }

  .lst-kix_list_10-0>li {
    counter-increment: lst-ctn-kix_list_10-0
  }

  ol.lst-kix_list_18-2.start {
    counter-reset: lst-ctn-kix_list_18-2 0
  }

  ol.lst-kix_list_3-5 {
    list-style-type: none
  }

  ol.lst-kix_list_3-6 {
    list-style-type: none
  }

  .lst-kix_list_26-8>li:before {
    content: ""counter(lst-ctn-kix_list_26-8, lower-roman) ". "
  }

  ol.lst-kix_list_3-7 {
    list-style-type: none
  }

  ol.lst-kix_list_3-8 {
    list-style-type: none
  }

  .lst-kix_list_21-0>li:before {
    content: "Schedule "
  }

  .lst-kix_list_26-1>li:before {
    content: ""counter(lst-ctn-kix_list_26-1, lower-latin) ". "
  }

  .lst-kix_list_13-1>li {
    counter-increment: lst-ctn-kix_list_13-1
  }

  .lst-kix_list_21-1>li:before {
    content: ""counter(lst-ctn-kix_list_21-1, lower-latin) ". "
  }

  ol.lst-kix_list_15-8.start {
    counter-reset: lst-ctn-kix_list_15-8 0
  }

  .lst-kix_list_26-4>li:before {
    content: ""counter(lst-ctn-kix_list_26-4, lower-latin) ". "
  }

  .lst-kix_list_10-2>li {
    counter-increment: lst-ctn-kix_list_10-2
  }

  .lst-kix_list_21-5>li:before {
    content: ""counter(lst-ctn-kix_list_21-5, lower-roman) ". "
  }

  .lst-kix_list_21-4>li:before {
    content: ""counter(lst-ctn-kix_list_21-4, lower-latin) ". "
  }

  .lst-kix_list_26-0>li:before {
    content: "SCHEDULE "counter(lst-ctn-kix_list_26-0, decimal) " "
  }

  .lst-kix_list_31-4>li {
    counter-increment: lst-ctn-kix_list_31-4
  }

  ul.lst-kix_list_23-0 {
    list-style-type: none
  }

  ul.lst-kix_list_23-1 {
    list-style-type: none
  }

  ul.lst-kix_list_23-2 {
    list-style-type: none
  }

  ol.lst-kix_list_7-2.start {
    counter-reset: lst-ctn-kix_list_7-2 0
  }

  ul.lst-kix_list_23-3 {
    list-style-type: none
  }

  ul.lst-kix_list_23-4 {
    list-style-type: none
  }

  ul.lst-kix_list_23-5 {
    list-style-type: none
  }

  .lst-kix_list_31-2>li {
    counter-increment: lst-ctn-kix_list_31-2
  }

  ul.lst-kix_list_23-6 {
    list-style-type: none
  }

  ol.lst-kix_list_12-5 {
    list-style-type: none
  }

  ol.lst-kix_list_12-6 {
    list-style-type: none
  }

  ol.lst-kix_list_12-7 {
    list-style-type: none
  }

  ol.lst-kix_list_12-8 {
    list-style-type: none
  }

  ol.lst-kix_list_12-1 {
    list-style-type: none
  }

  ol.lst-kix_list_12-2 {
    list-style-type: none
  }

  ol.lst-kix_list_12-3 {
    list-style-type: none
  }

  ol.lst-kix_list_12-4 {
    list-style-type: none
  }

  ol.lst-kix_list_26-3.start {
    counter-reset: lst-ctn-kix_list_26-3 0
  }

  ol.lst-kix_list_12-0 {
    list-style-type: none
  }

  ol.lst-kix_list_27-8.start {
    counter-reset: lst-ctn-kix_list_27-8 0
  }

  .lst-kix_list_21-0>li {
    counter-increment: lst-ctn-kix_list_21-0
  }

  .lst-kix_list_25-1>li:before {
    content: " "
  }

  ul.lst-kix_list_23-7 {
    list-style-type: none
  }

  .lst-kix_list_25-0>li:before {
    content: "Chapter "counter(lst-ctn-kix_list_25-0, decimal) " "
  }

  ul.lst-kix_list_23-8 {
    list-style-type: none
  }

  ol.lst-kix_list_10-8.start {
    counter-reset: lst-ctn-kix_list_10-8 0
  }

  ol.lst-kix_list_7-1.start {
    counter-reset: lst-ctn-kix_list_7-1 0
  }

  .lst-kix_list_16-7>li {
    counter-increment: lst-ctn-kix_list_16-7
  }

  ol.lst-kix_list_21-4.start {
    counter-reset: lst-ctn-kix_list_21-4 0
  }

  .lst-kix_list_27-7>li {
    counter-increment: lst-ctn-kix_list_27-7
  }

  ol.lst-kix_list_20-6.start {
    counter-reset: lst-ctn-kix_list_20-6 0
  }

  .lst-kix_list_13-8>li {
    counter-increment: lst-ctn-kix_list_13-8
  }

  .lst-kix_list_2-2>li {
    counter-increment: lst-ctn-kix_list_2-2
  }

  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0
  }

  .lst-kix_list_16-5>li {
    counter-increment: lst-ctn-kix_list_16-5
  }

  ol.lst-kix_list_5-0 {
    list-style-type: none
  }

  .lst-kix_list_3-7>li {
    counter-increment: lst-ctn-kix_list_3-7
  }

  ol.lst-kix_list_5-1 {
    list-style-type: none
  }

  .lst-kix_list_26-2>li {
    counter-increment: lst-ctn-kix_list_26-2
  }

  .lst-kix_list_22-5>li:before {
    content: " "
  }

  ol.lst-kix_list_5-2 {
    list-style-type: none
  }

  .lst-kix_list_21-2>li {
    counter-increment: lst-ctn-kix_list_21-2
  }

  .lst-kix_list_22-1>li:before {
    content: " "
  }

  .lst-kix_list_20-2>li {
    counter-increment: lst-ctn-kix_list_20-2
  }

  .lst-kix_list_27-4>li:before {
    content: ""counter(lst-ctn-kix_list_27-4, lower-latin) ". "
  }

  ol.lst-kix_list_15-7.start {
    counter-reset: lst-ctn-kix_list_15-7 0
  }

  .lst-kix_list_6-6>li {
    counter-increment: lst-ctn-kix_list_6-6
  }

  .lst-kix_list_13-6>li {
    counter-increment: lst-ctn-kix_list_13-6
  }

  ol.lst-kix_list_14-6.start {
    counter-reset: lst-ctn-kix_list_14-6 0
  }

  ol.lst-kix_list_5-7 {
    list-style-type: none
  }

  ol.lst-kix_list_5-8 {
    list-style-type: none
  }

  ol.lst-kix_list_26-8.start {
    counter-reset: lst-ctn-kix_list_26-8 0
  }

  ol.lst-kix_list_5-3 {
    list-style-type: none
  }

  .lst-kix_list_8-7>li {
    counter-increment: lst-ctn-kix_list_8-7
  }

  ol.lst-kix_list_5-4 {
    list-style-type: none
  }

  .lst-kix_list_27-0>li:before {
    content: "ANNEX "counter(lst-ctn-kix_list_27-0, upper-latin) " "
  }

  ol.lst-kix_list_5-5 {
    list-style-type: none
  }

  ol.lst-kix_list_5-6 {
    list-style-type: none
  }

  .lst-kix_list_20-4>li {
    counter-increment: lst-ctn-kix_list_20-4
  }

  .lst-kix_list_9-5>li {
    counter-increment: lst-ctn-kix_list_9-5
  }

  .lst-kix_list_5-8>li {
    counter-increment: lst-ctn-kix_list_5-8
  }

  .lst-kix_list_27-0>li {
    counter-increment: lst-ctn-kix_list_27-0
  }

  .lst-kix_list_32-0>li {
    counter-increment: lst-ctn-kix_list_32-0
  }

  ol.lst-kix_list_20-1.start {
    counter-reset: lst-ctn-kix_list_20-1 0
  }

  .lst-kix_list_25-4>li:before {
    content: " "
  }

  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0
  }

  .lst-kix_list_24-1>li {
    counter-increment: lst-ctn-kix_list_24-1
  }

  .lst-kix_list_25-8>li:before {
    content: " "
  }

  ol.lst-kix_list_25-1.start {
    counter-reset: lst-ctn-kix_list_25-1 0
  }

  ol.lst-kix_list_14-7 {
    list-style-type: none
  }

  ol.lst-kix_list_14-8 {
    list-style-type: none
  }

  ol.lst-kix_list_26-6.start {
    counter-reset: lst-ctn-kix_list_26-6 0
  }

  ol.lst-kix_list_14-3 {
    list-style-type: none
  }

  ol.lst-kix_list_21-8.start {
    counter-reset: lst-ctn-kix_list_21-8 0
  }

  ol.lst-kix_list_14-4 {
    list-style-type: none
  }

  ol.lst-kix_list_14-5 {
    list-style-type: none
  }

  ol.lst-kix_list_14-6 {
    list-style-type: none
  }

  .lst-kix_list_15-2>li {
    counter-increment: lst-ctn-kix_list_15-2
  }

  ol.lst-kix_list_14-0 {
    list-style-type: none
  }

  ol.lst-kix_list_14-1 {
    list-style-type: none
  }

  .lst-kix_list_27-5>li {
    counter-increment: lst-ctn-kix_list_27-5
  }

  ol.lst-kix_list_14-2 {
    list-style-type: none
  }

  .lst-kix_list_20-5>li:before {
    content: "("counter(lst-ctn-kix_list_20-5, lower-roman) ") "
  }

  .lst-kix_list_28-8>li:before {
    content: ""counter(lst-ctn-kix_list_28-8, lower-roman) ". "
  }

  .lst-kix_list_20-1>li:before {
    content: "("counter(lst-ctn-kix_list_20-1, lower-latin) ") "
  }

  .lst-kix_list_12-3>li {
    counter-increment: lst-ctn-kix_list_12-3
  }

  .lst-kix_list_24-6>li {
    counter-increment: lst-ctn-kix_list_24-6
  }

  .lst-kix_list_17-3>li {
    counter-increment: lst-ctn-kix_list_17-3
  }

  ol.lst-kix_list_8-4.start {
    counter-reset: lst-ctn-kix_list_8-4 0
  }

  .lst-kix_list_28-4>li:before {
    content: ""counter(lst-ctn-kix_list_28-4, lower-latin) ". "
  }

  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0
  }

  .lst-kix_list_21-7>li {
    counter-increment: lst-ctn-kix_list_21-7
  }

  ol.lst-kix_list_25-0.start {
    counter-reset: lst-ctn-kix_list_25-0 0
  }

  ol.lst-kix_list_25-8 {
    list-style-type: none
  }

  .lst-kix_list_28-3>li {
    counter-increment: lst-ctn-kix_list_28-3
  }

  ol.lst-kix_list_25-7 {
    list-style-type: none
  }

  ol.lst-kix_list_25-4 {
    list-style-type: none
  }

  ol.lst-kix_list_25-3 {
    list-style-type: none
  }

  ol.lst-kix_list_13-0.start {
    counter-reset: lst-ctn-kix_list_13-0 0
  }

  .lst-kix_list_14-4>li {
    counter-increment: lst-ctn-kix_list_14-4
  }

  ol.lst-kix_list_25-6 {
    list-style-type: none
  }

  ol.lst-kix_list_25-5 {
    list-style-type: none
  }

  ol.lst-kix_list_32-3.start {
    counter-reset: lst-ctn-kix_list_32-3 0
  }

  ol.lst-kix_list_25-0 {
    list-style-type: none
  }

  ol.lst-kix_list_25-2 {
    list-style-type: none
  }

  .lst-kix_list_10-7>li {
    counter-increment: lst-ctn-kix_list_10-7
  }

  ol.lst-kix_list_25-1 {
    list-style-type: none
  }

  .lst-kix_list_25-4>li {
    counter-increment: lst-ctn-kix_list_25-4
  }

  .lst-kix_list_18-1>li {
    counter-increment: lst-ctn-kix_list_18-1
  }

  ol.lst-kix_list_8-3.start {
    counter-reset: lst-ctn-kix_list_8-3 0
  }

  .lst-kix_list_11-5>li {
    counter-increment: lst-ctn-kix_list_11-5
  }

  ol.lst-kix_list_14-5.start {
    counter-reset: lst-ctn-kix_list_14-5 0
  }

  .lst-kix_list_28-0>li:before {
    content: "PART "counter(lst-ctn-kix_list_28-0, decimal) " "
  }

  .lst-kix_list_32-7>li {
    counter-increment: lst-ctn-kix_list_32-7
  }

  .lst-kix_list_27-8>li:before {
    content: ""counter(lst-ctn-kix_list_27-8, lower-roman) ". "
  }

  ol.lst-kix_list_20-3.start {
    counter-reset: lst-ctn-kix_list_20-3 0
  }

  .lst-kix_list_29-1>li {
    counter-increment: lst-ctn-kix_list_29-1
  }

  .lst-kix_list_4-1>li {
    counter-increment: lst-ctn-kix_list_4-1
  }

  ol.lst-kix_list_32-7.start {
    counter-reset: lst-ctn-kix_list_32-7 0
  }

  .lst-kix_list_19-1>li:before {
    content: "o  "
  }

  .lst-kix_list_19-4>li:before {
    content: "o  "
  }

  .lst-kix_list_27-8>li {
    counter-increment: lst-ctn-kix_list_27-8
  }

  .lst-kix_list_31-5>li {
    counter-increment: lst-ctn-kix_list_31-5
  }

  .lst-kix_list_19-3>li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_15-0>li {
    counter-increment: lst-ctn-kix_list_15-0
  }

  ol.lst-kix_list_24-5.start {
    counter-reset: lst-ctn-kix_list_24-5 0
  }

  ol.lst-kix_list_6-6.start {
    counter-reset: lst-ctn-kix_list_6-6 0
  }

  .lst-kix_list_32-6>li {
    counter-increment: lst-ctn-kix_list_32-6
  }

  ol.lst-kix_list_29-6.start {
    counter-reset: lst-ctn-kix_list_29-6 0
  }

  .lst-kix_list_11-0>li {
    counter-increment: lst-ctn-kix_list_11-0
  }

  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0
  }

  ol.lst-kix_list_9-6.start {
    counter-reset: lst-ctn-kix_list_9-6 0
  }

  ol.lst-kix_list_16-3.start {
    counter-reset: lst-ctn-kix_list_16-3 0
  }

  ol.lst-kix_list_27-5.start {
    counter-reset: lst-ctn-kix_list_27-5 0
  }

  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0
  }

  .lst-kix_list_26-7>li {
    counter-increment: lst-ctn-kix_list_26-7
  }

  ol.lst-kix_list_11-2.start {
    counter-reset: lst-ctn-kix_list_11-2 0
  }

  .lst-kix_list_5-2>li {
    counter-increment: lst-ctn-kix_list_5-2
  }

  .lst-kix_list_19-6>li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_17-2>li {
    counter-increment: lst-ctn-kix_list_17-2
  }

  ol.lst-kix_list_8-7.start {
    counter-reset: lst-ctn-kix_list_8-7 0
  }

  .lst-kix_list_32-2>li {
    counter-increment: lst-ctn-kix_list_32-2
  }

  .lst-kix_list_28-5>li {
    counter-increment: lst-ctn-kix_list_28-5
  }

  ol.lst-kix_list_31-8 {
    list-style-type: none
  }

  .lst-kix_list_20-5>li {
    counter-increment: lst-ctn-kix_list_20-5
  }

  ol.lst-kix_list_31-5 {
    list-style-type: none
  }

  ol.lst-kix_list_31-4 {
    list-style-type: none
  }

  ol.lst-kix_list_31-7 {
    list-style-type: none
  }

  ol.lst-kix_list_31-6 {
    list-style-type: none
  }

  ol.lst-kix_list_31-3 {
    list-style-type: none
  }

  ol.lst-kix_list_31-2 {
    list-style-type: none
  }

  .lst-kix_list_22-0>li {
    counter-increment: lst-ctn-kix_list_22-0
  }

  ol.lst-kix_list_24-0.start {
    counter-reset: lst-ctn-kix_list_24-0 0
  }

  .lst-kix_list_18-3>li {
    counter-increment: lst-ctn-kix_list_18-3
  }

  .lst-kix_list_21-6>li {
    counter-increment: lst-ctn-kix_list_21-6
  }

  .lst-kix_list_10-3>li {
    counter-increment: lst-ctn-kix_list_10-3
  }

  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0
  }

  .lst-kix_list_18-0>li:before {
    content: "("counter(lst-ctn-kix_list_18-0, upper-latin) ") "
  }

  ol.lst-kix_list_13-3.start {
    counter-reset: lst-ctn-kix_list_13-3 0
  }

  .lst-kix_list_3-0>li {
    counter-increment: lst-ctn-kix_list_3-0
  }

  .lst-kix_list_18-2>li:before {
    content: "("counter(lst-ctn-kix_list_18-2, lower-latin) ") "
  }

  .lst-kix_list_26-0>li {
    counter-increment: lst-ctn-kix_list_26-0
  }

  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0
  }

  ol.lst-kix_list_27-0.start {
    counter-reset: lst-ctn-kix_list_27-0 0
  }

  .lst-kix_list_27-4>li {
    counter-increment: lst-ctn-kix_list_27-4
  }

  ol.lst-kix_list_11-7.start {
    counter-reset: lst-ctn-kix_list_11-7 0
  }

  ol.lst-kix_list_14-2.start {
    counter-reset: lst-ctn-kix_list_14-2 0
  }

  .lst-kix_list_16-1>li {
    counter-increment: lst-ctn-kix_list_16-1
  }

  .lst-kix_list_30-4>li {
    counter-increment: lst-ctn-kix_list_30-4
  }

  .lst-kix_list_27-1>li {
    counter-increment: lst-ctn-kix_list_27-1
  }

  .lst-kix_list_25-2>li {
    counter-increment: lst-ctn-kix_list_25-2
  }

  .lst-kix_list_27-1>li:before {
    content: ""counter(lst-ctn-kix_list_27-1, lower-latin) ". "
  }

  .lst-kix_list_27-3>li:before {
    content: ""counter(lst-ctn-kix_list_27-3, decimal) ". "
  }

  .lst-kix_list_17-5>li {
    counter-increment: lst-ctn-kix_list_17-5
  }

  ol.lst-kix_list_9-4.start {
    counter-reset: lst-ctn-kix_list_9-4 0
  }

  .lst-kix_list_21-3>li {
    counter-increment: lst-ctn-kix_list_21-3
  }

  .lst-kix_list_18-8>li:before {
    content: ""counter(lst-ctn-kix_list_18-8, decimal) ". "
  }

  ol.lst-kix_list_27-3.start {
    counter-reset: lst-ctn-kix_list_27-3 0
  }

  .lst-kix_list_17-6>li {
    counter-increment: lst-ctn-kix_list_17-6
  }

  ol.lst-kix_list_30-1.start {
    counter-reset: lst-ctn-kix_list_30-1 0
  }

  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0
  }

  .lst-kix_list_10-7>li:before {
    content: ""counter(lst-ctn-kix_list_10-7, lower-latin) ". "
  }

  .lst-kix_list_7-8>li {
    counter-increment: lst-ctn-kix_list_7-8
  }

  .lst-kix_list_20-1>li {
    counter-increment: lst-ctn-kix_list_20-1
  }

  .lst-kix_list_10-5>li:before {
    content: ""counter(lst-ctn-kix_list_10-5, lower-roman) ". "
  }

  ol.lst-kix_list_13-5.start {
    counter-reset: lst-ctn-kix_list_13-5 0
  }

  ol.lst-kix_list_18-6.start {
    counter-reset: lst-ctn-kix_list_18-6 0
  }

  .lst-kix_list_29-3>li {
    counter-increment: lst-ctn-kix_list_29-3
  }

  ol.lst-kix_list_20-8 {
    list-style-type: none
  }

  ol.lst-kix_list_13-8.start {
    counter-reset: lst-ctn-kix_list_13-8 0
  }

  ol.lst-kix_list_20-5 {
    list-style-type: none
  }

  ol.lst-kix_list_20-4 {
    list-style-type: none
  }

  ol.lst-kix_list_20-7 {
    list-style-type: none
  }

  .lst-kix_list_11-7>li {
    counter-increment: lst-ctn-kix_list_11-7
  }

  ol.lst-kix_list_20-6 {
    list-style-type: none
  }

  ol.lst-kix_list_20-1 {
    list-style-type: none
  }

  .lst-kix_list_9-2>li:before {
    content: ""counter(lst-ctn-kix_list_9-2, lower-roman) ". "
  }

  ol.lst-kix_list_20-0 {
    list-style-type: none
  }

  ol.lst-kix_list_20-3 {
    list-style-type: none
  }

  ol.lst-kix_list_20-2 {
    list-style-type: none
  }

  ol.lst-kix_list_14-0.start {
    counter-reset: lst-ctn-kix_list_14-0 0
  }

  .lst-kix_list_12-5>li {
    counter-increment: lst-ctn-kix_list_12-5
  }

  .lst-kix_list_5-5>li {
    counter-increment: lst-ctn-kix_list_5-5
  }

  .lst-kix_list_9-0>li:before {
    content: ""counter(lst-ctn-kix_list_9-0, lower-latin) ". "
  }

  .lst-kix_list_16-8>li {
    counter-increment: lst-ctn-kix_list_16-8
  }

  ol.lst-kix_list_24-3.start {
    counter-reset: lst-ctn-kix_list_24-3 0
  }

  .lst-kix_list_11-3>li:before {
    content: ""counter(lst-ctn-kix_list_11-3, decimal) ". "
  }

  ol.lst-kix_list_18-4.start {
    counter-reset: lst-ctn-kix_list_18-4 0
  }

  .lst-kix_list_6-3>li {
    counter-increment: lst-ctn-kix_list_6-3
  }

  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0
  }

  ol.lst-kix_list_29-1.start {
    counter-reset: lst-ctn-kix_list_29-1 0
  }

  .lst-kix_list_20-4>li:before {
    content: "("counter(lst-ctn-kix_list_20-4, lower-latin) ") "
  }

  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0
  }

  .lst-kix_list_29-1>li:before {
    content: ""counter(lst-ctn-kix_list_29-0, decimal) "."counter(lst-ctn-kix_list_29-1, decimal) " "
  }

  .lst-kix_list_20-2>li:before {
    content: ""counter(lst-ctn-kix_list_20-2, lower-roman) ") "
  }

  ol.lst-kix_list_6-1.start {
    counter-reset: lst-ctn-kix_list_6-1 0
  }

  .lst-kix_list_29-3>li:before {
    content: "("counter(lst-ctn-kix_list_29-3, lower-roman) ") "
  }

  .lst-kix_list_9-8>li:before {
    content: ""counter(lst-ctn-kix_list_9-8, lower-roman) ". "
  }

  ol.lst-kix_list_16-8.start {
    counter-reset: lst-ctn-kix_list_16-8 0
  }

  .lst-kix_list_28-6>li {
    counter-increment: lst-ctn-kix_list_28-6
  }

  .lst-kix_list_28-7>li:before {
    content: ""counter(lst-ctn-kix_list_28-7, lower-latin) ". "
  }

  .lst-kix_list_4-8>li {
    counter-increment: lst-ctn-kix_list_4-8
  }

  .lst-kix_list_1-7>li:before {
    content: " "
  }

  .lst-kix_list_1-5>li:before {
    content: " "
  }

  .lst-kix_list_28-5>li:before {
    content: ""counter(lst-ctn-kix_list_28-5, lower-roman) ". "
  }

  ol.lst-kix_list_24-2.start {
    counter-reset: lst-ctn-kix_list_24-2 0
  }

  ol.lst-kix_list_9-1.start {
    counter-reset: lst-ctn-kix_list_9-1 0
  }

  .lst-kix_list_5-6>li {
    counter-increment: lst-ctn-kix_list_5-6
  }

  .lst-kix_list_2-1>li:before {
    content: ""counter(lst-ctn-kix_list_2-1, lower-latin) ". "
  }

  .lst-kix_list_2-3>li:before {
    content: ""counter(lst-ctn-kix_list_2-3, decimal) ". "
  }

  .lst-kix_list_11-8>li {
    counter-increment: lst-ctn-kix_list_11-8
  }

  ol.lst-kix_list_24-8.start {
    counter-reset: lst-ctn-kix_list_24-8 0
  }

  .lst-kix_list_30-4>li:before {
    content: ""counter(lst-ctn-kix_list_30-4, lower-latin) ". "
  }

  .lst-kix_list_20-8>li {
    counter-increment: lst-ctn-kix_list_20-8
  }

  .lst-kix_list_9-1>li {
    counter-increment: lst-ctn-kix_list_9-1
  }

  .lst-kix_list_3-2>li:before {
    content: "("counter(lst-ctn-kix_list_3-2, lower-latin) ") "
  }

  .lst-kix_list_26-7>li:before {
    content: ""counter(lst-ctn-kix_list_26-7, lower-latin) ". "
  }

  .lst-kix_list_8-1>li:before {
    content: "["counter(lst-ctn-kix_list_8-0, decimal) "."counter(lst-ctn-kix_list_8-1, decimal) " "
  }

  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0
  }

  .lst-kix_list_6-0>li {
    counter-increment: lst-ctn-kix_list_6-0
  }

  .lst-kix_list_3-5>li:before {
    content: " "
  }

  .lst-kix_list_18-0>li {
    counter-increment: lst-ctn-kix_list_18-0
  }

  .lst-kix_list_30-7>li:before {
    content: ""counter(lst-ctn-kix_list_30-7, lower-latin) ". "
  }

  ol.lst-kix_list_30-8.start {
    counter-reset: lst-ctn-kix_list_30-8 0
  }

  ol.lst-kix_list_11-5.start {
    counter-reset: lst-ctn-kix_list_11-5 0
  }

  .lst-kix_list_11-1>li {
    counter-increment: lst-ctn-kix_list_11-1
  }

  .lst-kix_list_8-6>li:before {
    content: " "
  }

  .lst-kix_list_26-2>li:before {
    content: ""counter(lst-ctn-kix_list_26-2, lower-roman) ". "
  }

  .lst-kix_list_21-6>li:before {
    content: ""counter(lst-ctn-kix_list_21-6, decimal) ". "
  }

  ol.lst-kix_list_16-6.start {
    counter-reset: lst-ctn-kix_list_16-6 0
  }

  ol.lst-kix_list_16-0.start {
    counter-reset: lst-ctn-kix_list_16-0 0
  }

  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0
  }

  .lst-kix_list_21-3>li:before {
    content: ""counter(lst-ctn-kix_list_21-3, decimal) ". "
  }

  ol.lst-kix_list_27-2.start {
    counter-reset: lst-ctn-kix_list_27-2 0
  }

  ol.lst-kix_list_18-7.start {
    counter-reset: lst-ctn-kix_list_18-7 0
  }

  .lst-kix_list_25-5>li {
    counter-increment: lst-ctn-kix_list_25-5
  }

  ol.lst-kix_list_30-2.start {
    counter-reset: lst-ctn-kix_list_30-2 0
  }

  ol.lst-kix_list_11-6.start {
    counter-reset: lst-ctn-kix_list_11-6 0
  }

  .lst-kix_list_4-4>li {
    counter-increment: lst-ctn-kix_list_4-4
  }

  ol.lst-kix_list_29-4.start {
    counter-reset: lst-ctn-kix_list_29-4 0
  }

  ol.lst-kix_list_6-4.start {
    counter-reset: lst-ctn-kix_list_6-4 0
  }

  .lst-kix_list_17-1>li:before {
    content: ""counter(lst-ctn-kix_list_17-1, lower-latin) ". "
  }

  .lst-kix_list_25-3>li:before {
    content: " "
  }

  .lst-kix_list_31-8>li {
    counter-increment: lst-ctn-kix_list_31-8
  }

  .lst-kix_list_32-3>li {
    counter-increment: lst-ctn-kix_list_32-3
  }

  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0
  }

  .lst-kix_list_16-2>li:before {
    content: ""counter(lst-ctn-kix_list_16-2, lower-roman) ". "
  }

  ol.lst-kix_list_27-1.start {
    counter-reset: lst-ctn-kix_list_27-1 0
  }

  .lst-kix_list_26-6>li {
    counter-increment: lst-ctn-kix_list_26-6
  }

  .lst-kix_list_16-5>li:before {
    content: ""counter(lst-ctn-kix_list_16-5, lower-roman) ". "
  }

  .lst-kix_list_15-3>li {
    counter-increment: lst-ctn-kix_list_15-3
  }

  ol.lst-kix_list_30-3.start {
    counter-reset: lst-ctn-kix_list_30-3 0
  }

  .lst-kix_list_30-7>li {
    counter-increment: lst-ctn-kix_list_30-7
  }

  ol.lst-kix_list_11-0.start {
    counter-reset: lst-ctn-kix_list_11-0 0
  }

  ol.lst-kix_list_18-8.start {
    counter-reset: lst-ctn-kix_list_18-8 0
  }

  ol.lst-kix_list_29-3.start {
    counter-reset: lst-ctn-kix_list_29-3 0
  }

  .lst-kix_list_16-4>li {
    counter-increment: lst-ctn-kix_list_16-4
  }

  .lst-kix_list_3-3>li {
    counter-increment: lst-ctn-kix_list_3-3
  }

  ol.lst-kix_list_6-3.start {
    counter-reset: lst-ctn-kix_list_6-3 0
  }

  ol.lst-kix_list_16-5.start {
    counter-reset: lst-ctn-kix_list_16-5 0
  }

  .lst-kix_list_17-6>li:before {
    content: ""counter(lst-ctn-kix_list_17-6, decimal) ". "
  }

  ol.lst-kix_list_16-2.start {
    counter-reset: lst-ctn-kix_list_16-2 0
  }

  .lst-kix_list_2-6>li:before {
    content: ""counter(lst-ctn-kix_list_2-6, decimal) ". "
  }

  .lst-kix_list_14-5>li {
    counter-increment: lst-ctn-kix_list_14-5
  }

  .lst-kix_list_7-5>li:before {
    content: " "
  }

  .lst-kix_list_27-6>li:before {
    content: ""counter(lst-ctn-kix_list_27-6, decimal) ". "
  }

  .lst-kix_list_28-2>li {
    counter-increment: lst-ctn-kix_list_28-2
  }

  .lst-kix_list_22-7>li:before {
    content: " "
  }

  ol.lst-kix_list_30-4.start {
    counter-reset: lst-ctn-kix_list_30-4 0
  }

  ol.lst-kix_list_11-1.start {
    counter-reset: lst-ctn-kix_list_11-1 0
  }

  .lst-kix_list_30-0>li {
    counter-increment: lst-ctn-kix_list_30-0
  }

  .lst-kix_list_18-5>li:before {
    content: ""counter(lst-ctn-kix_list_18-5, decimal) ". "
  }

  .lst-kix_list_13-6>li:before {
    content: ""counter(lst-ctn-kix_list_13-6, decimal) ". "
  }

  .lst-kix_list_6-7>li {
    counter-increment: lst-ctn-kix_list_6-7
  }

  .lst-kix_list_26-3>li {
    counter-increment: lst-ctn-kix_list_26-3
  }

  .lst-kix_list_10-6>li {
    counter-increment: lst-ctn-kix_list_10-6
  }

  .lst-kix_list_1-7>li {
    counter-increment: lst-ctn-kix_list_1-7
  }

  .lst-kix_list_15-6>li:before {
    content: " "
  }

  .lst-kix_list_7-5>li {
    counter-increment: lst-ctn-kix_list_7-5
  }

  .lst-kix_list_11-4>li {
    counter-increment: lst-ctn-kix_list_11-4
  }

  ol.lst-kix_list_24-7.start {
    counter-reset: lst-ctn-kix_list_24-7 0
  }

  .lst-kix_list_29-0>li {
    counter-increment: lst-ctn-kix_list_29-0
  }

  .lst-kix_list_31-3>li:before {
    content: " "
  }

  .lst-kix_list_10-2>li:before {
    content: ""counter(lst-ctn-kix_list_10-2, lower-roman) ". "
  }

  ol.lst-kix_list_6-8.start {
    counter-reset: lst-ctn-kix_list_6-8 0
  }

  .lst-kix_list_13-7>li {
    counter-increment: lst-ctn-kix_list_13-7
  }

  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0
  }

  .lst-kix_list_20-7>li:before {
    content: ""counter(lst-ctn-kix_list_20-7, lower-latin) ". "
  }

  ol.lst-kix_list_6-5.start {
    counter-reset: lst-ctn-kix_list_6-5 0
  }

  .lst-kix_list_4-6>li:before {
    content: ""counter(lst-ctn-kix_list_4-6, decimal) " "
  }

  ol.lst-kix_list_29-8.start {
    counter-reset: lst-ctn-kix_list_29-8 0
  }

  .lst-kix_list_25-6>li:before {
    content: " "
  }

  ol.lst-kix_list_29-5.start {
    counter-reset: lst-ctn-kix_list_29-5 0
  }

  ol.lst-kix_list_6-7.start {
    counter-reset: lst-ctn-kix_list_6-7 0
  }

  .lst-kix_list_12-2>li {
    counter-increment: lst-ctn-kix_list_12-2
  }

  .lst-kix_list_9-5>li:before {
    content: ""counter(lst-ctn-kix_list_9-5, lower-roman) ". "
  }

  .lst-kix_list_29-6>li:before {
    content: ""counter(lst-ctn-kix_list_29-6, decimal) ". "
  }

  ol.lst-kix_list_22-0.start {
    counter-reset: lst-ctn-kix_list_22-0 0
  }

  .lst-kix_list_24-0>li {
    counter-increment: lst-ctn-kix_list_24-0
  }

  .lst-kix_list_12-2>li:before {
    content: ""counter(lst-ctn-kix_list_12-2, lower-roman) ". "
  }

  .lst-kix_list_11-6>li:before {
    content: ""counter(lst-ctn-kix_list_11-6, decimal) ". "
  }

  .lst-kix_list_32-7>li:before {
    content: ""counter(lst-ctn-kix_list_32-7, lower-latin) ". "
  }

  ol.lst-kix_list_30-6.start {
    counter-reset: lst-ctn-kix_list_30-6 0
  }

  ol.lst-kix_list_11-3.start {
    counter-reset: lst-ctn-kix_list_11-3 0
  }

  .lst-kix_list_1-2>li:before {
    content: "("counter(lst-ctn-kix_list_1-2, lower-roman) ") "
  }

  .lst-kix_list_1-0>li {
    counter-increment: lst-ctn-kix_list_1-0
  }

  ol.lst-kix_list_16-1.start {
    counter-reset: lst-ctn-kix_list_16-1 0
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
  }

  ol.lst-kix_list_29-7.start {
    counter-reset: lst-ctn-kix_list_29-7 0
  }

  .lst-kix_list_18-7>li {
    counter-increment: lst-ctn-kix_list_18-7
  }

  .lst-kix_list_29-7>li {
    counter-increment: lst-ctn-kix_list_29-7
  }

  ol.lst-kix_list_11-4.start {
    counter-reset: lst-ctn-kix_list_11-4 0
  }

  ol.lst-kix_list_30-7.start {
    counter-reset: lst-ctn-kix_list_30-7 0
  }

  .lst-kix_list_28-2>li:before {
    content: ""counter(lst-ctn-kix_list_28-2, lower-roman) ". "
  }

  .lst-kix_list_14-1>li:before {
    content: ""counter(lst-ctn-kix_list_14-1, lower-latin) ". "
  }

  .lst-kix_list_14-3>li:before {
    content: ""counter(lst-ctn-kix_list_14-3, decimal) ". "
  }

  ol.lst-kix_list_15-6 {
    list-style-type: none
  }

  .lst-kix_list_25-8>li {
    counter-increment: lst-ctn-kix_list_25-8
  }

  ol.lst-kix_list_15-7 {
    list-style-type: none
  }

  ol.lst-kix_list_15-8 {
    list-style-type: none
  }

  .lst-kix_list_14-0>li:before {
    content: ""counter(lst-ctn-kix_list_14-0, upper-latin) ". "
  }

  .lst-kix_list_14-4>li:before {
    content: ""counter(lst-ctn-kix_list_14-4, lower-latin) ". "
  }

  ol.lst-kix_list_15-2 {
    list-style-type: none
  }

  ol.lst-kix_list_15-3 {
    list-style-type: none
  }

  ol.lst-kix_list_15-4 {
    list-style-type: none
  }

  ol.lst-kix_list_18-5.start {
    counter-reset: lst-ctn-kix_list_18-5 0
  }

  .lst-kix_list_14-5>li:before {
    content: ""counter(lst-ctn-kix_list_14-5, lower-roman) ". "
  }

  .lst-kix_list_14-7>li:before {
    content: ""counter(lst-ctn-kix_list_14-7, lower-latin) ". "
  }

  ol.lst-kix_list_15-5 {
    list-style-type: none
  }

  .lst-kix_list_6-1>li {
    counter-increment: lst-ctn-kix_list_6-1
  }

  ol.lst-kix_list_15-0 {
    list-style-type: none
  }

  .lst-kix_list_14-6>li:before {
    content: ""counter(lst-ctn-kix_list_14-6, decimal) ". "
  }

  ol.lst-kix_list_15-1 {
    list-style-type: none
  }

  .lst-kix_list_17-0>li {
    counter-increment: lst-ctn-kix_list_17-0
  }

  ol.lst-kix_list_7-4.start {
    counter-reset: lst-ctn-kix_list_7-4 0
  }

  .lst-kix_list_9-0>li {
    counter-increment: lst-ctn-kix_list_9-0
  }

  ol.lst-kix_list_25-3.start {
    counter-reset: lst-ctn-kix_list_25-3 0
  }

  ol.lst-kix_list_11-8.start {
    counter-reset: lst-ctn-kix_list_11-8 0
  }

  .lst-kix_list_14-2>li:before {
    content: ""counter(lst-ctn-kix_list_14-2, lower-roman) ". "
  }

  ol.lst-kix_list_32-1.start {
    counter-reset: lst-ctn-kix_list_32-1 0
  }

  .lst-kix_list_20-7>li {
    counter-increment: lst-ctn-kix_list_20-7
  }

  ol.lst-kix_list_26-7 {
    list-style-type: none
  }

  ol.lst-kix_list_26-6 {
    list-style-type: none
  }

  ol.lst-kix_list_26-8 {
    list-style-type: none
  }

  ol.lst-kix_list_26-3 {
    list-style-type: none
  }

  ol.lst-kix_list_12-0.start {
    counter-reset: lst-ctn-kix_list_12-0 0
  }

  ol.lst-kix_list_26-2 {
    list-style-type: none
  }

  ol.lst-kix_list_26-5 {
    list-style-type: none
  }

  ol.lst-kix_list_28-3.start {
    counter-reset: lst-ctn-kix_list_28-3 0
  }

  ol.lst-kix_list_26-4 {
    list-style-type: none
  }

  ol.lst-kix_list_26-1 {
    list-style-type: none
  }

  ol.lst-kix_list_26-0 {
    list-style-type: none
  }

  .lst-kix_list_32-2>li:before {
    content: "("counter(lst-ctn-kix_list_32-2, lower-latin) ") "
  }

  .lst-kix_list_32-1>li:before {
    content: "Section "counter(lst-ctn-kix_list_32-0, upper-roman) "."counter(lst-ctn-kix_list_32-1, decimal-leading-zero) " "
  }

  .lst-kix_list_32-3>li:before {
    content: "("counter(lst-ctn-kix_list_32-3, lower-roman) ") "
  }

  ol.lst-kix_list_21-6.start {
    counter-reset: lst-ctn-kix_list_21-6 0
  }

  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0
  }

  .lst-kix_list_28-7>li {
    counter-increment: lst-ctn-kix_list_28-7
  }

  .lst-kix_list_14-8>li:before {
    content: ""counter(lst-ctn-kix_list_14-8, lower-roman) ". "
  }

  .lst-kix_list_31-7>li {
    counter-increment: lst-ctn-kix_list_31-7
  }

  .lst-kix_list_32-0>li:before {
    content: "Article "counter(lst-ctn-kix_list_32-0, upper-roman) ". "
  }

  .lst-kix_list_3-2>li {
    counter-increment: lst-ctn-kix_list_3-2
  }

  ol.lst-kix_list_15-5.start {
    counter-reset: lst-ctn-kix_list_15-5 0
  }

  .lst-kix_list_5-0>li:before {
    content: "[ARTICLE "counter(lst-ctn-kix_list_5-0, upper-roman) " "
  }

  ol.lst-kix_list_6-0 {
    list-style-type: none
  }

  .lst-kix_list_14-8>li {
    counter-increment: lst-ctn-kix_list_14-8
  }

  ol.lst-kix_list_6-1 {
    list-style-type: none
  }

  .lst-kix_list_5-4>li {
    counter-increment: lst-ctn-kix_list_5-4
  }

  ol.lst-kix_list_24-6.start {
    counter-reset: lst-ctn-kix_list_24-6 0
  }

  .lst-kix_list_5-3>li:before {
    content: "[("counter(lst-ctn-kix_list_5-3, lower-roman) ") "
  }

  .lst-kix_list_5-2>li:before {
    content: "[("counter(lst-ctn-kix_list_5-2, lower-latin) ") "
  }

  .lst-kix_list_8-3>li {
    counter-increment: lst-ctn-kix_list_8-3
  }

  .lst-kix_list_5-1>li:before {
    content: "[Section "counter(lst-ctn-kix_list_5-0, upper-roman) "."counter(lst-ctn-kix_list_5-1, decimal-leading-zero) " "
  }

  ol.lst-kix_list_18-0.start {
    counter-reset: lst-ctn-kix_list_18-0 0
  }

  .lst-kix_list_5-7>li:before {
    content: " "
  }

  .lst-kix_list_5-6>li:before {
    content: " "
  }

  .lst-kix_list_5-8>li:before {
    content: " "
  }

  ol.lst-kix_list_6-6 {
    list-style-type: none
  }

  ol.lst-kix_list_6-7 {
    list-style-type: none
  }

  .lst-kix_list_5-4>li:before {
    content: "[("counter(lst-ctn-kix_list_5-4, upper-latin) ") "
  }

  ol.lst-kix_list_6-8 {
    list-style-type: none
  }

  .lst-kix_list_5-5>li:before {
    content: " "
  }

  ol.lst-kix_list_6-2 {
    list-style-type: none
  }

  ol.lst-kix_list_6-3 {
    list-style-type: none
  }

  ol.lst-kix_list_6-4 {
    list-style-type: none
  }

  ol.lst-kix_list_6-5 {
    list-style-type: none
  }

  ol.lst-kix_list_12-5.start {
    counter-reset: lst-ctn-kix_list_12-5 0
  }

  .lst-kix_list_6-1>li:before {
    content: ""counter(lst-ctn-kix_list_6-1, lower-latin) ". "
  }

  .lst-kix_list_6-3>li:before {
    content: ""counter(lst-ctn-kix_list_6-3, decimal) ". "
  }

  ol.lst-kix_list_32-6.start {
    counter-reset: lst-ctn-kix_list_32-6 0
  }

  .lst-kix_list_6-8>li {
    counter-increment: lst-ctn-kix_list_6-8
  }

  .lst-kix_list_6-0>li:before {
    content: "["counter(lst-ctn-kix_list_6-0, decimal) ".   "
  }

  .lst-kix_list_6-4>li:before {
    content: ""counter(lst-ctn-kix_list_6-4, lower-latin) ". "
  }

  ol.lst-kix_list_14-8.start {
    counter-reset: lst-ctn-kix_list_14-8 0
  }

  .lst-kix_list_6-2>li:before {
    content: ""counter(lst-ctn-kix_list_6-2, lower-roman) ". "
  }

  ol.lst-kix_list_15-0.start {
    counter-reset: lst-ctn-kix_list_15-0 0
  }

  .lst-kix_list_2-5>li {
    counter-increment: lst-ctn-kix_list_2-5
  }

  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0
  }

  .lst-kix_list_6-8>li:before {
    content: ""counter(lst-ctn-kix_list_6-8, lower-roman) ". "
  }

  .lst-kix_list_6-5>li:before {
    content: ""counter(lst-ctn-kix_list_6-5, lower-roman) ". "
  }

  .lst-kix_list_6-7>li:before {
    content: ""counter(lst-ctn-kix_list_6-7, lower-latin) ". "
  }

  .lst-kix_list_6-6>li:before {
    content: ""counter(lst-ctn-kix_list_6-6, decimal) ". "
  }

  ol.lst-kix_list_17-8 {
    list-style-type: none
  }

  ol.lst-kix_list_10-6.start {
    counter-reset: lst-ctn-kix_list_10-6 0
  }

  ol.lst-kix_list_27-6.start {
    counter-reset: lst-ctn-kix_list_27-6 0
  }

  .lst-kix_list_7-4>li:before {
    content: " "
  }

  .lst-kix_list_7-6>li:before {
    content: ""counter(lst-ctn-kix_list_7-6, decimal) " "
  }

  ol.lst-kix_list_17-4 {
    list-style-type: none
  }

  .lst-kix_list_18-5>li {
    counter-increment: lst-ctn-kix_list_18-5
  }

  ol.lst-kix_list_17-5 {
    list-style-type: none
  }

  .lst-kix_list_15-5>li {
    counter-increment: lst-ctn-kix_list_15-5
  }

  ol.lst-kix_list_17-6 {
    list-style-type: none
  }

  ol.lst-kix_list_6-2.start {
    counter-reset: lst-ctn-kix_list_6-2 0
  }

  ol.lst-kix_list_24-1.start {
    counter-reset: lst-ctn-kix_list_24-1 0
  }

  ol.lst-kix_list_17-7 {
    list-style-type: none
  }

  ol.lst-kix_list_17-0 {
    list-style-type: none
  }

  ol.lst-kix_list_17-1 {
    list-style-type: none
  }

  ol.lst-kix_list_17-2 {
    list-style-type: none
  }

  ol.lst-kix_list_17-3 {
    list-style-type: none
  }

  .lst-kix_list_7-2>li:before {
    content: "[("counter(lst-ctn-kix_list_7-2, lower-roman) ") "
  }

  .lst-kix_list_7-6>li {
    counter-increment: lst-ctn-kix_list_7-6
  }

  .lst-kix_list_27-2>li {
    counter-increment: lst-ctn-kix_list_27-2
  }

  .lst-kix_list_31-0>li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_12-6>li {
    counter-increment: lst-ctn-kix_list_12-6
  }

  .lst-kix_list_24-3>li {
    counter-increment: lst-ctn-kix_list_24-3
  }

  .lst-kix_list_13-7>li:before {
    content: ""counter(lst-ctn-kix_list_13-7, lower-latin) ". "
  }

  .lst-kix_list_7-8>li:before {
    content: " "
  }

  .lst-kix_list_15-6>li {
    counter-increment: lst-ctn-kix_list_15-6
  }

  .lst-kix_list_4-7>li {
    counter-increment: lst-ctn-kix_list_4-7
  }

  .lst-kix_list_15-5>li:before {
    content: " "
  }

  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0
  }

  ol.lst-kix_list_28-8 {
    list-style-type: none
  }

  .lst-kix_list_31-6>li:before {
    content: " "
  }

  .lst-kix_list_31-8>li:before {
    content: " "
  }

  ol.lst-kix_list_26-0.start {
    counter-reset: lst-ctn-kix_list_26-0 0
  }

  .lst-kix_list_13-4>li {
    counter-increment: lst-ctn-kix_list_13-4
  }

  .lst-kix_list_9-8>li {
    counter-increment: lst-ctn-kix_list_9-8
  }

  ol.lst-kix_list_28-5 {
    list-style-type: none
  }

  ol.lst-kix_list_28-4 {
    list-style-type: none
  }

  .lst-kix_list_27-3>li {
    counter-increment: lst-ctn-kix_list_27-3
  }

  ol.lst-kix_list_28-7 {
    list-style-type: none
  }

  ol.lst-kix_list_28-6 {
    list-style-type: none
  }

  ol.lst-kix_list_28-1 {
    list-style-type: none
  }

  ol.lst-kix_list_28-0 {
    list-style-type: none
  }

  .lst-kix_list_4-1>li:before {
    content: ""counter(lst-ctn-kix_list_4-0, decimal) "."counter(lst-ctn-kix_list_4-1, decimal) " "
  }

  .lst-kix_list_31-2>li:before {
    content: " "
  }

  .lst-kix_list_31-4>li:before {
    content: " "
  }

  ol.lst-kix_list_28-3 {
    list-style-type: none
  }

  ol.lst-kix_list_28-2 {
    list-style-type: none
  }

  .lst-kix_list_15-7>li:before {
    content: " "
  }

  .lst-kix_list_17-7>li {
    counter-increment: lst-ctn-kix_list_17-7
  }

  ul.lst-kix_list_19-7 {
    list-style-type: none
  }

  ul.lst-kix_list_19-6 {
    list-style-type: none
  }

  ul.lst-kix_list_19-5 {
    list-style-type: none
  }

  .lst-kix_list_4-3>li:before {
    content: "("counter(lst-ctn-kix_list_4-3, lower-roman) ") "
  }

  .lst-kix_list_4-5>li:before {
    content: " "
  }

  ul.lst-kix_list_19-4 {
    list-style-type: none
  }

  ul.lst-kix_list_19-3 {
    list-style-type: none
  }

  ul.lst-kix_list_19-2 {
    list-style-type: none
  }

  .lst-kix_list_1-8>li {
    counter-increment: lst-ctn-kix_list_1-8
  }

  ul.lst-kix_list_19-1 {
    list-style-type: none
  }

  .lst-kix_list_10-5>li {
    counter-increment: lst-ctn-kix_list_10-5
  }

  ul.lst-kix_list_19-0 {
    list-style-type: none
  }

  .lst-kix_list_15-1>li:before {
    content: ""counter(lst-ctn-kix_list_15-0, decimal) "("counter(lst-ctn-kix_list_15-1, lower-latin) ") "
  }

  .lst-kix_list_24-4>li {
    counter-increment: lst-ctn-kix_list_24-4
  }

  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0
  }

  .lst-kix_list_15-3>li:before {
    content: "("counter(lst-ctn-kix_list_15-3, upper-latin) ") "
  }

  ul.lst-kix_list_19-8 {
    list-style-type: none
  }

  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0
  }

  .lst-kix_list_16-2>li {
    counter-increment: lst-ctn-kix_list_16-2
  }

  .lst-kix_list_30-2>li {
    counter-increment: lst-ctn-kix_list_30-2
  }

  ol.lst-kix_list_9-2.start {
    counter-reset: lst-ctn-kix_list_9-2 0
  }

  .lst-kix_list_20-0>li {
    counter-increment: lst-ctn-kix_list_20-0
  }

  ol.lst-kix_list_16-7.start {
    counter-reset: lst-ctn-kix_list_16-7 0
  }

  .lst-kix_list_11-2>li {
    counter-increment: lst-ctn-kix_list_11-2
  }

  .lst-kix_list_32-4>li:before {
    content: ""counter(lst-ctn-kix_list_32-4, decimal) ") "
  }

  .lst-kix_list_26-5>li {
    counter-increment: lst-ctn-kix_list_26-5
  }

  .lst-kix_list_30-3>li {
    counter-increment: lst-ctn-kix_list_30-3
  }

  .lst-kix_list_12-3>li:before {
    content: ""counter(lst-ctn-kix_list_12-3, decimal) ". "
  }

  ol.lst-kix_list_8-8 {
    list-style-type: none
  }

  ol.lst-kix_list_31-4.start {
    counter-reset: lst-ctn-kix_list_31-4 -1
  }

  .lst-kix_list_12-1>li:before {
    content: ""counter(lst-ctn-kix_list_12-1, lower-latin) ". "
  }

  .lst-kix_list_32-6>li:before {
    content: ""counter(lst-ctn-kix_list_32-6, lower-roman) ") "
  }

  ol.lst-kix_list_8-4 {
    list-style-type: none
  }

  ol.lst-kix_list_8-5 {
    list-style-type: none
  }

  ol.lst-kix_list_8-6 {
    list-style-type: none
  }

  ol.lst-kix_list_8-7 {
    list-style-type: none
  }

  .lst-kix_list_16-3>li {
    counter-increment: lst-ctn-kix_list_16-3
  }

  .lst-kix_list_32-8>li:before {
    content: ""counter(lst-ctn-kix_list_32-8, lower-roman) ". "
  }

  ol.lst-kix_list_8-0 {
    list-style-type: none
  }

  ol.lst-kix_list_8-1 {
    list-style-type: none
  }

  .lst-kix_list_13-3>li {
    counter-increment: lst-ctn-kix_list_13-3
  }

  ol.lst-kix_list_13-6.start {
    counter-reset: lst-ctn-kix_list_13-6 0
  }

  ol.lst-kix_list_8-2 {
    list-style-type: none
  }

  ol.lst-kix_list_25-8.start {
    counter-reset: lst-ctn-kix_list_25-8 0
  }

  ol.lst-kix_list_8-3 {
    list-style-type: none
  }

  .lst-kix_list_10-4>li {
    counter-increment: lst-ctn-kix_list_10-4
  }

  .lst-kix_list_14-1>li {
    counter-increment: lst-ctn-kix_list_14-1
  }

  .lst-kix_list_21-4>li {
    counter-increment: lst-ctn-kix_list_21-4
  }

  .lst-kix_list_13-3>li:before {
    content: ""counter(lst-ctn-kix_list_13-3, decimal) ". "
  }

  .lst-kix_list_28-0>li {
    counter-increment: lst-ctn-kix_list_28-0
  }

  .lst-kix_list_13-5>li:before {
    content: ""counter(lst-ctn-kix_list_13-5, lower-roman) ". "
  }

  .lst-kix_list_12-5>li:before {
    content: ""counter(lst-ctn-kix_list_12-5, lower-roman) ". "
  }

  ol.lst-kix_list_28-8.start {
    counter-reset: lst-ctn-kix_list_28-8 0
  }

  ol.lst-kix_list_13-7.start {
    counter-reset: lst-ctn-kix_list_13-7 0
  }

  .lst-kix_list_18-4>li {
    counter-increment: lst-ctn-kix_list_18-4
  }

  .lst-kix_list_12-7>li:before {
    content: ""counter(lst-ctn-kix_list_12-7, lower-latin) ". "
  }

  .lst-kix_list_29-4>li {
    counter-increment: lst-ctn-kix_list_29-4
  }

  ol.lst-kix_list_21-1.start {
    counter-reset: lst-ctn-kix_list_21-1 0
  }

  .lst-kix_list_32-4>li {
    counter-increment: lst-ctn-kix_list_32-4
  }

  ol.lst-kix_list_29-0.start {
    counter-reset: lst-ctn-kix_list_29-0 0
  }

  .lst-kix_list_25-1>li {
    counter-increment: lst-ctn-kix_list_25-1
  }

  .lst-kix_list_13-1>li:before {
    content: ""counter(lst-ctn-kix_list_13-1, lower-latin) ". "
  }

  .lst-kix_list_32-5>li {
    counter-increment: lst-ctn-kix_list_32-5
  }

  .lst-kix_list_30-5>li:before {
    content: ""counter(lst-ctn-kix_list_30-5, lower-roman) ". "
  }

  ol.lst-kix_list_11-6 {
    list-style-type: none
  }

  ol.lst-kix_list_11-7 {
    list-style-type: none
  }

  .lst-kix_list_28-8>li {
    counter-increment: lst-ctn-kix_list_28-8
  }

  ol.lst-kix_list_11-8 {
    list-style-type: none
  }

  .lst-kix_list_1-1>li {
    counter-increment: lst-ctn-kix_list_1-1
  }

  .lst-kix_list_30-1>li:before {
    content: ""counter(lst-ctn-kix_list_30-1, lower-latin) ". "
  }

  ol.lst-kix_list_11-2 {
    list-style-type: none
  }

  ol.lst-kix_list_11-3 {
    list-style-type: none
  }

  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0
  }

  .lst-kix_list_3-0>li:before {
    content: "ARTICLE "counter(lst-ctn-kix_list_3-0, upper-roman) " "
  }

  ol.lst-kix_list_11-4 {
    list-style-type: none
  }

  ol.lst-kix_list_11-5 {
    list-style-type: none
  }

  ol.lst-kix_list_20-5.start {
    counter-reset: lst-ctn-kix_list_20-5 0
  }

  .lst-kix_list_30-2>li:before {
    content: ""counter(lst-ctn-kix_list_30-2, lower-roman) ". "
  }

  ol.lst-kix_list_13-1.start {
    counter-reset: lst-ctn-kix_list_13-1 0
  }

  ol.lst-kix_list_11-0 {
    list-style-type: none
  }

  ol.lst-kix_list_11-1 {
    list-style-type: none
  }

  .lst-kix_list_4-0>li {
    counter-increment: lst-ctn-kix_list_4-0
  }

  .lst-kix_list_31-6>li {
    counter-increment: lst-ctn-kix_list_31-6
  }

  .lst-kix_list_3-4>li:before {
    content: "("counter(lst-ctn-kix_list_3-4, upper-latin) ") "
  }

  .lst-kix_list_3-3>li:before {
    content: "("counter(lst-ctn-kix_list_3-3, lower-roman) ") "
  }

  .lst-kix_list_8-0>li:before {
    content: "["counter(lst-ctn-kix_list_8-0, decimal) ". "
  }

  .lst-kix_list_30-6>li:before {
    content: ""counter(lst-ctn-kix_list_30-6, decimal) ". "
  }

  .lst-kix_list_8-7>li:before {
    content: " "
  }

  .lst-kix_list_3-8>li:before {
    content: " "
  }

  ol.lst-kix_list_10-7.start {
    counter-reset: lst-ctn-kix_list_10-7 0
  }

  .lst-kix_list_8-3>li:before {
    content: "[("counter(lst-ctn-kix_list_8-3, lower-roman) ") "
  }

  .lst-kix_list_3-7>li:before {
    content: " "
  }

  .lst-kix_list_8-4>li:before {
    content: "[("counter(lst-ctn-kix_list_8-4, upper-latin) ") "
  }

  ol.lst-kix_list_22-0 {
    list-style-type: none
  }

  ol.lst-kix_list_8-5.start {
    counter-reset: lst-ctn-kix_list_8-5 0
  }

  ol.lst-kix_list_26-4.start {
    counter-reset: lst-ctn-kix_list_26-4 0
  }

  .lst-kix_list_17-1>li {
    counter-increment: lst-ctn-kix_list_17-1
  }

  .lst-kix_list_11-1>li:before {
    content: ""counter(lst-ctn-kix_list_11-1, lower-latin) ". "
  }

  .lst-kix_list_11-0>li:before {
    content: ""counter(lst-ctn-kix_list_11-0, lower-roman) ". "
  }

  ol.lst-kix_list_9-3.start {
    counter-reset: lst-ctn-kix_list_9-3 0
  }

  .lst-kix_list_8-8>li:before {
    content: " "
  }

  .lst-kix_list_16-8>li:before {
    content: ""counter(lst-ctn-kix_list_16-8, lower-roman) ". "
  }

  ol.lst-kix_list_2-2 {
    list-style-type: none
  }

  ol.lst-kix_list_2-3 {
    list-style-type: none
  }

  .lst-kix_list_16-7>li:before {
    content: ""counter(lst-ctn-kix_list_16-7, lower-latin) ". "
  }

  ol.lst-kix_list_2-4 {
    list-style-type: none
  }

  ol.lst-kix_list_2-5 {
    list-style-type: none
  }

  .lst-kix_list_17-8>li {
    counter-increment: lst-ctn-kix_list_17-8
  }

  ol.lst-kix_list_2-0 {
    list-style-type: none
  }

  ol.lst-kix_list_2-1 {
    list-style-type: none
  }

  .lst-kix_list_4-8>li:before {
    content: " "
  }

  .lst-kix_list_21-5>li {
    counter-increment: lst-ctn-kix_list_21-5
  }

  .lst-kix_list_14-2>li {
    counter-increment: lst-ctn-kix_list_14-2
  }

  ol.lst-kix_list_20-0.start {
    counter-reset: lst-ctn-kix_list_20-0 0
  }

  .lst-kix_list_17-0>li:before {
    content: ""counter(lst-ctn-kix_list_17-0, decimal) ".   "
  }

  .lst-kix_list_4-7>li:before {
    content: ""counter(lst-ctn-kix_list_4-7, decimal) " "
  }

  .lst-kix_list_16-0>li:before {
    content: ""counter(lst-ctn-kix_list_16-0, decimal) ".   "
  }

  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0
  }

  .lst-kix_list_8-4>li {
    counter-increment: lst-ctn-kix_list_8-4
  }

  .lst-kix_list_16-4>li:before {
    content: ""counter(lst-ctn-kix_list_16-4, lower-latin) ". "
  }

  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0
  }

  .lst-kix_list_16-3>li:before {
    content: ""counter(lst-ctn-kix_list_16-3, decimal) ". "
  }

  ol.lst-kix_list_2-6 {
    list-style-type: none
  }

  ol.lst-kix_list_2-7 {
    list-style-type: none
  }

  ol.lst-kix_list_2-8 {
    list-style-type: none
  }

  .lst-kix_list_11-3>li {
    counter-increment: lst-ctn-kix_list_11-3
  }

  ol.lst-kix_list_18-1.start {
    counter-reset: lst-ctn-kix_list_18-1 0
  }

  .lst-kix_list_30-1>li {
    counter-increment: lst-ctn-kix_list_30-1
  }

  .lst-kix_list_17-7>li:before {
    content: ""counter(lst-ctn-kix_list_17-7, lower-latin) ". "
  }

  ol.lst-kix_list_8-6.start {
    counter-reset: lst-ctn-kix_list_8-6 0
  }

  .lst-kix_list_17-8>li:before {
    content: ""counter(lst-ctn-kix_list_17-8, lower-roman) ". "
  }

  .lst-kix_list_17-3>li:before {
    content: ""counter(lst-ctn-kix_list_17-3, decimal) ". "
  }

  .lst-kix_list_17-4>li:before {
    content: ""counter(lst-ctn-kix_list_17-4, lower-latin) ". "
  }

  ol.lst-kix_list_8-0.start {
    counter-reset: lst-ctn-kix_list_8-0 0
  }

  .lst-kix_list_7-0>li:before {
    content: "["counter(lst-ctn-kix_list_7-0, decimal) ". "
  }

  ol.lst-kix_list_27-7.start {
    counter-reset: lst-ctn-kix_list_27-7 0
  }

  ol.lst-kix_list_9-7.start {
    counter-reset: lst-ctn-kix_list_9-7 0
  }

  ol.lst-kix_list_13-8 {
    list-style-type: none
  }

  .lst-kix_list_2-4>li:before {
    content: ""counter(lst-ctn-kix_list_2-4, lower-latin) ". "
  }

  .lst-kix_list_2-8>li:before {
    content: ""counter(lst-ctn-kix_list_2-8, lower-roman) ". "
  }

  ol.lst-kix_list_13-4 {
    list-style-type: none
  }

  ol.lst-kix_list_13-5 {
    list-style-type: none
  }

  ol.lst-kix_list_13-6 {
    list-style-type: none
  }

  ol.lst-kix_list_13-7 {
    list-style-type: none
  }

  ol.lst-kix_list_13-0 {
    list-style-type: none
  }

  ol.lst-kix_list_13-1 {
    list-style-type: none
  }

  ol.lst-kix_list_13-2 {
    list-style-type: none
  }

  ol.lst-kix_list_13-3 {
    list-style-type: none
  }

  .lst-kix_list_7-3>li:before {
    content: "[("counter(lst-ctn-kix_list_7-3, upper-latin) ") "
  }

  .lst-kix_list_10-0>li:before {
    content: ""counter(lst-ctn-kix_list_10-0, lower-latin) ". "
  }

  ol.lst-kix_list_21-7.start {
    counter-reset: lst-ctn-kix_list_21-7 0
  }

  .lst-kix_list_9-7>li {
    counter-increment: lst-ctn-kix_list_9-7
  }

  .lst-kix_list_13-8>li:before {
    content: ""counter(lst-ctn-kix_list_13-8, lower-roman) ". "
  }

  .lst-kix_list_31-1>li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_18-3>li:before {
    content: "("counter(lst-ctn-kix_list_18-3, lower-roman) ") "
  }

  .lst-kix_list_18-7>li:before {
    content: ""counter(lst-ctn-kix_list_18-7, decimal) ". "
  }

  .lst-kix_list_18-6>li {
    counter-increment: lst-ctn-kix_list_18-6
  }

  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0
  }

  .lst-kix_list_7-7>li:before {
    content: ""counter(lst-ctn-kix_list_7-7, decimal) " "
  }

  ol.lst-kix_list_8-1.start {
    counter-reset: lst-ctn-kix_list_8-1 0
  }

  .lst-kix_list_15-4>li:before {
    content: " "
  }

  .lst-kix_list_31-5>li:before {
    content: " "
  }

  ol.lst-kix_list_24-8 {
    list-style-type: none
  }

  .lst-kix_list_10-4>li:before {
    content: ""counter(lst-ctn-kix_list_10-4, lower-latin) ". "
  }

  .lst-kix_list_10-8>li:before {
    content: ""counter(lst-ctn-kix_list_10-8, lower-roman) ". "
  }

  ol.lst-kix_list_20-4.start {
    counter-reset: lst-ctn-kix_list_20-4 0
  }

  ol.lst-kix_list_24-5 {
    list-style-type: none
  }

  .lst-kix_list_4-0>li:before {
    content: ""counter(lst-ctn-kix_list_4-0, decimal) ". "
  }

  ol.lst-kix_list_24-4 {
    list-style-type: none
  }

  ol.lst-kix_list_24-7 {
    list-style-type: none
  }

  ol.lst-kix_list_25-2.start {
    counter-reset: lst-ctn-kix_list_25-2 0
  }

  .lst-kix_list_15-0>li:before {
    content: ""counter(lst-ctn-kix_list_15-0, decimal) " "
  }

  .lst-kix_list_15-8>li:before {
    content: " "
  }

  ol.lst-kix_list_24-6 {
    list-style-type: none
  }

  ol.lst-kix_list_24-1 {
    list-style-type: none
  }

  ol.lst-kix_list_14-3.start {
    counter-reset: lst-ctn-kix_list_14-3 0
  }

  ol.lst-kix_list_24-0 {
    list-style-type: none
  }

  ol.lst-kix_list_24-3 {
    list-style-type: none
  }

  .lst-kix_list_15-7>li {
    counter-increment: lst-ctn-kix_list_15-7
  }

  ol.lst-kix_list_24-2 {
    list-style-type: none
  }

  .lst-kix_list_4-4>li:before {
    content: "("counter(lst-ctn-kix_list_4-4, upper-latin) ") "
  }

  .lst-kix_list_9-3>li:before {
    content: ""counter(lst-ctn-kix_list_9-3, decimal) ". "
  }

  ol.lst-kix_list_7-0.start {
    counter-reset: lst-ctn-kix_list_7-0 0
  }

  ol.lst-kix_list_32-5.start {
    counter-reset: lst-ctn-kix_list_32-5 0
  }

  .lst-kix_list_12-8>li {
    counter-increment: lst-ctn-kix_list_12-8
  }

  ol.lst-kix_list_13-2.start {
    counter-reset: lst-ctn-kix_list_13-2 0
  }

  ol.lst-kix_list_4-0 {
    list-style-type: none
  }

  ol.lst-kix_list_4-1 {
    list-style-type: none
  }

  ol.lst-kix_list_4-2 {
    list-style-type: none
  }

  ol.lst-kix_list_4-3 {
    list-style-type: none
  }

  ol.lst-kix_list_14-4.start {
    counter-reset: lst-ctn-kix_list_14-4 0
  }

  .lst-kix_list_9-7>li:before {
    content: ""counter(lst-ctn-kix_list_9-7, lower-latin) ". "
  }

  .lst-kix_list_2-4>li {
    counter-increment: lst-ctn-kix_list_2-4
  }

  .lst-kix_list_29-4>li:before {
    content: "("counter(lst-ctn-kix_list_29-4, upper-latin) ") "
  }

  .lst-kix_list_29-8>li:before {
    content: ""counter(lst-ctn-kix_list_29-8, decimal) ". "
  }

  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0
  }

  ol.lst-kix_list_32-2.start {
    counter-reset: lst-ctn-kix_list_32-2 0
  }

  .lst-kix_list_32-5>li:before {
    content: ""counter(lst-ctn-kix_list_32-5, lower-latin) ") "
  }

  .lst-kix_list_11-4>li:before {
    content: ""counter(lst-ctn-kix_list_11-4, lower-latin) ". "
  }

  .lst-kix_list_12-4>li:before {
    content: ""counter(lst-ctn-kix_list_12-4, lower-latin) ". "
  }

  .lst-kix_list_5-3>li {
    counter-increment: lst-ctn-kix_list_5-3
  }

  .lst-kix_list_29-0>li:before {
    content: ""counter(lst-ctn-kix_list_29-0, decimal) ". "
  }

  ol.lst-kix_list_4-8 {
    list-style-type: none
  }

  .lst-kix_list_7-4>li {
    counter-increment: lst-ctn-kix_list_7-4
  }

  .lst-kix_list_1-0>li:before {
    content: ""counter(lst-ctn-kix_list_1-0, decimal) ". "
  }

  .lst-kix_list_11-8>li:before {
    content: ""counter(lst-ctn-kix_list_11-8, lower-roman) ". "
  }

  ol.lst-kix_list_4-4 {
    list-style-type: none
  }

  ol.lst-kix_list_4-5 {
    list-style-type: none
  }

  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0
  }

  .lst-kix_list_12-0>li:before {
    content: ""counter(lst-ctn-kix_list_12-0, lower-roman) ". "
  }

  ol.lst-kix_list_4-6 {
    list-style-type: none
  }

  ol.lst-kix_list_4-7 {
    list-style-type: none
  }

  .lst-kix_list_1-4>li:before {
    content: " "
  }

  .lst-kix_list_13-0>li:before {
    content: ""counter(lst-ctn-kix_list_13-0, upper-latin) ". "
  }

  .lst-kix_list_1-6>li {
    counter-increment: lst-ctn-kix_list_1-6
  }

  .lst-kix_list_13-4>li:before {
    content: ""counter(lst-ctn-kix_list_13-4, lower-latin) ". "
  }

  ol.lst-kix_list_26-5.start {
    counter-reset: lst-ctn-kix_list_26-5 0
  }

  .lst-kix_list_2-0>li:before {
    content: ""counter(lst-ctn-kix_list_2-0, decimal) ".   "
  }

  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0
  }

  .lst-kix_list_4-5>li {
    counter-increment: lst-ctn-kix_list_4-5
  }

  ol.lst-kix_list_9-8.start {
    counter-reset: lst-ctn-kix_list_9-8 0
  }

  .lst-kix_list_1-8>li:before {
    content: " "
  }

  .lst-kix_list_12-8>li:before {
    content: ""counter(lst-ctn-kix_list_12-8, lower-roman) ". "
  }

  .lst-kix_list_8-2>li {
    counter-increment: lst-ctn-kix_list_8-2
  }

  .lst-kix_list_19-0>li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_17-7.start {
    counter-reset: lst-ctn-kix_list_17-7 0
  }

  ol.lst-kix_list_12-6.start {
    counter-reset: lst-ctn-kix_list_12-6 0
  }

  .lst-kix_list_8-1>li {
    counter-increment: lst-ctn-kix_list_8-1
  }

  ol.lst-kix_list_30-6 {
    list-style-type: none
  }

  ol.lst-kix_list_8-2.start {
    counter-reset: lst-ctn-kix_list_8-2 0
  }

  ol.lst-kix_list_26-1.start {
    counter-reset: lst-ctn-kix_list_26-1 0
  }

  .lst-kix_list_19-2>li:before {
    content: "\0025aa  "
  }

  ol.lst-kix_list_30-5 {
    list-style-type: none
  }

  ol.lst-kix_list_21-0.start {
    counter-reset: lst-ctn-kix_list_21-0 0
  }

  ol.lst-kix_list_30-8 {
    list-style-type: none
  }

  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0
  }

  ol.lst-kix_list_30-7 {
    list-style-type: none
  }

  ol.lst-kix_list_30-2 {
    list-style-type: none
  }

  ol.lst-kix_list_30-1 {
    list-style-type: none
  }

  ol.lst-kix_list_30-4 {
    list-style-type: none
  }

  ol.lst-kix_list_30-3 {
    list-style-type: none
  }

  ol.lst-kix_list_30-0 {
    list-style-type: none
  }

  .lst-kix_list_7-0>li {
    counter-increment: lst-ctn-kix_list_7-0
  }

  .lst-kix_list_29-6>li {
    counter-increment: lst-ctn-kix_list_29-6
  }

  ol.lst-kix_list_31-3.start {
    counter-reset: lst-ctn-kix_list_31-3 -1
  }

  .lst-kix_list_2-3>li {
    counter-increment: lst-ctn-kix_list_2-3
  }

  .lst-kix_list_1-2>li {
    counter-increment: lst-ctn-kix_list_1-2
  }

  .lst-kix_list_19-8>li:before {
    content: "\0025aa  "
  }

  ol.lst-kix_list_14-7.start {
    counter-reset: lst-ctn-kix_list_14-7 0
  }

  ol.lst-kix_list_20-8.start {
    counter-reset: lst-ctn-kix_list_20-8 0
  }

  .lst-kix_list_19-5>li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_19-7>li:before {
    content: "o  "
  }

  .lst-kix_list_9-2>li {
    counter-increment: lst-ctn-kix_list_9-2
  }

  .lst-kix_list_24-5>li {
    counter-increment: lst-ctn-kix_list_24-5
  }

  ol.lst-kix_list_17-2.start {
    counter-reset: lst-ctn-kix_list_17-2 0
  }

  .lst-kix_list_13-2>li {
    counter-increment: lst-ctn-kix_list_13-2
  }

  ol.lst-kix_list_21-5.start {
    counter-reset: lst-ctn-kix_list_21-5 0
  }

  .lst-kix_list_14-3>li {
    counter-increment: lst-ctn-kix_list_14-3
  }

  ol.lst-kix_list_15-6.start {
    counter-reset: lst-ctn-kix_list_15-6 0
  }

  .lst-kix_list_25-6>li {
    counter-increment: lst-ctn-kix_list_25-6
  }

  .lst-kix_list_12-1>li {
    counter-increment: lst-ctn-kix_list_12-1
  }

  ol.lst-kix_list_31-8.start {
    counter-reset: lst-ctn-kix_list_31-8 -1
  }

  .lst-kix_list_18-1>li:before {
    content: "("counter(lst-ctn-kix_list_18-1, lower-latin) ") "
  }

  ul.lst-kix_list_31-0 {
    list-style-type: none
  }

  ul.lst-kix_list_31-1 {
    list-style-type: none
  }

  ol.lst-kix_list_25-4.start {
    counter-reset: lst-ctn-kix_list_25-4 0
  }

  ol.lst-kix_list_32-0.start {
    counter-reset: lst-ctn-kix_list_32-0 0
  }

  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0
  }

  ol.lst-kix_list_1-3 {
    list-style-type: none
  }

  ul.lst-kix_list_22-1 {
    list-style-type: none
  }

  ol.lst-kix_list_1-4 {
    list-style-type: none
  }

  .lst-kix_list_2-7>li:before {
    content: ""counter(lst-ctn-kix_list_2-7, lower-latin) ". "
  }

  .lst-kix_list_2-7>li {
    counter-increment: lst-ctn-kix_list_2-7
  }

  ul.lst-kix_list_22-2 {
    list-style-type: none
  }

  .lst-kix_list_24-2>li {
    counter-increment: lst-ctn-kix_list_24-2
  }

  ol.lst-kix_list_1-5 {
    list-style-type: none
  }

  ul.lst-kix_list_22-3 {
    list-style-type: none
  }

  ol.lst-kix_list_1-6 {
    list-style-type: none
  }

  ul.lst-kix_list_22-4 {
    list-style-type: none
  }

  ul.lst-kix_list_22-5 {
    list-style-type: none
  }

  ol.lst-kix_list_1-0 {
    list-style-type: none
  }

  .lst-kix_list_2-5>li:before {
    content: ""counter(lst-ctn-kix_list_2-5, lower-roman) ". "
  }

  ul.lst-kix_list_22-6 {
    list-style-type: none
  }

  ol.lst-kix_list_1-1 {
    list-style-type: none
  }

  ul.lst-kix_list_22-7 {
    list-style-type: none
  }

  ol.lst-kix_list_1-2 {
    list-style-type: none
  }

  ol.lst-kix_list_17-0.start {
    counter-reset: lst-ctn-kix_list_17-0 0
  }

  ol.lst-kix_list_32-8 {
    list-style-type: none
  }

  ol.lst-kix_list_32-7 {
    list-style-type: none
  }

  .lst-kix_list_27-5>li:before {
    content: ""counter(lst-ctn-kix_list_27-5, lower-roman) ". "
  }

  ol.lst-kix_list_32-4 {
    list-style-type: none
  }

  ol.lst-kix_list_32-3 {
    list-style-type: none
  }

  ol.lst-kix_list_32-6 {
    list-style-type: none
  }

  ol.lst-kix_list_32-5 {
    list-style-type: none
  }

  ol.lst-kix_list_10-3.start {
    counter-reset: lst-ctn-kix_list_10-3 0
  }

  ol.lst-kix_list_32-0 {
    list-style-type: none
  }

  .lst-kix_list_18-6>li:before {
    content: ""counter(lst-ctn-kix_list_18-6, decimal) ". "
  }

  ol.lst-kix_list_32-2 {
    list-style-type: none
  }

  .lst-kix_list_14-6>li {
    counter-increment: lst-ctn-kix_list_14-6
  }

  ol.lst-kix_list_32-1 {
    list-style-type: none
  }

  .lst-kix_list_10-1>li:before {
    content: ""counter(lst-ctn-kix_list_10-1, lower-latin) ". "
  }

  .lst-kix_list_18-4>li:before {
    content: "("counter(lst-ctn-kix_list_18-4, upper-latin) ") "
  }

  ul.lst-kix_list_22-8 {
    list-style-type: none
  }

  ol.lst-kix_list_15-1.start {
    counter-reset: lst-ctn-kix_list_15-1 0
  }

  ol.lst-kix_list_15-4.start {
    counter-reset: lst-ctn-kix_list_15-4 0
  }

  .lst-kix_list_7-7>li {
    counter-increment: lst-ctn-kix_list_7-7
  }

  ol.lst-kix_list_1-7 {
    list-style-type: none
  }

  ol.lst-kix_list_1-8 {
    list-style-type: none
  }

  .lst-kix_list_10-3>li:before {
    content: ""counter(lst-ctn-kix_list_10-3, decimal) ". "
  }

  .lst-kix_list_15-4>li {
    counter-increment: lst-ctn-kix_list_15-4
  }

  .lst-kix_list_2-6>li {
    counter-increment: lst-ctn-kix_list_2-6
  }

  ol.lst-kix_list_7-3.start {
    counter-reset: lst-ctn-kix_list_7-3 0
  }

  .lst-kix_list_28-1>li {
    counter-increment: lst-ctn-kix_list_28-1
  }

  .lst-kix_list_26-4>li {
    counter-increment: lst-ctn-kix_list_26-4
  }

  ol.lst-kix_list_5-7.start {
    counter-reset: lst-ctn-kix_list_5-7 0
  }

  ol.lst-kix_list_28-7.start {
    counter-reset: lst-ctn-kix_list_28-7 0
  }

  .lst-kix_list_20-8>li:before {
    content: ""counter(lst-ctn-kix_list_20-8, lower-roman) ". "
  }

  .lst-kix_list_3-4>li {
    counter-increment: lst-ctn-kix_list_3-4
  }

  .lst-kix_list_29-7>li:before {
    content: ""counter(lst-ctn-kix_list_29-7, decimal) ". "
  }

  .lst-kix_list_20-0>li:before {
    content: "("counter(lst-ctn-kix_list_20-0, decimal) ") "
  }

  .lst-kix_list_29-5>li:before {
    content: ""counter(lst-ctn-kix_list_29-5, decimal) ". "
  }

  ol.lst-kix_list_10-7 {
    list-style-type: none
  }

  .lst-kix_list_9-6>li:before {
    content: ""counter(lst-ctn-kix_list_9-6, decimal) ". "
  }

  ol.lst-kix_list_10-8 {
    list-style-type: none
  }

  ol.lst-kix_list_10-3 {
    list-style-type: none
  }

  .lst-kix_list_9-4>li:before {
    content: ""counter(lst-ctn-kix_list_9-4, lower-latin) ". "
  }

  ol.lst-kix_list_10-4 {
    list-style-type: none
  }

  .lst-kix_list_30-8>li {
    counter-increment: lst-ctn-kix_list_30-8
  }

  ol.lst-kix_list_10-5 {
    list-style-type: none
  }

  ol.lst-kix_list_10-6 {
    list-style-type: none
  }

  .lst-kix_list_20-6>li:before {
    content: ""counter(lst-ctn-kix_list_20-6, decimal) ". "
  }

  ol.lst-kix_list_10-0 {
    list-style-type: none
  }

  ol.lst-kix_list_10-1 {
    list-style-type: none
  }

  ol.lst-kix_list_10-2 {
    list-style-type: none
  }

  ol.lst-kix_list_12-1.start {
    counter-reset: lst-ctn-kix_list_12-1 0
  }

  .lst-kix_list_11-5>li:before {
    content: ""counter(lst-ctn-kix_list_11-5, lower-roman) ". "
  }

  ol.lst-kix_list_21-2.start {
    counter-reset: lst-ctn-kix_list_21-2 0
  }

  .lst-kix_list_20-6>li {
    counter-increment: lst-ctn-kix_list_20-6
  }

  .lst-kix_list_1-1>li:before {
    content: "("counter(lst-ctn-kix_list_1-1, lower-latin) ") "
  }

  .lst-kix_list_11-7>li:before {
    content: ""counter(lst-ctn-kix_list_11-7, lower-latin) ". "
  }

  ol.lst-kix_list_25-7.start {
    counter-reset: lst-ctn-kix_list_25-7 0
  }

  .lst-kix_list_8-5>li {
    counter-increment: lst-ctn-kix_list_8-5
  }

  .lst-kix_list_1-3>li:before {
    content: "("counter(lst-ctn-kix_list_1-3, upper-latin) ") "
  }

  ol.lst-kix_list_10-5.start {
    counter-reset: lst-ctn-kix_list_10-5 0
  }

  .lst-kix_list_28-3>li:before {
    content: ""counter(lst-ctn-kix_list_28-3, decimal) ". "
  }

  ol.lst-kix_list_21-8 {
    list-style-type: none
  }

  ol.lst-kix_list_21-7 {
    list-style-type: none
  }

  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0
  }

  ol.lst-kix_list_21-4 {
    list-style-type: none
  }

  ol.lst-kix_list_21-3 {
    list-style-type: none
  }

  ol.lst-kix_list_21-6 {
    list-style-type: none
  }

  .lst-kix_list_14-7>li {
    counter-increment: lst-ctn-kix_list_14-7
  }

  ol.lst-kix_list_21-5 {
    list-style-type: none
  }

  ol.lst-kix_list_21-0 {
    list-style-type: none
  }

  ol.lst-kix_list_7-5.start {
    counter-reset: lst-ctn-kix_list_7-5 0
  }

  ol.lst-kix_list_21-2 {
    list-style-type: none
  }

  ol.lst-kix_list_31-5.start {
    counter-reset: lst-ctn-kix_list_31-5 -1
  }

  ol.lst-kix_list_21-1 {
    list-style-type: none
  }

  .lst-kix_list_25-7>li {
    counter-increment: lst-ctn-kix_list_25-7
  }

  .lst-kix_list_27-7>li:before {
    content: ""counter(lst-ctn-kix_list_27-7, lower-latin) ". "
  }

  .lst-kix_list_28-1>li:before {
    content: ""counter(lst-ctn-kix_list_28-1, lower-latin) ". "
  }

  .lst-kix_list_3-1>li {
    counter-increment: lst-ctn-kix_list_3-1
  }

  .lst-kix_list_30-3>li:before {
    content: ""counter(lst-ctn-kix_list_30-3, decimal) ". "
  }

  .lst-kix_list_26-8>li {
    counter-increment: lst-ctn-kix_list_26-8
  }

  .lst-kix_list_30-8>li:before {
    content: ""counter(lst-ctn-kix_list_30-8, lower-roman) ". "
  }

  .lst-kix_list_30-5>li {
    counter-increment: lst-ctn-kix_list_30-5
  }

  ol.lst-kix_list_7-7.start {
    counter-reset: lst-ctn-kix_list_7-7 0
  }

  .lst-kix_list_3-1>li:before {
    content: "Section "counter(lst-ctn-kix_list_3-0, upper-roman) "."counter(lst-ctn-kix_list_3-1, decimal-leading-zero) " "
  }

  .lst-kix_list_26-6>li:before {
    content: ""counter(lst-ctn-kix_list_26-6, decimal) ". "
  }

  .lst-kix_list_14-0>li {
    counter-increment: lst-ctn-kix_list_14-0
  }

  ol.lst-kix_list_17-4.start {
    counter-reset: lst-ctn-kix_list_17-4 0
  }

  .lst-kix_list_8-2>li:before {
    content: "[("counter(lst-ctn-kix_list_8-2, lower-latin) ") "
  }

  ol.lst-kix_list_31-6.start {
    counter-reset: lst-ctn-kix_list_31-6 -1
  }

  .lst-kix_list_12-0>li {
    counter-increment: lst-ctn-kix_list_12-0
  }

  ol.lst-kix_list_12-3.start {
    counter-reset: lst-ctn-kix_list_12-3 0
  }

  .lst-kix_list_21-2>li:before {
    content: ""counter(lst-ctn-kix_list_21-2, lower-roman) ". "
  }

  .lst-kix_list_8-5>li:before {
    content: " "
  }

  .lst-kix_list_15-1>li {
    counter-increment: lst-ctn-kix_list_15-1
  }

  .lst-kix_list_2-0>li {
    counter-increment: lst-ctn-kix_list_2-0
  }

  ol.lst-kix_list_28-0.start {
    counter-reset: lst-ctn-kix_list_28-0 0
  }

  .lst-kix_list_26-3>li:before {
    content: ""counter(lst-ctn-kix_list_26-3, decimal) ". "
  }

  .lst-kix_list_3-6>li:before {
    content: " "
  }

  .lst-kix_list_21-7>li:before {
    content: ""counter(lst-ctn-kix_list_21-7, lower-latin) ". "
  }

  .lst-kix_list_11-2>li:before {
    content: ""counter(lst-ctn-kix_list_11-2, lower-roman) ". "
  }

  ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 0
  }

  ol.lst-kix_list_31-7.start {
    counter-reset: lst-ctn-kix_list_31-7 -1
  }

  ol.lst-kix_list_12-4.start {
    counter-reset: lst-ctn-kix_list_12-4 0
  }

  .lst-kix_list_16-6>li:before {
    content: ""counter(lst-ctn-kix_list_16-6, decimal) ". "
  }

  ol.lst-kix_list_10-1.start {
    counter-reset: lst-ctn-kix_list_10-1 0
  }

  .lst-kix_list_25-2>li:before {
    content: " "
  }

  .lst-kix_list_18-2>li {
    counter-increment: lst-ctn-kix_list_18-2
  }

  .lst-kix_list_29-5>li {
    counter-increment: lst-ctn-kix_list_29-5
  }

  ol.lst-kix_list_5-6.start {
    counter-reset: lst-ctn-kix_list_5-6 0
  }

  .lst-kix_list_16-1>li:before {
    content: ""counter(lst-ctn-kix_list_16-1, lower-latin) ". "
  }

  .lst-kix_list_7-3>li {
    counter-increment: lst-ctn-kix_list_7-3
  }

  .lst-kix_list_25-0>li {
    counter-increment: lst-ctn-kix_list_25-0
  }

  ol.lst-kix_list_28-6.start {
    counter-reset: lst-ctn-kix_list_28-6 0
  }

  ol.lst-kix_list_7-8.start {
    counter-reset: lst-ctn-kix_list_7-8 0
  }

  ol.lst-kix_list_28-5.start {
    counter-reset: lst-ctn-kix_list_28-5 0
  }

  .lst-kix_list_12-4>li {
    counter-increment: lst-ctn-kix_list_12-4
  }

  ol.lst-kix_list_10-2.start {
    counter-reset: lst-ctn-kix_list_10-2 0
  }

  .lst-kix_list_12-7>li {
    counter-increment: lst-ctn-kix_list_12-7
  }

  .lst-kix_list_17-2>li:before {
    content: ""counter(lst-ctn-kix_list_17-2, lower-roman) ". "
  }

  ol.lst-kix_list_5-5.start {
    counter-reset: lst-ctn-kix_list_5-5 0
  }

  .lst-kix_list_30-0>li:before {
    content: ""counter(lst-ctn-kix_list_30-0, decimal) ". "
  }

  ol.lst-kix_list_17-3.start {
    counter-reset: lst-ctn-kix_list_17-3 0
  }

  .lst-kix_list_17-5>li:before {
    content: ""counter(lst-ctn-kix_list_17-5, lower-roman) ". "
  }

  .lst-kix_list_6-2>li {
    counter-increment: lst-ctn-kix_list_6-2
  }

  .lst-kix_list_27-2>li:before {
    content: ""counter(lst-ctn-kix_list_27-2, lower-roman) ". "
  }

  .lst-kix_list_22-3>li:before {
    content: " "
  }

  .lst-kix_list_13-5>li {
    counter-increment: lst-ctn-kix_list_13-5
  }

  .lst-kix_list_7-1>li:before {
    content: "[("counter(lst-ctn-kix_list_7-1, lower-latin) ") "
  }

  .lst-kix_list_9-6>li {
    counter-increment: lst-ctn-kix_list_9-6
  }

  .lst-kix_list_29-2>li {
    counter-increment: lst-ctn-kix_list_29-2
  }

  ol.lst-kix_list_28-4.start {
    counter-reset: lst-ctn-kix_list_28-4 0
  }

  ol.lst-kix_list_5-4.start {
    counter-reset: lst-ctn-kix_list_5-4 0
  }

  .lst-kix_list_20-3>li {
    counter-increment: lst-ctn-kix_list_20-3
  }

  ol.lst-kix_list_5-1.start {
    counter-reset: lst-ctn-kix_list_5-1 0
  }

  .lst-kix_list_25-3>li {
    counter-increment: lst-ctn-kix_list_25-3
  }

  .lst-kix_list_16-6>li {
    counter-increment: lst-ctn-kix_list_16-6
  }

  .lst-kix_list_11-6>li {
    counter-increment: lst-ctn-kix_list_11-6
  }

  ol.lst-kix_list_28-1.start {
    counter-reset: lst-ctn-kix_list_28-1 0
  }

  ol.lst-kix_list_10-0.start {
    counter-reset: lst-ctn-kix_list_10-0 0
  }

  .lst-kix_list_31-7>li:before {
    content: " "
  }

  ol.lst-kix_list_17-8.start {
    counter-reset: lst-ctn-kix_list_17-8 0
  }

  .lst-kix_list_3-8>li {
    counter-increment: lst-ctn-kix_list_3-8
  }

  .lst-kix_list_4-6>li {
    counter-increment: lst-ctn-kix_list_4-6
  }

  .lst-kix_list_1-5>li {
    counter-increment: lst-ctn-kix_list_1-5
  }

  ol.lst-kix_list_17-5.start {
    counter-reset: lst-ctn-kix_list_17-5 0
  }

  .lst-kix_list_28-4>li {
    counter-increment: lst-ctn-kix_list_28-4
  }

  .lst-kix_list_17-4>li {
    counter-increment: lst-ctn-kix_list_17-4
  }

  .lst-kix_list_4-2>li:before {
    content: "("counter(lst-ctn-kix_list_4-2, lower-latin) ") "
  }

  .lst-kix_list_15-2>li:before {
    content: "("counter(lst-ctn-kix_list_15-2, lower-roman) ") "
  }

  .lst-kix_list_10-8>li {
    counter-increment: lst-ctn-kix_list_10-8
  }

  .lst-kix_list_26-1>li {
    counter-increment: lst-ctn-kix_list_26-1
  }

  .lst-kix_list_10-6>li:before {
    content: ""counter(lst-ctn-kix_list_10-6, decimal) ". "
  }

  .lst-kix_list_9-1>li:before {
    content: ""counter(lst-ctn-kix_list_9-1, lower-latin) ". "
  }

  ol.lst-kix_list_12-7.start {
    counter-reset: lst-ctn-kix_list_12-7 0
  }

  .lst-kix_list_15-8>li {
    counter-increment: lst-ctn-kix_list_15-8
  }

  .lst-kix_list_31-3>li {
    counter-increment: lst-ctn-kix_list_31-3
  }

  ol.lst-kix_list_12-8.start {
    counter-reset: lst-ctn-kix_list_12-8 0
  }

  .lst-kix_list_20-3>li:before {
    content: "("counter(lst-ctn-kix_list_20-3, decimal) ") "
  }

  .lst-kix_list_27-6>li {
    counter-increment: lst-ctn-kix_list_27-6
  }

  .lst-kix_list_29-2>li:before {
    content: "("counter(lst-ctn-kix_list_29-2, lower-latin) ") "
  }

  .lst-kix_list_21-1>li {
    counter-increment: lst-ctn-kix_list_21-1
  }

  .lst-kix_list_10-1>li {
    counter-increment: lst-ctn-kix_list_10-1
  }

  .lst-kix_list_8-8>li {
    counter-increment: lst-ctn-kix_list_8-8
  }

  ol.lst-kix_list_17-6.start {
    counter-reset: lst-ctn-kix_list_17-6 0
  }

  .lst-kix_list_28-6>li:before {
    content: ""counter(lst-ctn-kix_list_28-6, decimal) ". "
  }

  .lst-kix_list_1-6>li:before {
    content: " "
  }

  ol.lst-kix_list_28-2.start {
    counter-reset: lst-ctn-kix_list_28-2 0
  }

  .lst-kix_list_12-6>li:before {
    content: ""counter(lst-ctn-kix_list_12-6, decimal) ". "
  }

  .lst-kix_list_2-2>li:before {
    content: ""counter(lst-ctn-kix_list_2-2, lower-roman) ". "
  }

  .lst-kix_list_13-2>li:before {
    content: ""counter(lst-ctn-kix_list_13-2, lower-roman) ". "
  }

  .lst-kix_list_32-1>li {
    counter-increment: lst-ctn-kix_list_32-1
  }

  ol.lst-kix_list_5-2.start {
    counter-reset: lst-ctn-kix_list_5-2 0
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td,
  table th {
    padding: 0
  }

  .c16 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c0 {
    margin-left: 18pt;
    padding-top: 6pt;
    padding-left: 0pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c11 {
    padding-top: 6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center;
    height: 12pt
  }

  .c7 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
  }

  .c2 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c14 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
  }

  .c4 {
    color: #252525;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c1 {
    padding-top: 6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c13 {
    padding-top: 6pt;
    padding-bottom: 12pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c12 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
  }

  .c9 {
    vertical-align: baseline;
    font-family: "Times New Roman";
    color: #252525;
    font-weight: 700
  }

  .c8 {
    padding: 0;
    margin: 0
  }

  .c6 {
    color: inherit;
    text-decoration: inherit
  }

  .c15 {
    /* max-width: 468pt; */
    /* padding: 72pt 72pt 72pt 72pt */
  }

  .c10 {
    border: 1px solid black;
    margin: 5px
  }

  .c5 {
    background-color: #ffffff
  }

  .title {
    padding-top: 6pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  h1 {
    padding-top: 12pt;
    color: #000000;
    border-bottom-color: #000000;
    font-size: 14pt;
    padding-bottom: 1pt;
    line-height: 1.0;
    page-break-after: avoid;
    border-bottom-width: 0.5pt;
    font-family: "Arial";
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 13pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 10pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }
}

#privacy-policy {
  ol.lst-kix_list_7-0 {
    list-style-type: none
  }

  .lst-kix_list_2-1>li {
    counter-increment: lst-ctn-kix_list_2-1
  }

  ol.lst-kix_list_38-6 {
    list-style-type: none
  }

  ol.lst-kix_list_38-5 {
    list-style-type: none
  }

  ol.lst-kix_list_38-8 {
    list-style-type: none
  }

  .lst-kix_list_21-8>li {
    counter-increment: lst-ctn-kix_list_21-8
  }

  ol.lst-kix_list_9-0.start {
    counter-reset: lst-ctn-kix_list_9-0 0
  }

  ol.lst-kix_list_38-7 {
    list-style-type: none
  }

  ol.lst-kix_list_38-2 {
    list-style-type: none
  }

  ol.lst-kix_list_38-1 {
    list-style-type: none
  }

  ol.lst-kix_list_38-4 {
    list-style-type: none
  }

  ol.lst-kix_list_38-3 {
    list-style-type: none
  }

  ol.lst-kix_list_38-0 {
    list-style-type: none
  }

  ol.lst-kix_list_13-4.start {
    counter-reset: lst-ctn-kix_list_13-4 0
  }

  ol.lst-kix_list_40-3.start {
    counter-reset: lst-ctn-kix_list_40-3 0
  }

  .lst-kix_list_13-0>li {
    counter-increment: lst-ctn-kix_list_13-0
  }

  ol.lst-kix_list_20-2.start {
    counter-reset: lst-ctn-kix_list_20-2 0
  }

  .lst-kix_list_5-0>li {
    counter-increment: lst-ctn-kix_list_5-0
  }

  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0
  }

  ol.lst-kix_list_7-5 {
    list-style-type: none
  }

  ol.lst-kix_list_7-6 {
    list-style-type: none
  }

  ol.lst-kix_list_7-7 {
    list-style-type: none
  }

  ol.lst-kix_list_7-8 {
    list-style-type: none
  }

  ol.lst-kix_list_7-1 {
    list-style-type: none
  }

  ol.lst-kix_list_7-2 {
    list-style-type: none
  }

  ol.lst-kix_list_7-3 {
    list-style-type: none
  }

  ol.lst-kix_list_7-4 {
    list-style-type: none
  }

  ol.lst-kix_list_23-2.start {
    counter-reset: lst-ctn-kix_list_23-2 0
  }

  .lst-kix_list_40-1>li {
    counter-increment: lst-ctn-kix_list_40-1
  }

  ol.lst-kix_list_5-3.start {
    counter-reset: lst-ctn-kix_list_5-3 0
  }

  .lst-kix_list_38-6>li {
    counter-increment: lst-ctn-kix_list_38-6
  }

  .lst-kix_list_4-3>li {
    counter-increment: lst-ctn-kix_list_4-3
  }

  ol.lst-kix_list_17-1.start {
    counter-reset: lst-ctn-kix_list_17-1 0
  }

  .lst-kix_list_24-7>li {
    counter-increment: lst-ctn-kix_list_24-7
  }

  .lst-kix_list_18-8>li {
    counter-increment: lst-ctn-kix_list_18-8
  }

  ol.lst-kix_list_8-8.start {
    counter-reset: lst-ctn-kix_list_8-8 0
  }

  ol.lst-kix_list_26-7.start {
    counter-reset: lst-ctn-kix_list_26-7 0
  }

  .lst-kix_list_7-2>li {
    counter-increment: lst-ctn-kix_list_7-2
  }

  ol.lst-kix_list_10-4.start {
    counter-reset: lst-ctn-kix_list_10-4 0
  }

  .lst-kix_list_24-7>li:before {
    content: ""counter(lst-ctn-kix_list_24-7, lower-latin) ". "
  }

  ol.lst-kix_list_16-5 {
    list-style-type: none
  }

  .lst-kix_list_1-4>li {
    counter-increment: lst-ctn-kix_list_1-4
  }

  ol.lst-kix_list_16-6 {
    list-style-type: none
  }

  .lst-kix_list_24-8>li:before {
    content: ""counter(lst-ctn-kix_list_24-8, lower-roman) ". "
  }

  ol.lst-kix_list_16-7 {
    list-style-type: none
  }

  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0
  }

  ol.lst-kix_list_16-8 {
    list-style-type: none
  }

  ol.lst-kix_list_9-5.start {
    counter-reset: lst-ctn-kix_list_9-5 0
  }

  ol.lst-kix_list_16-1 {
    list-style-type: none
  }

  ol.lst-kix_list_40-8.start {
    counter-reset: lst-ctn-kix_list_40-8 0
  }

  ol.lst-kix_list_16-2 {
    list-style-type: none
  }

  ol.lst-kix_list_16-3 {
    list-style-type: none
  }

  ol.lst-kix_list_16-4 {
    list-style-type: none
  }

  .lst-kix_list_24-2>li:before {
    content: "("counter(lst-ctn-kix_list_24-2, lower-latin) ") "
  }

  ol.lst-kix_list_16-0 {
    list-style-type: none
  }

  ol.lst-kix_list_20-7.start {
    counter-reset: lst-ctn-kix_list_20-7 0
  }

  .lst-kix_list_24-3>li:before {
    content: "("counter(lst-ctn-kix_list_24-3, lower-roman) ") "
  }

  .lst-kix_list_24-4>li:before {
    content: ""counter(lst-ctn-kix_list_24-4, decimal) ") "
  }

  .lst-kix_list_9-4>li {
    counter-increment: lst-ctn-kix_list_9-4
  }

  .lst-kix_list_24-5>li:before {
    content: ""counter(lst-ctn-kix_list_24-5, lower-latin) ") "
  }

  .lst-kix_list_24-6>li:before {
    content: ""counter(lst-ctn-kix_list_24-6, lower-roman) ") "
  }

  ol.lst-kix_list_16-4.start {
    counter-reset: lst-ctn-kix_list_16-4 0
  }

  ol.lst-kix_list_14-1.start {
    counter-reset: lst-ctn-kix_list_14-1 0
  }

  .lst-kix_list_23-6>li:before {
    content: ""counter(lst-ctn-kix_list_23-6, decimal) ". "
  }

  .lst-kix_list_6-5>li {
    counter-increment: lst-ctn-kix_list_6-5
  }

  ol.lst-kix_list_27-6 {
    list-style-type: none
  }

  ol.lst-kix_list_27-5 {
    list-style-type: none
  }

  ol.lst-kix_list_27-8 {
    list-style-type: none
  }

  .lst-kix_list_23-3>li:before {
    content: "("counter(lst-ctn-kix_list_23-3, decimal) ") "
  }

  .lst-kix_list_23-7>li:before {
    content: ""counter(lst-ctn-kix_list_23-7, lower-latin) ". "
  }

  ol.lst-kix_list_27-7 {
    list-style-type: none
  }

  ol.lst-kix_list_27-2 {
    list-style-type: none
  }

  .lst-kix_list_23-2>li:before {
    content: ""counter(lst-ctn-kix_list_23-2, lower-roman) ") "
  }

  ol.lst-kix_list_27-1 {
    list-style-type: none
  }

  ol.lst-kix_list_23-7.start {
    counter-reset: lst-ctn-kix_list_23-7 0
  }

  ol.lst-kix_list_27-4 {
    list-style-type: none
  }

  ol.lst-kix_list_27-3 {
    list-style-type: none
  }

  .lst-kix_list_23-0>li:before {
    content: ""counter(lst-ctn-kix_list_23-0, decimal) ") "
  }

  .lst-kix_list_23-8>li:before {
    content: ""counter(lst-ctn-kix_list_23-8, lower-roman) ". "
  }

  .lst-kix_list_3-6>li {
    counter-increment: lst-ctn-kix_list_3-6
  }

  ol.lst-kix_list_27-0 {
    list-style-type: none
  }

  .lst-kix_list_23-1>li:before {
    content: ""counter(lst-ctn-kix_list_23-1, lower-latin) ") "
  }

  .lst-kix_list_24-1>li:before {
    content: "Section "counter(lst-ctn-kix_list_24-0, upper-roman) "."counter(lst-ctn-kix_list_24-1, decimal-leading-zero) " "
  }

  .lst-kix_list_2-8>li {
    counter-increment: lst-ctn-kix_list_2-8
  }

  .lst-kix_list_24-0>li:before {
    content: "Article "counter(lst-ctn-kix_list_24-0, upper-roman) ". "
  }

  ol.lst-kix_list_26-2.start {
    counter-reset: lst-ctn-kix_list_26-2 0
  }

  .lst-kix_list_23-4>li:before {
    content: "("counter(lst-ctn-kix_list_23-4, lower-latin) ") "
  }

  .lst-kix_list_23-5>li:before {
    content: "("counter(lst-ctn-kix_list_23-5, lower-roman) ") "
  }

  .lst-kix_list_22-2>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) ". "
  }

  .lst-kix_list_22-6>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) "."counter(lst-ctn-kix_list_22-3, decimal) "."counter(lst-ctn-kix_list_22-4, decimal) "."counter(lst-ctn-kix_list_22-5, decimal) "."counter(lst-ctn-kix_list_22-6, decimal) ". "
  }

  ol.lst-kix_list_36-0.start {
    counter-reset: lst-ctn-kix_list_36-0 0
  }

  .lst-kix_list_8-6>li {
    counter-increment: lst-ctn-kix_list_8-6
  }

  .lst-kix_list_22-0>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) ". "
  }

  .lst-kix_list_22-8>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) "."counter(lst-ctn-kix_list_22-3, decimal) "."counter(lst-ctn-kix_list_22-4, decimal) "."counter(lst-ctn-kix_list_22-5, decimal) "."counter(lst-ctn-kix_list_22-6, decimal) "."counter(lst-ctn-kix_list_22-7, decimal) "."counter(lst-ctn-kix_list_22-8, decimal) ". "
  }

  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0
  }

  ol.lst-kix_list_9-7 {
    list-style-type: none
  }

  ol.lst-kix_list_9-8 {
    list-style-type: none
  }

  ol.lst-kix_list_22-5.start {
    counter-reset: lst-ctn-kix_list_22-5 0
  }

  ol.lst-kix_list_39-5.start {
    counter-reset: lst-ctn-kix_list_39-5 0
  }

  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0
  }

  ol.lst-kix_list_9-3 {
    list-style-type: none
  }

  ol.lst-kix_list_9-4 {
    list-style-type: none
  }

  .lst-kix_list_5-7>li {
    counter-increment: lst-ctn-kix_list_5-7
  }

  ol.lst-kix_list_9-5 {
    list-style-type: none
  }

  ol.lst-kix_list_9-6 {
    list-style-type: none
  }

  ol.lst-kix_list_9-0 {
    list-style-type: none
  }

  ol.lst-kix_list_9-1 {
    list-style-type: none
  }

  ol.lst-kix_list_9-2 {
    list-style-type: none
  }

  .lst-kix_list_22-4>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) "."counter(lst-ctn-kix_list_22-3, decimal) "."counter(lst-ctn-kix_list_22-4, decimal) ". "
  }

  ol.lst-kix_list_25-5.start {
    counter-reset: lst-ctn-kix_list_25-5 0
  }

  .lst-kix_list_41-7>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) "."counter(lst-ctn-kix_list_41-3, decimal) "."counter(lst-ctn-kix_list_41-4, decimal) "."counter(lst-ctn-kix_list_41-5, decimal) "."counter(lst-ctn-kix_list_41-6, decimal) "."counter(lst-ctn-kix_list_41-7, decimal) ". "
  }

  .lst-kix_list_25-5>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) "."counter(lst-ctn-kix_list_25-3, decimal) "."counter(lst-ctn-kix_list_25-4, decimal) "."counter(lst-ctn-kix_list_25-5, decimal) ". "
  }

  .lst-kix_list_25-7>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) "."counter(lst-ctn-kix_list_25-3, decimal) "."counter(lst-ctn-kix_list_25-4, decimal) "."counter(lst-ctn-kix_list_25-5, decimal) "."counter(lst-ctn-kix_list_25-6, decimal) "."counter(lst-ctn-kix_list_25-7, decimal) ". "
  }

  .lst-kix_list_3-5>li {
    counter-increment: lst-ctn-kix_list_3-5
  }

  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0
  }

  ol.lst-kix_list_18-3.start {
    counter-reset: lst-ctn-kix_list_18-3 0
  }

  ol.lst-kix_list_24-4.start {
    counter-reset: lst-ctn-kix_list_24-4 0
  }

  ol.lst-kix_list_18-7 {
    list-style-type: none
  }

  ol.lst-kix_list_18-8 {
    list-style-type: none
  }

  ol.lst-kix_list_33-0.start {
    counter-reset: lst-ctn-kix_list_33-0 0
  }

  ol.lst-kix_list_18-3 {
    list-style-type: none
  }

  ol.lst-kix_list_18-4 {
    list-style-type: none
  }

  .lst-kix_list_6-4>li {
    counter-increment: lst-ctn-kix_list_6-4
  }

  .lst-kix_list_41-1>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) ". "
  }

  ol.lst-kix_list_18-5 {
    list-style-type: none
  }

  ul.lst-kix_list_29-0 {
    list-style-type: none
  }

  ol.lst-kix_list_18-6 {
    list-style-type: none
  }

  .lst-kix_list_40-7>li:before {
    content: ""counter(lst-ctn-kix_list_40-7, lower-latin) ". "
  }

  ol.lst-kix_list_27-4.start {
    counter-reset: lst-ctn-kix_list_27-4 0
  }

  ol.lst-kix_list_18-0 {
    list-style-type: none
  }

  ol.lst-kix_list_18-1 {
    list-style-type: none
  }

  .lst-kix_list_9-3>li {
    counter-increment: lst-ctn-kix_list_9-3
  }

  ol.lst-kix_list_18-2 {
    list-style-type: none
  }

  .lst-kix_list_40-5>li:before {
    content: "("counter(lst-ctn-kix_list_40-5, lower-roman) ") "
  }

  ol.lst-kix_list_15-2.start {
    counter-reset: lst-ctn-kix_list_15-2 0
  }

  .lst-kix_list_41-5>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) "."counter(lst-ctn-kix_list_41-3, decimal) "."counter(lst-ctn-kix_list_41-4, decimal) "."counter(lst-ctn-kix_list_41-5, decimal) ". "
  }

  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0
  }

  ol.lst-kix_list_7-6.start {
    counter-reset: lst-ctn-kix_list_7-6 0
  }

  ul.lst-kix_list_29-1 {
    list-style-type: none
  }

  ul.lst-kix_list_29-2 {
    list-style-type: none
  }

  ul.lst-kix_list_29-3 {
    list-style-type: none
  }

  ul.lst-kix_list_29-4 {
    list-style-type: none
  }

  ul.lst-kix_list_29-5 {
    list-style-type: none
  }

  ul.lst-kix_list_29-6 {
    list-style-type: none
  }

  .lst-kix_list_41-3>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) "."counter(lst-ctn-kix_list_41-3, decimal) ". "
  }

  ul.lst-kix_list_29-7 {
    list-style-type: none
  }

  ul.lst-kix_list_29-8 {
    list-style-type: none
  }

  ol.lst-kix_list_15-3.start {
    counter-reset: lst-ctn-kix_list_15-3 0
  }

  ol.lst-kix_list_5-8.start {
    counter-reset: lst-ctn-kix_list_5-8 0
  }

  .lst-kix_list_1-3>li {
    counter-increment: lst-ctn-kix_list_1-3
  }

  .lst-kix_list_40-3>li:before {
    content: "("counter(lst-ctn-kix_list_40-3, decimal) ") "
  }

  ol.lst-kix_list_12-2.start {
    counter-reset: lst-ctn-kix_list_12-2 0
  }

  ol.lst-kix_list_6-0.start {
    counter-reset: lst-ctn-kix_list_6-0 0
  }

  .lst-kix_list_40-1>li:before {
    content: ""counter(lst-ctn-kix_list_40-1, lower-latin) ") "
  }

  .lst-kix_list_4-2>li {
    counter-increment: lst-ctn-kix_list_4-2
  }

  ol.lst-kix_list_3-1 {
    list-style-type: none
  }

  ol.lst-kix_list_38-3.start {
    counter-reset: lst-ctn-kix_list_38-3 0
  }

  ol.lst-kix_list_3-2 {
    list-style-type: none
  }

  .lst-kix_list_24-8>li {
    counter-increment: lst-ctn-kix_list_24-8
  }

  ol.lst-kix_list_3-3 {
    list-style-type: none
  }

  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0
  }

  .lst-kix_list_5-1>li {
    counter-increment: lst-ctn-kix_list_5-1
  }

  ol.lst-kix_list_3-4 {
    list-style-type: none
  }

  ol.lst-kix_list_21-3.start {
    counter-reset: lst-ctn-kix_list_21-3 0
  }

  ol.lst-kix_list_3-0 {
    list-style-type: none
  }

  .lst-kix_list_7-1>li {
    counter-increment: lst-ctn-kix_list_7-1
  }

  .lst-kix_list_38-5>li {
    counter-increment: lst-ctn-kix_list_38-5
  }

  ol.lst-kix_list_25-6.start {
    counter-reset: lst-ctn-kix_list_25-6 0
  }

  ol.lst-kix_list_34-0 {
    list-style-type: none
  }

  .lst-kix_list_21-8>li:before {
    content: ""counter(lst-ctn-kix_list_21-8, lower-roman) ". "
  }

  .lst-kix_list_26-5>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) "."counter(lst-ctn-kix_list_26-3, decimal) "."counter(lst-ctn-kix_list_26-4, decimal) "."counter(lst-ctn-kix_list_26-5, decimal) ". "
  }

  .lst-kix_list_16-0>li {
    counter-increment: lst-ctn-kix_list_16-0
  }

  .lst-kix_list_8-0>li {
    counter-increment: lst-ctn-kix_list_8-0
  }

  .lst-kix_list_10-0>li {
    counter-increment: lst-ctn-kix_list_10-0
  }

  ol.lst-kix_list_18-2.start {
    counter-reset: lst-ctn-kix_list_18-2 0
  }

  ol.lst-kix_list_3-5 {
    list-style-type: none
  }

  ol.lst-kix_list_3-6 {
    list-style-type: none
  }

  .lst-kix_list_26-8>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) "."counter(lst-ctn-kix_list_26-3, decimal) "."counter(lst-ctn-kix_list_26-4, decimal) "."counter(lst-ctn-kix_list_26-5, decimal) "."counter(lst-ctn-kix_list_26-6, decimal) "."counter(lst-ctn-kix_list_26-7, decimal) "."counter(lst-ctn-kix_list_26-8, decimal) ". "
  }

  ol.lst-kix_list_3-7 {
    list-style-type: none
  }

  ol.lst-kix_list_3-8 {
    list-style-type: none
  }

  .lst-kix_list_21-0>li:before {
    content: "Article "counter(lst-ctn-kix_list_21-0, upper-roman) ". "
  }

  ul.lst-kix_list_36-1 {
    list-style-type: none
  }

  ul.lst-kix_list_36-2 {
    list-style-type: none
  }

  .lst-kix_list_26-1>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) ". "
  }

  ul.lst-kix_list_36-3 {
    list-style-type: none
  }

  .lst-kix_list_13-1>li {
    counter-increment: lst-ctn-kix_list_13-1
  }

  ul.lst-kix_list_36-4 {
    list-style-type: none
  }

  .lst-kix_list_21-1>li:before {
    content: "Section "counter(lst-ctn-kix_list_21-0, upper-roman) "."counter(lst-ctn-kix_list_21-1, decimal-leading-zero) " "
  }

  ol.lst-kix_list_15-8.start {
    counter-reset: lst-ctn-kix_list_15-8 0
  }

  .lst-kix_list_26-4>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) "."counter(lst-ctn-kix_list_26-3, decimal) "."counter(lst-ctn-kix_list_26-4, decimal) ". "
  }

  .lst-kix_list_10-2>li {
    counter-increment: lst-ctn-kix_list_10-2
  }

  .lst-kix_list_21-5>li:before {
    content: ""counter(lst-ctn-kix_list_21-5, lower-latin) ") "
  }

  ul.lst-kix_list_36-5 {
    list-style-type: none
  }

  ul.lst-kix_list_36-6 {
    list-style-type: none
  }

  ul.lst-kix_list_36-7 {
    list-style-type: none
  }

  ul.lst-kix_list_36-8 {
    list-style-type: none
  }

  .lst-kix_list_21-4>li:before {
    content: ""counter(lst-ctn-kix_list_21-4, decimal) ") "
  }

  .lst-kix_list_26-0>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) ". "
  }

  ol.lst-kix_list_7-2.start {
    counter-reset: lst-ctn-kix_list_7-2 0
  }

  ol.lst-kix_list_38-8.start {
    counter-reset: lst-ctn-kix_list_38-8 0
  }

  ol.lst-kix_list_19-5.start {
    counter-reset: lst-ctn-kix_list_19-5 -1
  }

  ol.lst-kix_list_12-5 {
    list-style-type: none
  }

  ol.lst-kix_list_12-6 {
    list-style-type: none
  }

  ol.lst-kix_list_12-7 {
    list-style-type: none
  }

  ol.lst-kix_list_12-8 {
    list-style-type: none
  }

  ol.lst-kix_list_12-1 {
    list-style-type: none
  }

  ol.lst-kix_list_12-2 {
    list-style-type: none
  }

  ol.lst-kix_list_12-3 {
    list-style-type: none
  }

  ol.lst-kix_list_39-6.start {
    counter-reset: lst-ctn-kix_list_39-6 0
  }

  ol.lst-kix_list_12-4 {
    list-style-type: none
  }

  ol.lst-kix_list_26-3.start {
    counter-reset: lst-ctn-kix_list_26-3 0
  }

  ol.lst-kix_list_12-0 {
    list-style-type: none
  }

  ol.lst-kix_list_27-8.start {
    counter-reset: lst-ctn-kix_list_27-8 0
  }

  .lst-kix_list_21-0>li {
    counter-increment: lst-ctn-kix_list_21-0
  }

  .lst-kix_list_25-1>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) ". "
  }

  .lst-kix_list_25-0>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) ". "
  }

  ol.lst-kix_list_10-8.start {
    counter-reset: lst-ctn-kix_list_10-8 0
  }

  ol.lst-kix_list_7-1.start {
    counter-reset: lst-ctn-kix_list_7-1 0
  }

  .lst-kix_list_39-0>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) ". "
  }

  ol.lst-kix_list_23-6 {
    list-style-type: none
  }

  ol.lst-kix_list_23-5 {
    list-style-type: none
  }

  ol.lst-kix_list_23-8 {
    list-style-type: none
  }

  .lst-kix_list_39-1>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) ". "
  }

  ol.lst-kix_list_23-7 {
    list-style-type: none
  }

  ol.lst-kix_list_40-7.start {
    counter-reset: lst-ctn-kix_list_40-7 0
  }

  .lst-kix_list_37-0>li {
    counter-increment: lst-ctn-kix_list_37-0
  }

  ol.lst-kix_list_23-2 {
    list-style-type: none
  }

  .lst-kix_list_16-7>li {
    counter-increment: lst-ctn-kix_list_16-7
  }

  ol.lst-kix_list_21-4.start {
    counter-reset: lst-ctn-kix_list_21-4 0
  }

  ol.lst-kix_list_23-1 {
    list-style-type: none
  }

  ol.lst-kix_list_23-4 {
    list-style-type: none
  }

  ol.lst-kix_list_23-3 {
    list-style-type: none
  }

  .lst-kix_list_27-7>li {
    counter-increment: lst-ctn-kix_list_27-7
  }

  ol.lst-kix_list_23-0 {
    list-style-type: none
  }

  ol.lst-kix_list_20-6.start {
    counter-reset: lst-ctn-kix_list_20-6 0
  }

  .lst-kix_list_38-7>li {
    counter-increment: lst-ctn-kix_list_38-7
  }

  .lst-kix_list_13-8>li {
    counter-increment: lst-ctn-kix_list_13-8
  }

  .lst-kix_list_2-2>li {
    counter-increment: lst-ctn-kix_list_2-2
  }

  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0
  }

  .lst-kix_list_16-5>li {
    counter-increment: lst-ctn-kix_list_16-5
  }

  ol.lst-kix_list_5-0 {
    list-style-type: none
  }

  .lst-kix_list_3-7>li {
    counter-increment: lst-ctn-kix_list_3-7
  }

  ol.lst-kix_list_5-1 {
    list-style-type: none
  }

  .lst-kix_list_26-2>li {
    counter-increment: lst-ctn-kix_list_26-2
  }

  ol.lst-kix_list_5-2 {
    list-style-type: none
  }

  .lst-kix_list_40-8>li {
    counter-increment: lst-ctn-kix_list_40-8
  }

  .lst-kix_list_22-5>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) "."counter(lst-ctn-kix_list_22-3, decimal) "."counter(lst-ctn-kix_list_22-4, decimal) "."counter(lst-ctn-kix_list_22-5, decimal) ". "
  }

  .lst-kix_list_21-2>li {
    counter-increment: lst-ctn-kix_list_21-2
  }

  .lst-kix_list_22-1>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) ". "
  }

  ol.lst-kix_list_36-0 {
    list-style-type: none
  }

  .lst-kix_list_27-4>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) "."counter(lst-ctn-kix_list_27-3, decimal) "."counter(lst-ctn-kix_list_27-4, decimal) ". "
  }

  .lst-kix_list_20-2>li {
    counter-increment: lst-ctn-kix_list_20-2
  }

  .lst-kix_list_6-6>li {
    counter-increment: lst-ctn-kix_list_6-6
  }

  ol.lst-kix_list_15-7.start {
    counter-reset: lst-ctn-kix_list_15-7 0
  }

  .lst-kix_list_23-3>li {
    counter-increment: lst-ctn-kix_list_23-3
  }

  .lst-kix_list_13-6>li {
    counter-increment: lst-ctn-kix_list_13-6
  }

  ol.lst-kix_list_14-6.start {
    counter-reset: lst-ctn-kix_list_14-6 0
  }

  .lst-kix_list_39-4>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) "."counter(lst-ctn-kix_list_39-3, decimal) "."counter(lst-ctn-kix_list_39-4, decimal) ". "
  }

  .lst-kix_list_39-8>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) "."counter(lst-ctn-kix_list_39-3, decimal) "."counter(lst-ctn-kix_list_39-4, decimal) "."counter(lst-ctn-kix_list_39-5, decimal) "."counter(lst-ctn-kix_list_39-6, decimal) "."counter(lst-ctn-kix_list_39-7, decimal) "."counter(lst-ctn-kix_list_39-8, decimal) ". "
  }

  ol.lst-kix_list_5-7 {
    list-style-type: none
  }

  ol.lst-kix_list_5-8 {
    list-style-type: none
  }

  ol.lst-kix_list_26-8.start {
    counter-reset: lst-ctn-kix_list_26-8 0
  }

  ol.lst-kix_list_5-3 {
    list-style-type: none
  }

  .lst-kix_list_8-7>li {
    counter-increment: lst-ctn-kix_list_8-7
  }

  .lst-kix_list_19-6>li {
    counter-increment: lst-ctn-kix_list_19-6
  }

  ol.lst-kix_list_5-4 {
    list-style-type: none
  }

  .lst-kix_list_27-0>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) ". "
  }

  ol.lst-kix_list_5-5 {
    list-style-type: none
  }

  ol.lst-kix_list_5-6 {
    list-style-type: none
  }

  .lst-kix_list_20-4>li {
    counter-increment: lst-ctn-kix_list_20-4
  }

  .lst-kix_list_9-5>li {
    counter-increment: lst-ctn-kix_list_9-5
  }

  .lst-kix_list_5-8>li {
    counter-increment: lst-ctn-kix_list_5-8
  }

  .lst-kix_list_27-0>li {
    counter-increment: lst-ctn-kix_list_27-0
  }

  .lst-kix_list_41-6>li {
    counter-increment: lst-ctn-kix_list_41-6
  }

  ol.lst-kix_list_19-4.start {
    counter-reset: lst-ctn-kix_list_19-4 -1
  }

  .lst-kix_list_38-0>li {
    counter-increment: lst-ctn-kix_list_38-0
  }

  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0
  }

  ol.lst-kix_list_20-1.start {
    counter-reset: lst-ctn-kix_list_20-1 0
  }

  .lst-kix_list_25-4>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) "."counter(lst-ctn-kix_list_25-3, decimal) "."counter(lst-ctn-kix_list_25-4, decimal) ". "
  }

  .lst-kix_list_19-4>li {
    counter-increment: lst-ctn-kix_list_19-4
  }

  .lst-kix_list_24-1>li {
    counter-increment: lst-ctn-kix_list_24-1
  }

  .lst-kix_list_25-8>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) "."counter(lst-ctn-kix_list_25-3, decimal) "."counter(lst-ctn-kix_list_25-4, decimal) "."counter(lst-ctn-kix_list_25-5, decimal) "."counter(lst-ctn-kix_list_25-6, decimal) "."counter(lst-ctn-kix_list_25-7, decimal) "."counter(lst-ctn-kix_list_25-8, decimal) ". "
  }

  ol.lst-kix_list_38-4.start {
    counter-reset: lst-ctn-kix_list_38-4 0
  }

  ol.lst-kix_list_25-1.start {
    counter-reset: lst-ctn-kix_list_25-1 0
  }

  ol.lst-kix_list_14-7 {
    list-style-type: none
  }

  ol.lst-kix_list_14-8 {
    list-style-type: none
  }

  ol.lst-kix_list_26-6.start {
    counter-reset: lst-ctn-kix_list_26-6 0
  }

  ol.lst-kix_list_14-3 {
    list-style-type: none
  }

  ol.lst-kix_list_21-8.start {
    counter-reset: lst-ctn-kix_list_21-8 0
  }

  ol.lst-kix_list_14-4 {
    list-style-type: none
  }

  ol.lst-kix_list_14-5 {
    list-style-type: none
  }

  ol.lst-kix_list_14-6 {
    list-style-type: none
  }

  .lst-kix_list_15-2>li {
    counter-increment: lst-ctn-kix_list_15-2
  }

  ol.lst-kix_list_14-0 {
    list-style-type: none
  }

  .lst-kix_list_22-5>li {
    counter-increment: lst-ctn-kix_list_22-5
  }

  .lst-kix_list_27-5>li {
    counter-increment: lst-ctn-kix_list_27-5
  }

  .lst-kix_list_40-6>li:before {
    content: ""counter(lst-ctn-kix_list_40-6, decimal) ". "
  }

  ol.lst-kix_list_14-1 {
    list-style-type: none
  }

  ol.lst-kix_list_14-2 {
    list-style-type: none
  }

  .lst-kix_list_41-6>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) "."counter(lst-ctn-kix_list_41-3, decimal) "."counter(lst-ctn-kix_list_41-4, decimal) "."counter(lst-ctn-kix_list_41-5, decimal) "."counter(lst-ctn-kix_list_41-6, decimal) ". "
  }

  .lst-kix_list_20-5>li:before {
    content: ""counter(lst-ctn-kix_list_20-5, lower-latin) ") "
  }

  .lst-kix_list_28-8>li:before {
    content: " "
  }

  .lst-kix_list_41-2>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) ". "
  }

  .lst-kix_list_20-1>li:before {
    content: "Section "counter(lst-ctn-kix_list_20-0, upper-roman) "."counter(lst-ctn-kix_list_20-1, decimal-leading-zero) " "
  }

  .lst-kix_list_12-3>li {
    counter-increment: lst-ctn-kix_list_12-3
  }

  .lst-kix_list_24-6>li {
    counter-increment: lst-ctn-kix_list_24-6
  }

  .lst-kix_list_17-3>li {
    counter-increment: lst-ctn-kix_list_17-3
  }

  ol.lst-kix_list_8-4.start {
    counter-reset: lst-ctn-kix_list_8-4 0
  }

  .lst-kix_list_28-4>li:before {
    content: " "
  }

  .lst-kix_list_39-3>li {
    counter-increment: lst-ctn-kix_list_39-3
  }

  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0
  }

  .lst-kix_list_21-7>li {
    counter-increment: lst-ctn-kix_list_21-7
  }

  ol.lst-kix_list_25-0.start {
    counter-reset: lst-ctn-kix_list_25-0 0
  }

  ol.lst-kix_list_25-8 {
    list-style-type: none
  }

  ol.lst-kix_list_25-7 {
    list-style-type: none
  }

  ol.lst-kix_list_25-4 {
    list-style-type: none
  }

  ol.lst-kix_list_25-3 {
    list-style-type: none
  }

  ol.lst-kix_list_13-0.start {
    counter-reset: lst-ctn-kix_list_13-0 0
  }

  .lst-kix_list_14-4>li {
    counter-increment: lst-ctn-kix_list_14-4
  }

  ol.lst-kix_list_25-6 {
    list-style-type: none
  }

  ol.lst-kix_list_25-5 {
    list-style-type: none
  }

  ol.lst-kix_list_25-0 {
    list-style-type: none
  }

  ol.lst-kix_list_25-2 {
    list-style-type: none
  }

  .lst-kix_list_10-7>li {
    counter-increment: lst-ctn-kix_list_10-7
  }

  ol.lst-kix_list_25-1 {
    list-style-type: none
  }

  .lst-kix_list_25-4>li {
    counter-increment: lst-ctn-kix_list_25-4
  }

  .lst-kix_list_18-1>li {
    counter-increment: lst-ctn-kix_list_18-1
  }

  ol.lst-kix_list_8-3.start {
    counter-reset: lst-ctn-kix_list_8-3 0
  }

  .lst-kix_list_11-5>li {
    counter-increment: lst-ctn-kix_list_11-5
  }

  .lst-kix_list_28-0>li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_14-5.start {
    counter-reset: lst-ctn-kix_list_14-5 0
  }

  .lst-kix_list_27-8>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) "."counter(lst-ctn-kix_list_27-3, decimal) "."counter(lst-ctn-kix_list_27-4, decimal) "."counter(lst-ctn-kix_list_27-5, decimal) "."counter(lst-ctn-kix_list_27-6, decimal) "."counter(lst-ctn-kix_list_27-7, decimal) "."counter(lst-ctn-kix_list_27-8, decimal) ". "
  }

  .lst-kix_list_40-2>li:before {
    content: ""counter(lst-ctn-kix_list_40-2, lower-roman) ") "
  }

  ol.lst-kix_list_20-3.start {
    counter-reset: lst-ctn-kix_list_20-3 0
  }

  .lst-kix_list_4-1>li {
    counter-increment: lst-ctn-kix_list_4-1
  }

  .lst-kix_list_19-1>li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_41-1.start {
    counter-reset: lst-ctn-kix_list_41-1 0
  }

  .lst-kix_list_27-8>li {
    counter-increment: lst-ctn-kix_list_27-8
  }

  .lst-kix_list_19-4>li:before {
    content: " "
  }

  .lst-kix_list_19-3>li:before {
    content: " "
  }

  ol.lst-kix_list_38-0.start {
    counter-reset: lst-ctn-kix_list_38-0 0
  }

  .lst-kix_list_15-0>li {
    counter-increment: lst-ctn-kix_list_15-0
  }

  ol.lst-kix_list_24-5.start {
    counter-reset: lst-ctn-kix_list_24-5 0
  }

  ol.lst-kix_list_6-6.start {
    counter-reset: lst-ctn-kix_list_6-6 0
  }

  .lst-kix_list_39-5>li {
    counter-increment: lst-ctn-kix_list_39-5
  }

  .lst-kix_list_11-0>li {
    counter-increment: lst-ctn-kix_list_11-0
  }

  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0
  }

  ul.lst-kix_list_32-1 {
    list-style-type: none
  }

  ul.lst-kix_list_32-2 {
    list-style-type: none
  }

  ul.lst-kix_list_32-3 {
    list-style-type: none
  }

  ul.lst-kix_list_32-4 {
    list-style-type: none
  }

  ol.lst-kix_list_9-6.start {
    counter-reset: lst-ctn-kix_list_9-6 0
  }

  ul.lst-kix_list_32-5 {
    list-style-type: none
  }

  ol.lst-kix_list_16-3.start {
    counter-reset: lst-ctn-kix_list_16-3 0
  }

  ul.lst-kix_list_32-6 {
    list-style-type: none
  }

  ol.lst-kix_list_27-5.start {
    counter-reset: lst-ctn-kix_list_27-5 0
  }

  ul.lst-kix_list_32-7 {
    list-style-type: none
  }

  ul.lst-kix_list_32-8 {
    list-style-type: none
  }

  ol.lst-kix_list_22-4.start {
    counter-reset: lst-ctn-kix_list_22-4 0
  }

  .lst-kix_list_22-7>li {
    counter-increment: lst-ctn-kix_list_22-7
  }

  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0
  }

  ul.lst-kix_list_32-0 {
    list-style-type: none
  }

  .lst-kix_list_37-0>li:before {
    content: ""counter(lst-ctn-kix_list_37-0, decimal) ". "
  }

  .lst-kix_list_26-7>li {
    counter-increment: lst-ctn-kix_list_26-7
  }

  .lst-kix_list_41-0>li {
    counter-increment: lst-ctn-kix_list_41-0
  }

  ol.lst-kix_list_11-2.start {
    counter-reset: lst-ctn-kix_list_11-2 0
  }

  .lst-kix_list_5-2>li {
    counter-increment: lst-ctn-kix_list_5-2
  }

  .lst-kix_list_19-6>li:before {
    content: " "
  }

  .lst-kix_list_37-8>li:before {
    content: " "
  }

  ol.lst-kix_list_8-7.start {
    counter-reset: lst-ctn-kix_list_8-7 0
  }

  .lst-kix_list_17-2>li {
    counter-increment: lst-ctn-kix_list_17-2
  }

  ol.lst-kix_list_41-6.start {
    counter-reset: lst-ctn-kix_list_41-6 0
  }

  .lst-kix_list_37-6>li:before {
    content: " "
  }

  .lst-kix_list_20-5>li {
    counter-increment: lst-ctn-kix_list_20-5
  }

  ol.lst-kix_list_35-0.start {
    counter-reset: lst-ctn-kix_list_35-0 0
  }

  .lst-kix_list_37-1>li:before {
    content: " "
  }

  .lst-kix_list_22-0>li {
    counter-increment: lst-ctn-kix_list_22-0
  }

  ol.lst-kix_list_24-0.start {
    counter-reset: lst-ctn-kix_list_24-0 0
  }

  .lst-kix_list_18-3>li {
    counter-increment: lst-ctn-kix_list_18-3
  }

  .lst-kix_list_37-3>li:before {
    content: " "
  }

  .lst-kix_list_21-6>li {
    counter-increment: lst-ctn-kix_list_21-6
  }

  .lst-kix_list_10-3>li {
    counter-increment: lst-ctn-kix_list_10-3
  }

  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0
  }

  .lst-kix_list_18-0>li:before {
    content: "Chapter "counter(lst-ctn-kix_list_18-0, decimal) " "
  }

  ol.lst-kix_list_13-3.start {
    counter-reset: lst-ctn-kix_list_13-3 0
  }

  .lst-kix_list_38-7>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) "."counter(lst-ctn-kix_list_38-3, decimal) "."counter(lst-ctn-kix_list_38-4, decimal) "."counter(lst-ctn-kix_list_38-5, decimal) "."counter(lst-ctn-kix_list_38-6, decimal) "."counter(lst-ctn-kix_list_38-7, decimal) ". "
  }

  .lst-kix_list_3-0>li {
    counter-increment: lst-ctn-kix_list_3-0
  }

  .lst-kix_list_18-2>li:before {
    content: " "
  }

  .lst-kix_list_26-0>li {
    counter-increment: lst-ctn-kix_list_26-0
  }

  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0
  }

  ol.lst-kix_list_27-0.start {
    counter-reset: lst-ctn-kix_list_27-0 0
  }

  .lst-kix_list_27-4>li {
    counter-increment: lst-ctn-kix_list_27-4
  }

  .lst-kix_list_38-2>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) ". "
  }

  .lst-kix_list_38-4>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) "."counter(lst-ctn-kix_list_38-3, decimal) "."counter(lst-ctn-kix_list_38-4, decimal) ". "
  }

  ol.lst-kix_list_11-7.start {
    counter-reset: lst-ctn-kix_list_11-7 0
  }

  ol.lst-kix_list_14-2.start {
    counter-reset: lst-ctn-kix_list_14-2 0
  }

  .lst-kix_list_16-1>li {
    counter-increment: lst-ctn-kix_list_16-1
  }

  .lst-kix_list_41-4>li {
    counter-increment: lst-ctn-kix_list_41-4
  }

  .lst-kix_list_27-1>li {
    counter-increment: lst-ctn-kix_list_27-1
  }

  .lst-kix_list_38-4>li {
    counter-increment: lst-ctn-kix_list_38-4
  }

  .lst-kix_list_38-5>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) "."counter(lst-ctn-kix_list_38-3, decimal) "."counter(lst-ctn-kix_list_38-4, decimal) "."counter(lst-ctn-kix_list_38-5, decimal) ". "
  }

  .lst-kix_list_25-2>li {
    counter-increment: lst-ctn-kix_list_25-2
  }

  .lst-kix_list_27-1>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) ". "
  }

  .lst-kix_list_27-3>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) "."counter(lst-ctn-kix_list_27-3, decimal) ". "
  }

  .lst-kix_list_17-5>li {
    counter-increment: lst-ctn-kix_list_17-5
  }

  ol.lst-kix_list_9-4.start {
    counter-reset: lst-ctn-kix_list_9-4 0
  }

  ol.lst-kix_list_41-8.start {
    counter-reset: lst-ctn-kix_list_41-8 0
  }

  .lst-kix_list_21-3>li {
    counter-increment: lst-ctn-kix_list_21-3
  }

  .lst-kix_list_18-8>li:before {
    content: " "
  }

  ol.lst-kix_list_27-3.start {
    counter-reset: lst-ctn-kix_list_27-3 0
  }

  .lst-kix_list_17-6>li {
    counter-increment: lst-ctn-kix_list_17-6
  }

  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0
  }

  .lst-kix_list_7-8>li {
    counter-increment: lst-ctn-kix_list_7-8
  }

  .lst-kix_list_10-7>li:before {
    content: ""counter(lst-ctn-kix_list_10-7, lower-latin) ". "
  }

  .lst-kix_list_20-1>li {
    counter-increment: lst-ctn-kix_list_20-1
  }

  ul.lst-kix_list_34-1 {
    list-style-type: none
  }

  .lst-kix_list_36-4>li:before {
    content: " "
  }

  ul.lst-kix_list_34-2 {
    list-style-type: none
  }

  ul.lst-kix_list_34-3 {
    list-style-type: none
  }

  .lst-kix_list_10-5>li:before {
    content: ""counter(lst-ctn-kix_list_10-5, lower-roman) ". "
  }

  ol.lst-kix_list_13-5.start {
    counter-reset: lst-ctn-kix_list_13-5 0
  }

  ul.lst-kix_list_34-4 {
    list-style-type: none
  }

  ol.lst-kix_list_18-6.start {
    counter-reset: lst-ctn-kix_list_18-6 0
  }

  ul.lst-kix_list_34-5 {
    list-style-type: none
  }

  ul.lst-kix_list_34-6 {
    list-style-type: none
  }

  ol.lst-kix_list_20-8 {
    list-style-type: none
  }

  ol.lst-kix_list_13-8.start {
    counter-reset: lst-ctn-kix_list_13-8 0
  }

  ol.lst-kix_list_20-5 {
    list-style-type: none
  }

  ol.lst-kix_list_20-4 {
    list-style-type: none
  }

  ol.lst-kix_list_20-7 {
    list-style-type: none
  }

  .lst-kix_list_11-7>li {
    counter-increment: lst-ctn-kix_list_11-7
  }

  ol.lst-kix_list_20-6 {
    list-style-type: none
  }

  .lst-kix_list_9-2>li:before {
    content: ""counter(lst-ctn-kix_list_9-2, lower-roman) ". "
  }

  ol.lst-kix_list_20-1 {
    list-style-type: none
  }

  ol.lst-kix_list_20-0 {
    list-style-type: none
  }

  ol.lst-kix_list_20-3 {
    list-style-type: none
  }

  ol.lst-kix_list_20-2 {
    list-style-type: none
  }

  ol.lst-kix_list_14-0.start {
    counter-reset: lst-ctn-kix_list_14-0 0
  }

  .lst-kix_list_12-5>li {
    counter-increment: lst-ctn-kix_list_12-5
  }

  ul.lst-kix_list_34-7 {
    list-style-type: none
  }

  ul.lst-kix_list_34-8 {
    list-style-type: none
  }

  .lst-kix_list_5-5>li {
    counter-increment: lst-ctn-kix_list_5-5
  }

  .lst-kix_list_36-2>li:before {
    content: " "
  }

  .lst-kix_list_9-0>li:before {
    content: ""counter(lst-ctn-kix_list_9-0, lower-latin) ". "
  }

  .lst-kix_list_16-8>li {
    counter-increment: lst-ctn-kix_list_16-8
  }

  ol.lst-kix_list_24-3.start {
    counter-reset: lst-ctn-kix_list_24-3 0
  }

  .lst-kix_list_23-5>li {
    counter-increment: lst-ctn-kix_list_23-5
  }

  .lst-kix_list_11-3>li:before {
    content: ""counter(lst-ctn-kix_list_11-3, decimal) ". "
  }

  .lst-kix_list_38-8>li {
    counter-increment: lst-ctn-kix_list_38-8
  }

  .lst-kix_list_6-3>li {
    counter-increment: lst-ctn-kix_list_6-3
  }

  ol.lst-kix_list_18-4.start {
    counter-reset: lst-ctn-kix_list_18-4 0
  }

  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0
  }

  ol.lst-kix_list_33-0 {
    list-style-type: none
  }

  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0
  }

  .lst-kix_list_29-1>li:before {
    content: " "
  }

  .lst-kix_list_20-4>li:before {
    content: ""counter(lst-ctn-kix_list_20-4, decimal) ") "
  }

  ol.lst-kix_list_22-7.start {
    counter-reset: lst-ctn-kix_list_22-7 0
  }

  ol.lst-kix_list_6-1.start {
    counter-reset: lst-ctn-kix_list_6-1 0
  }

  .lst-kix_list_29-3>li:before {
    content: " "
  }

  .lst-kix_list_20-2>li:before {
    content: "("counter(lst-ctn-kix_list_20-2, lower-latin) ") "
  }

  .lst-kix_list_9-8>li:before {
    content: ""counter(lst-ctn-kix_list_9-8, lower-roman) ". "
  }

  ol.lst-kix_list_16-8.start {
    counter-reset: lst-ctn-kix_list_16-8 0
  }

  .lst-kix_list_28-7>li:before {
    content: " "
  }

  .lst-kix_list_4-8>li {
    counter-increment: lst-ctn-kix_list_4-8
  }

  .lst-kix_list_1-7>li:before {
    content: " "
  }

  .lst-kix_list_1-5>li:before {
    content: " "
  }

  .lst-kix_list_28-5>li:before {
    content: " "
  }

  ol.lst-kix_list_9-1.start {
    counter-reset: lst-ctn-kix_list_9-1 0
  }

  ol.lst-kix_list_24-2.start {
    counter-reset: lst-ctn-kix_list_24-2 0
  }

  .lst-kix_list_5-6>li {
    counter-increment: lst-ctn-kix_list_5-6
  }

  ol.lst-kix_list_22-6.start {
    counter-reset: lst-ctn-kix_list_22-6 0
  }

  .lst-kix_list_2-1>li:before {
    content: ""counter(lst-ctn-kix_list_2-1, lower-latin) ". "
  }

  .lst-kix_list_19-8>li {
    counter-increment: lst-ctn-kix_list_19-8
  }

  .lst-kix_list_2-3>li:before {
    content: ""counter(lst-ctn-kix_list_2-3, decimal) ". "
  }

  .lst-kix_list_11-8>li {
    counter-increment: lst-ctn-kix_list_11-8
  }

  .lst-kix_list_35-3>li:before {
    content: " "
  }

  ol.lst-kix_list_24-8.start {
    counter-reset: lst-ctn-kix_list_24-8 0
  }

  .lst-kix_list_30-4>li:before {
    content: " "
  }

  .lst-kix_list_20-8>li {
    counter-increment: lst-ctn-kix_list_20-8
  }

  .lst-kix_list_35-6>li:before {
    content: " "
  }

  .lst-kix_list_9-1>li {
    counter-increment: lst-ctn-kix_list_9-1
  }

  .lst-kix_list_3-2>li:before {
    content: "("counter(lst-ctn-kix_list_3-2, lower-latin) ") "
  }

  .lst-kix_list_26-7>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) "."counter(lst-ctn-kix_list_26-3, decimal) "."counter(lst-ctn-kix_list_26-4, decimal) "."counter(lst-ctn-kix_list_26-5, decimal) "."counter(lst-ctn-kix_list_26-6, decimal) "."counter(lst-ctn-kix_list_26-7, decimal) ". "
  }

  .lst-kix_list_8-1>li:before {
    content: "["counter(lst-ctn-kix_list_8-0, decimal) "."counter(lst-ctn-kix_list_8-1, decimal) " "
  }

  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0
  }

  .lst-kix_list_6-0>li {
    counter-increment: lst-ctn-kix_list_6-0
  }

  .lst-kix_list_3-5>li:before {
    content: " "
  }

  .lst-kix_list_18-0>li {
    counter-increment: lst-ctn-kix_list_18-0
  }

  .lst-kix_list_30-7>li:before {
    content: " "
  }

  .lst-kix_list_40-2>li {
    counter-increment: lst-ctn-kix_list_40-2
  }

  ol.lst-kix_list_11-5.start {
    counter-reset: lst-ctn-kix_list_11-5 0
  }

  .lst-kix_list_8-6>li:before {
    content: " "
  }

  .lst-kix_list_11-1>li {
    counter-increment: lst-ctn-kix_list_11-1
  }

  .lst-kix_list_26-2>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) ". "
  }

  .lst-kix_list_21-6>li:before {
    content: ""counter(lst-ctn-kix_list_21-6, lower-roman) ") "
  }

  .lst-kix_list_41-3>li {
    counter-increment: lst-ctn-kix_list_41-3
  }

  ol.lst-kix_list_16-6.start {
    counter-reset: lst-ctn-kix_list_16-6 0
  }

  ol.lst-kix_list_41-4.start {
    counter-reset: lst-ctn-kix_list_41-4 0
  }

  ol.lst-kix_list_22-1.start {
    counter-reset: lst-ctn-kix_list_22-1 0
  }

  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0
  }

  ol.lst-kix_list_16-0.start {
    counter-reset: lst-ctn-kix_list_16-0 0
  }

  ol.lst-kix_list_27-2.start {
    counter-reset: lst-ctn-kix_list_27-2 0
  }

  .lst-kix_list_21-3>li:before {
    content: "("counter(lst-ctn-kix_list_21-3, lower-roman) ") "
  }

  ol.lst-kix_list_18-7.start {
    counter-reset: lst-ctn-kix_list_18-7 0
  }

  .lst-kix_list_25-5>li {
    counter-increment: lst-ctn-kix_list_25-5
  }

  ol.lst-kix_list_11-6.start {
    counter-reset: lst-ctn-kix_list_11-6 0
  }

  .lst-kix_list_4-4>li {
    counter-increment: lst-ctn-kix_list_4-4
  }

  ol.lst-kix_list_6-4.start {
    counter-reset: lst-ctn-kix_list_6-4 0
  }

  .lst-kix_list_17-1>li:before {
    content: ""counter(lst-ctn-kix_list_17-1, lower-latin) ". "
  }

  .lst-kix_list_25-3>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) "."counter(lst-ctn-kix_list_25-3, decimal) ". "
  }

  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0
  }

  .lst-kix_list_39-2>li {
    counter-increment: lst-ctn-kix_list_39-2
  }

  .lst-kix_list_16-2>li:before {
    content: ""counter(lst-ctn-kix_list_16-2, lower-roman) ". "
  }

  ol.lst-kix_list_27-1.start {
    counter-reset: lst-ctn-kix_list_27-1 0
  }

  .lst-kix_list_26-6>li {
    counter-increment: lst-ctn-kix_list_26-6
  }

  .lst-kix_list_16-5>li:before {
    content: ""counter(lst-ctn-kix_list_16-5, lower-roman) ". "
  }

  .lst-kix_list_15-3>li {
    counter-increment: lst-ctn-kix_list_15-3
  }

  ol.lst-kix_list_22-2.start {
    counter-reset: lst-ctn-kix_list_22-2 0
  }

  ol.lst-kix_list_41-5.start {
    counter-reset: lst-ctn-kix_list_41-5 0
  }

  ol.lst-kix_list_11-0.start {
    counter-reset: lst-ctn-kix_list_11-0 0
  }

  ol.lst-kix_list_18-8.start {
    counter-reset: lst-ctn-kix_list_18-8 0
  }

  .lst-kix_list_3-3>li {
    counter-increment: lst-ctn-kix_list_3-3
  }

  .lst-kix_list_16-4>li {
    counter-increment: lst-ctn-kix_list_16-4
  }

  .lst-kix_list_39-3>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) "."counter(lst-ctn-kix_list_39-3, decimal) ". "
  }

  ol.lst-kix_list_6-3.start {
    counter-reset: lst-ctn-kix_list_6-3 0
  }

  .lst-kix_list_38-1>li {
    counter-increment: lst-ctn-kix_list_38-1
  }

  ol.lst-kix_list_16-5.start {
    counter-reset: lst-ctn-kix_list_16-5 0
  }

  .lst-kix_list_17-6>li:before {
    content: ""counter(lst-ctn-kix_list_17-6, decimal) ". "
  }

  .lst-kix_list_41-7>li {
    counter-increment: lst-ctn-kix_list_41-7
  }

  .lst-kix_list_2-6>li:before {
    content: ""counter(lst-ctn-kix_list_2-6, decimal) ". "
  }

  ol.lst-kix_list_16-2.start {
    counter-reset: lst-ctn-kix_list_16-2 0
  }

  .lst-kix_list_14-5>li {
    counter-increment: lst-ctn-kix_list_14-5
  }

  .lst-kix_list_7-5>li:before {
    content: " "
  }

  .lst-kix_list_27-6>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) "."counter(lst-ctn-kix_list_27-3, decimal) "."counter(lst-ctn-kix_list_27-4, decimal) "."counter(lst-ctn-kix_list_27-5, decimal) "."counter(lst-ctn-kix_list_27-6, decimal) ". "
  }

  .lst-kix_list_19-5>li {
    counter-increment: lst-ctn-kix_list_19-5
  }

  .lst-kix_list_22-7>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) "."counter(lst-ctn-kix_list_22-3, decimal) "."counter(lst-ctn-kix_list_22-4, decimal) "."counter(lst-ctn-kix_list_22-5, decimal) "."counter(lst-ctn-kix_list_22-6, decimal) "."counter(lst-ctn-kix_list_22-7, decimal) ". "
  }

  .lst-kix_list_23-2>li {
    counter-increment: lst-ctn-kix_list_23-2
  }

  ol.lst-kix_list_11-1.start {
    counter-reset: lst-ctn-kix_list_11-1 0
  }

  .lst-kix_list_34-7>li:before {
    content: " "
  }

  .lst-kix_list_18-5>li:before {
    content: " "
  }

  .lst-kix_list_13-6>li:before {
    content: ""counter(lst-ctn-kix_list_13-6, decimal) ". "
  }

  .lst-kix_list_6-7>li {
    counter-increment: lst-ctn-kix_list_6-7
  }

  .lst-kix_list_26-3>li {
    counter-increment: lst-ctn-kix_list_26-3
  }

  .lst-kix_list_10-6>li {
    counter-increment: lst-ctn-kix_list_10-6
  }

  .lst-kix_list_1-7>li {
    counter-increment: lst-ctn-kix_list_1-7
  }

  ol.lst-kix_list_41-0.start {
    counter-reset: lst-ctn-kix_list_41-0 0
  }

  .lst-kix_list_39-6>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) "."counter(lst-ctn-kix_list_39-3, decimal) "."counter(lst-ctn-kix_list_39-4, decimal) "."counter(lst-ctn-kix_list_39-5, decimal) "."counter(lst-ctn-kix_list_39-6, decimal) ". "
  }

  ul.lst-kix_list_30-3 {
    list-style-type: none
  }

  ul.lst-kix_list_30-4 {
    list-style-type: none
  }

  ul.lst-kix_list_30-5 {
    list-style-type: none
  }

  .lst-kix_list_7-5>li {
    counter-increment: lst-ctn-kix_list_7-5
  }

  .lst-kix_list_15-6>li:before {
    content: " "
  }

  ul.lst-kix_list_30-6 {
    list-style-type: none
  }

  .lst-kix_list_11-4>li {
    counter-increment: lst-ctn-kix_list_11-4
  }

  ol.lst-kix_list_24-7.start {
    counter-reset: lst-ctn-kix_list_24-7 0
  }

  ul.lst-kix_list_30-7 {
    list-style-type: none
  }

  ul.lst-kix_list_30-8 {
    list-style-type: none
  }

  .lst-kix_list_22-4>li {
    counter-increment: lst-ctn-kix_list_22-4
  }

  .lst-kix_list_31-3>li:before {
    content: " "
  }

  .lst-kix_list_36-7>li:before {
    content: " "
  }

  ul.lst-kix_list_30-0 {
    list-style-type: none
  }

  ol.lst-kix_list_6-8.start {
    counter-reset: lst-ctn-kix_list_6-8 0
  }

  .lst-kix_list_10-2>li:before {
    content: ""counter(lst-ctn-kix_list_10-2, lower-roman) ". "
  }

  ul.lst-kix_list_30-1 {
    list-style-type: none
  }

  ul.lst-kix_list_30-2 {
    list-style-type: none
  }

  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0
  }

  .lst-kix_list_13-7>li {
    counter-increment: lst-ctn-kix_list_13-7
  }

  .lst-kix_list_20-7>li:before {
    content: ""counter(lst-ctn-kix_list_20-7, lower-latin) ". "
  }

  ol.lst-kix_list_6-5.start {
    counter-reset: lst-ctn-kix_list_6-5 0
  }

  .lst-kix_list_4-6>li:before {
    content: ""counter(lst-ctn-kix_list_4-6, decimal) " "
  }

  .lst-kix_list_41-8>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) "."counter(lst-ctn-kix_list_41-3, decimal) "."counter(lst-ctn-kix_list_41-4, decimal) "."counter(lst-ctn-kix_list_41-5, decimal) "."counter(lst-ctn-kix_list_41-6, decimal) "."counter(lst-ctn-kix_list_41-7, decimal) "."counter(lst-ctn-kix_list_41-8, decimal) ". "
  }

  .lst-kix_list_25-6>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) "."counter(lst-ctn-kix_list_25-3, decimal) "."counter(lst-ctn-kix_list_25-4, decimal) "."counter(lst-ctn-kix_list_25-5, decimal) "."counter(lst-ctn-kix_list_25-6, decimal) ". "
  }

  .lst-kix_list_41-0>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) ". "
  }

  ol.lst-kix_list_6-7.start {
    counter-reset: lst-ctn-kix_list_6-7 0
  }

  .lst-kix_list_12-2>li {
    counter-increment: lst-ctn-kix_list_12-2
  }

  .lst-kix_list_9-5>li:before {
    content: ""counter(lst-ctn-kix_list_9-5, lower-roman) ". "
  }

  .lst-kix_list_29-6>li:before {
    content: " "
  }

  ol.lst-kix_list_22-0.start {
    counter-reset: lst-ctn-kix_list_22-0 0
  }

  .lst-kix_list_24-0>li {
    counter-increment: lst-ctn-kix_list_24-0
  }

  .lst-kix_list_33-3>li:before {
    content: " "
  }

  ol.lst-kix_list_41-3.start {
    counter-reset: lst-ctn-kix_list_41-3 0
  }

  .lst-kix_list_12-2>li:before {
    content: ""counter(lst-ctn-kix_list_12-2, lower-roman) ". "
  }

  .lst-kix_list_11-6>li:before {
    content: ""counter(lst-ctn-kix_list_11-6, decimal) ". "
  }

  .lst-kix_list_32-7>li:before {
    content: " "
  }

  .lst-kix_list_1-2>li:before {
    content: "("counter(lst-ctn-kix_list_1-2, lower-roman) ") "
  }

  ol.lst-kix_list_11-3.start {
    counter-reset: lst-ctn-kix_list_11-3 0
  }

  .lst-kix_list_1-0>li {
    counter-increment: lst-ctn-kix_list_1-0
  }

  ol.lst-kix_list_16-1.start {
    counter-reset: lst-ctn-kix_list_16-1 0
  }

  ol.lst-kix_list_40-8 {
    list-style-type: none
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
  }

  ol.lst-kix_list_40-6 {
    list-style-type: none
  }

  .lst-kix_list_35-0>li {
    counter-increment: lst-ctn-kix_list_35-0
  }

  ol.lst-kix_list_40-7 {
    list-style-type: none
  }

  ol.lst-kix_list_41-2.start {
    counter-reset: lst-ctn-kix_list_41-2 0
  }

  ol.lst-kix_list_40-4 {
    list-style-type: none
  }

  .lst-kix_list_18-7>li {
    counter-increment: lst-ctn-kix_list_18-7
  }

  ol.lst-kix_list_40-5 {
    list-style-type: none
  }

  ol.lst-kix_list_40-2 {
    list-style-type: none
  }

  ol.lst-kix_list_40-3 {
    list-style-type: none
  }

  ol.lst-kix_list_40-0 {
    list-style-type: none
  }

  ol.lst-kix_list_40-1 {
    list-style-type: none
  }

  .lst-kix_list_40-4>li:before {
    content: "("counter(lst-ctn-kix_list_40-4, lower-latin) ") "
  }

  ol.lst-kix_list_11-4.start {
    counter-reset: lst-ctn-kix_list_11-4 0
  }

  .lst-kix_list_28-2>li:before {
    content: " "
  }

  .lst-kix_list_41-2>li {
    counter-increment: lst-ctn-kix_list_41-2
  }

  .lst-kix_list_14-1>li:before {
    content: ""counter(lst-ctn-kix_list_14-1, lower-latin) ". "
  }

  .lst-kix_list_14-3>li:before {
    content: ""counter(lst-ctn-kix_list_14-3, decimal) ". "
  }

  ol.lst-kix_list_15-6 {
    list-style-type: none
  }

  .lst-kix_list_25-8>li {
    counter-increment: lst-ctn-kix_list_25-8
  }

  ol.lst-kix_list_15-7 {
    list-style-type: none
  }

  ul.lst-kix_list_28-0 {
    list-style-type: none
  }

  ol.lst-kix_list_15-8 {
    list-style-type: none
  }

  ul.lst-kix_list_28-1 {
    list-style-type: none
  }

  .lst-kix_list_14-0>li:before {
    content: ""counter(lst-ctn-kix_list_14-0, upper-latin) ". "
  }

  .lst-kix_list_14-4>li:before {
    content: ""counter(lst-ctn-kix_list_14-4, lower-latin) ". "
  }

  ol.lst-kix_list_15-2 {
    list-style-type: none
  }

  ol.lst-kix_list_15-3 {
    list-style-type: none
  }

  ol.lst-kix_list_15-4 {
    list-style-type: none
  }

  ol.lst-kix_list_18-5.start {
    counter-reset: lst-ctn-kix_list_18-5 0
  }

  .lst-kix_list_6-1>li {
    counter-increment: lst-ctn-kix_list_6-1
  }

  .lst-kix_list_14-5>li:before {
    content: ""counter(lst-ctn-kix_list_14-5, lower-roman) ". "
  }

  .lst-kix_list_14-7>li:before {
    content: ""counter(lst-ctn-kix_list_14-7, lower-latin) ". "
  }

  ol.lst-kix_list_15-5 {
    list-style-type: none
  }

  ol.lst-kix_list_15-0 {
    list-style-type: none
  }

  .lst-kix_list_14-6>li:before {
    content: ""counter(lst-ctn-kix_list_14-6, decimal) ". "
  }

  ol.lst-kix_list_15-1 {
    list-style-type: none
  }

  ol.lst-kix_list_7-4.start {
    counter-reset: lst-ctn-kix_list_7-4 0
  }

  .lst-kix_list_17-0>li {
    counter-increment: lst-ctn-kix_list_17-0
  }

  .lst-kix_list_9-0>li {
    counter-increment: lst-ctn-kix_list_9-0
  }

  ul.lst-kix_list_28-2 {
    list-style-type: none
  }

  ul.lst-kix_list_28-3 {
    list-style-type: none
  }

  ul.lst-kix_list_28-4 {
    list-style-type: none
  }

  ul.lst-kix_list_28-5 {
    list-style-type: none
  }

  ol.lst-kix_list_25-3.start {
    counter-reset: lst-ctn-kix_list_25-3 0
  }

  ul.lst-kix_list_28-6 {
    list-style-type: none
  }

  ul.lst-kix_list_28-7 {
    list-style-type: none
  }

  ol.lst-kix_list_11-8.start {
    counter-reset: lst-ctn-kix_list_11-8 0
  }

  ul.lst-kix_list_28-8 {
    list-style-type: none
  }

  .lst-kix_list_14-2>li:before {
    content: ""counter(lst-ctn-kix_list_14-2, lower-roman) ". "
  }

  .lst-kix_list_20-7>li {
    counter-increment: lst-ctn-kix_list_20-7
  }

  ol.lst-kix_list_26-7 {
    list-style-type: none
  }

  ol.lst-kix_list_26-6 {
    list-style-type: none
  }

  ol.lst-kix_list_26-8 {
    list-style-type: none
  }

  ol.lst-kix_list_26-3 {
    list-style-type: none
  }

  ol.lst-kix_list_26-2 {
    list-style-type: none
  }

  ol.lst-kix_list_12-0.start {
    counter-reset: lst-ctn-kix_list_12-0 0
  }

  ol.lst-kix_list_26-5 {
    list-style-type: none
  }

  ol.lst-kix_list_26-4 {
    list-style-type: none
  }

  ol.lst-kix_list_41-7.start {
    counter-reset: lst-ctn-kix_list_41-7 0
  }

  ol.lst-kix_list_26-1 {
    list-style-type: none
  }

  ol.lst-kix_list_26-0 {
    list-style-type: none
  }

  .lst-kix_list_32-2>li:before {
    content: " "
  }

  .lst-kix_list_32-1>li:before {
    content: " "
  }

  .lst-kix_list_32-3>li:before {
    content: " "
  }

  ol.lst-kix_list_21-6.start {
    counter-reset: lst-ctn-kix_list_21-6 0
  }

  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0
  }

  .lst-kix_list_14-8>li:before {
    content: ""counter(lst-ctn-kix_list_14-8, lower-roman) ". "
  }

  .lst-kix_list_39-7>li {
    counter-increment: lst-ctn-kix_list_39-7
  }

  .lst-kix_list_32-0>li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_3-2>li {
    counter-increment: lst-ctn-kix_list_3-2
  }

  ol.lst-kix_list_15-5.start {
    counter-reset: lst-ctn-kix_list_15-5 0
  }

  ol.lst-kix_list_39-8 {
    list-style-type: none
  }

  .lst-kix_list_5-0>li:before {
    content: "[ARTICLE "counter(lst-ctn-kix_list_5-0, upper-roman) " "
  }

  ol.lst-kix_list_6-0 {
    list-style-type: none
  }

  ol.lst-kix_list_6-1 {
    list-style-type: none
  }

  .lst-kix_list_14-8>li {
    counter-increment: lst-ctn-kix_list_14-8
  }

  ol.lst-kix_list_22-3.start {
    counter-reset: lst-ctn-kix_list_22-3 0
  }

  .lst-kix_list_5-4>li {
    counter-increment: lst-ctn-kix_list_5-4
  }

  ol.lst-kix_list_39-5 {
    list-style-type: none
  }

  ol.lst-kix_list_39-4 {
    list-style-type: none
  }

  ol.lst-kix_list_39-7 {
    list-style-type: none
  }

  ol.lst-kix_list_39-6 {
    list-style-type: none
  }

  .lst-kix_list_5-3>li:before {
    content: "[("counter(lst-ctn-kix_list_5-3, lower-roman) ") "
  }

  ol.lst-kix_list_39-1 {
    list-style-type: none
  }

  ol.lst-kix_list_24-6.start {
    counter-reset: lst-ctn-kix_list_24-6 0
  }

  ol.lst-kix_list_39-0 {
    list-style-type: none
  }

  .lst-kix_list_5-2>li:before {
    content: "[("counter(lst-ctn-kix_list_5-2, lower-latin) ") "
  }

  ol.lst-kix_list_39-3 {
    list-style-type: none
  }

  .lst-kix_list_8-3>li {
    counter-increment: lst-ctn-kix_list_8-3
  }

  ol.lst-kix_list_39-2 {
    list-style-type: none
  }

  .lst-kix_list_5-1>li:before {
    content: "[Section "counter(lst-ctn-kix_list_5-0, upper-roman) "."counter(lst-ctn-kix_list_5-1, decimal-leading-zero) " "
  }

  ol.lst-kix_list_18-0.start {
    counter-reset: lst-ctn-kix_list_18-0 0
  }

  .lst-kix_list_5-7>li:before {
    content: " "
  }

  .lst-kix_list_5-6>li:before {
    content: " "
  }

  .lst-kix_list_5-8>li:before {
    content: " "
  }

  ol.lst-kix_list_6-6 {
    list-style-type: none
  }

  ol.lst-kix_list_6-7 {
    list-style-type: none
  }

  .lst-kix_list_5-4>li:before {
    content: "[("counter(lst-ctn-kix_list_5-4, upper-latin) ") "
  }

  ol.lst-kix_list_6-8 {
    list-style-type: none
  }

  .lst-kix_list_5-5>li:before {
    content: " "
  }

  ol.lst-kix_list_6-2 {
    list-style-type: none
  }

  ol.lst-kix_list_6-3 {
    list-style-type: none
  }

  ol.lst-kix_list_6-4 {
    list-style-type: none
  }

  ol.lst-kix_list_6-5 {
    list-style-type: none
  }

  ol.lst-kix_list_12-5.start {
    counter-reset: lst-ctn-kix_list_12-5 0
  }

  .lst-kix_list_6-1>li:before {
    content: ""counter(lst-ctn-kix_list_6-1, lower-latin) ". "
  }

  .lst-kix_list_6-3>li:before {
    content: ""counter(lst-ctn-kix_list_6-3, decimal) ". "
  }

  .lst-kix_list_6-8>li {
    counter-increment: lst-ctn-kix_list_6-8
  }

  .lst-kix_list_6-0>li:before {
    content: "["counter(lst-ctn-kix_list_6-0, decimal) ".   "
  }

  .lst-kix_list_6-4>li:before {
    content: ""counter(lst-ctn-kix_list_6-4, lower-latin) ". "
  }

  ol.lst-kix_list_14-8.start {
    counter-reset: lst-ctn-kix_list_14-8 0
  }

  .lst-kix_list_6-2>li:before {
    content: ""counter(lst-ctn-kix_list_6-2, lower-roman) ". "
  }

  ol.lst-kix_list_15-0.start {
    counter-reset: lst-ctn-kix_list_15-0 0
  }

  .lst-kix_list_2-5>li {
    counter-increment: lst-ctn-kix_list_2-5
  }

  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0
  }

  .lst-kix_list_6-8>li:before {
    content: ""counter(lst-ctn-kix_list_6-8, lower-roman) ". "
  }

  .lst-kix_list_6-5>li:before {
    content: ""counter(lst-ctn-kix_list_6-5, lower-roman) ". "
  }

  .lst-kix_list_6-7>li:before {
    content: ""counter(lst-ctn-kix_list_6-7, lower-latin) ". "
  }

  .lst-kix_list_6-6>li:before {
    content: ""counter(lst-ctn-kix_list_6-6, decimal) ". "
  }

  ol.lst-kix_list_17-8 {
    list-style-type: none
  }

  ol.lst-kix_list_10-6.start {
    counter-reset: lst-ctn-kix_list_10-6 0
  }

  ol.lst-kix_list_27-6.start {
    counter-reset: lst-ctn-kix_list_27-6 0
  }

  .lst-kix_list_7-4>li:before {
    content: " "
  }

  .lst-kix_list_7-6>li:before {
    content: ""counter(lst-ctn-kix_list_7-6, decimal) " "
  }

  ol.lst-kix_list_17-4 {
    list-style-type: none
  }

  .lst-kix_list_18-5>li {
    counter-increment: lst-ctn-kix_list_18-5
  }

  ol.lst-kix_list_19-7.start {
    counter-reset: lst-ctn-kix_list_19-7 -1
  }

  ol.lst-kix_list_17-5 {
    list-style-type: none
  }

  ol.lst-kix_list_6-2.start {
    counter-reset: lst-ctn-kix_list_6-2 0
  }

  .lst-kix_list_15-5>li {
    counter-increment: lst-ctn-kix_list_15-5
  }

  ol.lst-kix_list_17-6 {
    list-style-type: none
  }

  ol.lst-kix_list_24-1.start {
    counter-reset: lst-ctn-kix_list_24-1 0
  }

  ol.lst-kix_list_17-7 {
    list-style-type: none
  }

  ol.lst-kix_list_17-0 {
    list-style-type: none
  }

  .lst-kix_list_22-2>li {
    counter-increment: lst-ctn-kix_list_22-2
  }

  ol.lst-kix_list_17-1 {
    list-style-type: none
  }

  ol.lst-kix_list_17-2 {
    list-style-type: none
  }

  .lst-kix_list_7-2>li:before {
    content: "[("counter(lst-ctn-kix_list_7-2, lower-roman) ") "
  }

  ol.lst-kix_list_17-3 {
    list-style-type: none
  }

  .lst-kix_list_7-6>li {
    counter-increment: lst-ctn-kix_list_7-6
  }

  .lst-kix_list_27-2>li {
    counter-increment: lst-ctn-kix_list_27-2
  }

  ol.lst-kix_list_22-8.start {
    counter-reset: lst-ctn-kix_list_22-8 0
  }

  .lst-kix_list_34-8>li:before {
    content: " "
  }

  .lst-kix_list_31-0>li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_12-6>li {
    counter-increment: lst-ctn-kix_list_12-6
  }

  .lst-kix_list_24-3>li {
    counter-increment: lst-ctn-kix_list_24-3
  }

  .lst-kix_list_13-7>li:before {
    content: ""counter(lst-ctn-kix_list_13-7, lower-latin) ". "
  }

  .lst-kix_list_7-8>li:before {
    content: " "
  }

  ol.lst-kix_list_23-0.start {
    counter-reset: lst-ctn-kix_list_23-0 0
  }

  .lst-kix_list_15-6>li {
    counter-increment: lst-ctn-kix_list_15-6
  }

  .lst-kix_list_4-7>li {
    counter-increment: lst-ctn-kix_list_4-7
  }

  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0
  }

  .lst-kix_list_15-5>li:before {
    content: " "
  }

  .lst-kix_list_31-6>li:before {
    content: " "
  }

  .lst-kix_list_31-8>li:before {
    content: " "
  }

  ol.lst-kix_list_26-0.start {
    counter-reset: lst-ctn-kix_list_26-0 0
  }

  .lst-kix_list_9-8>li {
    counter-increment: lst-ctn-kix_list_9-8
  }

  .lst-kix_list_13-4>li {
    counter-increment: lst-ctn-kix_list_13-4
  }

  .lst-kix_list_27-3>li {
    counter-increment: lst-ctn-kix_list_27-3
  }

  .lst-kix_list_4-1>li:before {
    content: ""counter(lst-ctn-kix_list_4-0, decimal) "."counter(lst-ctn-kix_list_4-1, decimal) " "
  }

  .lst-kix_list_31-2>li:before {
    content: " "
  }

  .lst-kix_list_31-4>li:before {
    content: " "
  }

  .lst-kix_list_15-7>li:before {
    content: " "
  }

  .lst-kix_list_36-0>li {
    counter-increment: lst-ctn-kix_list_36-0
  }

  .lst-kix_list_17-7>li {
    counter-increment: lst-ctn-kix_list_17-7
  }

  .lst-kix_list_4-3>li:before {
    content: "("counter(lst-ctn-kix_list_4-3, lower-roman) ") "
  }

  .lst-kix_list_4-5>li:before {
    content: " "
  }

  .lst-kix_list_1-8>li {
    counter-increment: lst-ctn-kix_list_1-8
  }

  ol.lst-kix_list_38-1.start {
    counter-reset: lst-ctn-kix_list_38-1 0
  }

  ul.lst-kix_list_19-1 {
    list-style-type: none
  }

  .lst-kix_list_10-5>li {
    counter-increment: lst-ctn-kix_list_10-5
  }

  ul.lst-kix_list_19-0 {
    list-style-type: none
  }

  .lst-kix_list_15-1>li:before {
    content: ""counter(lst-ctn-kix_list_15-0, decimal) "("counter(lst-ctn-kix_list_15-1, lower-latin) ") "
  }

  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0
  }

  .lst-kix_list_24-4>li {
    counter-increment: lst-ctn-kix_list_24-4
  }

  .lst-kix_list_15-3>li:before {
    content: "("counter(lst-ctn-kix_list_15-3, upper-latin) ") "
  }

  .lst-kix_list_22-1>li {
    counter-increment: lst-ctn-kix_list_22-1
  }

  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0
  }

  .lst-kix_list_16-2>li {
    counter-increment: lst-ctn-kix_list_16-2
  }

  ol.lst-kix_list_39-3.start {
    counter-reset: lst-ctn-kix_list_39-3 0
  }

  .lst-kix_list_40-5>li {
    counter-increment: lst-ctn-kix_list_40-5
  }

  ol.lst-kix_list_9-2.start {
    counter-reset: lst-ctn-kix_list_9-2 0
  }

  .lst-kix_list_20-0>li {
    counter-increment: lst-ctn-kix_list_20-0
  }

  ol.lst-kix_list_16-7.start {
    counter-reset: lst-ctn-kix_list_16-7 0
  }

  .lst-kix_list_32-4>li:before {
    content: " "
  }

  .lst-kix_list_11-2>li {
    counter-increment: lst-ctn-kix_list_11-2
  }

  .lst-kix_list_26-5>li {
    counter-increment: lst-ctn-kix_list_26-5
  }

  .lst-kix_list_19-2>li {
    counter-increment: lst-ctn-kix_list_19-2
  }

  .lst-kix_list_33-4>li:before {
    content: " "
  }

  ol.lst-kix_list_8-8 {
    list-style-type: none
  }

  .lst-kix_list_12-3>li:before {
    content: ""counter(lst-ctn-kix_list_12-3, decimal) ". "
  }

  .lst-kix_list_38-2>li {
    counter-increment: lst-ctn-kix_list_38-2
  }

  .lst-kix_list_32-6>li:before {
    content: " "
  }

  ol.lst-kix_list_8-4 {
    list-style-type: none
  }

  .lst-kix_list_12-1>li:before {
    content: ""counter(lst-ctn-kix_list_12-1, lower-latin) ". "
  }

  ol.lst-kix_list_8-5 {
    list-style-type: none
  }

  .lst-kix_list_33-0>li:before {
    content: ""counter(lst-ctn-kix_list_33-0, decimal) ". "
  }

  .lst-kix_list_33-2>li:before {
    content: " "
  }

  ol.lst-kix_list_8-6 {
    list-style-type: none
  }

  ol.lst-kix_list_8-7 {
    list-style-type: none
  }

  .lst-kix_list_32-8>li:before {
    content: " "
  }

  ol.lst-kix_list_8-0 {
    list-style-type: none
  }

  .lst-kix_list_16-3>li {
    counter-increment: lst-ctn-kix_list_16-3
  }

  ol.lst-kix_list_8-1 {
    list-style-type: none
  }

  .lst-kix_list_23-6>li {
    counter-increment: lst-ctn-kix_list_23-6
  }

  ol.lst-kix_list_8-2 {
    list-style-type: none
  }

  .lst-kix_list_13-3>li {
    counter-increment: lst-ctn-kix_list_13-3
  }

  ol.lst-kix_list_13-6.start {
    counter-reset: lst-ctn-kix_list_13-6 0
  }

  ol.lst-kix_list_8-3 {
    list-style-type: none
  }

  ol.lst-kix_list_40-5.start {
    counter-reset: lst-ctn-kix_list_40-5 0
  }

  ol.lst-kix_list_25-8.start {
    counter-reset: lst-ctn-kix_list_25-8 0
  }

  .lst-kix_list_39-0>li {
    counter-increment: lst-ctn-kix_list_39-0
  }

  .lst-kix_list_34-0>li:before {
    content: ""counter(lst-ctn-kix_list_34-0, decimal) ". "
  }

  .lst-kix_list_10-4>li {
    counter-increment: lst-ctn-kix_list_10-4
  }

  .lst-kix_list_14-1>li {
    counter-increment: lst-ctn-kix_list_14-1
  }

  .lst-kix_list_21-4>li {
    counter-increment: lst-ctn-kix_list_21-4
  }

  .lst-kix_list_39-6>li {
    counter-increment: lst-ctn-kix_list_39-6
  }

  .lst-kix_list_13-3>li:before {
    content: ""counter(lst-ctn-kix_list_13-3, decimal) ". "
  }

  .lst-kix_list_34-4>li:before {
    content: " "
  }

  .lst-kix_list_34-6>li:before {
    content: " "
  }

  .lst-kix_list_13-5>li:before {
    content: ""counter(lst-ctn-kix_list_13-5, lower-roman) ". "
  }

  .lst-kix_list_12-5>li:before {
    content: ""counter(lst-ctn-kix_list_12-5, lower-roman) ". "
  }

  ol.lst-kix_list_13-7.start {
    counter-reset: lst-ctn-kix_list_13-7 0
  }

  .lst-kix_list_18-4>li {
    counter-increment: lst-ctn-kix_list_18-4
  }

  .lst-kix_list_12-7>li:before {
    content: ""counter(lst-ctn-kix_list_12-7, lower-latin) ". "
  }

  .lst-kix_list_33-6>li:before {
    content: " "
  }

  ol.lst-kix_list_21-1.start {
    counter-reset: lst-ctn-kix_list_21-1 0
  }

  .lst-kix_list_33-8>li:before {
    content: " "
  }

  .lst-kix_list_34-2>li:before {
    content: " "
  }

  .lst-kix_list_25-1>li {
    counter-increment: lst-ctn-kix_list_25-1
  }

  .lst-kix_list_13-1>li:before {
    content: ""counter(lst-ctn-kix_list_13-1, lower-latin) ". "
  }

  .lst-kix_list_30-5>li:before {
    content: " "
  }

  .lst-kix_list_22-8>li {
    counter-increment: lst-ctn-kix_list_22-8
  }

  .lst-kix_list_35-0>li:before {
    content: ""counter(lst-ctn-kix_list_35-0, decimal) ". "
  }

  .lst-kix_list_35-1>li:before {
    content: " "
  }

  .lst-kix_list_35-4>li:before {
    content: " "
  }

  .lst-kix_list_35-5>li:before {
    content: " "
  }

  ol.lst-kix_list_40-6.start {
    counter-reset: lst-ctn-kix_list_40-6 0
  }

  ol.lst-kix_list_11-6 {
    list-style-type: none
  }

  ol.lst-kix_list_11-7 {
    list-style-type: none
  }

  .lst-kix_list_1-1>li {
    counter-increment: lst-ctn-kix_list_1-1
  }

  ol.lst-kix_list_11-8 {
    list-style-type: none
  }

  .lst-kix_list_30-1>li:before {
    content: " "
  }

  ol.lst-kix_list_11-2 {
    list-style-type: none
  }

  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0
  }

  .lst-kix_list_3-0>li:before {
    content: "ARTICLE "counter(lst-ctn-kix_list_3-0, upper-roman) " "
  }

  ol.lst-kix_list_11-3 {
    list-style-type: none
  }

  ol.lst-kix_list_11-4 {
    list-style-type: none
  }

  .lst-kix_list_30-2>li:before {
    content: " "
  }

  ol.lst-kix_list_11-5 {
    list-style-type: none
  }

  ol.lst-kix_list_20-5.start {
    counter-reset: lst-ctn-kix_list_20-5 0
  }

  ol.lst-kix_list_13-1.start {
    counter-reset: lst-ctn-kix_list_13-1 0
  }

  ol.lst-kix_list_11-0 {
    list-style-type: none
  }

  ol.lst-kix_list_11-1 {
    list-style-type: none
  }

  .lst-kix_list_4-0>li {
    counter-increment: lst-ctn-kix_list_4-0
  }

  .lst-kix_list_3-4>li:before {
    content: "("counter(lst-ctn-kix_list_3-4, upper-latin) ") "
  }

  .lst-kix_list_3-3>li:before {
    content: "("counter(lst-ctn-kix_list_3-3, lower-roman) ") "
  }

  .lst-kix_list_8-0>li:before {
    content: "["counter(lst-ctn-kix_list_8-0, decimal) ". "
  }

  .lst-kix_list_30-6>li:before {
    content: " "
  }

  .lst-kix_list_8-7>li:before {
    content: " "
  }

  .lst-kix_list_38-3>li {
    counter-increment: lst-ctn-kix_list_38-3
  }

  .lst-kix_list_3-8>li:before {
    content: " "
  }

  ol.lst-kix_list_10-7.start {
    counter-reset: lst-ctn-kix_list_10-7 0
  }

  .lst-kix_list_8-3>li:before {
    content: "[("counter(lst-ctn-kix_list_8-3, lower-roman) ") "
  }

  ol.lst-kix_list_22-7 {
    list-style-type: none
  }

  ol.lst-kix_list_22-6 {
    list-style-type: none
  }

  ol.lst-kix_list_22-8 {
    list-style-type: none
  }

  .lst-kix_list_3-7>li:before {
    content: " "
  }

  ol.lst-kix_list_22-3 {
    list-style-type: none
  }

  ol.lst-kix_list_22-2 {
    list-style-type: none
  }

  .lst-kix_list_8-4>li:before {
    content: "[("counter(lst-ctn-kix_list_8-4, upper-latin) ") "
  }

  ol.lst-kix_list_22-5 {
    list-style-type: none
  }

  ol.lst-kix_list_22-4 {
    list-style-type: none
  }

  ol.lst-kix_list_22-1 {
    list-style-type: none
  }

  ol.lst-kix_list_22-0 {
    list-style-type: none
  }

  ol.lst-kix_list_8-5.start {
    counter-reset: lst-ctn-kix_list_8-5 0
  }

  ol.lst-kix_list_26-4.start {
    counter-reset: lst-ctn-kix_list_26-4 0
  }

  .lst-kix_list_35-8>li:before {
    content: " "
  }

  .lst-kix_list_17-1>li {
    counter-increment: lst-ctn-kix_list_17-1
  }

  .lst-kix_list_11-1>li:before {
    content: ""counter(lst-ctn-kix_list_11-1, lower-latin) ". "
  }

  .lst-kix_list_11-0>li:before {
    content: ""counter(lst-ctn-kix_list_11-0, lower-roman) ". "
  }

  ol.lst-kix_list_9-3.start {
    counter-reset: lst-ctn-kix_list_9-3 0
  }

  .lst-kix_list_8-8>li:before {
    content: " "
  }

  ol.lst-kix_list_2-2 {
    list-style-type: none
  }

  .lst-kix_list_16-8>li:before {
    content: ""counter(lst-ctn-kix_list_16-8, lower-roman) ". "
  }

  ol.lst-kix_list_2-3 {
    list-style-type: none
  }

  ol.lst-kix_list_2-4 {
    list-style-type: none
  }

  .lst-kix_list_16-7>li:before {
    content: ""counter(lst-ctn-kix_list_16-7, lower-latin) ". "
  }

  ol.lst-kix_list_2-5 {
    list-style-type: none
  }

  .lst-kix_list_17-8>li {
    counter-increment: lst-ctn-kix_list_17-8
  }

  ol.lst-kix_list_2-0 {
    list-style-type: none
  }

  ol.lst-kix_list_2-1 {
    list-style-type: none
  }

  .lst-kix_list_4-8>li:before {
    content: " "
  }

  .lst-kix_list_21-5>li {
    counter-increment: lst-ctn-kix_list_21-5
  }

  .lst-kix_list_4-7>li:before {
    content: ""counter(lst-ctn-kix_list_4-7, decimal) " "
  }

  .lst-kix_list_14-2>li {
    counter-increment: lst-ctn-kix_list_14-2
  }

  ol.lst-kix_list_20-0.start {
    counter-reset: lst-ctn-kix_list_20-0 0
  }

  .lst-kix_list_17-0>li:before {
    content: ""counter(lst-ctn-kix_list_17-0, decimal) ".   "
  }

  ol.lst-kix_list_35-0 {
    list-style-type: none
  }

  .lst-kix_list_39-8>li {
    counter-increment: lst-ctn-kix_list_39-8
  }

  .lst-kix_list_16-0>li:before {
    content: ""counter(lst-ctn-kix_list_16-0, decimal) ".   "
  }

  .lst-kix_list_40-6>li {
    counter-increment: lst-ctn-kix_list_40-6
  }

  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0
  }

  .lst-kix_list_8-4>li {
    counter-increment: lst-ctn-kix_list_8-4
  }

  .lst-kix_list_16-4>li:before {
    content: ""counter(lst-ctn-kix_list_16-4, lower-latin) ". "
  }

  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0
  }

  .lst-kix_list_16-3>li:before {
    content: ""counter(lst-ctn-kix_list_16-3, decimal) ". "
  }

  ol.lst-kix_list_2-6 {
    list-style-type: none
  }

  ol.lst-kix_list_2-7 {
    list-style-type: none
  }

  ol.lst-kix_list_2-8 {
    list-style-type: none
  }

  .lst-kix_list_11-3>li {
    counter-increment: lst-ctn-kix_list_11-3
  }

  ol.lst-kix_list_18-1.start {
    counter-reset: lst-ctn-kix_list_18-1 0
  }

  ul.lst-kix_list_35-1 {
    list-style-type: none
  }

  ul.lst-kix_list_35-2 {
    list-style-type: none
  }

  ul.lst-kix_list_35-3 {
    list-style-type: none
  }

  ul.lst-kix_list_35-4 {
    list-style-type: none
  }

  ul.lst-kix_list_35-5 {
    list-style-type: none
  }

  .lst-kix_list_41-1>li {
    counter-increment: lst-ctn-kix_list_41-1
  }

  ol.lst-kix_list_39-7.start {
    counter-reset: lst-ctn-kix_list_39-7 0
  }

  ol.lst-kix_list_8-6.start {
    counter-reset: lst-ctn-kix_list_8-6 0
  }

  .lst-kix_list_17-7>li:before {
    content: ""counter(lst-ctn-kix_list_17-7, lower-latin) ". "
  }

  ol.lst-kix_list_38-2.start {
    counter-reset: lst-ctn-kix_list_38-2 0
  }

  .lst-kix_list_17-8>li:before {
    content: ""counter(lst-ctn-kix_list_17-8, lower-roman) ". "
  }

  .lst-kix_list_33-0>li {
    counter-increment: lst-ctn-kix_list_33-0
  }

  .lst-kix_list_17-3>li:before {
    content: ""counter(lst-ctn-kix_list_17-3, decimal) ". "
  }

  .lst-kix_list_17-4>li:before {
    content: ""counter(lst-ctn-kix_list_17-4, lower-latin) ". "
  }

  ul.lst-kix_list_35-6 {
    list-style-type: none
  }

  ul.lst-kix_list_35-7 {
    list-style-type: none
  }

  ol.lst-kix_list_8-0.start {
    counter-reset: lst-ctn-kix_list_8-0 0
  }

  ul.lst-kix_list_35-8 {
    list-style-type: none
  }

  .lst-kix_list_7-0>li:before {
    content: "["counter(lst-ctn-kix_list_7-0, decimal) ". "
  }

  ol.lst-kix_list_27-7.start {
    counter-reset: lst-ctn-kix_list_27-7 0
  }

  ol.lst-kix_list_19-6.start {
    counter-reset: lst-ctn-kix_list_19-6 -1
  }

  ol.lst-kix_list_9-7.start {
    counter-reset: lst-ctn-kix_list_9-7 0
  }

  ol.lst-kix_list_13-8 {
    list-style-type: none
  }

  .lst-kix_list_2-4>li:before {
    content: ""counter(lst-ctn-kix_list_2-4, lower-latin) ". "
  }

  .lst-kix_list_2-8>li:before {
    content: ""counter(lst-ctn-kix_list_2-8, lower-roman) ". "
  }

  ol.lst-kix_list_13-4 {
    list-style-type: none
  }

  ol.lst-kix_list_13-5 {
    list-style-type: none
  }

  ol.lst-kix_list_13-6 {
    list-style-type: none
  }

  ol.lst-kix_list_13-7 {
    list-style-type: none
  }

  ol.lst-kix_list_13-0 {
    list-style-type: none
  }

  ol.lst-kix_list_13-1 {
    list-style-type: none
  }

  ol.lst-kix_list_13-2 {
    list-style-type: none
  }

  .lst-kix_list_7-3>li:before {
    content: "[("counter(lst-ctn-kix_list_7-3, upper-latin) ") "
  }

  ol.lst-kix_list_13-3 {
    list-style-type: none
  }

  .lst-kix_list_10-0>li:before {
    content: ""counter(lst-ctn-kix_list_10-0, lower-latin) ". "
  }

  .lst-kix_list_9-7>li {
    counter-increment: lst-ctn-kix_list_9-7
  }

  ol.lst-kix_list_21-7.start {
    counter-reset: lst-ctn-kix_list_21-7 0
  }

  .lst-kix_list_13-8>li:before {
    content: ""counter(lst-ctn-kix_list_13-8, lower-roman) ". "
  }

  .lst-kix_list_31-1>li:before {
    content: " "
  }

  .lst-kix_list_18-3>li:before {
    content: " "
  }

  .lst-kix_list_18-7>li:before {
    content: " "
  }

  .lst-kix_list_18-6>li {
    counter-increment: lst-ctn-kix_list_18-6
  }

  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0
  }

  ol.lst-kix_list_39-8.start {
    counter-reset: lst-ctn-kix_list_39-8 0
  }

  .lst-kix_list_7-7>li:before {
    content: ""counter(lst-ctn-kix_list_7-7, decimal) " "
  }

  .lst-kix_list_36-5>li:before {
    content: " "
  }

  ol.lst-kix_list_8-1.start {
    counter-reset: lst-ctn-kix_list_8-1 0
  }

  .lst-kix_list_15-4>li:before {
    content: " "
  }

  .lst-kix_list_31-5>li:before {
    content: " "
  }

  ol.lst-kix_list_24-8 {
    list-style-type: none
  }

  .lst-kix_list_10-4>li:before {
    content: ""counter(lst-ctn-kix_list_10-4, lower-latin) ". "
  }

  .lst-kix_list_10-8>li:before {
    content: ""counter(lst-ctn-kix_list_10-8, lower-roman) ". "
  }

  ol.lst-kix_list_20-4.start {
    counter-reset: lst-ctn-kix_list_20-4 0
  }

  .lst-kix_list_4-0>li:before {
    content: ""counter(lst-ctn-kix_list_4-0, decimal) ". "
  }

  .lst-kix_list_36-1>li:before {
    content: " "
  }

  ol.lst-kix_list_24-5 {
    list-style-type: none
  }

  ol.lst-kix_list_24-4 {
    list-style-type: none
  }

  ol.lst-kix_list_24-7 {
    list-style-type: none
  }

  ol.lst-kix_list_25-2.start {
    counter-reset: lst-ctn-kix_list_25-2 0
  }

  .lst-kix_list_15-0>li:before {
    content: ""counter(lst-ctn-kix_list_15-0, decimal) " "
  }

  .lst-kix_list_15-8>li:before {
    content: " "
  }

  ol.lst-kix_list_24-6 {
    list-style-type: none
  }

  ol.lst-kix_list_24-1 {
    list-style-type: none
  }

  ol.lst-kix_list_38-7.start {
    counter-reset: lst-ctn-kix_list_38-7 0
  }

  ol.lst-kix_list_14-3.start {
    counter-reset: lst-ctn-kix_list_14-3 0
  }

  ol.lst-kix_list_24-0 {
    list-style-type: none
  }

  ol.lst-kix_list_24-3 {
    list-style-type: none
  }

  .lst-kix_list_15-7>li {
    counter-increment: lst-ctn-kix_list_15-7
  }

  ol.lst-kix_list_24-2 {
    list-style-type: none
  }

  .lst-kix_list_4-4>li:before {
    content: "("counter(lst-ctn-kix_list_4-4, upper-latin) ") "
  }

  .lst-kix_list_9-3>li:before {
    content: ""counter(lst-ctn-kix_list_9-3, decimal) ". "
  }

  ol.lst-kix_list_7-0.start {
    counter-reset: lst-ctn-kix_list_7-0 0
  }

  .lst-kix_list_12-8>li {
    counter-increment: lst-ctn-kix_list_12-8
  }

  ol.lst-kix_list_13-2.start {
    counter-reset: lst-ctn-kix_list_13-2 0
  }

  ol.lst-kix_list_4-0 {
    list-style-type: none
  }

  ol.lst-kix_list_4-1 {
    list-style-type: none
  }

  ol.lst-kix_list_4-2 {
    list-style-type: none
  }

  ol.lst-kix_list_4-3 {
    list-style-type: none
  }

  ol.lst-kix_list_14-4.start {
    counter-reset: lst-ctn-kix_list_14-4 0
  }

  .lst-kix_list_9-7>li:before {
    content: ""counter(lst-ctn-kix_list_9-7, lower-latin) ". "
  }

  .lst-kix_list_2-4>li {
    counter-increment: lst-ctn-kix_list_2-4
  }

  .lst-kix_list_29-4>li:before {
    content: " "
  }

  .lst-kix_list_29-8>li:before {
    content: " "
  }

  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0
  }

  .lst-kix_list_32-5>li:before {
    content: " "
  }

  .lst-kix_list_11-4>li:before {
    content: ""counter(lst-ctn-kix_list_11-4, lower-latin) ". "
  }

  .lst-kix_list_12-4>li:before {
    content: ""counter(lst-ctn-kix_list_12-4, lower-latin) ". "
  }

  ol.lst-kix_list_37-0 {
    list-style-type: none
  }

  .lst-kix_list_5-3>li {
    counter-increment: lst-ctn-kix_list_5-3
  }

  ol.lst-kix_list_37-0.start {
    counter-reset: lst-ctn-kix_list_37-0 0
  }

  .lst-kix_list_29-0>li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_4-8 {
    list-style-type: none
  }

  .lst-kix_list_7-4>li {
    counter-increment: lst-ctn-kix_list_7-4
  }

  .lst-kix_list_33-1>li:before {
    content: " "
  }

  .lst-kix_list_1-0>li:before {
    content: ""counter(lst-ctn-kix_list_1-0, decimal) ". "
  }

  ol.lst-kix_list_19-2.start {
    counter-reset: lst-ctn-kix_list_19-2 -1
  }

  ol.lst-kix_list_38-5.start {
    counter-reset: lst-ctn-kix_list_38-5 0
  }

  ol.lst-kix_list_4-4 {
    list-style-type: none
  }

  .lst-kix_list_11-8>li:before {
    content: ""counter(lst-ctn-kix_list_11-8, lower-roman) ". "
  }

  ol.lst-kix_list_4-5 {
    list-style-type: none
  }

  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0
  }

  ol.lst-kix_list_4-6 {
    list-style-type: none
  }

  .lst-kix_list_12-0>li:before {
    content: ""counter(lst-ctn-kix_list_12-0, lower-roman) ". "
  }

  ol.lst-kix_list_4-7 {
    list-style-type: none
  }

  .lst-kix_list_1-4>li:before {
    content: " "
  }

  .lst-kix_list_13-0>li:before {
    content: ""counter(lst-ctn-kix_list_13-0, upper-latin) ". "
  }

  ul.lst-kix_list_37-1 {
    list-style-type: none
  }

  ul.lst-kix_list_37-2 {
    list-style-type: none
  }

  ul.lst-kix_list_37-3 {
    list-style-type: none
  }

  .lst-kix_list_1-6>li {
    counter-increment: lst-ctn-kix_list_1-6
  }

  .lst-kix_list_13-4>li:before {
    content: ""counter(lst-ctn-kix_list_13-4, lower-latin) ". "
  }

  .lst-kix_list_34-5>li:before {
    content: " "
  }

  ol.lst-kix_list_26-5.start {
    counter-reset: lst-ctn-kix_list_26-5 0
  }

  ol.lst-kix_list_38-6.start {
    counter-reset: lst-ctn-kix_list_38-6 0
  }

  .lst-kix_list_33-5>li:before {
    content: " "
  }

  ol.lst-kix_list_19-3.start {
    counter-reset: lst-ctn-kix_list_19-3 -1
  }

  .lst-kix_list_2-0>li:before {
    content: ""counter(lst-ctn-kix_list_2-0, decimal) ".   "
  }

  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0
  }

  .lst-kix_list_4-5>li {
    counter-increment: lst-ctn-kix_list_4-5
  }

  ul.lst-kix_list_37-4 {
    list-style-type: none
  }

  ol.lst-kix_list_9-8.start {
    counter-reset: lst-ctn-kix_list_9-8 0
  }

  .lst-kix_list_1-8>li:before {
    content: " "
  }

  ul.lst-kix_list_37-5 {
    list-style-type: none
  }

  ul.lst-kix_list_37-6 {
    list-style-type: none
  }

  ul.lst-kix_list_37-7 {
    list-style-type: none
  }

  ul.lst-kix_list_37-8 {
    list-style-type: none
  }

  .lst-kix_list_34-1>li:before {
    content: " "
  }

  .lst-kix_list_12-8>li:before {
    content: ""counter(lst-ctn-kix_list_12-8, lower-roman) ". "
  }

  .lst-kix_list_8-2>li {
    counter-increment: lst-ctn-kix_list_8-2
  }

  .lst-kix_list_19-0>li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_17-7.start {
    counter-reset: lst-ctn-kix_list_17-7 0
  }

  ol.lst-kix_list_12-6.start {
    counter-reset: lst-ctn-kix_list_12-6 0
  }

  .lst-kix_list_23-8>li {
    counter-increment: lst-ctn-kix_list_23-8
  }

  .lst-kix_list_8-1>li {
    counter-increment: lst-ctn-kix_list_8-1
  }

  ol.lst-kix_list_8-2.start {
    counter-reset: lst-ctn-kix_list_8-2 0
  }

  ol.lst-kix_list_26-1.start {
    counter-reset: lst-ctn-kix_list_26-1 0
  }

  .lst-kix_list_19-2>li:before {
    content: " "
  }

  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0
  }

  ol.lst-kix_list_21-0.start {
    counter-reset: lst-ctn-kix_list_21-0 0
  }

  .lst-kix_list_7-0>li {
    counter-increment: lst-ctn-kix_list_7-0
  }

  .lst-kix_list_2-3>li {
    counter-increment: lst-ctn-kix_list_2-3
  }

  ol.lst-kix_list_19-8.start {
    counter-reset: lst-ctn-kix_list_19-8 -1
  }

  ol.lst-kix_list_41-7 {
    list-style-type: none
  }

  ol.lst-kix_list_41-8 {
    list-style-type: none
  }

  ol.lst-kix_list_41-5 {
    list-style-type: none
  }

  ol.lst-kix_list_41-6 {
    list-style-type: none
  }

  .lst-kix_list_1-2>li {
    counter-increment: lst-ctn-kix_list_1-2
  }

  ol.lst-kix_list_41-3 {
    list-style-type: none
  }

  ol.lst-kix_list_41-4 {
    list-style-type: none
  }

  .lst-kix_list_19-8>li:before {
    content: " "
  }

  ol.lst-kix_list_41-1 {
    list-style-type: none
  }

  ol.lst-kix_list_14-7.start {
    counter-reset: lst-ctn-kix_list_14-7 0
  }

  ol.lst-kix_list_41-2 {
    list-style-type: none
  }

  ol.lst-kix_list_20-8.start {
    counter-reset: lst-ctn-kix_list_20-8 0
  }

  ol.lst-kix_list_41-0 {
    list-style-type: none
  }

  .lst-kix_list_19-5>li:before {
    content: " "
  }

  .lst-kix_list_19-7>li:before {
    content: " "
  }

  .lst-kix_list_9-2>li {
    counter-increment: lst-ctn-kix_list_9-2
  }

  ol.lst-kix_list_23-8.start {
    counter-reset: lst-ctn-kix_list_23-8 0
  }

  .lst-kix_list_24-5>li {
    counter-increment: lst-ctn-kix_list_24-5
  }

  .lst-kix_list_37-7>li:before {
    content: " "
  }

  ol.lst-kix_list_17-2.start {
    counter-reset: lst-ctn-kix_list_17-2 0
  }

  .lst-kix_list_13-2>li {
    counter-increment: lst-ctn-kix_list_13-2
  }

  ol.lst-kix_list_21-5.start {
    counter-reset: lst-ctn-kix_list_21-5 0
  }

  .lst-kix_list_38-0>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) ". "
  }

  .lst-kix_list_19-7>li {
    counter-increment: lst-ctn-kix_list_19-7
  }

  .lst-kix_list_38-1>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) ". "
  }

  .lst-kix_list_14-3>li {
    counter-increment: lst-ctn-kix_list_14-3
  }

  ol.lst-kix_list_15-6.start {
    counter-reset: lst-ctn-kix_list_15-6 0
  }

  .lst-kix_list_37-2>li:before {
    content: " "
  }

  .lst-kix_list_37-4>li:before {
    content: " "
  }

  .lst-kix_list_25-6>li {
    counter-increment: lst-ctn-kix_list_25-6
  }

  .lst-kix_list_37-5>li:before {
    content: " "
  }

  ul.lst-kix_list_31-2 {
    list-style-type: none
  }

  .lst-kix_list_12-1>li {
    counter-increment: lst-ctn-kix_list_12-1
  }

  ul.lst-kix_list_31-3 {
    list-style-type: none
  }

  ul.lst-kix_list_31-4 {
    list-style-type: none
  }

  ul.lst-kix_list_31-5 {
    list-style-type: none
  }

  ul.lst-kix_list_31-6 {
    list-style-type: none
  }

  ul.lst-kix_list_31-7 {
    list-style-type: none
  }

  ul.lst-kix_list_31-8 {
    list-style-type: none
  }

  .lst-kix_list_18-1>li:before {
    content: " "
  }

  .lst-kix_list_38-8>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) "."counter(lst-ctn-kix_list_38-3, decimal) "."counter(lst-ctn-kix_list_38-4, decimal) "."counter(lst-ctn-kix_list_38-5, decimal) "."counter(lst-ctn-kix_list_38-6, decimal) "."counter(lst-ctn-kix_list_38-7, decimal) "."counter(lst-ctn-kix_list_38-8, decimal) ". "
  }

  ul.lst-kix_list_31-0 {
    list-style-type: none
  }

  .lst-kix_list_40-3>li {
    counter-increment: lst-ctn-kix_list_40-3
  }

  ul.lst-kix_list_31-1 {
    list-style-type: none
  }

  ol.lst-kix_list_25-4.start {
    counter-reset: lst-ctn-kix_list_25-4 0
  }

  .lst-kix_list_38-3>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) "."counter(lst-ctn-kix_list_38-3, decimal) ". "
  }

  .lst-kix_list_23-4>li {
    counter-increment: lst-ctn-kix_list_23-4
  }

  ol.lst-kix_list_23-1.start {
    counter-reset: lst-ctn-kix_list_23-1 0
  }

  .lst-kix_list_38-6>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) "."counter(lst-ctn-kix_list_38-3, decimal) "."counter(lst-ctn-kix_list_38-4, decimal) "."counter(lst-ctn-kix_list_38-5, decimal) "."counter(lst-ctn-kix_list_38-6, decimal) ". "
  }

  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0
  }

  .lst-kix_list_23-1>li {
    counter-increment: lst-ctn-kix_list_23-1
  }

  ol.lst-kix_list_1-3 {
    list-style-type: none
  }

  ol.lst-kix_list_1-4 {
    list-style-type: none
  }

  .lst-kix_list_2-7>li:before {
    content: ""counter(lst-ctn-kix_list_2-7, lower-latin) ". "
  }

  .lst-kix_list_2-7>li {
    counter-increment: lst-ctn-kix_list_2-7
  }

  ol.lst-kix_list_1-5 {
    list-style-type: none
  }

  .lst-kix_list_24-2>li {
    counter-increment: lst-ctn-kix_list_24-2
  }

  ol.lst-kix_list_1-6 {
    list-style-type: none
  }

  .lst-kix_list_41-8>li {
    counter-increment: lst-ctn-kix_list_41-8
  }

  ol.lst-kix_list_1-0 {
    list-style-type: none
  }

  .lst-kix_list_2-5>li:before {
    content: ""counter(lst-ctn-kix_list_2-5, lower-roman) ". "
  }

  ol.lst-kix_list_1-1 {
    list-style-type: none
  }

  ol.lst-kix_list_1-2 {
    list-style-type: none
  }

  ol.lst-kix_list_17-0.start {
    counter-reset: lst-ctn-kix_list_17-0 0
  }

  .lst-kix_list_27-5>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) "."counter(lst-ctn-kix_list_27-3, decimal) "."counter(lst-ctn-kix_list_27-4, decimal) "."counter(lst-ctn-kix_list_27-5, decimal) ". "
  }

  ol.lst-kix_list_10-3.start {
    counter-reset: lst-ctn-kix_list_10-3 0
  }

  .lst-kix_list_22-3>li {
    counter-increment: lst-ctn-kix_list_22-3
  }

  .lst-kix_list_18-6>li:before {
    content: " "
  }

  .lst-kix_list_39-5>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) "."counter(lst-ctn-kix_list_39-3, decimal) "."counter(lst-ctn-kix_list_39-4, decimal) "."counter(lst-ctn-kix_list_39-5, decimal) ". "
  }

  .lst-kix_list_39-7>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) "."counter(lst-ctn-kix_list_39-3, decimal) "."counter(lst-ctn-kix_list_39-4, decimal) "."counter(lst-ctn-kix_list_39-5, decimal) "."counter(lst-ctn-kix_list_39-6, decimal) "."counter(lst-ctn-kix_list_39-7, decimal) ". "
  }

  .lst-kix_list_14-6>li {
    counter-increment: lst-ctn-kix_list_14-6
  }

  .lst-kix_list_10-1>li:before {
    content: ""counter(lst-ctn-kix_list_10-1, lower-latin) ". "
  }

  .lst-kix_list_18-4>li:before {
    content: " "
  }

  .lst-kix_list_7-7>li {
    counter-increment: lst-ctn-kix_list_7-7
  }

  ol.lst-kix_list_15-1.start {
    counter-reset: lst-ctn-kix_list_15-1 0
  }

  ol.lst-kix_list_15-4.start {
    counter-reset: lst-ctn-kix_list_15-4 0
  }

  ol.lst-kix_list_1-7 {
    list-style-type: none
  }

  ol.lst-kix_list_1-8 {
    list-style-type: none
  }

  .lst-kix_list_36-6>li:before {
    content: " "
  }

  ol.lst-kix_list_39-2.start {
    counter-reset: lst-ctn-kix_list_39-2 0
  }

  .lst-kix_list_10-3>li:before {
    content: ""counter(lst-ctn-kix_list_10-3, decimal) ". "
  }

  .lst-kix_list_15-4>li {
    counter-increment: lst-ctn-kix_list_15-4
  }

  .lst-kix_list_2-6>li {
    counter-increment: lst-ctn-kix_list_2-6
  }

  ol.lst-kix_list_7-3.start {
    counter-reset: lst-ctn-kix_list_7-3 0
  }

  .lst-kix_list_36-0>li:before {
    content: ""counter(lst-ctn-kix_list_36-0, decimal) ". "
  }

  .lst-kix_list_36-8>li:before {
    content: " "
  }

  ol.lst-kix_list_40-2.start {
    counter-reset: lst-ctn-kix_list_40-2 0
  }

  .lst-kix_list_34-0>li {
    counter-increment: lst-ctn-kix_list_34-0
  }

  .lst-kix_list_26-4>li {
    counter-increment: lst-ctn-kix_list_26-4
  }

  ol.lst-kix_list_5-7.start {
    counter-reset: lst-ctn-kix_list_5-7 0
  }

  .lst-kix_list_20-8>li:before {
    content: ""counter(lst-ctn-kix_list_20-8, lower-roman) ". "
  }

  .lst-kix_list_39-1>li {
    counter-increment: lst-ctn-kix_list_39-1
  }

  .lst-kix_list_3-4>li {
    counter-increment: lst-ctn-kix_list_3-4
  }

  .lst-kix_list_29-7>li:before {
    content: " "
  }

  .lst-kix_list_29-5>li:before {
    content: " "
  }

  .lst-kix_list_20-0>li:before {
    content: "Article "counter(lst-ctn-kix_list_20-0, upper-roman) ". "
  }

  .lst-kix_list_9-6>li:before {
    content: ""counter(lst-ctn-kix_list_9-6, decimal) ". "
  }

  ol.lst-kix_list_10-7 {
    list-style-type: none
  }

  ol.lst-kix_list_10-8 {
    list-style-type: none
  }

  .lst-kix_list_9-4>li:before {
    content: ""counter(lst-ctn-kix_list_9-4, lower-latin) ". "
  }

  ol.lst-kix_list_10-3 {
    list-style-type: none
  }

  ol.lst-kix_list_10-4 {
    list-style-type: none
  }

  ol.lst-kix_list_10-5 {
    list-style-type: none
  }

  ol.lst-kix_list_10-6 {
    list-style-type: none
  }

  .lst-kix_list_20-6>li:before {
    content: ""counter(lst-ctn-kix_list_20-6, lower-roman) ") "
  }

  .lst-kix_list_23-0>li {
    counter-increment: lst-ctn-kix_list_23-0
  }

  ol.lst-kix_list_10-0 {
    list-style-type: none
  }

  ol.lst-kix_list_10-1 {
    list-style-type: none
  }

  ol.lst-kix_list_10-2 {
    list-style-type: none
  }

  ol.lst-kix_list_12-1.start {
    counter-reset: lst-ctn-kix_list_12-1 0
  }

  .lst-kix_list_11-5>li:before {
    content: ""counter(lst-ctn-kix_list_11-5, lower-roman) ". "
  }

  ol.lst-kix_list_21-2.start {
    counter-reset: lst-ctn-kix_list_21-2 0
  }

  .lst-kix_list_20-6>li {
    counter-increment: lst-ctn-kix_list_20-6
  }

  .lst-kix_list_1-1>li:before {
    content: "("counter(lst-ctn-kix_list_1-1, lower-latin) ") "
  }

  .lst-kix_list_11-7>li:before {
    content: ""counter(lst-ctn-kix_list_11-7, lower-latin) ". "
  }

  .lst-kix_list_8-5>li {
    counter-increment: lst-ctn-kix_list_8-5
  }

  ol.lst-kix_list_25-7.start {
    counter-reset: lst-ctn-kix_list_25-7 0
  }

  ul.lst-kix_list_33-1 {
    list-style-type: none
  }

  .lst-kix_list_1-3>li:before {
    content: "("counter(lst-ctn-kix_list_1-3, upper-latin) ") "
  }

  ul.lst-kix_list_33-2 {
    list-style-type: none
  }

  ul.lst-kix_list_33-3 {
    list-style-type: none
  }

  ul.lst-kix_list_33-4 {
    list-style-type: none
  }

  ul.lst-kix_list_33-5 {
    list-style-type: none
  }

  ul.lst-kix_list_33-6 {
    list-style-type: none
  }

  .lst-kix_list_28-3>li:before {
    content: " "
  }

  ol.lst-kix_list_10-5.start {
    counter-reset: lst-ctn-kix_list_10-5 0
  }

  ul.lst-kix_list_33-7 {
    list-style-type: none
  }

  ol.lst-kix_list_21-8 {
    list-style-type: none
  }

  ol.lst-kix_list_21-7 {
    list-style-type: none
  }

  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0
  }

  ol.lst-kix_list_21-4 {
    list-style-type: none
  }

  ol.lst-kix_list_21-3 {
    list-style-type: none
  }

  ol.lst-kix_list_21-6 {
    list-style-type: none
  }

  .lst-kix_list_14-7>li {
    counter-increment: lst-ctn-kix_list_14-7
  }

  ol.lst-kix_list_21-5 {
    list-style-type: none
  }

  ol.lst-kix_list_21-0 {
    list-style-type: none
  }

  ol.lst-kix_list_7-5.start {
    counter-reset: lst-ctn-kix_list_7-5 0
  }

  ol.lst-kix_list_21-2 {
    list-style-type: none
  }

  ol.lst-kix_list_21-1 {
    list-style-type: none
  }

  ol.lst-kix_list_40-4.start {
    counter-reset: lst-ctn-kix_list_40-4 0
  }

  .lst-kix_list_27-7>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) "."counter(lst-ctn-kix_list_27-3, decimal) "."counter(lst-ctn-kix_list_27-4, decimal) "."counter(lst-ctn-kix_list_27-5, decimal) "."counter(lst-ctn-kix_list_27-6, decimal) "."counter(lst-ctn-kix_list_27-7, decimal) ". "
  }

  .lst-kix_list_25-7>li {
    counter-increment: lst-ctn-kix_list_25-7
  }

  ul.lst-kix_list_33-8 {
    list-style-type: none
  }

  ol.lst-kix_list_39-4.start {
    counter-reset: lst-ctn-kix_list_39-4 0
  }

  .lst-kix_list_28-1>li:before {
    content: " "
  }

  ol.lst-kix_list_19-6 {
    list-style-type: none
  }

  .lst-kix_list_35-2>li:before {
    content: " "
  }

  ol.lst-kix_list_19-7 {
    list-style-type: none
  }

  .lst-kix_list_3-1>li {
    counter-increment: lst-ctn-kix_list_3-1
  }

  ol.lst-kix_list_19-8 {
    list-style-type: none
  }

  .lst-kix_list_30-3>li:before {
    content: " "
  }

  ol.lst-kix_list_19-2 {
    list-style-type: none
  }

  ol.lst-kix_list_34-0.start {
    counter-reset: lst-ctn-kix_list_34-0 0
  }

  .lst-kix_list_26-8>li {
    counter-increment: lst-ctn-kix_list_26-8
  }

  ol.lst-kix_list_19-3 {
    list-style-type: none
  }

  ol.lst-kix_list_19-4 {
    list-style-type: none
  }

  .lst-kix_list_30-8>li:before {
    content: " "
  }

  ol.lst-kix_list_19-5 {
    list-style-type: none
  }

  .lst-kix_list_35-7>li:before {
    content: " "
  }

  ol.lst-kix_list_39-1.start {
    counter-reset: lst-ctn-kix_list_39-1 0
  }

  ol.lst-kix_list_7-7.start {
    counter-reset: lst-ctn-kix_list_7-7 0
  }

  .lst-kix_list_3-1>li:before {
    content: "Section "counter(lst-ctn-kix_list_3-0, upper-roman) "."counter(lst-ctn-kix_list_3-1, decimal-leading-zero) " "
  }

  .lst-kix_list_26-6>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) "."counter(lst-ctn-kix_list_26-3, decimal) "."counter(lst-ctn-kix_list_26-4, decimal) "."counter(lst-ctn-kix_list_26-5, decimal) "."counter(lst-ctn-kix_list_26-6, decimal) ". "
  }

  .lst-kix_list_14-0>li {
    counter-increment: lst-ctn-kix_list_14-0
  }

  ol.lst-kix_list_17-4.start {
    counter-reset: lst-ctn-kix_list_17-4 0
  }

  .lst-kix_list_8-2>li:before {
    content: "[("counter(lst-ctn-kix_list_8-2, lower-latin) ") "
  }

  .lst-kix_list_12-0>li {
    counter-increment: lst-ctn-kix_list_12-0
  }

  ol.lst-kix_list_12-3.start {
    counter-reset: lst-ctn-kix_list_12-3 0
  }

  ol.lst-kix_list_40-0.start {
    counter-reset: lst-ctn-kix_list_40-0 0
  }

  .lst-kix_list_21-2>li:before {
    content: "("counter(lst-ctn-kix_list_21-2, lower-latin) ") "
  }

  .lst-kix_list_8-5>li:before {
    content: " "
  }

  .lst-kix_list_2-0>li {
    counter-increment: lst-ctn-kix_list_2-0
  }

  .lst-kix_list_15-1>li {
    counter-increment: lst-ctn-kix_list_15-1
  }

  .lst-kix_list_26-3>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) "."counter(lst-ctn-kix_list_26-3, decimal) ". "
  }

  .lst-kix_list_3-6>li:before {
    content: " "
  }

  .lst-kix_list_21-7>li:before {
    content: ""counter(lst-ctn-kix_list_21-7, lower-latin) ". "
  }

  ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 0
  }

  .lst-kix_list_11-2>li:before {
    content: ""counter(lst-ctn-kix_list_11-2, lower-roman) ". "
  }

  .lst-kix_list_39-4>li {
    counter-increment: lst-ctn-kix_list_39-4
  }

  .lst-kix_list_40-4>li {
    counter-increment: lst-ctn-kix_list_40-4
  }

  ol.lst-kix_list_12-4.start {
    counter-reset: lst-ctn-kix_list_12-4 0
  }

  .lst-kix_list_16-6>li:before {
    content: ""counter(lst-ctn-kix_list_16-6, decimal) ". "
  }

  ol.lst-kix_list_10-1.start {
    counter-reset: lst-ctn-kix_list_10-1 0
  }

  .lst-kix_list_25-2>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) ". "
  }

  .lst-kix_list_18-2>li {
    counter-increment: lst-ctn-kix_list_18-2
  }

  ol.lst-kix_list_5-6.start {
    counter-reset: lst-ctn-kix_list_5-6 0
  }

  .lst-kix_list_22-6>li {
    counter-increment: lst-ctn-kix_list_22-6
  }

  .lst-kix_list_16-1>li:before {
    content: ""counter(lst-ctn-kix_list_16-1, lower-latin) ". "
  }

  .lst-kix_list_7-3>li {
    counter-increment: lst-ctn-kix_list_7-3
  }

  ol.lst-kix_list_39-0.start {
    counter-reset: lst-ctn-kix_list_39-0 0
  }

  .lst-kix_list_25-0>li {
    counter-increment: lst-ctn-kix_list_25-0
  }

  .lst-kix_list_19-3>li {
    counter-increment: lst-ctn-kix_list_19-3
  }

  ol.lst-kix_list_7-8.start {
    counter-reset: lst-ctn-kix_list_7-8 0
  }

  .lst-kix_list_12-4>li {
    counter-increment: lst-ctn-kix_list_12-4
  }

  .lst-kix_list_23-7>li {
    counter-increment: lst-ctn-kix_list_23-7
  }

  ol.lst-kix_list_10-2.start {
    counter-reset: lst-ctn-kix_list_10-2 0
  }

  .lst-kix_list_39-2>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) ". "
  }

  .lst-kix_list_12-7>li {
    counter-increment: lst-ctn-kix_list_12-7
  }

  .lst-kix_list_40-0>li {
    counter-increment: lst-ctn-kix_list_40-0
  }

  .lst-kix_list_17-2>li:before {
    content: ""counter(lst-ctn-kix_list_17-2, lower-roman) ". "
  }

  ol.lst-kix_list_5-5.start {
    counter-reset: lst-ctn-kix_list_5-5 0
  }

  .lst-kix_list_30-0>li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_17-3.start {
    counter-reset: lst-ctn-kix_list_17-3 0
  }

  ol.lst-kix_list_40-1.start {
    counter-reset: lst-ctn-kix_list_40-1 0
  }

  .lst-kix_list_17-5>li:before {
    content: ""counter(lst-ctn-kix_list_17-5, lower-roman) ". "
  }

  .lst-kix_list_6-2>li {
    counter-increment: lst-ctn-kix_list_6-2
  }

  .lst-kix_list_27-2>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) ". "
  }

  .lst-kix_list_22-3>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) "."counter(lst-ctn-kix_list_22-3, decimal) ". "
  }

  .lst-kix_list_7-1>li:before {
    content: "[("counter(lst-ctn-kix_list_7-1, lower-latin) ") "
  }

  .lst-kix_list_13-5>li {
    counter-increment: lst-ctn-kix_list_13-5
  }

  .lst-kix_list_9-6>li {
    counter-increment: lst-ctn-kix_list_9-6
  }

  ol.lst-kix_list_23-3.start {
    counter-reset: lst-ctn-kix_list_23-3 0
  }

  ol.lst-kix_list_5-4.start {
    counter-reset: lst-ctn-kix_list_5-4 0
  }

  ol.lst-kix_list_5-1.start {
    counter-reset: lst-ctn-kix_list_5-1 0
  }

  .lst-kix_list_20-3>li {
    counter-increment: lst-ctn-kix_list_20-3
  }

  .lst-kix_list_25-3>li {
    counter-increment: lst-ctn-kix_list_25-3
  }

  .lst-kix_list_16-6>li {
    counter-increment: lst-ctn-kix_list_16-6
  }

  .lst-kix_list_11-6>li {
    counter-increment: lst-ctn-kix_list_11-6
  }

  .lst-kix_list_31-7>li:before {
    content: " "
  }

  ol.lst-kix_list_10-0.start {
    counter-reset: lst-ctn-kix_list_10-0 0
  }

  ol.lst-kix_list_17-8.start {
    counter-reset: lst-ctn-kix_list_17-8 0
  }

  .lst-kix_list_3-8>li {
    counter-increment: lst-ctn-kix_list_3-8
  }

  .lst-kix_list_4-6>li {
    counter-increment: lst-ctn-kix_list_4-6
  }

  .lst-kix_list_1-5>li {
    counter-increment: lst-ctn-kix_list_1-5
  }

  ol.lst-kix_list_17-5.start {
    counter-reset: lst-ctn-kix_list_17-5 0
  }

  .lst-kix_list_4-2>li:before {
    content: "("counter(lst-ctn-kix_list_4-2, lower-latin) ") "
  }

  ol.lst-kix_list_23-6.start {
    counter-reset: lst-ctn-kix_list_23-6 0
  }

  .lst-kix_list_17-4>li {
    counter-increment: lst-ctn-kix_list_17-4
  }

  .lst-kix_list_15-2>li:before {
    content: "("counter(lst-ctn-kix_list_15-2, lower-roman) ") "
  }

  .lst-kix_list_36-3>li:before {
    content: " "
  }

  .lst-kix_list_26-1>li {
    counter-increment: lst-ctn-kix_list_26-1
  }

  .lst-kix_list_10-8>li {
    counter-increment: lst-ctn-kix_list_10-8
  }

  .lst-kix_list_9-1>li:before {
    content: ""counter(lst-ctn-kix_list_9-1, lower-latin) ". "
  }

  .lst-kix_list_10-6>li:before {
    content: ""counter(lst-ctn-kix_list_10-6, decimal) ". "
  }

  .lst-kix_list_40-7>li {
    counter-increment: lst-ctn-kix_list_40-7
  }

  ol.lst-kix_list_12-7.start {
    counter-reset: lst-ctn-kix_list_12-7 0
  }

  .lst-kix_list_15-8>li {
    counter-increment: lst-ctn-kix_list_15-8
  }

  .lst-kix_list_40-8>li:before {
    content: ""counter(lst-ctn-kix_list_40-8, lower-roman) ". "
  }

  .lst-kix_list_41-5>li {
    counter-increment: lst-ctn-kix_list_41-5
  }

  .lst-kix_list_41-4>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) "."counter(lst-ctn-kix_list_41-3, decimal) "."counter(lst-ctn-kix_list_41-4, decimal) ". "
  }

  ol.lst-kix_list_12-8.start {
    counter-reset: lst-ctn-kix_list_12-8 0
  }

  .lst-kix_list_20-3>li:before {
    content: "("counter(lst-ctn-kix_list_20-3, lower-roman) ") "
  }

  .lst-kix_list_27-6>li {
    counter-increment: lst-ctn-kix_list_27-6
  }

  .lst-kix_list_29-2>li:before {
    content: " "
  }

  ol.lst-kix_list_23-5.start {
    counter-reset: lst-ctn-kix_list_23-5 0
  }

  .lst-kix_list_21-1>li {
    counter-increment: lst-ctn-kix_list_21-1
  }

  .lst-kix_list_10-1>li {
    counter-increment: lst-ctn-kix_list_10-1
  }

  .lst-kix_list_8-8>li {
    counter-increment: lst-ctn-kix_list_8-8
  }

  ol.lst-kix_list_17-6.start {
    counter-reset: lst-ctn-kix_list_17-6 0
  }

  .lst-kix_list_28-6>li:before {
    content: " "
  }

  .lst-kix_list_1-6>li:before {
    content: " "
  }

  .lst-kix_list_33-7>li:before {
    content: " "
  }

  .lst-kix_list_40-0>li:before {
    content: ""counter(lst-ctn-kix_list_40-0, decimal) ") "
  }

  .lst-kix_list_12-6>li:before {
    content: ""counter(lst-ctn-kix_list_12-6, decimal) ". "
  }

  ol.lst-kix_list_23-4.start {
    counter-reset: lst-ctn-kix_list_23-4 0
  }

  .lst-kix_list_34-3>li:before {
    content: " "
  }

  .lst-kix_list_2-2>li:before {
    content: ""counter(lst-ctn-kix_list_2-2, lower-roman) ". "
  }

  .lst-kix_list_13-2>li:before {
    content: ""counter(lst-ctn-kix_list_13-2, lower-roman) ". "
  }

  ol.lst-kix_list_5-2.start {
    counter-reset: lst-ctn-kix_list_5-2 0
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td,
  table th {
    padding: 0
  }

  .c6 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    vertical-align: baseline;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c15 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c2 {
    margin-left: 18pt;
    padding-top: 6pt;
    padding-left: 0pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c11 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: italic
  }

  .c13 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: italic
  }

  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c18 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c1 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c4 {
    padding-top: 6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 12pt
  }

  .c16 {
    padding-top: 6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center;
    height: 12pt
  }

  .c17 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
  }

  .c8 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
  }

  .c5 {
    padding-top: 6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c14 {
    padding-top: 6pt;
    padding-bottom: 12pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c10 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
  }

  .c19 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
  }

  .c12 {
    border: 1px solid black;
    margin: 5px
  }

  .c9 {
    color: inherit;
    text-decoration: inherit
  }

  .c7 {
    padding: 0;
    margin: 0
  }

  .c3 {
    background-color: #ffff00
  }

  .title {
    padding-top: 6pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  h1 {
    padding-top: 12pt;
    color: #000000;
    border-bottom-color: #000000;
    font-size: 16pt;
    padding-bottom: 1pt;
    line-height: 1.0;
    page-break-after: avoid;
    border-bottom-width: 0.5pt;
    font-family: "Arial";
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 13pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 10pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }
}

#cali-privacy-policy {
  p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:0in;
	 margin-left:0in;
	 font-size:12.0pt;
	 font-family:"Arial",sans-serif;
	 color:black;}
  span.MsoCommentReference
	{color:black;}
  a:link, span.MsoHyperlink
	{color:black;
	 text-decoration:none none;}
  span.CustomizableHeadingChar
	{mso-style-name:"Customizable Heading Char";
	 mso-style-link:"Customizable Heading";
	 font-family:"Times New Roman",serif;
	 color:black;
	 font-weight:bold;}
  p.CustomizableHeading, li.CustomizableHeading, div.CustomizableHeading
	{mso-style-name:"Customizable Heading";
	 mso-style-link:"Customizable Heading Char";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:0in;
	 margin-left:0in;
	 text-align:center;
	 font-size:12.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;
	 font-weight:bold;}
  span.BulletList1Char
	{mso-style-name:"Bullet List 1 Char";
	 mso-style-link:"Bullet List 1";
	 font-family:"Times New Roman",serif;
	 color:black;}
  p.BulletList1, li.BulletList1, div.BulletList1
	{mso-style-name:"Bullet List 1";
	 mso-style-link:"Bullet List 1 Char";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:6.0pt;
	 margin-left:.5in;
	 text-indent:-.25in;
	 font-size:12.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;}
  span.BulletList2Char
	{mso-style-name:"Bullet List 2 Char";
	 mso-style-link:"Bullet List 2";
	 font-family:"Times New Roman",serif;
	 color:black;}
  p.BulletList2, li.BulletList2, div.BulletList2
	{mso-style-name:"Bullet List 2";
	 mso-style-link:"Bullet List 2 Char";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:6.0pt;
	 margin-left:.75in;
	 text-indent:-.25in;
	 font-size:12.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;}
  span.List-NumberedListLevel1Char
	{mso-style-name:"List - Numbered List Level 1 Char";
	 mso-style-link:"List - Numbered List Level 1";
	 font-family:"Times New Roman",serif;
	 color:black;}
  p.List-NumberedListLevel1, li.List-NumberedListLevel1, div.List-NumberedListLevel1
	{mso-style-name:"List - Numbered List Level 1";
	 mso-style-link:"List - Numbered List Level 1 Char";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:6.0pt;
	 margin-left:.5in;
	 text-indent:-.3in;
	 font-size:12.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;}
  span.ParaFirst-lineIndentChar
	{mso-style-name:"Para First-line Indent Char";
	 mso-style-link:"Para First-line Indent";
	 font-family:"Times New Roman",serif;
	 color:black;}
  p.ParaFirst-lineIndent, li.ParaFirst-lineIndent, div.ParaFirst-lineIndent
	{mso-style-name:"Para First-line Indent";
	 mso-style-link:"Para First-line Indent Char";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:0in;
	 margin-left:0in;
	 text-indent:.5in;
	 font-size:12.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;}
  span.ParagraphChar1
	{mso-style-name:"Paragraph Char1";
	 mso-style-link:Paragraph;
	 font-family:"Times New Roman",serif;
	 color:black;}
  p.Paragraph, li.Paragraph, div.Paragraph
	{mso-style-name:Paragraph;
	 mso-style-link:"Paragraph Char1";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:0in;
	 margin-left:0in;
	 font-size:12.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;}
  span.DocumentTitleChar
	{mso-style-name:"Document Title Char";
	 mso-style-link:"Document Title";
	 font-family:"Times New Roman",serif;
	 color:black;
	 font-weight:bold;}
  p.DocumentTitle, li.DocumentTitle, div.DocumentTitle
	{mso-style-name:"Document Title";
	 mso-style-link:"Document Title Char";
	 margin-top:6.0pt;
	 margin-right:0in;
	 margin-bottom:12.0pt;
	 margin-left:0in;
	 text-align:center;
	 font-size:16.0pt;
	 font-family:"Times New Roman",serif;
	 color:black;
	 font-weight:bold;}
  .MsoChpDefault
	{font-size:10.0pt;
	 font-family:"Arial",sans-serif;}
  @page WordSection1
	{size:8.5in 11.0in;
	 margin:.5in .5in .5in .5in;}
  div.WordSection1
	{page:WordSection1;}
}

body {
  /* background-color: #fff; */
  background-color: #FAF9F9;
  font-family: Barlow, "Helvetica Neue", Helvetica, sans-serif;
}

body:after {
    content:'';
    position: fixed;
    height: 150px;
    width: 100%;
    top: 0%;
    /* background: #fff; */
    /* background:--original--#fff...; */
    z-index: -999;
}

.app-container {
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.ui.grid > * {
  padding-left: 1.2rem;
  padding-right: 1.35rem;
}

.ui.grid > .row.youtip-header {
  margin-top: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #fff;
}

.row.youtip-header + .row {
  padding-top: 0.25rem;
}

.ui.rating .active.icon {
  color: #fff;
}

#blank-container-col {
  margin-top: 1rem;
}

#blank-container-col img {
  margin-right: 1rem;
}

#blank-container-segment {
  /* background-color: #fff; */
  border-radius: 12px;
}



#tip-container-row {
  /* padding: 1.25rem 0.1rem 2rem 0.1rem; */
}
#tip-container-segment {
  border-radius: 14px;
  padding: 0; /* 1rem 1rem 2.25rem 1rem; */
}
#tip-container-segment .ui.segment {
  padding: 1rem 1.1rem;
}
.optional-tip-input-field.ui.input {
  width: 70%;
  color: #666;
  margin-bottom: 0.25em;
}
.optional-tip-input-field.ui.input > input.input {
  border: 0;
  border-radius: 0;
  padding: 0.25em;
  width: 100%;
  text-align: center;
}
#tip-container-segment .ui.attached.segment {
  border: none;
  /* border-radius: 14px; */
}
#tip-container-segment .ui[class*="top attached"].segment {
  border-radius: 14px 14px 0 0;
}
#tip-container-segment .ui[class*="bottom attached "].segment {
  border-radius: 0 0 14px 14px;
}
#tip-presets {
  background-color: #f2f2f2;
  font-family: 'Helvetica Neueu', Helvetica, sans-serif;
}
#tip-presets.ui.segment {
  /* padding-top: 0.5em; */
  /* padding-bottom: 0.5em;*/
  padding: 0.5em;
}
#tip-presets.ui.segment span {
  margin-right: 0.5em;
  font-weight: 500;
  font-size: 1em;
  color: #666;
  /* font-style: italic; */
}
#tip-presets .ui.circular.label {
  min-height: 2.8em;
  min-width: 2.8em;
  max-height: 2.75em;
  max-width: 2.75em;
  line-height: 1.7em;
  font-weight: 500;
  padding: 0.5em !important;
  font-family: Barlow, 'Helvetica Neueu', Helvetica, sans-serif;
  /* .5833 .833 */
}
#tip-presets .ui.youtip.label {
  background-color: #fff;
  /* border: 1px solid #fff; */
  border: 1px solid #ccc;
  font-size: 1rem;
  margin: 0 0.4em;
}

#tip-presets .ui.youtip.label.preset-left-adjust {
  padding-left: 0.35em !important;
}
/* #tip-presets:not(.horizontal).segment:last-child { */
/*   border-radius: 0 0 14px 14px; */
/* } */



/* .bg-green, .bg-blue, */
.bg-blue {
  /* background-color: #fff; */
  background-color: #ddd;
}
.bg-green,
.ui.button.bg-green {
  background-color: #ddd;
}


/* #e22288 */
/* #051e5e */
/* #dd7a80 */
/* #f7f762 */
/* #2d87fc */
/* #0f6ba8 */

#pay-with-cc-form #card-number-element,
#pay-with-cc-form #card-expiry-element,
#pay-with-cc-form #card-cvc-element {
  /* height: auto; */
  /* max-height: 35px; */
  background-color: white;
}

.ui.progress.top {
  background: #ccc;
  /* background: #eebcbf; */
  /* background: rgba(219, 123, 130, .7); */

}

.ui.progress .bar {
  background: #999;
  /* background: #dd7a80; */
  /* background: #e3be32; */
  /* background: #f1d127; */
}

.ui.centered.grid .column .terms-and-conditions-link,
.ui.centered.grid .column .wallet-info-link {
  /* text-transform: uppercase; */
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.terms-and-conditions-link i.icon {
  color: #ccc;
}
.wallet-info-link i.icon {
  color: #999;
  font-style: italic;
}

#wallet-info-button,
#terms-and-conditions-button {
  font-size: 0.85em;
  font-style: italic;
  border: none;
  padding: 0.5em;
  /* text-transform: uppercase; */
}

#wallet-info-button:hover,
#terms-and-conditions-button:hover {
  text-decoration: underline;
  color: #444
}

#wallet-info-button {
  padding: 0.5em 0.5em 0.6em 0.5em;
}

.wallet-info-link button.ui.basic.button#wallet-info-button,
.wallet-info-link a,
a.wallet-info-link,
.terms-and-conditions-link button.ui.basic.button#terms-and-conditions-button,
.terms-and-conditions-link a,
a.terms-and-conditions-link {
  font-family: Barlow, 'Helvetica Neue', Helvetica, sans-serif;
  /* text-decoration: underline; */
  font-size: 0.8em;
  text-align: center;
  font-style: italic;
  /*color: rbga(0, 0, 0, .6);*/ /*#fff;*/ /*#999;*/
  color: #666;
}

.wallet-info-link button.ui.basic.button#wallet-info-button,
.wallet-info-link a,
a.wallet-info-link {
  color: #999;
}

.ui.mini.message {
  padding: 0.6em;
}

.divider.slim {
  margin: 0.75rem 0;
}
div.ui.horizontal.divider {
  color: #dd7a80;
  font-size: 0.9rem;
}

button.ui.button.applepay-button,
button.ui.button.googlepay-button{
  background-origin: content-box;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 0px;
  border-radius: 12px;
  cursor: pointer;
  height: 40px;
  min-height: 40px;
  background-color: #000;
  box-shadow: none;
  padding: 12px 24px 10px;
}

.ui.grid .column.applepay-col {
  padding-right: 0.5rem;
}

.ui.grid .column.googlepay-col {
  padding-left: 0.5rem;
}

#pay-with-room {
  margin: 1.25rem 0;
}

#pay-with-cc-button-toggle {
  color: #fff;
  border-radius: 12px;
}

#pay-with-cc-view {
  margin-top: 0.3em;
}

#pay-with-cc-form {
  border-radius: 12px 12px 0 0;
  border: 1px solid #eee;
  line-height: 24px;
  padding: 1em 1.3em 1em 1.3em;
  padding-bottom: 1.5em;
  font-family: Helvetica Neue, Helvetica, sans-serif;
  background-color: #f5f7fa;
}

#pay-with-cc-button {
  border-radius: 0 0 12px 12px;
  color: #fff;
}

/**************************************************/
/*     prefactor's RecipientInfo.css contents     */
/**************************************************/
#recipient-info {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  text-align: left;
  /* padding-bottom: 0.85rem; */
  padding-bottom: 0;
}

#recipient-info .timestamp {
  color: #999;
  padding-bottom: 0.25rem;
  font-size: 0.85rem;
  border-bottom: 1px dashed #e5e5e5;
}

#recipient-info .details {
  margin-top: 0.8rem;
  line-height: 1.3rem;
}

#recipient-info .details .name {
  font-weight: bold;
  font-size: 1.15rem;
}

#recipient-info .details .info {
  text-align: right;
  font-weight: normal;
  font-size: 1rem;
}

#recipient-info .details .service {
  text-align: left;
  font-style: italic;
  font-size: 0.85rem;
  color: #666;
}

/**************************************************/
/*    prefactor's TipAmountInput.css contents     */
/**************************************************/
.tip-amount-header {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  margin-top: 0;  /* 1.25rem; */
  margin-bottom: 0.65rem;
  font-size: 1em;
  color: #333;
}

.ui.input.tip-amount-input {
  font-family: Barlow, 'Helvetica Neue', Helvetica, sans-serif;
  border-color: #fff;
  color: #fff;
  text-align: center;
  min-width: 30%;
  max-width: 90%;
  width: 70%;
}

input.tip-amount-input-currency,
.ui.input.tip-amount-input > input {
  font-family: Barlow, 'Helvetica Neue', Helvetica, sans-serif;
  border-color: #fff;
  color: #fff;
  font-size: 2.75em;
  text-align: right;
  /* line-height: 1em; */
  font-weight: 300;
  padding: 0.55rem 0.95rem !important;
  /* padding-left: 1.15em !important; */
  /* padding-right: 0.5em !important; */
  /* max-width: 50%; */
}

/*
.ui.input.tip-amount-input > input:selected {
  color: #fff;
}
*/

.ui.input.tip-amount-input i.icon {
  color: #fff;

  /* color: #fff; */
  font-size: 1.25em;
  width: 1.5em;
  padding-left: 2em;
}

.tip-fee-info {
  margin-top: 1rem;
  font-size: 0.9em;
  color: #999;
  margin-bottom: 0.9rem;
}
.tip-fee-info .transaction-info-fee,
.tip-fee-info .transaction-info-total {
}

.tip-fee-info span {
  color: #fff;
}

.credit-card-logos {
  text-align: center;
  margin-top: 1.2rem;
}

.credit-card-logos img {
  max-width: 225px;
}

.credit-card-logos svg {
  margin: 0.25rem 0.5rem;
}

/**************************************************/
/*    prefactor's Receipt.css contents     */
/**************************************************/
#receipt-container .rate-service-row .rate-service-segment .comment-box-link {
  font-size: 0.85em;
  font-weight: bold;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
}

#receipt-container .rate-service-row .rate-service-segment .comment-box {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  background: none;
  border: none;
  border-bottom: 1px solid #fff;
  margin-top: 0;
}

#receipt-container .rate-service-row .rate-service-segment .comment-box > input {
  border: none !important;
  padding: 0.25em 0;
  background-color: #f9f9f9;
  font-size: 16px;
}

#receipt-container .receipt-email-header {
  padding-bottom: 8px;
}
#receipt-container .receipt-email-box {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  background: none;
  border: none;
  border-bottom: 1px solid #999;
  margin-top: 0;
}
#receipt-container .receipt-email-box .receipt-email-input > input {
  padding: 0.25em 0;
  border: none !important;
  background-color: #f9f9f9;
  font-size: 16px;
}
#receipt-container .receipt-email-submit {
  margin-top: 0.5em;
}
#receipt-container .receipt-message {
  font-size: 12px;
  font-style: italic;
}
#receipt-container .receipt-email-error {
  font-size: 11px;
  color: #cc0000;
}

#receipt-container .rate-service-row .rate-service-segment h5 {
  margin-top: 0.5em;
}

#receipt-container .rate-service-row .rate-service-segment .submitted-comment {
  font-size: 0.9em;
  font-style: italic;
  color: #666;
  padding-top: 0.7em;
}

#receipt-container .rate-service-row .rate-service-segment button {
  margin-top: 0.5em;
  color: #fff;
}

#receipt-container .receipt-image-row {
  padding-bottom: 0.8em;
}

#receipt-container .rate-service-row {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  padding-top: 0;
  padding-bottom: 1.5em;
}

#receipt-container .rate-service-row .rate-service-segment,
#receipt-container .receipt-content-row .receipt-content-segment {
  padding-top: 1.5em;
  padding-bottom: 2em;
  border-radius: 12px;
}

#receipt-container .rate-service-row .rate-service-segment .rating {
  margin: 0.25rem 0 1rem 0;
}

#receipt-container .rate-service-row .rate-service-segment .stars {
  margin-bottom: 0.75em;
}

#receipt-container .rate-service-row .rate-service-segment .stars .star-empty {
  color: #fff;
}

#receipt-container .rate-service-row .rate-service-segment .stars .star-full {
  color: #fff;
}


#receipt-container table.receipt-details {
  margin-top: 0.5em;
}

#receipt-container table.receipt-details .receipt-field-label {
  color: #666;
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: normal;
  text-align: left;
}

#receipt-container table.receipt-details .receipt-field-value {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  font-size: 1em;
  text-align: right;
}

#receipt-container table.receipt-details .receipt-field-value.transaction-id {
  font-size: 0.85em;
}

#receipt-container table.receipt-details .receipt-field-value .amount-details {
  font-size: 0.85em;
  color: #999;
}

#receipt-container .service-details {
  font-size: 1em;
  font-weight: normal;
  background-color: #eee;
}

#receipt-container #recipient-info {
  margin-top: 0;
  padding-bottom: 0.5em;
  border-bottom: 2px dotted #ccc;
}

#receipt-container #recipient-info .details {
  margin-top: 0;
}

#pay-with-cc-error-message {
  font-size: 0.9em;
  text-align: center;
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0.25em;
  margin-top: 0;
  font-family: Helvetica Neue, Helvetica, sans-serif;
  color: #e25950;
}


/**************************************************/
/*    prefactor's Payments.css contents     */
/**************************************************/

#pay-with-cc-form .field {
}

#pay-with-cc-form .field.no-border {
  border: none;
}

#pay-with-cc-form .field > label {
  font-weight: normal;
  color: #999;
  text-align: left;
}

#pay-with-cc-form .cc-form-cancel {
  margin-bottom: 0.5em;
}

#pay-with-cc-form form.ElementsApp,
#pay-with-cc-form input,
#pay-with-cc-form .ui.field input,
#pay-with-cc-form .CardNumberField input,
#pay-with-cc-form form.ElementsApp input,
#pay-with-cc-form .CardNumberField input,
#pay-with-cc-form .CardNumberField-input-wrapper,
.ElementsApp input,
.InputContainer input,
span.InputContainer input.InputElement,
#card-expiry-element,
#card-number-element,
#card-cvc-element,
.stripe-input-container {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  background: none;
  border-bottom: 1px solid #fff;
  padding: 0;
  font-size: 18px;
}

.pay-with-apple-dimmer {
  vertical-align: bottom;
}

.pay-with-apple-screenshot {
  vertical-align: bottom;
}

.ui.utton.pay-with-apple-button img {
  max-height: 30px;
  height: 25px;
  min-height: 15px;
}

.ui.menu.payment-options-menu {
  background-color: #565e77;
}

.ui.menu.payment-options-menu .item {
  color: #eee;
  border-color: #999;
}

.ui.menu.payment-options-menu .item:hover {
  color: #fff;
}

.ui.menu.payment-options-menu .item.active {
  background-color: #fff;
  border-color: #fff;
  color: #fff;
}
.ui.menu.payment-options-menu .item:after {
  border-color: #fff;
  background-color: #fff;
  color: #fff;
}

#pay-with-cc-form a.change-payment-method,
#pay-with-cc-form .change-payment-method {
  font-weight: bold;
  color: #555;
  background: none;
  border: none;
  cursor: pointer;
}

.optional-tip-input-field.ui.input {
  color: #666;
}

.optional-tip-input-field.ui.input > input.input {
  background-color: #fff;
  color: #666;
  font-style: italic;
  text-align: center;
  padding-bottom: 0.1em;
}

/***********************************************************/

.apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: donate; /* Use any supported button type. */
}
.apple-pay-button-black {
    -apple-pay-button-style: black;
}
.apple-pay-button-white {
    -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
}

@supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
    }
    .apple-pay-button-black {
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-color: black;
        -apple-pay-button-style: black;
    }
    .apple-pay-button-white {
        -apple-pay-button-style: white;
    }
    .apple-pay-button-white-with-line {
        -apple-pay-button-style: white-outline;
    }
}

@supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        background-size: 100% 60%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-radius: 5px;
        padding: 0px;
        box-sizing: border-box;
        min-width: 200px;
        min-height: 32px;
        max-height: 64px;
    }

    .apple-pay-button-black {
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-color: black;
    }
    .apple-pay-button-white {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
    }
    .apple-pay-button-white-with-line {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
        border: .5px solid black;
    }
}

#root > div > div > div.centered.row > div > div:last-child > div {
  border-top: 1px solid #ccc;
  padding-top: 0.75rem;
  text-align: center;
  margin-top: 0.6rem;
}

#receipt-container .receipt-content-segment.email-receipt-segment button:disabled {
  background-color: #666 !important;
}

