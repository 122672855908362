#privacy-policy {
  ol.lst-kix_list_7-0 {
    list-style-type: none
  }

  .lst-kix_list_2-1>li {
    counter-increment: lst-ctn-kix_list_2-1
  }

  ol.lst-kix_list_38-6 {
    list-style-type: none
  }

  ol.lst-kix_list_38-5 {
    list-style-type: none
  }

  ol.lst-kix_list_38-8 {
    list-style-type: none
  }

  .lst-kix_list_21-8>li {
    counter-increment: lst-ctn-kix_list_21-8
  }

  ol.lst-kix_list_9-0.start {
    counter-reset: lst-ctn-kix_list_9-0 0
  }

  ol.lst-kix_list_38-7 {
    list-style-type: none
  }

  ol.lst-kix_list_38-2 {
    list-style-type: none
  }

  ol.lst-kix_list_38-1 {
    list-style-type: none
  }

  ol.lst-kix_list_38-4 {
    list-style-type: none
  }

  ol.lst-kix_list_38-3 {
    list-style-type: none
  }

  ol.lst-kix_list_38-0 {
    list-style-type: none
  }

  ol.lst-kix_list_13-4.start {
    counter-reset: lst-ctn-kix_list_13-4 0
  }

  ol.lst-kix_list_40-3.start {
    counter-reset: lst-ctn-kix_list_40-3 0
  }

  .lst-kix_list_13-0>li {
    counter-increment: lst-ctn-kix_list_13-0
  }

  ol.lst-kix_list_20-2.start {
    counter-reset: lst-ctn-kix_list_20-2 0
  }

  .lst-kix_list_5-0>li {
    counter-increment: lst-ctn-kix_list_5-0
  }

  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0
  }

  ol.lst-kix_list_7-5 {
    list-style-type: none
  }

  ol.lst-kix_list_7-6 {
    list-style-type: none
  }

  ol.lst-kix_list_7-7 {
    list-style-type: none
  }

  ol.lst-kix_list_7-8 {
    list-style-type: none
  }

  ol.lst-kix_list_7-1 {
    list-style-type: none
  }

  ol.lst-kix_list_7-2 {
    list-style-type: none
  }

  ol.lst-kix_list_7-3 {
    list-style-type: none
  }

  ol.lst-kix_list_7-4 {
    list-style-type: none
  }

  ol.lst-kix_list_23-2.start {
    counter-reset: lst-ctn-kix_list_23-2 0
  }

  .lst-kix_list_40-1>li {
    counter-increment: lst-ctn-kix_list_40-1
  }

  ol.lst-kix_list_5-3.start {
    counter-reset: lst-ctn-kix_list_5-3 0
  }

  .lst-kix_list_38-6>li {
    counter-increment: lst-ctn-kix_list_38-6
  }

  .lst-kix_list_4-3>li {
    counter-increment: lst-ctn-kix_list_4-3
  }

  ol.lst-kix_list_17-1.start {
    counter-reset: lst-ctn-kix_list_17-1 0
  }

  .lst-kix_list_24-7>li {
    counter-increment: lst-ctn-kix_list_24-7
  }

  .lst-kix_list_18-8>li {
    counter-increment: lst-ctn-kix_list_18-8
  }

  ol.lst-kix_list_8-8.start {
    counter-reset: lst-ctn-kix_list_8-8 0
  }

  ol.lst-kix_list_26-7.start {
    counter-reset: lst-ctn-kix_list_26-7 0
  }

  .lst-kix_list_7-2>li {
    counter-increment: lst-ctn-kix_list_7-2
  }

  ol.lst-kix_list_10-4.start {
    counter-reset: lst-ctn-kix_list_10-4 0
  }

  .lst-kix_list_24-7>li:before {
    content: ""counter(lst-ctn-kix_list_24-7, lower-latin) ". "
  }

  ol.lst-kix_list_16-5 {
    list-style-type: none
  }

  .lst-kix_list_1-4>li {
    counter-increment: lst-ctn-kix_list_1-4
  }

  ol.lst-kix_list_16-6 {
    list-style-type: none
  }

  .lst-kix_list_24-8>li:before {
    content: ""counter(lst-ctn-kix_list_24-8, lower-roman) ". "
  }

  ol.lst-kix_list_16-7 {
    list-style-type: none
  }

  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0
  }

  ol.lst-kix_list_16-8 {
    list-style-type: none
  }

  ol.lst-kix_list_9-5.start {
    counter-reset: lst-ctn-kix_list_9-5 0
  }

  ol.lst-kix_list_16-1 {
    list-style-type: none
  }

  ol.lst-kix_list_40-8.start {
    counter-reset: lst-ctn-kix_list_40-8 0
  }

  ol.lst-kix_list_16-2 {
    list-style-type: none
  }

  ol.lst-kix_list_16-3 {
    list-style-type: none
  }

  ol.lst-kix_list_16-4 {
    list-style-type: none
  }

  .lst-kix_list_24-2>li:before {
    content: "("counter(lst-ctn-kix_list_24-2, lower-latin) ") "
  }

  ol.lst-kix_list_16-0 {
    list-style-type: none
  }

  ol.lst-kix_list_20-7.start {
    counter-reset: lst-ctn-kix_list_20-7 0
  }

  .lst-kix_list_24-3>li:before {
    content: "("counter(lst-ctn-kix_list_24-3, lower-roman) ") "
  }

  .lst-kix_list_24-4>li:before {
    content: ""counter(lst-ctn-kix_list_24-4, decimal) ") "
  }

  .lst-kix_list_9-4>li {
    counter-increment: lst-ctn-kix_list_9-4
  }

  .lst-kix_list_24-5>li:before {
    content: ""counter(lst-ctn-kix_list_24-5, lower-latin) ") "
  }

  .lst-kix_list_24-6>li:before {
    content: ""counter(lst-ctn-kix_list_24-6, lower-roman) ") "
  }

  ol.lst-kix_list_16-4.start {
    counter-reset: lst-ctn-kix_list_16-4 0
  }

  ol.lst-kix_list_14-1.start {
    counter-reset: lst-ctn-kix_list_14-1 0
  }

  .lst-kix_list_23-6>li:before {
    content: ""counter(lst-ctn-kix_list_23-6, decimal) ". "
  }

  .lst-kix_list_6-5>li {
    counter-increment: lst-ctn-kix_list_6-5
  }

  ol.lst-kix_list_27-6 {
    list-style-type: none
  }

  ol.lst-kix_list_27-5 {
    list-style-type: none
  }

  ol.lst-kix_list_27-8 {
    list-style-type: none
  }

  .lst-kix_list_23-3>li:before {
    content: "("counter(lst-ctn-kix_list_23-3, decimal) ") "
  }

  .lst-kix_list_23-7>li:before {
    content: ""counter(lst-ctn-kix_list_23-7, lower-latin) ". "
  }

  ol.lst-kix_list_27-7 {
    list-style-type: none
  }

  ol.lst-kix_list_27-2 {
    list-style-type: none
  }

  .lst-kix_list_23-2>li:before {
    content: ""counter(lst-ctn-kix_list_23-2, lower-roman) ") "
  }

  ol.lst-kix_list_27-1 {
    list-style-type: none
  }

  ol.lst-kix_list_23-7.start {
    counter-reset: lst-ctn-kix_list_23-7 0
  }

  ol.lst-kix_list_27-4 {
    list-style-type: none
  }

  ol.lst-kix_list_27-3 {
    list-style-type: none
  }

  .lst-kix_list_23-0>li:before {
    content: ""counter(lst-ctn-kix_list_23-0, decimal) ") "
  }

  .lst-kix_list_23-8>li:before {
    content: ""counter(lst-ctn-kix_list_23-8, lower-roman) ". "
  }

  .lst-kix_list_3-6>li {
    counter-increment: lst-ctn-kix_list_3-6
  }

  ol.lst-kix_list_27-0 {
    list-style-type: none
  }

  .lst-kix_list_23-1>li:before {
    content: ""counter(lst-ctn-kix_list_23-1, lower-latin) ") "
  }

  .lst-kix_list_24-1>li:before {
    content: "Section "counter(lst-ctn-kix_list_24-0, upper-roman) "."counter(lst-ctn-kix_list_24-1, decimal-leading-zero) " "
  }

  .lst-kix_list_2-8>li {
    counter-increment: lst-ctn-kix_list_2-8
  }

  .lst-kix_list_24-0>li:before {
    content: "Article "counter(lst-ctn-kix_list_24-0, upper-roman) ". "
  }

  ol.lst-kix_list_26-2.start {
    counter-reset: lst-ctn-kix_list_26-2 0
  }

  .lst-kix_list_23-4>li:before {
    content: "("counter(lst-ctn-kix_list_23-4, lower-latin) ") "
  }

  .lst-kix_list_23-5>li:before {
    content: "("counter(lst-ctn-kix_list_23-5, lower-roman) ") "
  }

  .lst-kix_list_22-2>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) ". "
  }

  .lst-kix_list_22-6>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) "."counter(lst-ctn-kix_list_22-3, decimal) "."counter(lst-ctn-kix_list_22-4, decimal) "."counter(lst-ctn-kix_list_22-5, decimal) "."counter(lst-ctn-kix_list_22-6, decimal) ". "
  }

  ol.lst-kix_list_36-0.start {
    counter-reset: lst-ctn-kix_list_36-0 0
  }

  .lst-kix_list_8-6>li {
    counter-increment: lst-ctn-kix_list_8-6
  }

  .lst-kix_list_22-0>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) ". "
  }

  .lst-kix_list_22-8>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) "."counter(lst-ctn-kix_list_22-3, decimal) "."counter(lst-ctn-kix_list_22-4, decimal) "."counter(lst-ctn-kix_list_22-5, decimal) "."counter(lst-ctn-kix_list_22-6, decimal) "."counter(lst-ctn-kix_list_22-7, decimal) "."counter(lst-ctn-kix_list_22-8, decimal) ". "
  }

  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0
  }

  ol.lst-kix_list_9-7 {
    list-style-type: none
  }

  ol.lst-kix_list_9-8 {
    list-style-type: none
  }

  ol.lst-kix_list_22-5.start {
    counter-reset: lst-ctn-kix_list_22-5 0
  }

  ol.lst-kix_list_39-5.start {
    counter-reset: lst-ctn-kix_list_39-5 0
  }

  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0
  }

  ol.lst-kix_list_9-3 {
    list-style-type: none
  }

  ol.lst-kix_list_9-4 {
    list-style-type: none
  }

  .lst-kix_list_5-7>li {
    counter-increment: lst-ctn-kix_list_5-7
  }

  ol.lst-kix_list_9-5 {
    list-style-type: none
  }

  ol.lst-kix_list_9-6 {
    list-style-type: none
  }

  ol.lst-kix_list_9-0 {
    list-style-type: none
  }

  ol.lst-kix_list_9-1 {
    list-style-type: none
  }

  ol.lst-kix_list_9-2 {
    list-style-type: none
  }

  .lst-kix_list_22-4>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) "."counter(lst-ctn-kix_list_22-3, decimal) "."counter(lst-ctn-kix_list_22-4, decimal) ". "
  }

  ol.lst-kix_list_25-5.start {
    counter-reset: lst-ctn-kix_list_25-5 0
  }

  .lst-kix_list_41-7>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) "."counter(lst-ctn-kix_list_41-3, decimal) "."counter(lst-ctn-kix_list_41-4, decimal) "."counter(lst-ctn-kix_list_41-5, decimal) "."counter(lst-ctn-kix_list_41-6, decimal) "."counter(lst-ctn-kix_list_41-7, decimal) ". "
  }

  .lst-kix_list_25-5>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) "."counter(lst-ctn-kix_list_25-3, decimal) "."counter(lst-ctn-kix_list_25-4, decimal) "."counter(lst-ctn-kix_list_25-5, decimal) ". "
  }

  .lst-kix_list_25-7>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) "."counter(lst-ctn-kix_list_25-3, decimal) "."counter(lst-ctn-kix_list_25-4, decimal) "."counter(lst-ctn-kix_list_25-5, decimal) "."counter(lst-ctn-kix_list_25-6, decimal) "."counter(lst-ctn-kix_list_25-7, decimal) ". "
  }

  .lst-kix_list_3-5>li {
    counter-increment: lst-ctn-kix_list_3-5
  }

  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0
  }

  ol.lst-kix_list_18-3.start {
    counter-reset: lst-ctn-kix_list_18-3 0
  }

  ol.lst-kix_list_24-4.start {
    counter-reset: lst-ctn-kix_list_24-4 0
  }

  ol.lst-kix_list_18-7 {
    list-style-type: none
  }

  ol.lst-kix_list_18-8 {
    list-style-type: none
  }

  ol.lst-kix_list_33-0.start {
    counter-reset: lst-ctn-kix_list_33-0 0
  }

  ol.lst-kix_list_18-3 {
    list-style-type: none
  }

  ol.lst-kix_list_18-4 {
    list-style-type: none
  }

  .lst-kix_list_6-4>li {
    counter-increment: lst-ctn-kix_list_6-4
  }

  .lst-kix_list_41-1>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) ". "
  }

  ol.lst-kix_list_18-5 {
    list-style-type: none
  }

  ul.lst-kix_list_29-0 {
    list-style-type: none
  }

  ol.lst-kix_list_18-6 {
    list-style-type: none
  }

  .lst-kix_list_40-7>li:before {
    content: ""counter(lst-ctn-kix_list_40-7, lower-latin) ". "
  }

  ol.lst-kix_list_27-4.start {
    counter-reset: lst-ctn-kix_list_27-4 0
  }

  ol.lst-kix_list_18-0 {
    list-style-type: none
  }

  ol.lst-kix_list_18-1 {
    list-style-type: none
  }

  .lst-kix_list_9-3>li {
    counter-increment: lst-ctn-kix_list_9-3
  }

  ol.lst-kix_list_18-2 {
    list-style-type: none
  }

  .lst-kix_list_40-5>li:before {
    content: "("counter(lst-ctn-kix_list_40-5, lower-roman) ") "
  }

  ol.lst-kix_list_15-2.start {
    counter-reset: lst-ctn-kix_list_15-2 0
  }

  .lst-kix_list_41-5>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) "."counter(lst-ctn-kix_list_41-3, decimal) "."counter(lst-ctn-kix_list_41-4, decimal) "."counter(lst-ctn-kix_list_41-5, decimal) ". "
  }

  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0
  }

  ol.lst-kix_list_7-6.start {
    counter-reset: lst-ctn-kix_list_7-6 0
  }

  ul.lst-kix_list_29-1 {
    list-style-type: none
  }

  ul.lst-kix_list_29-2 {
    list-style-type: none
  }

  ul.lst-kix_list_29-3 {
    list-style-type: none
  }

  ul.lst-kix_list_29-4 {
    list-style-type: none
  }

  ul.lst-kix_list_29-5 {
    list-style-type: none
  }

  ul.lst-kix_list_29-6 {
    list-style-type: none
  }

  .lst-kix_list_41-3>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) "."counter(lst-ctn-kix_list_41-3, decimal) ". "
  }

  ul.lst-kix_list_29-7 {
    list-style-type: none
  }

  ul.lst-kix_list_29-8 {
    list-style-type: none
  }

  ol.lst-kix_list_15-3.start {
    counter-reset: lst-ctn-kix_list_15-3 0
  }

  ol.lst-kix_list_5-8.start {
    counter-reset: lst-ctn-kix_list_5-8 0
  }

  .lst-kix_list_1-3>li {
    counter-increment: lst-ctn-kix_list_1-3
  }

  .lst-kix_list_40-3>li:before {
    content: "("counter(lst-ctn-kix_list_40-3, decimal) ") "
  }

  ol.lst-kix_list_12-2.start {
    counter-reset: lst-ctn-kix_list_12-2 0
  }

  ol.lst-kix_list_6-0.start {
    counter-reset: lst-ctn-kix_list_6-0 0
  }

  .lst-kix_list_40-1>li:before {
    content: ""counter(lst-ctn-kix_list_40-1, lower-latin) ") "
  }

  .lst-kix_list_4-2>li {
    counter-increment: lst-ctn-kix_list_4-2
  }

  ol.lst-kix_list_3-1 {
    list-style-type: none
  }

  ol.lst-kix_list_38-3.start {
    counter-reset: lst-ctn-kix_list_38-3 0
  }

  ol.lst-kix_list_3-2 {
    list-style-type: none
  }

  .lst-kix_list_24-8>li {
    counter-increment: lst-ctn-kix_list_24-8
  }

  ol.lst-kix_list_3-3 {
    list-style-type: none
  }

  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0
  }

  .lst-kix_list_5-1>li {
    counter-increment: lst-ctn-kix_list_5-1
  }

  ol.lst-kix_list_3-4 {
    list-style-type: none
  }

  ol.lst-kix_list_21-3.start {
    counter-reset: lst-ctn-kix_list_21-3 0
  }

  ol.lst-kix_list_3-0 {
    list-style-type: none
  }

  .lst-kix_list_7-1>li {
    counter-increment: lst-ctn-kix_list_7-1
  }

  .lst-kix_list_38-5>li {
    counter-increment: lst-ctn-kix_list_38-5
  }

  ol.lst-kix_list_25-6.start {
    counter-reset: lst-ctn-kix_list_25-6 0
  }

  ol.lst-kix_list_34-0 {
    list-style-type: none
  }

  .lst-kix_list_21-8>li:before {
    content: ""counter(lst-ctn-kix_list_21-8, lower-roman) ". "
  }

  .lst-kix_list_26-5>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) "."counter(lst-ctn-kix_list_26-3, decimal) "."counter(lst-ctn-kix_list_26-4, decimal) "."counter(lst-ctn-kix_list_26-5, decimal) ". "
  }

  .lst-kix_list_16-0>li {
    counter-increment: lst-ctn-kix_list_16-0
  }

  .lst-kix_list_8-0>li {
    counter-increment: lst-ctn-kix_list_8-0
  }

  .lst-kix_list_10-0>li {
    counter-increment: lst-ctn-kix_list_10-0
  }

  ol.lst-kix_list_18-2.start {
    counter-reset: lst-ctn-kix_list_18-2 0
  }

  ol.lst-kix_list_3-5 {
    list-style-type: none
  }

  ol.lst-kix_list_3-6 {
    list-style-type: none
  }

  .lst-kix_list_26-8>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) "."counter(lst-ctn-kix_list_26-3, decimal) "."counter(lst-ctn-kix_list_26-4, decimal) "."counter(lst-ctn-kix_list_26-5, decimal) "."counter(lst-ctn-kix_list_26-6, decimal) "."counter(lst-ctn-kix_list_26-7, decimal) "."counter(lst-ctn-kix_list_26-8, decimal) ". "
  }

  ol.lst-kix_list_3-7 {
    list-style-type: none
  }

  ol.lst-kix_list_3-8 {
    list-style-type: none
  }

  .lst-kix_list_21-0>li:before {
    content: "Article "counter(lst-ctn-kix_list_21-0, upper-roman) ". "
  }

  ul.lst-kix_list_36-1 {
    list-style-type: none
  }

  ul.lst-kix_list_36-2 {
    list-style-type: none
  }

  .lst-kix_list_26-1>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) ". "
  }

  ul.lst-kix_list_36-3 {
    list-style-type: none
  }

  .lst-kix_list_13-1>li {
    counter-increment: lst-ctn-kix_list_13-1
  }

  ul.lst-kix_list_36-4 {
    list-style-type: none
  }

  .lst-kix_list_21-1>li:before {
    content: "Section "counter(lst-ctn-kix_list_21-0, upper-roman) "."counter(lst-ctn-kix_list_21-1, decimal-leading-zero) " "
  }

  ol.lst-kix_list_15-8.start {
    counter-reset: lst-ctn-kix_list_15-8 0
  }

  .lst-kix_list_26-4>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) "."counter(lst-ctn-kix_list_26-3, decimal) "."counter(lst-ctn-kix_list_26-4, decimal) ". "
  }

  .lst-kix_list_10-2>li {
    counter-increment: lst-ctn-kix_list_10-2
  }

  .lst-kix_list_21-5>li:before {
    content: ""counter(lst-ctn-kix_list_21-5, lower-latin) ") "
  }

  ul.lst-kix_list_36-5 {
    list-style-type: none
  }

  ul.lst-kix_list_36-6 {
    list-style-type: none
  }

  ul.lst-kix_list_36-7 {
    list-style-type: none
  }

  ul.lst-kix_list_36-8 {
    list-style-type: none
  }

  .lst-kix_list_21-4>li:before {
    content: ""counter(lst-ctn-kix_list_21-4, decimal) ") "
  }

  .lst-kix_list_26-0>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) ". "
  }

  ol.lst-kix_list_7-2.start {
    counter-reset: lst-ctn-kix_list_7-2 0
  }

  ol.lst-kix_list_38-8.start {
    counter-reset: lst-ctn-kix_list_38-8 0
  }

  ol.lst-kix_list_19-5.start {
    counter-reset: lst-ctn-kix_list_19-5 -1
  }

  ol.lst-kix_list_12-5 {
    list-style-type: none
  }

  ol.lst-kix_list_12-6 {
    list-style-type: none
  }

  ol.lst-kix_list_12-7 {
    list-style-type: none
  }

  ol.lst-kix_list_12-8 {
    list-style-type: none
  }

  ol.lst-kix_list_12-1 {
    list-style-type: none
  }

  ol.lst-kix_list_12-2 {
    list-style-type: none
  }

  ol.lst-kix_list_12-3 {
    list-style-type: none
  }

  ol.lst-kix_list_39-6.start {
    counter-reset: lst-ctn-kix_list_39-6 0
  }

  ol.lst-kix_list_12-4 {
    list-style-type: none
  }

  ol.lst-kix_list_26-3.start {
    counter-reset: lst-ctn-kix_list_26-3 0
  }

  ol.lst-kix_list_12-0 {
    list-style-type: none
  }

  ol.lst-kix_list_27-8.start {
    counter-reset: lst-ctn-kix_list_27-8 0
  }

  .lst-kix_list_21-0>li {
    counter-increment: lst-ctn-kix_list_21-0
  }

  .lst-kix_list_25-1>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) ". "
  }

  .lst-kix_list_25-0>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) ". "
  }

  ol.lst-kix_list_10-8.start {
    counter-reset: lst-ctn-kix_list_10-8 0
  }

  ol.lst-kix_list_7-1.start {
    counter-reset: lst-ctn-kix_list_7-1 0
  }

  .lst-kix_list_39-0>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) ". "
  }

  ol.lst-kix_list_23-6 {
    list-style-type: none
  }

  ol.lst-kix_list_23-5 {
    list-style-type: none
  }

  ol.lst-kix_list_23-8 {
    list-style-type: none
  }

  .lst-kix_list_39-1>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) ". "
  }

  ol.lst-kix_list_23-7 {
    list-style-type: none
  }

  ol.lst-kix_list_40-7.start {
    counter-reset: lst-ctn-kix_list_40-7 0
  }

  .lst-kix_list_37-0>li {
    counter-increment: lst-ctn-kix_list_37-0
  }

  ol.lst-kix_list_23-2 {
    list-style-type: none
  }

  .lst-kix_list_16-7>li {
    counter-increment: lst-ctn-kix_list_16-7
  }

  ol.lst-kix_list_21-4.start {
    counter-reset: lst-ctn-kix_list_21-4 0
  }

  ol.lst-kix_list_23-1 {
    list-style-type: none
  }

  ol.lst-kix_list_23-4 {
    list-style-type: none
  }

  ol.lst-kix_list_23-3 {
    list-style-type: none
  }

  .lst-kix_list_27-7>li {
    counter-increment: lst-ctn-kix_list_27-7
  }

  ol.lst-kix_list_23-0 {
    list-style-type: none
  }

  ol.lst-kix_list_20-6.start {
    counter-reset: lst-ctn-kix_list_20-6 0
  }

  .lst-kix_list_38-7>li {
    counter-increment: lst-ctn-kix_list_38-7
  }

  .lst-kix_list_13-8>li {
    counter-increment: lst-ctn-kix_list_13-8
  }

  .lst-kix_list_2-2>li {
    counter-increment: lst-ctn-kix_list_2-2
  }

  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0
  }

  .lst-kix_list_16-5>li {
    counter-increment: lst-ctn-kix_list_16-5
  }

  ol.lst-kix_list_5-0 {
    list-style-type: none
  }

  .lst-kix_list_3-7>li {
    counter-increment: lst-ctn-kix_list_3-7
  }

  ol.lst-kix_list_5-1 {
    list-style-type: none
  }

  .lst-kix_list_26-2>li {
    counter-increment: lst-ctn-kix_list_26-2
  }

  ol.lst-kix_list_5-2 {
    list-style-type: none
  }

  .lst-kix_list_40-8>li {
    counter-increment: lst-ctn-kix_list_40-8
  }

  .lst-kix_list_22-5>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) "."counter(lst-ctn-kix_list_22-3, decimal) "."counter(lst-ctn-kix_list_22-4, decimal) "."counter(lst-ctn-kix_list_22-5, decimal) ". "
  }

  .lst-kix_list_21-2>li {
    counter-increment: lst-ctn-kix_list_21-2
  }

  .lst-kix_list_22-1>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) ". "
  }

  ol.lst-kix_list_36-0 {
    list-style-type: none
  }

  .lst-kix_list_27-4>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) "."counter(lst-ctn-kix_list_27-3, decimal) "."counter(lst-ctn-kix_list_27-4, decimal) ". "
  }

  .lst-kix_list_20-2>li {
    counter-increment: lst-ctn-kix_list_20-2
  }

  .lst-kix_list_6-6>li {
    counter-increment: lst-ctn-kix_list_6-6
  }

  ol.lst-kix_list_15-7.start {
    counter-reset: lst-ctn-kix_list_15-7 0
  }

  .lst-kix_list_23-3>li {
    counter-increment: lst-ctn-kix_list_23-3
  }

  .lst-kix_list_13-6>li {
    counter-increment: lst-ctn-kix_list_13-6
  }

  ol.lst-kix_list_14-6.start {
    counter-reset: lst-ctn-kix_list_14-6 0
  }

  .lst-kix_list_39-4>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) "."counter(lst-ctn-kix_list_39-3, decimal) "."counter(lst-ctn-kix_list_39-4, decimal) ". "
  }

  .lst-kix_list_39-8>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) "."counter(lst-ctn-kix_list_39-3, decimal) "."counter(lst-ctn-kix_list_39-4, decimal) "."counter(lst-ctn-kix_list_39-5, decimal) "."counter(lst-ctn-kix_list_39-6, decimal) "."counter(lst-ctn-kix_list_39-7, decimal) "."counter(lst-ctn-kix_list_39-8, decimal) ". "
  }

  ol.lst-kix_list_5-7 {
    list-style-type: none
  }

  ol.lst-kix_list_5-8 {
    list-style-type: none
  }

  ol.lst-kix_list_26-8.start {
    counter-reset: lst-ctn-kix_list_26-8 0
  }

  ol.lst-kix_list_5-3 {
    list-style-type: none
  }

  .lst-kix_list_8-7>li {
    counter-increment: lst-ctn-kix_list_8-7
  }

  .lst-kix_list_19-6>li {
    counter-increment: lst-ctn-kix_list_19-6
  }

  ol.lst-kix_list_5-4 {
    list-style-type: none
  }

  .lst-kix_list_27-0>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) ". "
  }

  ol.lst-kix_list_5-5 {
    list-style-type: none
  }

  ol.lst-kix_list_5-6 {
    list-style-type: none
  }

  .lst-kix_list_20-4>li {
    counter-increment: lst-ctn-kix_list_20-4
  }

  .lst-kix_list_9-5>li {
    counter-increment: lst-ctn-kix_list_9-5
  }

  .lst-kix_list_5-8>li {
    counter-increment: lst-ctn-kix_list_5-8
  }

  .lst-kix_list_27-0>li {
    counter-increment: lst-ctn-kix_list_27-0
  }

  .lst-kix_list_41-6>li {
    counter-increment: lst-ctn-kix_list_41-6
  }

  ol.lst-kix_list_19-4.start {
    counter-reset: lst-ctn-kix_list_19-4 -1
  }

  .lst-kix_list_38-0>li {
    counter-increment: lst-ctn-kix_list_38-0
  }

  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0
  }

  ol.lst-kix_list_20-1.start {
    counter-reset: lst-ctn-kix_list_20-1 0
  }

  .lst-kix_list_25-4>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) "."counter(lst-ctn-kix_list_25-3, decimal) "."counter(lst-ctn-kix_list_25-4, decimal) ". "
  }

  .lst-kix_list_19-4>li {
    counter-increment: lst-ctn-kix_list_19-4
  }

  .lst-kix_list_24-1>li {
    counter-increment: lst-ctn-kix_list_24-1
  }

  .lst-kix_list_25-8>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) "."counter(lst-ctn-kix_list_25-3, decimal) "."counter(lst-ctn-kix_list_25-4, decimal) "."counter(lst-ctn-kix_list_25-5, decimal) "."counter(lst-ctn-kix_list_25-6, decimal) "."counter(lst-ctn-kix_list_25-7, decimal) "."counter(lst-ctn-kix_list_25-8, decimal) ". "
  }

  ol.lst-kix_list_38-4.start {
    counter-reset: lst-ctn-kix_list_38-4 0
  }

  ol.lst-kix_list_25-1.start {
    counter-reset: lst-ctn-kix_list_25-1 0
  }

  ol.lst-kix_list_14-7 {
    list-style-type: none
  }

  ol.lst-kix_list_14-8 {
    list-style-type: none
  }

  ol.lst-kix_list_26-6.start {
    counter-reset: lst-ctn-kix_list_26-6 0
  }

  ol.lst-kix_list_14-3 {
    list-style-type: none
  }

  ol.lst-kix_list_21-8.start {
    counter-reset: lst-ctn-kix_list_21-8 0
  }

  ol.lst-kix_list_14-4 {
    list-style-type: none
  }

  ol.lst-kix_list_14-5 {
    list-style-type: none
  }

  ol.lst-kix_list_14-6 {
    list-style-type: none
  }

  .lst-kix_list_15-2>li {
    counter-increment: lst-ctn-kix_list_15-2
  }

  ol.lst-kix_list_14-0 {
    list-style-type: none
  }

  .lst-kix_list_22-5>li {
    counter-increment: lst-ctn-kix_list_22-5
  }

  .lst-kix_list_27-5>li {
    counter-increment: lst-ctn-kix_list_27-5
  }

  .lst-kix_list_40-6>li:before {
    content: ""counter(lst-ctn-kix_list_40-6, decimal) ". "
  }

  ol.lst-kix_list_14-1 {
    list-style-type: none
  }

  ol.lst-kix_list_14-2 {
    list-style-type: none
  }

  .lst-kix_list_41-6>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) "."counter(lst-ctn-kix_list_41-3, decimal) "."counter(lst-ctn-kix_list_41-4, decimal) "."counter(lst-ctn-kix_list_41-5, decimal) "."counter(lst-ctn-kix_list_41-6, decimal) ". "
  }

  .lst-kix_list_20-5>li:before {
    content: ""counter(lst-ctn-kix_list_20-5, lower-latin) ") "
  }

  .lst-kix_list_28-8>li:before {
    content: " "
  }

  .lst-kix_list_41-2>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) ". "
  }

  .lst-kix_list_20-1>li:before {
    content: "Section "counter(lst-ctn-kix_list_20-0, upper-roman) "."counter(lst-ctn-kix_list_20-1, decimal-leading-zero) " "
  }

  .lst-kix_list_12-3>li {
    counter-increment: lst-ctn-kix_list_12-3
  }

  .lst-kix_list_24-6>li {
    counter-increment: lst-ctn-kix_list_24-6
  }

  .lst-kix_list_17-3>li {
    counter-increment: lst-ctn-kix_list_17-3
  }

  ol.lst-kix_list_8-4.start {
    counter-reset: lst-ctn-kix_list_8-4 0
  }

  .lst-kix_list_28-4>li:before {
    content: " "
  }

  .lst-kix_list_39-3>li {
    counter-increment: lst-ctn-kix_list_39-3
  }

  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0
  }

  .lst-kix_list_21-7>li {
    counter-increment: lst-ctn-kix_list_21-7
  }

  ol.lst-kix_list_25-0.start {
    counter-reset: lst-ctn-kix_list_25-0 0
  }

  ol.lst-kix_list_25-8 {
    list-style-type: none
  }

  ol.lst-kix_list_25-7 {
    list-style-type: none
  }

  ol.lst-kix_list_25-4 {
    list-style-type: none
  }

  ol.lst-kix_list_25-3 {
    list-style-type: none
  }

  ol.lst-kix_list_13-0.start {
    counter-reset: lst-ctn-kix_list_13-0 0
  }

  .lst-kix_list_14-4>li {
    counter-increment: lst-ctn-kix_list_14-4
  }

  ol.lst-kix_list_25-6 {
    list-style-type: none
  }

  ol.lst-kix_list_25-5 {
    list-style-type: none
  }

  ol.lst-kix_list_25-0 {
    list-style-type: none
  }

  ol.lst-kix_list_25-2 {
    list-style-type: none
  }

  .lst-kix_list_10-7>li {
    counter-increment: lst-ctn-kix_list_10-7
  }

  ol.lst-kix_list_25-1 {
    list-style-type: none
  }

  .lst-kix_list_25-4>li {
    counter-increment: lst-ctn-kix_list_25-4
  }

  .lst-kix_list_18-1>li {
    counter-increment: lst-ctn-kix_list_18-1
  }

  ol.lst-kix_list_8-3.start {
    counter-reset: lst-ctn-kix_list_8-3 0
  }

  .lst-kix_list_11-5>li {
    counter-increment: lst-ctn-kix_list_11-5
  }

  .lst-kix_list_28-0>li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_14-5.start {
    counter-reset: lst-ctn-kix_list_14-5 0
  }

  .lst-kix_list_27-8>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) "."counter(lst-ctn-kix_list_27-3, decimal) "."counter(lst-ctn-kix_list_27-4, decimal) "."counter(lst-ctn-kix_list_27-5, decimal) "."counter(lst-ctn-kix_list_27-6, decimal) "."counter(lst-ctn-kix_list_27-7, decimal) "."counter(lst-ctn-kix_list_27-8, decimal) ". "
  }

  .lst-kix_list_40-2>li:before {
    content: ""counter(lst-ctn-kix_list_40-2, lower-roman) ") "
  }

  ol.lst-kix_list_20-3.start {
    counter-reset: lst-ctn-kix_list_20-3 0
  }

  .lst-kix_list_4-1>li {
    counter-increment: lst-ctn-kix_list_4-1
  }

  .lst-kix_list_19-1>li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_41-1.start {
    counter-reset: lst-ctn-kix_list_41-1 0
  }

  .lst-kix_list_27-8>li {
    counter-increment: lst-ctn-kix_list_27-8
  }

  .lst-kix_list_19-4>li:before {
    content: " "
  }

  .lst-kix_list_19-3>li:before {
    content: " "
  }

  ol.lst-kix_list_38-0.start {
    counter-reset: lst-ctn-kix_list_38-0 0
  }

  .lst-kix_list_15-0>li {
    counter-increment: lst-ctn-kix_list_15-0
  }

  ol.lst-kix_list_24-5.start {
    counter-reset: lst-ctn-kix_list_24-5 0
  }

  ol.lst-kix_list_6-6.start {
    counter-reset: lst-ctn-kix_list_6-6 0
  }

  .lst-kix_list_39-5>li {
    counter-increment: lst-ctn-kix_list_39-5
  }

  .lst-kix_list_11-0>li {
    counter-increment: lst-ctn-kix_list_11-0
  }

  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0
  }

  ul.lst-kix_list_32-1 {
    list-style-type: none
  }

  ul.lst-kix_list_32-2 {
    list-style-type: none
  }

  ul.lst-kix_list_32-3 {
    list-style-type: none
  }

  ul.lst-kix_list_32-4 {
    list-style-type: none
  }

  ol.lst-kix_list_9-6.start {
    counter-reset: lst-ctn-kix_list_9-6 0
  }

  ul.lst-kix_list_32-5 {
    list-style-type: none
  }

  ol.lst-kix_list_16-3.start {
    counter-reset: lst-ctn-kix_list_16-3 0
  }

  ul.lst-kix_list_32-6 {
    list-style-type: none
  }

  ol.lst-kix_list_27-5.start {
    counter-reset: lst-ctn-kix_list_27-5 0
  }

  ul.lst-kix_list_32-7 {
    list-style-type: none
  }

  ul.lst-kix_list_32-8 {
    list-style-type: none
  }

  ol.lst-kix_list_22-4.start {
    counter-reset: lst-ctn-kix_list_22-4 0
  }

  .lst-kix_list_22-7>li {
    counter-increment: lst-ctn-kix_list_22-7
  }

  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0
  }

  ul.lst-kix_list_32-0 {
    list-style-type: none
  }

  .lst-kix_list_37-0>li:before {
    content: ""counter(lst-ctn-kix_list_37-0, decimal) ". "
  }

  .lst-kix_list_26-7>li {
    counter-increment: lst-ctn-kix_list_26-7
  }

  .lst-kix_list_41-0>li {
    counter-increment: lst-ctn-kix_list_41-0
  }

  ol.lst-kix_list_11-2.start {
    counter-reset: lst-ctn-kix_list_11-2 0
  }

  .lst-kix_list_5-2>li {
    counter-increment: lst-ctn-kix_list_5-2
  }

  .lst-kix_list_19-6>li:before {
    content: " "
  }

  .lst-kix_list_37-8>li:before {
    content: " "
  }

  ol.lst-kix_list_8-7.start {
    counter-reset: lst-ctn-kix_list_8-7 0
  }

  .lst-kix_list_17-2>li {
    counter-increment: lst-ctn-kix_list_17-2
  }

  ol.lst-kix_list_41-6.start {
    counter-reset: lst-ctn-kix_list_41-6 0
  }

  .lst-kix_list_37-6>li:before {
    content: " "
  }

  .lst-kix_list_20-5>li {
    counter-increment: lst-ctn-kix_list_20-5
  }

  ol.lst-kix_list_35-0.start {
    counter-reset: lst-ctn-kix_list_35-0 0
  }

  .lst-kix_list_37-1>li:before {
    content: " "
  }

  .lst-kix_list_22-0>li {
    counter-increment: lst-ctn-kix_list_22-0
  }

  ol.lst-kix_list_24-0.start {
    counter-reset: lst-ctn-kix_list_24-0 0
  }

  .lst-kix_list_18-3>li {
    counter-increment: lst-ctn-kix_list_18-3
  }

  .lst-kix_list_37-3>li:before {
    content: " "
  }

  .lst-kix_list_21-6>li {
    counter-increment: lst-ctn-kix_list_21-6
  }

  .lst-kix_list_10-3>li {
    counter-increment: lst-ctn-kix_list_10-3
  }

  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0
  }

  .lst-kix_list_18-0>li:before {
    content: "Chapter "counter(lst-ctn-kix_list_18-0, decimal) " "
  }

  ol.lst-kix_list_13-3.start {
    counter-reset: lst-ctn-kix_list_13-3 0
  }

  .lst-kix_list_38-7>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) "."counter(lst-ctn-kix_list_38-3, decimal) "."counter(lst-ctn-kix_list_38-4, decimal) "."counter(lst-ctn-kix_list_38-5, decimal) "."counter(lst-ctn-kix_list_38-6, decimal) "."counter(lst-ctn-kix_list_38-7, decimal) ". "
  }

  .lst-kix_list_3-0>li {
    counter-increment: lst-ctn-kix_list_3-0
  }

  .lst-kix_list_18-2>li:before {
    content: " "
  }

  .lst-kix_list_26-0>li {
    counter-increment: lst-ctn-kix_list_26-0
  }

  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0
  }

  ol.lst-kix_list_27-0.start {
    counter-reset: lst-ctn-kix_list_27-0 0
  }

  .lst-kix_list_27-4>li {
    counter-increment: lst-ctn-kix_list_27-4
  }

  .lst-kix_list_38-2>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) ". "
  }

  .lst-kix_list_38-4>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) "."counter(lst-ctn-kix_list_38-3, decimal) "."counter(lst-ctn-kix_list_38-4, decimal) ". "
  }

  ol.lst-kix_list_11-7.start {
    counter-reset: lst-ctn-kix_list_11-7 0
  }

  ol.lst-kix_list_14-2.start {
    counter-reset: lst-ctn-kix_list_14-2 0
  }

  .lst-kix_list_16-1>li {
    counter-increment: lst-ctn-kix_list_16-1
  }

  .lst-kix_list_41-4>li {
    counter-increment: lst-ctn-kix_list_41-4
  }

  .lst-kix_list_27-1>li {
    counter-increment: lst-ctn-kix_list_27-1
  }

  .lst-kix_list_38-4>li {
    counter-increment: lst-ctn-kix_list_38-4
  }

  .lst-kix_list_38-5>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) "."counter(lst-ctn-kix_list_38-3, decimal) "."counter(lst-ctn-kix_list_38-4, decimal) "."counter(lst-ctn-kix_list_38-5, decimal) ". "
  }

  .lst-kix_list_25-2>li {
    counter-increment: lst-ctn-kix_list_25-2
  }

  .lst-kix_list_27-1>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) ". "
  }

  .lst-kix_list_27-3>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) "."counter(lst-ctn-kix_list_27-3, decimal) ". "
  }

  .lst-kix_list_17-5>li {
    counter-increment: lst-ctn-kix_list_17-5
  }

  ol.lst-kix_list_9-4.start {
    counter-reset: lst-ctn-kix_list_9-4 0
  }

  ol.lst-kix_list_41-8.start {
    counter-reset: lst-ctn-kix_list_41-8 0
  }

  .lst-kix_list_21-3>li {
    counter-increment: lst-ctn-kix_list_21-3
  }

  .lst-kix_list_18-8>li:before {
    content: " "
  }

  ol.lst-kix_list_27-3.start {
    counter-reset: lst-ctn-kix_list_27-3 0
  }

  .lst-kix_list_17-6>li {
    counter-increment: lst-ctn-kix_list_17-6
  }

  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0
  }

  .lst-kix_list_7-8>li {
    counter-increment: lst-ctn-kix_list_7-8
  }

  .lst-kix_list_10-7>li:before {
    content: ""counter(lst-ctn-kix_list_10-7, lower-latin) ". "
  }

  .lst-kix_list_20-1>li {
    counter-increment: lst-ctn-kix_list_20-1
  }

  ul.lst-kix_list_34-1 {
    list-style-type: none
  }

  .lst-kix_list_36-4>li:before {
    content: " "
  }

  ul.lst-kix_list_34-2 {
    list-style-type: none
  }

  ul.lst-kix_list_34-3 {
    list-style-type: none
  }

  .lst-kix_list_10-5>li:before {
    content: ""counter(lst-ctn-kix_list_10-5, lower-roman) ". "
  }

  ol.lst-kix_list_13-5.start {
    counter-reset: lst-ctn-kix_list_13-5 0
  }

  ul.lst-kix_list_34-4 {
    list-style-type: none
  }

  ol.lst-kix_list_18-6.start {
    counter-reset: lst-ctn-kix_list_18-6 0
  }

  ul.lst-kix_list_34-5 {
    list-style-type: none
  }

  ul.lst-kix_list_34-6 {
    list-style-type: none
  }

  ol.lst-kix_list_20-8 {
    list-style-type: none
  }

  ol.lst-kix_list_13-8.start {
    counter-reset: lst-ctn-kix_list_13-8 0
  }

  ol.lst-kix_list_20-5 {
    list-style-type: none
  }

  ol.lst-kix_list_20-4 {
    list-style-type: none
  }

  ol.lst-kix_list_20-7 {
    list-style-type: none
  }

  .lst-kix_list_11-7>li {
    counter-increment: lst-ctn-kix_list_11-7
  }

  ol.lst-kix_list_20-6 {
    list-style-type: none
  }

  .lst-kix_list_9-2>li:before {
    content: ""counter(lst-ctn-kix_list_9-2, lower-roman) ". "
  }

  ol.lst-kix_list_20-1 {
    list-style-type: none
  }

  ol.lst-kix_list_20-0 {
    list-style-type: none
  }

  ol.lst-kix_list_20-3 {
    list-style-type: none
  }

  ol.lst-kix_list_20-2 {
    list-style-type: none
  }

  ol.lst-kix_list_14-0.start {
    counter-reset: lst-ctn-kix_list_14-0 0
  }

  .lst-kix_list_12-5>li {
    counter-increment: lst-ctn-kix_list_12-5
  }

  ul.lst-kix_list_34-7 {
    list-style-type: none
  }

  ul.lst-kix_list_34-8 {
    list-style-type: none
  }

  .lst-kix_list_5-5>li {
    counter-increment: lst-ctn-kix_list_5-5
  }

  .lst-kix_list_36-2>li:before {
    content: " "
  }

  .lst-kix_list_9-0>li:before {
    content: ""counter(lst-ctn-kix_list_9-0, lower-latin) ". "
  }

  .lst-kix_list_16-8>li {
    counter-increment: lst-ctn-kix_list_16-8
  }

  ol.lst-kix_list_24-3.start {
    counter-reset: lst-ctn-kix_list_24-3 0
  }

  .lst-kix_list_23-5>li {
    counter-increment: lst-ctn-kix_list_23-5
  }

  .lst-kix_list_11-3>li:before {
    content: ""counter(lst-ctn-kix_list_11-3, decimal) ". "
  }

  .lst-kix_list_38-8>li {
    counter-increment: lst-ctn-kix_list_38-8
  }

  .lst-kix_list_6-3>li {
    counter-increment: lst-ctn-kix_list_6-3
  }

  ol.lst-kix_list_18-4.start {
    counter-reset: lst-ctn-kix_list_18-4 0
  }

  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0
  }

  ol.lst-kix_list_33-0 {
    list-style-type: none
  }

  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0
  }

  .lst-kix_list_29-1>li:before {
    content: " "
  }

  .lst-kix_list_20-4>li:before {
    content: ""counter(lst-ctn-kix_list_20-4, decimal) ") "
  }

  ol.lst-kix_list_22-7.start {
    counter-reset: lst-ctn-kix_list_22-7 0
  }

  ol.lst-kix_list_6-1.start {
    counter-reset: lst-ctn-kix_list_6-1 0
  }

  .lst-kix_list_29-3>li:before {
    content: " "
  }

  .lst-kix_list_20-2>li:before {
    content: "("counter(lst-ctn-kix_list_20-2, lower-latin) ") "
  }

  .lst-kix_list_9-8>li:before {
    content: ""counter(lst-ctn-kix_list_9-8, lower-roman) ". "
  }

  ol.lst-kix_list_16-8.start {
    counter-reset: lst-ctn-kix_list_16-8 0
  }

  .lst-kix_list_28-7>li:before {
    content: " "
  }

  .lst-kix_list_4-8>li {
    counter-increment: lst-ctn-kix_list_4-8
  }

  .lst-kix_list_1-7>li:before {
    content: " "
  }

  .lst-kix_list_1-5>li:before {
    content: " "
  }

  .lst-kix_list_28-5>li:before {
    content: " "
  }

  ol.lst-kix_list_9-1.start {
    counter-reset: lst-ctn-kix_list_9-1 0
  }

  ol.lst-kix_list_24-2.start {
    counter-reset: lst-ctn-kix_list_24-2 0
  }

  .lst-kix_list_5-6>li {
    counter-increment: lst-ctn-kix_list_5-6
  }

  ol.lst-kix_list_22-6.start {
    counter-reset: lst-ctn-kix_list_22-6 0
  }

  .lst-kix_list_2-1>li:before {
    content: ""counter(lst-ctn-kix_list_2-1, lower-latin) ". "
  }

  .lst-kix_list_19-8>li {
    counter-increment: lst-ctn-kix_list_19-8
  }

  .lst-kix_list_2-3>li:before {
    content: ""counter(lst-ctn-kix_list_2-3, decimal) ". "
  }

  .lst-kix_list_11-8>li {
    counter-increment: lst-ctn-kix_list_11-8
  }

  .lst-kix_list_35-3>li:before {
    content: " "
  }

  ol.lst-kix_list_24-8.start {
    counter-reset: lst-ctn-kix_list_24-8 0
  }

  .lst-kix_list_30-4>li:before {
    content: " "
  }

  .lst-kix_list_20-8>li {
    counter-increment: lst-ctn-kix_list_20-8
  }

  .lst-kix_list_35-6>li:before {
    content: " "
  }

  .lst-kix_list_9-1>li {
    counter-increment: lst-ctn-kix_list_9-1
  }

  .lst-kix_list_3-2>li:before {
    content: "("counter(lst-ctn-kix_list_3-2, lower-latin) ") "
  }

  .lst-kix_list_26-7>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) "."counter(lst-ctn-kix_list_26-3, decimal) "."counter(lst-ctn-kix_list_26-4, decimal) "."counter(lst-ctn-kix_list_26-5, decimal) "."counter(lst-ctn-kix_list_26-6, decimal) "."counter(lst-ctn-kix_list_26-7, decimal) ". "
  }

  .lst-kix_list_8-1>li:before {
    content: "["counter(lst-ctn-kix_list_8-0, decimal) "."counter(lst-ctn-kix_list_8-1, decimal) " "
  }

  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0
  }

  .lst-kix_list_6-0>li {
    counter-increment: lst-ctn-kix_list_6-0
  }

  .lst-kix_list_3-5>li:before {
    content: " "
  }

  .lst-kix_list_18-0>li {
    counter-increment: lst-ctn-kix_list_18-0
  }

  .lst-kix_list_30-7>li:before {
    content: " "
  }

  .lst-kix_list_40-2>li {
    counter-increment: lst-ctn-kix_list_40-2
  }

  ol.lst-kix_list_11-5.start {
    counter-reset: lst-ctn-kix_list_11-5 0
  }

  .lst-kix_list_8-6>li:before {
    content: " "
  }

  .lst-kix_list_11-1>li {
    counter-increment: lst-ctn-kix_list_11-1
  }

  .lst-kix_list_26-2>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) ". "
  }

  .lst-kix_list_21-6>li:before {
    content: ""counter(lst-ctn-kix_list_21-6, lower-roman) ") "
  }

  .lst-kix_list_41-3>li {
    counter-increment: lst-ctn-kix_list_41-3
  }

  ol.lst-kix_list_16-6.start {
    counter-reset: lst-ctn-kix_list_16-6 0
  }

  ol.lst-kix_list_41-4.start {
    counter-reset: lst-ctn-kix_list_41-4 0
  }

  ol.lst-kix_list_22-1.start {
    counter-reset: lst-ctn-kix_list_22-1 0
  }

  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0
  }

  ol.lst-kix_list_16-0.start {
    counter-reset: lst-ctn-kix_list_16-0 0
  }

  ol.lst-kix_list_27-2.start {
    counter-reset: lst-ctn-kix_list_27-2 0
  }

  .lst-kix_list_21-3>li:before {
    content: "("counter(lst-ctn-kix_list_21-3, lower-roman) ") "
  }

  ol.lst-kix_list_18-7.start {
    counter-reset: lst-ctn-kix_list_18-7 0
  }

  .lst-kix_list_25-5>li {
    counter-increment: lst-ctn-kix_list_25-5
  }

  ol.lst-kix_list_11-6.start {
    counter-reset: lst-ctn-kix_list_11-6 0
  }

  .lst-kix_list_4-4>li {
    counter-increment: lst-ctn-kix_list_4-4
  }

  ol.lst-kix_list_6-4.start {
    counter-reset: lst-ctn-kix_list_6-4 0
  }

  .lst-kix_list_17-1>li:before {
    content: ""counter(lst-ctn-kix_list_17-1, lower-latin) ". "
  }

  .lst-kix_list_25-3>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) "."counter(lst-ctn-kix_list_25-3, decimal) ". "
  }

  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0
  }

  .lst-kix_list_39-2>li {
    counter-increment: lst-ctn-kix_list_39-2
  }

  .lst-kix_list_16-2>li:before {
    content: ""counter(lst-ctn-kix_list_16-2, lower-roman) ". "
  }

  ol.lst-kix_list_27-1.start {
    counter-reset: lst-ctn-kix_list_27-1 0
  }

  .lst-kix_list_26-6>li {
    counter-increment: lst-ctn-kix_list_26-6
  }

  .lst-kix_list_16-5>li:before {
    content: ""counter(lst-ctn-kix_list_16-5, lower-roman) ". "
  }

  .lst-kix_list_15-3>li {
    counter-increment: lst-ctn-kix_list_15-3
  }

  ol.lst-kix_list_22-2.start {
    counter-reset: lst-ctn-kix_list_22-2 0
  }

  ol.lst-kix_list_41-5.start {
    counter-reset: lst-ctn-kix_list_41-5 0
  }

  ol.lst-kix_list_11-0.start {
    counter-reset: lst-ctn-kix_list_11-0 0
  }

  ol.lst-kix_list_18-8.start {
    counter-reset: lst-ctn-kix_list_18-8 0
  }

  .lst-kix_list_3-3>li {
    counter-increment: lst-ctn-kix_list_3-3
  }

  .lst-kix_list_16-4>li {
    counter-increment: lst-ctn-kix_list_16-4
  }

  .lst-kix_list_39-3>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) "."counter(lst-ctn-kix_list_39-3, decimal) ". "
  }

  ol.lst-kix_list_6-3.start {
    counter-reset: lst-ctn-kix_list_6-3 0
  }

  .lst-kix_list_38-1>li {
    counter-increment: lst-ctn-kix_list_38-1
  }

  ol.lst-kix_list_16-5.start {
    counter-reset: lst-ctn-kix_list_16-5 0
  }

  .lst-kix_list_17-6>li:before {
    content: ""counter(lst-ctn-kix_list_17-6, decimal) ". "
  }

  .lst-kix_list_41-7>li {
    counter-increment: lst-ctn-kix_list_41-7
  }

  .lst-kix_list_2-6>li:before {
    content: ""counter(lst-ctn-kix_list_2-6, decimal) ". "
  }

  ol.lst-kix_list_16-2.start {
    counter-reset: lst-ctn-kix_list_16-2 0
  }

  .lst-kix_list_14-5>li {
    counter-increment: lst-ctn-kix_list_14-5
  }

  .lst-kix_list_7-5>li:before {
    content: " "
  }

  .lst-kix_list_27-6>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) "."counter(lst-ctn-kix_list_27-3, decimal) "."counter(lst-ctn-kix_list_27-4, decimal) "."counter(lst-ctn-kix_list_27-5, decimal) "."counter(lst-ctn-kix_list_27-6, decimal) ". "
  }

  .lst-kix_list_19-5>li {
    counter-increment: lst-ctn-kix_list_19-5
  }

  .lst-kix_list_22-7>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) "."counter(lst-ctn-kix_list_22-3, decimal) "."counter(lst-ctn-kix_list_22-4, decimal) "."counter(lst-ctn-kix_list_22-5, decimal) "."counter(lst-ctn-kix_list_22-6, decimal) "."counter(lst-ctn-kix_list_22-7, decimal) ". "
  }

  .lst-kix_list_23-2>li {
    counter-increment: lst-ctn-kix_list_23-2
  }

  ol.lst-kix_list_11-1.start {
    counter-reset: lst-ctn-kix_list_11-1 0
  }

  .lst-kix_list_34-7>li:before {
    content: " "
  }

  .lst-kix_list_18-5>li:before {
    content: " "
  }

  .lst-kix_list_13-6>li:before {
    content: ""counter(lst-ctn-kix_list_13-6, decimal) ". "
  }

  .lst-kix_list_6-7>li {
    counter-increment: lst-ctn-kix_list_6-7
  }

  .lst-kix_list_26-3>li {
    counter-increment: lst-ctn-kix_list_26-3
  }

  .lst-kix_list_10-6>li {
    counter-increment: lst-ctn-kix_list_10-6
  }

  .lst-kix_list_1-7>li {
    counter-increment: lst-ctn-kix_list_1-7
  }

  ol.lst-kix_list_41-0.start {
    counter-reset: lst-ctn-kix_list_41-0 0
  }

  .lst-kix_list_39-6>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) "."counter(lst-ctn-kix_list_39-3, decimal) "."counter(lst-ctn-kix_list_39-4, decimal) "."counter(lst-ctn-kix_list_39-5, decimal) "."counter(lst-ctn-kix_list_39-6, decimal) ". "
  }

  ul.lst-kix_list_30-3 {
    list-style-type: none
  }

  ul.lst-kix_list_30-4 {
    list-style-type: none
  }

  ul.lst-kix_list_30-5 {
    list-style-type: none
  }

  .lst-kix_list_7-5>li {
    counter-increment: lst-ctn-kix_list_7-5
  }

  .lst-kix_list_15-6>li:before {
    content: " "
  }

  ul.lst-kix_list_30-6 {
    list-style-type: none
  }

  .lst-kix_list_11-4>li {
    counter-increment: lst-ctn-kix_list_11-4
  }

  ol.lst-kix_list_24-7.start {
    counter-reset: lst-ctn-kix_list_24-7 0
  }

  ul.lst-kix_list_30-7 {
    list-style-type: none
  }

  ul.lst-kix_list_30-8 {
    list-style-type: none
  }

  .lst-kix_list_22-4>li {
    counter-increment: lst-ctn-kix_list_22-4
  }

  .lst-kix_list_31-3>li:before {
    content: " "
  }

  .lst-kix_list_36-7>li:before {
    content: " "
  }

  ul.lst-kix_list_30-0 {
    list-style-type: none
  }

  ol.lst-kix_list_6-8.start {
    counter-reset: lst-ctn-kix_list_6-8 0
  }

  .lst-kix_list_10-2>li:before {
    content: ""counter(lst-ctn-kix_list_10-2, lower-roman) ". "
  }

  ul.lst-kix_list_30-1 {
    list-style-type: none
  }

  ul.lst-kix_list_30-2 {
    list-style-type: none
  }

  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0
  }

  .lst-kix_list_13-7>li {
    counter-increment: lst-ctn-kix_list_13-7
  }

  .lst-kix_list_20-7>li:before {
    content: ""counter(lst-ctn-kix_list_20-7, lower-latin) ". "
  }

  ol.lst-kix_list_6-5.start {
    counter-reset: lst-ctn-kix_list_6-5 0
  }

  .lst-kix_list_4-6>li:before {
    content: ""counter(lst-ctn-kix_list_4-6, decimal) " "
  }

  .lst-kix_list_41-8>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) "."counter(lst-ctn-kix_list_41-3, decimal) "."counter(lst-ctn-kix_list_41-4, decimal) "."counter(lst-ctn-kix_list_41-5, decimal) "."counter(lst-ctn-kix_list_41-6, decimal) "."counter(lst-ctn-kix_list_41-7, decimal) "."counter(lst-ctn-kix_list_41-8, decimal) ". "
  }

  .lst-kix_list_25-6>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) "."counter(lst-ctn-kix_list_25-3, decimal) "."counter(lst-ctn-kix_list_25-4, decimal) "."counter(lst-ctn-kix_list_25-5, decimal) "."counter(lst-ctn-kix_list_25-6, decimal) ". "
  }

  .lst-kix_list_41-0>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) ". "
  }

  ol.lst-kix_list_6-7.start {
    counter-reset: lst-ctn-kix_list_6-7 0
  }

  .lst-kix_list_12-2>li {
    counter-increment: lst-ctn-kix_list_12-2
  }

  .lst-kix_list_9-5>li:before {
    content: ""counter(lst-ctn-kix_list_9-5, lower-roman) ". "
  }

  .lst-kix_list_29-6>li:before {
    content: " "
  }

  ol.lst-kix_list_22-0.start {
    counter-reset: lst-ctn-kix_list_22-0 0
  }

  .lst-kix_list_24-0>li {
    counter-increment: lst-ctn-kix_list_24-0
  }

  .lst-kix_list_33-3>li:before {
    content: " "
  }

  ol.lst-kix_list_41-3.start {
    counter-reset: lst-ctn-kix_list_41-3 0
  }

  .lst-kix_list_12-2>li:before {
    content: ""counter(lst-ctn-kix_list_12-2, lower-roman) ". "
  }

  .lst-kix_list_11-6>li:before {
    content: ""counter(lst-ctn-kix_list_11-6, decimal) ". "
  }

  .lst-kix_list_32-7>li:before {
    content: " "
  }

  .lst-kix_list_1-2>li:before {
    content: "("counter(lst-ctn-kix_list_1-2, lower-roman) ") "
  }

  ol.lst-kix_list_11-3.start {
    counter-reset: lst-ctn-kix_list_11-3 0
  }

  .lst-kix_list_1-0>li {
    counter-increment: lst-ctn-kix_list_1-0
  }

  ol.lst-kix_list_16-1.start {
    counter-reset: lst-ctn-kix_list_16-1 0
  }

  ol.lst-kix_list_40-8 {
    list-style-type: none
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
  }

  ol.lst-kix_list_40-6 {
    list-style-type: none
  }

  .lst-kix_list_35-0>li {
    counter-increment: lst-ctn-kix_list_35-0
  }

  ol.lst-kix_list_40-7 {
    list-style-type: none
  }

  ol.lst-kix_list_41-2.start {
    counter-reset: lst-ctn-kix_list_41-2 0
  }

  ol.lst-kix_list_40-4 {
    list-style-type: none
  }

  .lst-kix_list_18-7>li {
    counter-increment: lst-ctn-kix_list_18-7
  }

  ol.lst-kix_list_40-5 {
    list-style-type: none
  }

  ol.lst-kix_list_40-2 {
    list-style-type: none
  }

  ol.lst-kix_list_40-3 {
    list-style-type: none
  }

  ol.lst-kix_list_40-0 {
    list-style-type: none
  }

  ol.lst-kix_list_40-1 {
    list-style-type: none
  }

  .lst-kix_list_40-4>li:before {
    content: "("counter(lst-ctn-kix_list_40-4, lower-latin) ") "
  }

  ol.lst-kix_list_11-4.start {
    counter-reset: lst-ctn-kix_list_11-4 0
  }

  .lst-kix_list_28-2>li:before {
    content: " "
  }

  .lst-kix_list_41-2>li {
    counter-increment: lst-ctn-kix_list_41-2
  }

  .lst-kix_list_14-1>li:before {
    content: ""counter(lst-ctn-kix_list_14-1, lower-latin) ". "
  }

  .lst-kix_list_14-3>li:before {
    content: ""counter(lst-ctn-kix_list_14-3, decimal) ". "
  }

  ol.lst-kix_list_15-6 {
    list-style-type: none
  }

  .lst-kix_list_25-8>li {
    counter-increment: lst-ctn-kix_list_25-8
  }

  ol.lst-kix_list_15-7 {
    list-style-type: none
  }

  ul.lst-kix_list_28-0 {
    list-style-type: none
  }

  ol.lst-kix_list_15-8 {
    list-style-type: none
  }

  ul.lst-kix_list_28-1 {
    list-style-type: none
  }

  .lst-kix_list_14-0>li:before {
    content: ""counter(lst-ctn-kix_list_14-0, upper-latin) ". "
  }

  .lst-kix_list_14-4>li:before {
    content: ""counter(lst-ctn-kix_list_14-4, lower-latin) ". "
  }

  ol.lst-kix_list_15-2 {
    list-style-type: none
  }

  ol.lst-kix_list_15-3 {
    list-style-type: none
  }

  ol.lst-kix_list_15-4 {
    list-style-type: none
  }

  ol.lst-kix_list_18-5.start {
    counter-reset: lst-ctn-kix_list_18-5 0
  }

  .lst-kix_list_6-1>li {
    counter-increment: lst-ctn-kix_list_6-1
  }

  .lst-kix_list_14-5>li:before {
    content: ""counter(lst-ctn-kix_list_14-5, lower-roman) ". "
  }

  .lst-kix_list_14-7>li:before {
    content: ""counter(lst-ctn-kix_list_14-7, lower-latin) ". "
  }

  ol.lst-kix_list_15-5 {
    list-style-type: none
  }

  ol.lst-kix_list_15-0 {
    list-style-type: none
  }

  .lst-kix_list_14-6>li:before {
    content: ""counter(lst-ctn-kix_list_14-6, decimal) ". "
  }

  ol.lst-kix_list_15-1 {
    list-style-type: none
  }

  ol.lst-kix_list_7-4.start {
    counter-reset: lst-ctn-kix_list_7-4 0
  }

  .lst-kix_list_17-0>li {
    counter-increment: lst-ctn-kix_list_17-0
  }

  .lst-kix_list_9-0>li {
    counter-increment: lst-ctn-kix_list_9-0
  }

  ul.lst-kix_list_28-2 {
    list-style-type: none
  }

  ul.lst-kix_list_28-3 {
    list-style-type: none
  }

  ul.lst-kix_list_28-4 {
    list-style-type: none
  }

  ul.lst-kix_list_28-5 {
    list-style-type: none
  }

  ol.lst-kix_list_25-3.start {
    counter-reset: lst-ctn-kix_list_25-3 0
  }

  ul.lst-kix_list_28-6 {
    list-style-type: none
  }

  ul.lst-kix_list_28-7 {
    list-style-type: none
  }

  ol.lst-kix_list_11-8.start {
    counter-reset: lst-ctn-kix_list_11-8 0
  }

  ul.lst-kix_list_28-8 {
    list-style-type: none
  }

  .lst-kix_list_14-2>li:before {
    content: ""counter(lst-ctn-kix_list_14-2, lower-roman) ". "
  }

  .lst-kix_list_20-7>li {
    counter-increment: lst-ctn-kix_list_20-7
  }

  ol.lst-kix_list_26-7 {
    list-style-type: none
  }

  ol.lst-kix_list_26-6 {
    list-style-type: none
  }

  ol.lst-kix_list_26-8 {
    list-style-type: none
  }

  ol.lst-kix_list_26-3 {
    list-style-type: none
  }

  ol.lst-kix_list_26-2 {
    list-style-type: none
  }

  ol.lst-kix_list_12-0.start {
    counter-reset: lst-ctn-kix_list_12-0 0
  }

  ol.lst-kix_list_26-5 {
    list-style-type: none
  }

  ol.lst-kix_list_26-4 {
    list-style-type: none
  }

  ol.lst-kix_list_41-7.start {
    counter-reset: lst-ctn-kix_list_41-7 0
  }

  ol.lst-kix_list_26-1 {
    list-style-type: none
  }

  ol.lst-kix_list_26-0 {
    list-style-type: none
  }

  .lst-kix_list_32-2>li:before {
    content: " "
  }

  .lst-kix_list_32-1>li:before {
    content: " "
  }

  .lst-kix_list_32-3>li:before {
    content: " "
  }

  ol.lst-kix_list_21-6.start {
    counter-reset: lst-ctn-kix_list_21-6 0
  }

  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0
  }

  .lst-kix_list_14-8>li:before {
    content: ""counter(lst-ctn-kix_list_14-8, lower-roman) ". "
  }

  .lst-kix_list_39-7>li {
    counter-increment: lst-ctn-kix_list_39-7
  }

  .lst-kix_list_32-0>li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_3-2>li {
    counter-increment: lst-ctn-kix_list_3-2
  }

  ol.lst-kix_list_15-5.start {
    counter-reset: lst-ctn-kix_list_15-5 0
  }

  ol.lst-kix_list_39-8 {
    list-style-type: none
  }

  .lst-kix_list_5-0>li:before {
    content: "[ARTICLE "counter(lst-ctn-kix_list_5-0, upper-roman) " "
  }

  ol.lst-kix_list_6-0 {
    list-style-type: none
  }

  ol.lst-kix_list_6-1 {
    list-style-type: none
  }

  .lst-kix_list_14-8>li {
    counter-increment: lst-ctn-kix_list_14-8
  }

  ol.lst-kix_list_22-3.start {
    counter-reset: lst-ctn-kix_list_22-3 0
  }

  .lst-kix_list_5-4>li {
    counter-increment: lst-ctn-kix_list_5-4
  }

  ol.lst-kix_list_39-5 {
    list-style-type: none
  }

  ol.lst-kix_list_39-4 {
    list-style-type: none
  }

  ol.lst-kix_list_39-7 {
    list-style-type: none
  }

  ol.lst-kix_list_39-6 {
    list-style-type: none
  }

  .lst-kix_list_5-3>li:before {
    content: "[("counter(lst-ctn-kix_list_5-3, lower-roman) ") "
  }

  ol.lst-kix_list_39-1 {
    list-style-type: none
  }

  ol.lst-kix_list_24-6.start {
    counter-reset: lst-ctn-kix_list_24-6 0
  }

  ol.lst-kix_list_39-0 {
    list-style-type: none
  }

  .lst-kix_list_5-2>li:before {
    content: "[("counter(lst-ctn-kix_list_5-2, lower-latin) ") "
  }

  ol.lst-kix_list_39-3 {
    list-style-type: none
  }

  .lst-kix_list_8-3>li {
    counter-increment: lst-ctn-kix_list_8-3
  }

  ol.lst-kix_list_39-2 {
    list-style-type: none
  }

  .lst-kix_list_5-1>li:before {
    content: "[Section "counter(lst-ctn-kix_list_5-0, upper-roman) "."counter(lst-ctn-kix_list_5-1, decimal-leading-zero) " "
  }

  ol.lst-kix_list_18-0.start {
    counter-reset: lst-ctn-kix_list_18-0 0
  }

  .lst-kix_list_5-7>li:before {
    content: " "
  }

  .lst-kix_list_5-6>li:before {
    content: " "
  }

  .lst-kix_list_5-8>li:before {
    content: " "
  }

  ol.lst-kix_list_6-6 {
    list-style-type: none
  }

  ol.lst-kix_list_6-7 {
    list-style-type: none
  }

  .lst-kix_list_5-4>li:before {
    content: "[("counter(lst-ctn-kix_list_5-4, upper-latin) ") "
  }

  ol.lst-kix_list_6-8 {
    list-style-type: none
  }

  .lst-kix_list_5-5>li:before {
    content: " "
  }

  ol.lst-kix_list_6-2 {
    list-style-type: none
  }

  ol.lst-kix_list_6-3 {
    list-style-type: none
  }

  ol.lst-kix_list_6-4 {
    list-style-type: none
  }

  ol.lst-kix_list_6-5 {
    list-style-type: none
  }

  ol.lst-kix_list_12-5.start {
    counter-reset: lst-ctn-kix_list_12-5 0
  }

  .lst-kix_list_6-1>li:before {
    content: ""counter(lst-ctn-kix_list_6-1, lower-latin) ". "
  }

  .lst-kix_list_6-3>li:before {
    content: ""counter(lst-ctn-kix_list_6-3, decimal) ". "
  }

  .lst-kix_list_6-8>li {
    counter-increment: lst-ctn-kix_list_6-8
  }

  .lst-kix_list_6-0>li:before {
    content: "["counter(lst-ctn-kix_list_6-0, decimal) ".   "
  }

  .lst-kix_list_6-4>li:before {
    content: ""counter(lst-ctn-kix_list_6-4, lower-latin) ". "
  }

  ol.lst-kix_list_14-8.start {
    counter-reset: lst-ctn-kix_list_14-8 0
  }

  .lst-kix_list_6-2>li:before {
    content: ""counter(lst-ctn-kix_list_6-2, lower-roman) ". "
  }

  ol.lst-kix_list_15-0.start {
    counter-reset: lst-ctn-kix_list_15-0 0
  }

  .lst-kix_list_2-5>li {
    counter-increment: lst-ctn-kix_list_2-5
  }

  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0
  }

  .lst-kix_list_6-8>li:before {
    content: ""counter(lst-ctn-kix_list_6-8, lower-roman) ". "
  }

  .lst-kix_list_6-5>li:before {
    content: ""counter(lst-ctn-kix_list_6-5, lower-roman) ". "
  }

  .lst-kix_list_6-7>li:before {
    content: ""counter(lst-ctn-kix_list_6-7, lower-latin) ". "
  }

  .lst-kix_list_6-6>li:before {
    content: ""counter(lst-ctn-kix_list_6-6, decimal) ". "
  }

  ol.lst-kix_list_17-8 {
    list-style-type: none
  }

  ol.lst-kix_list_10-6.start {
    counter-reset: lst-ctn-kix_list_10-6 0
  }

  ol.lst-kix_list_27-6.start {
    counter-reset: lst-ctn-kix_list_27-6 0
  }

  .lst-kix_list_7-4>li:before {
    content: " "
  }

  .lst-kix_list_7-6>li:before {
    content: ""counter(lst-ctn-kix_list_7-6, decimal) " "
  }

  ol.lst-kix_list_17-4 {
    list-style-type: none
  }

  .lst-kix_list_18-5>li {
    counter-increment: lst-ctn-kix_list_18-5
  }

  ol.lst-kix_list_19-7.start {
    counter-reset: lst-ctn-kix_list_19-7 -1
  }

  ol.lst-kix_list_17-5 {
    list-style-type: none
  }

  ol.lst-kix_list_6-2.start {
    counter-reset: lst-ctn-kix_list_6-2 0
  }

  .lst-kix_list_15-5>li {
    counter-increment: lst-ctn-kix_list_15-5
  }

  ol.lst-kix_list_17-6 {
    list-style-type: none
  }

  ol.lst-kix_list_24-1.start {
    counter-reset: lst-ctn-kix_list_24-1 0
  }

  ol.lst-kix_list_17-7 {
    list-style-type: none
  }

  ol.lst-kix_list_17-0 {
    list-style-type: none
  }

  .lst-kix_list_22-2>li {
    counter-increment: lst-ctn-kix_list_22-2
  }

  ol.lst-kix_list_17-1 {
    list-style-type: none
  }

  ol.lst-kix_list_17-2 {
    list-style-type: none
  }

  .lst-kix_list_7-2>li:before {
    content: "[("counter(lst-ctn-kix_list_7-2, lower-roman) ") "
  }

  ol.lst-kix_list_17-3 {
    list-style-type: none
  }

  .lst-kix_list_7-6>li {
    counter-increment: lst-ctn-kix_list_7-6
  }

  .lst-kix_list_27-2>li {
    counter-increment: lst-ctn-kix_list_27-2
  }

  ol.lst-kix_list_22-8.start {
    counter-reset: lst-ctn-kix_list_22-8 0
  }

  .lst-kix_list_34-8>li:before {
    content: " "
  }

  .lst-kix_list_31-0>li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_12-6>li {
    counter-increment: lst-ctn-kix_list_12-6
  }

  .lst-kix_list_24-3>li {
    counter-increment: lst-ctn-kix_list_24-3
  }

  .lst-kix_list_13-7>li:before {
    content: ""counter(lst-ctn-kix_list_13-7, lower-latin) ". "
  }

  .lst-kix_list_7-8>li:before {
    content: " "
  }

  ol.lst-kix_list_23-0.start {
    counter-reset: lst-ctn-kix_list_23-0 0
  }

  .lst-kix_list_15-6>li {
    counter-increment: lst-ctn-kix_list_15-6
  }

  .lst-kix_list_4-7>li {
    counter-increment: lst-ctn-kix_list_4-7
  }

  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0
  }

  .lst-kix_list_15-5>li:before {
    content: " "
  }

  .lst-kix_list_31-6>li:before {
    content: " "
  }

  .lst-kix_list_31-8>li:before {
    content: " "
  }

  ol.lst-kix_list_26-0.start {
    counter-reset: lst-ctn-kix_list_26-0 0
  }

  .lst-kix_list_9-8>li {
    counter-increment: lst-ctn-kix_list_9-8
  }

  .lst-kix_list_13-4>li {
    counter-increment: lst-ctn-kix_list_13-4
  }

  .lst-kix_list_27-3>li {
    counter-increment: lst-ctn-kix_list_27-3
  }

  .lst-kix_list_4-1>li:before {
    content: ""counter(lst-ctn-kix_list_4-0, decimal) "."counter(lst-ctn-kix_list_4-1, decimal) " "
  }

  .lst-kix_list_31-2>li:before {
    content: " "
  }

  .lst-kix_list_31-4>li:before {
    content: " "
  }

  .lst-kix_list_15-7>li:before {
    content: " "
  }

  .lst-kix_list_36-0>li {
    counter-increment: lst-ctn-kix_list_36-0
  }

  .lst-kix_list_17-7>li {
    counter-increment: lst-ctn-kix_list_17-7
  }

  .lst-kix_list_4-3>li:before {
    content: "("counter(lst-ctn-kix_list_4-3, lower-roman) ") "
  }

  .lst-kix_list_4-5>li:before {
    content: " "
  }

  .lst-kix_list_1-8>li {
    counter-increment: lst-ctn-kix_list_1-8
  }

  ol.lst-kix_list_38-1.start {
    counter-reset: lst-ctn-kix_list_38-1 0
  }

  ul.lst-kix_list_19-1 {
    list-style-type: none
  }

  .lst-kix_list_10-5>li {
    counter-increment: lst-ctn-kix_list_10-5
  }

  ul.lst-kix_list_19-0 {
    list-style-type: none
  }

  .lst-kix_list_15-1>li:before {
    content: ""counter(lst-ctn-kix_list_15-0, decimal) "("counter(lst-ctn-kix_list_15-1, lower-latin) ") "
  }

  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0
  }

  .lst-kix_list_24-4>li {
    counter-increment: lst-ctn-kix_list_24-4
  }

  .lst-kix_list_15-3>li:before {
    content: "("counter(lst-ctn-kix_list_15-3, upper-latin) ") "
  }

  .lst-kix_list_22-1>li {
    counter-increment: lst-ctn-kix_list_22-1
  }

  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0
  }

  .lst-kix_list_16-2>li {
    counter-increment: lst-ctn-kix_list_16-2
  }

  ol.lst-kix_list_39-3.start {
    counter-reset: lst-ctn-kix_list_39-3 0
  }

  .lst-kix_list_40-5>li {
    counter-increment: lst-ctn-kix_list_40-5
  }

  ol.lst-kix_list_9-2.start {
    counter-reset: lst-ctn-kix_list_9-2 0
  }

  .lst-kix_list_20-0>li {
    counter-increment: lst-ctn-kix_list_20-0
  }

  ol.lst-kix_list_16-7.start {
    counter-reset: lst-ctn-kix_list_16-7 0
  }

  .lst-kix_list_32-4>li:before {
    content: " "
  }

  .lst-kix_list_11-2>li {
    counter-increment: lst-ctn-kix_list_11-2
  }

  .lst-kix_list_26-5>li {
    counter-increment: lst-ctn-kix_list_26-5
  }

  .lst-kix_list_19-2>li {
    counter-increment: lst-ctn-kix_list_19-2
  }

  .lst-kix_list_33-4>li:before {
    content: " "
  }

  ol.lst-kix_list_8-8 {
    list-style-type: none
  }

  .lst-kix_list_12-3>li:before {
    content: ""counter(lst-ctn-kix_list_12-3, decimal) ". "
  }

  .lst-kix_list_38-2>li {
    counter-increment: lst-ctn-kix_list_38-2
  }

  .lst-kix_list_32-6>li:before {
    content: " "
  }

  ol.lst-kix_list_8-4 {
    list-style-type: none
  }

  .lst-kix_list_12-1>li:before {
    content: ""counter(lst-ctn-kix_list_12-1, lower-latin) ". "
  }

  ol.lst-kix_list_8-5 {
    list-style-type: none
  }

  .lst-kix_list_33-0>li:before {
    content: ""counter(lst-ctn-kix_list_33-0, decimal) ". "
  }

  .lst-kix_list_33-2>li:before {
    content: " "
  }

  ol.lst-kix_list_8-6 {
    list-style-type: none
  }

  ol.lst-kix_list_8-7 {
    list-style-type: none
  }

  .lst-kix_list_32-8>li:before {
    content: " "
  }

  ol.lst-kix_list_8-0 {
    list-style-type: none
  }

  .lst-kix_list_16-3>li {
    counter-increment: lst-ctn-kix_list_16-3
  }

  ol.lst-kix_list_8-1 {
    list-style-type: none
  }

  .lst-kix_list_23-6>li {
    counter-increment: lst-ctn-kix_list_23-6
  }

  ol.lst-kix_list_8-2 {
    list-style-type: none
  }

  .lst-kix_list_13-3>li {
    counter-increment: lst-ctn-kix_list_13-3
  }

  ol.lst-kix_list_13-6.start {
    counter-reset: lst-ctn-kix_list_13-6 0
  }

  ol.lst-kix_list_8-3 {
    list-style-type: none
  }

  ol.lst-kix_list_40-5.start {
    counter-reset: lst-ctn-kix_list_40-5 0
  }

  ol.lst-kix_list_25-8.start {
    counter-reset: lst-ctn-kix_list_25-8 0
  }

  .lst-kix_list_39-0>li {
    counter-increment: lst-ctn-kix_list_39-0
  }

  .lst-kix_list_34-0>li:before {
    content: ""counter(lst-ctn-kix_list_34-0, decimal) ". "
  }

  .lst-kix_list_10-4>li {
    counter-increment: lst-ctn-kix_list_10-4
  }

  .lst-kix_list_14-1>li {
    counter-increment: lst-ctn-kix_list_14-1
  }

  .lst-kix_list_21-4>li {
    counter-increment: lst-ctn-kix_list_21-4
  }

  .lst-kix_list_39-6>li {
    counter-increment: lst-ctn-kix_list_39-6
  }

  .lst-kix_list_13-3>li:before {
    content: ""counter(lst-ctn-kix_list_13-3, decimal) ". "
  }

  .lst-kix_list_34-4>li:before {
    content: " "
  }

  .lst-kix_list_34-6>li:before {
    content: " "
  }

  .lst-kix_list_13-5>li:before {
    content: ""counter(lst-ctn-kix_list_13-5, lower-roman) ". "
  }

  .lst-kix_list_12-5>li:before {
    content: ""counter(lst-ctn-kix_list_12-5, lower-roman) ". "
  }

  ol.lst-kix_list_13-7.start {
    counter-reset: lst-ctn-kix_list_13-7 0
  }

  .lst-kix_list_18-4>li {
    counter-increment: lst-ctn-kix_list_18-4
  }

  .lst-kix_list_12-7>li:before {
    content: ""counter(lst-ctn-kix_list_12-7, lower-latin) ". "
  }

  .lst-kix_list_33-6>li:before {
    content: " "
  }

  ol.lst-kix_list_21-1.start {
    counter-reset: lst-ctn-kix_list_21-1 0
  }

  .lst-kix_list_33-8>li:before {
    content: " "
  }

  .lst-kix_list_34-2>li:before {
    content: " "
  }

  .lst-kix_list_25-1>li {
    counter-increment: lst-ctn-kix_list_25-1
  }

  .lst-kix_list_13-1>li:before {
    content: ""counter(lst-ctn-kix_list_13-1, lower-latin) ". "
  }

  .lst-kix_list_30-5>li:before {
    content: " "
  }

  .lst-kix_list_22-8>li {
    counter-increment: lst-ctn-kix_list_22-8
  }

  .lst-kix_list_35-0>li:before {
    content: ""counter(lst-ctn-kix_list_35-0, decimal) ". "
  }

  .lst-kix_list_35-1>li:before {
    content: " "
  }

  .lst-kix_list_35-4>li:before {
    content: " "
  }

  .lst-kix_list_35-5>li:before {
    content: " "
  }

  ol.lst-kix_list_40-6.start {
    counter-reset: lst-ctn-kix_list_40-6 0
  }

  ol.lst-kix_list_11-6 {
    list-style-type: none
  }

  ol.lst-kix_list_11-7 {
    list-style-type: none
  }

  .lst-kix_list_1-1>li {
    counter-increment: lst-ctn-kix_list_1-1
  }

  ol.lst-kix_list_11-8 {
    list-style-type: none
  }

  .lst-kix_list_30-1>li:before {
    content: " "
  }

  ol.lst-kix_list_11-2 {
    list-style-type: none
  }

  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0
  }

  .lst-kix_list_3-0>li:before {
    content: "ARTICLE "counter(lst-ctn-kix_list_3-0, upper-roman) " "
  }

  ol.lst-kix_list_11-3 {
    list-style-type: none
  }

  ol.lst-kix_list_11-4 {
    list-style-type: none
  }

  .lst-kix_list_30-2>li:before {
    content: " "
  }

  ol.lst-kix_list_11-5 {
    list-style-type: none
  }

  ol.lst-kix_list_20-5.start {
    counter-reset: lst-ctn-kix_list_20-5 0
  }

  ol.lst-kix_list_13-1.start {
    counter-reset: lst-ctn-kix_list_13-1 0
  }

  ol.lst-kix_list_11-0 {
    list-style-type: none
  }

  ol.lst-kix_list_11-1 {
    list-style-type: none
  }

  .lst-kix_list_4-0>li {
    counter-increment: lst-ctn-kix_list_4-0
  }

  .lst-kix_list_3-4>li:before {
    content: "("counter(lst-ctn-kix_list_3-4, upper-latin) ") "
  }

  .lst-kix_list_3-3>li:before {
    content: "("counter(lst-ctn-kix_list_3-3, lower-roman) ") "
  }

  .lst-kix_list_8-0>li:before {
    content: "["counter(lst-ctn-kix_list_8-0, decimal) ". "
  }

  .lst-kix_list_30-6>li:before {
    content: " "
  }

  .lst-kix_list_8-7>li:before {
    content: " "
  }

  .lst-kix_list_38-3>li {
    counter-increment: lst-ctn-kix_list_38-3
  }

  .lst-kix_list_3-8>li:before {
    content: " "
  }

  ol.lst-kix_list_10-7.start {
    counter-reset: lst-ctn-kix_list_10-7 0
  }

  .lst-kix_list_8-3>li:before {
    content: "[("counter(lst-ctn-kix_list_8-3, lower-roman) ") "
  }

  ol.lst-kix_list_22-7 {
    list-style-type: none
  }

  ol.lst-kix_list_22-6 {
    list-style-type: none
  }

  ol.lst-kix_list_22-8 {
    list-style-type: none
  }

  .lst-kix_list_3-7>li:before {
    content: " "
  }

  ol.lst-kix_list_22-3 {
    list-style-type: none
  }

  ol.lst-kix_list_22-2 {
    list-style-type: none
  }

  .lst-kix_list_8-4>li:before {
    content: "[("counter(lst-ctn-kix_list_8-4, upper-latin) ") "
  }

  ol.lst-kix_list_22-5 {
    list-style-type: none
  }

  ol.lst-kix_list_22-4 {
    list-style-type: none
  }

  ol.lst-kix_list_22-1 {
    list-style-type: none
  }

  ol.lst-kix_list_22-0 {
    list-style-type: none
  }

  ol.lst-kix_list_8-5.start {
    counter-reset: lst-ctn-kix_list_8-5 0
  }

  ol.lst-kix_list_26-4.start {
    counter-reset: lst-ctn-kix_list_26-4 0
  }

  .lst-kix_list_35-8>li:before {
    content: " "
  }

  .lst-kix_list_17-1>li {
    counter-increment: lst-ctn-kix_list_17-1
  }

  .lst-kix_list_11-1>li:before {
    content: ""counter(lst-ctn-kix_list_11-1, lower-latin) ". "
  }

  .lst-kix_list_11-0>li:before {
    content: ""counter(lst-ctn-kix_list_11-0, lower-roman) ". "
  }

  ol.lst-kix_list_9-3.start {
    counter-reset: lst-ctn-kix_list_9-3 0
  }

  .lst-kix_list_8-8>li:before {
    content: " "
  }

  ol.lst-kix_list_2-2 {
    list-style-type: none
  }

  .lst-kix_list_16-8>li:before {
    content: ""counter(lst-ctn-kix_list_16-8, lower-roman) ". "
  }

  ol.lst-kix_list_2-3 {
    list-style-type: none
  }

  ol.lst-kix_list_2-4 {
    list-style-type: none
  }

  .lst-kix_list_16-7>li:before {
    content: ""counter(lst-ctn-kix_list_16-7, lower-latin) ". "
  }

  ol.lst-kix_list_2-5 {
    list-style-type: none
  }

  .lst-kix_list_17-8>li {
    counter-increment: lst-ctn-kix_list_17-8
  }

  ol.lst-kix_list_2-0 {
    list-style-type: none
  }

  ol.lst-kix_list_2-1 {
    list-style-type: none
  }

  .lst-kix_list_4-8>li:before {
    content: " "
  }

  .lst-kix_list_21-5>li {
    counter-increment: lst-ctn-kix_list_21-5
  }

  .lst-kix_list_4-7>li:before {
    content: ""counter(lst-ctn-kix_list_4-7, decimal) " "
  }

  .lst-kix_list_14-2>li {
    counter-increment: lst-ctn-kix_list_14-2
  }

  ol.lst-kix_list_20-0.start {
    counter-reset: lst-ctn-kix_list_20-0 0
  }

  .lst-kix_list_17-0>li:before {
    content: ""counter(lst-ctn-kix_list_17-0, decimal) ".   "
  }

  ol.lst-kix_list_35-0 {
    list-style-type: none
  }

  .lst-kix_list_39-8>li {
    counter-increment: lst-ctn-kix_list_39-8
  }

  .lst-kix_list_16-0>li:before {
    content: ""counter(lst-ctn-kix_list_16-0, decimal) ".   "
  }

  .lst-kix_list_40-6>li {
    counter-increment: lst-ctn-kix_list_40-6
  }

  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0
  }

  .lst-kix_list_8-4>li {
    counter-increment: lst-ctn-kix_list_8-4
  }

  .lst-kix_list_16-4>li:before {
    content: ""counter(lst-ctn-kix_list_16-4, lower-latin) ". "
  }

  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0
  }

  .lst-kix_list_16-3>li:before {
    content: ""counter(lst-ctn-kix_list_16-3, decimal) ". "
  }

  ol.lst-kix_list_2-6 {
    list-style-type: none
  }

  ol.lst-kix_list_2-7 {
    list-style-type: none
  }

  ol.lst-kix_list_2-8 {
    list-style-type: none
  }

  .lst-kix_list_11-3>li {
    counter-increment: lst-ctn-kix_list_11-3
  }

  ol.lst-kix_list_18-1.start {
    counter-reset: lst-ctn-kix_list_18-1 0
  }

  ul.lst-kix_list_35-1 {
    list-style-type: none
  }

  ul.lst-kix_list_35-2 {
    list-style-type: none
  }

  ul.lst-kix_list_35-3 {
    list-style-type: none
  }

  ul.lst-kix_list_35-4 {
    list-style-type: none
  }

  ul.lst-kix_list_35-5 {
    list-style-type: none
  }

  .lst-kix_list_41-1>li {
    counter-increment: lst-ctn-kix_list_41-1
  }

  ol.lst-kix_list_39-7.start {
    counter-reset: lst-ctn-kix_list_39-7 0
  }

  ol.lst-kix_list_8-6.start {
    counter-reset: lst-ctn-kix_list_8-6 0
  }

  .lst-kix_list_17-7>li:before {
    content: ""counter(lst-ctn-kix_list_17-7, lower-latin) ". "
  }

  ol.lst-kix_list_38-2.start {
    counter-reset: lst-ctn-kix_list_38-2 0
  }

  .lst-kix_list_17-8>li:before {
    content: ""counter(lst-ctn-kix_list_17-8, lower-roman) ". "
  }

  .lst-kix_list_33-0>li {
    counter-increment: lst-ctn-kix_list_33-0
  }

  .lst-kix_list_17-3>li:before {
    content: ""counter(lst-ctn-kix_list_17-3, decimal) ". "
  }

  .lst-kix_list_17-4>li:before {
    content: ""counter(lst-ctn-kix_list_17-4, lower-latin) ". "
  }

  ul.lst-kix_list_35-6 {
    list-style-type: none
  }

  ul.lst-kix_list_35-7 {
    list-style-type: none
  }

  ol.lst-kix_list_8-0.start {
    counter-reset: lst-ctn-kix_list_8-0 0
  }

  ul.lst-kix_list_35-8 {
    list-style-type: none
  }

  .lst-kix_list_7-0>li:before {
    content: "["counter(lst-ctn-kix_list_7-0, decimal) ". "
  }

  ol.lst-kix_list_27-7.start {
    counter-reset: lst-ctn-kix_list_27-7 0
  }

  ol.lst-kix_list_19-6.start {
    counter-reset: lst-ctn-kix_list_19-6 -1
  }

  ol.lst-kix_list_9-7.start {
    counter-reset: lst-ctn-kix_list_9-7 0
  }

  ol.lst-kix_list_13-8 {
    list-style-type: none
  }

  .lst-kix_list_2-4>li:before {
    content: ""counter(lst-ctn-kix_list_2-4, lower-latin) ". "
  }

  .lst-kix_list_2-8>li:before {
    content: ""counter(lst-ctn-kix_list_2-8, lower-roman) ". "
  }

  ol.lst-kix_list_13-4 {
    list-style-type: none
  }

  ol.lst-kix_list_13-5 {
    list-style-type: none
  }

  ol.lst-kix_list_13-6 {
    list-style-type: none
  }

  ol.lst-kix_list_13-7 {
    list-style-type: none
  }

  ol.lst-kix_list_13-0 {
    list-style-type: none
  }

  ol.lst-kix_list_13-1 {
    list-style-type: none
  }

  ol.lst-kix_list_13-2 {
    list-style-type: none
  }

  .lst-kix_list_7-3>li:before {
    content: "[("counter(lst-ctn-kix_list_7-3, upper-latin) ") "
  }

  ol.lst-kix_list_13-3 {
    list-style-type: none
  }

  .lst-kix_list_10-0>li:before {
    content: ""counter(lst-ctn-kix_list_10-0, lower-latin) ". "
  }

  .lst-kix_list_9-7>li {
    counter-increment: lst-ctn-kix_list_9-7
  }

  ol.lst-kix_list_21-7.start {
    counter-reset: lst-ctn-kix_list_21-7 0
  }

  .lst-kix_list_13-8>li:before {
    content: ""counter(lst-ctn-kix_list_13-8, lower-roman) ". "
  }

  .lst-kix_list_31-1>li:before {
    content: " "
  }

  .lst-kix_list_18-3>li:before {
    content: " "
  }

  .lst-kix_list_18-7>li:before {
    content: " "
  }

  .lst-kix_list_18-6>li {
    counter-increment: lst-ctn-kix_list_18-6
  }

  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0
  }

  ol.lst-kix_list_39-8.start {
    counter-reset: lst-ctn-kix_list_39-8 0
  }

  .lst-kix_list_7-7>li:before {
    content: ""counter(lst-ctn-kix_list_7-7, decimal) " "
  }

  .lst-kix_list_36-5>li:before {
    content: " "
  }

  ol.lst-kix_list_8-1.start {
    counter-reset: lst-ctn-kix_list_8-1 0
  }

  .lst-kix_list_15-4>li:before {
    content: " "
  }

  .lst-kix_list_31-5>li:before {
    content: " "
  }

  ol.lst-kix_list_24-8 {
    list-style-type: none
  }

  .lst-kix_list_10-4>li:before {
    content: ""counter(lst-ctn-kix_list_10-4, lower-latin) ". "
  }

  .lst-kix_list_10-8>li:before {
    content: ""counter(lst-ctn-kix_list_10-8, lower-roman) ". "
  }

  ol.lst-kix_list_20-4.start {
    counter-reset: lst-ctn-kix_list_20-4 0
  }

  .lst-kix_list_4-0>li:before {
    content: ""counter(lst-ctn-kix_list_4-0, decimal) ". "
  }

  .lst-kix_list_36-1>li:before {
    content: " "
  }

  ol.lst-kix_list_24-5 {
    list-style-type: none
  }

  ol.lst-kix_list_24-4 {
    list-style-type: none
  }

  ol.lst-kix_list_24-7 {
    list-style-type: none
  }

  ol.lst-kix_list_25-2.start {
    counter-reset: lst-ctn-kix_list_25-2 0
  }

  .lst-kix_list_15-0>li:before {
    content: ""counter(lst-ctn-kix_list_15-0, decimal) " "
  }

  .lst-kix_list_15-8>li:before {
    content: " "
  }

  ol.lst-kix_list_24-6 {
    list-style-type: none
  }

  ol.lst-kix_list_24-1 {
    list-style-type: none
  }

  ol.lst-kix_list_38-7.start {
    counter-reset: lst-ctn-kix_list_38-7 0
  }

  ol.lst-kix_list_14-3.start {
    counter-reset: lst-ctn-kix_list_14-3 0
  }

  ol.lst-kix_list_24-0 {
    list-style-type: none
  }

  ol.lst-kix_list_24-3 {
    list-style-type: none
  }

  .lst-kix_list_15-7>li {
    counter-increment: lst-ctn-kix_list_15-7
  }

  ol.lst-kix_list_24-2 {
    list-style-type: none
  }

  .lst-kix_list_4-4>li:before {
    content: "("counter(lst-ctn-kix_list_4-4, upper-latin) ") "
  }

  .lst-kix_list_9-3>li:before {
    content: ""counter(lst-ctn-kix_list_9-3, decimal) ". "
  }

  ol.lst-kix_list_7-0.start {
    counter-reset: lst-ctn-kix_list_7-0 0
  }

  .lst-kix_list_12-8>li {
    counter-increment: lst-ctn-kix_list_12-8
  }

  ol.lst-kix_list_13-2.start {
    counter-reset: lst-ctn-kix_list_13-2 0
  }

  ol.lst-kix_list_4-0 {
    list-style-type: none
  }

  ol.lst-kix_list_4-1 {
    list-style-type: none
  }

  ol.lst-kix_list_4-2 {
    list-style-type: none
  }

  ol.lst-kix_list_4-3 {
    list-style-type: none
  }

  ol.lst-kix_list_14-4.start {
    counter-reset: lst-ctn-kix_list_14-4 0
  }

  .lst-kix_list_9-7>li:before {
    content: ""counter(lst-ctn-kix_list_9-7, lower-latin) ". "
  }

  .lst-kix_list_2-4>li {
    counter-increment: lst-ctn-kix_list_2-4
  }

  .lst-kix_list_29-4>li:before {
    content: " "
  }

  .lst-kix_list_29-8>li:before {
    content: " "
  }

  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0
  }

  .lst-kix_list_32-5>li:before {
    content: " "
  }

  .lst-kix_list_11-4>li:before {
    content: ""counter(lst-ctn-kix_list_11-4, lower-latin) ". "
  }

  .lst-kix_list_12-4>li:before {
    content: ""counter(lst-ctn-kix_list_12-4, lower-latin) ". "
  }

  ol.lst-kix_list_37-0 {
    list-style-type: none
  }

  .lst-kix_list_5-3>li {
    counter-increment: lst-ctn-kix_list_5-3
  }

  ol.lst-kix_list_37-0.start {
    counter-reset: lst-ctn-kix_list_37-0 0
  }

  .lst-kix_list_29-0>li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_4-8 {
    list-style-type: none
  }

  .lst-kix_list_7-4>li {
    counter-increment: lst-ctn-kix_list_7-4
  }

  .lst-kix_list_33-1>li:before {
    content: " "
  }

  .lst-kix_list_1-0>li:before {
    content: ""counter(lst-ctn-kix_list_1-0, decimal) ". "
  }

  ol.lst-kix_list_19-2.start {
    counter-reset: lst-ctn-kix_list_19-2 -1
  }

  ol.lst-kix_list_38-5.start {
    counter-reset: lst-ctn-kix_list_38-5 0
  }

  ol.lst-kix_list_4-4 {
    list-style-type: none
  }

  .lst-kix_list_11-8>li:before {
    content: ""counter(lst-ctn-kix_list_11-8, lower-roman) ". "
  }

  ol.lst-kix_list_4-5 {
    list-style-type: none
  }

  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0
  }

  ol.lst-kix_list_4-6 {
    list-style-type: none
  }

  .lst-kix_list_12-0>li:before {
    content: ""counter(lst-ctn-kix_list_12-0, lower-roman) ". "
  }

  ol.lst-kix_list_4-7 {
    list-style-type: none
  }

  .lst-kix_list_1-4>li:before {
    content: " "
  }

  .lst-kix_list_13-0>li:before {
    content: ""counter(lst-ctn-kix_list_13-0, upper-latin) ". "
  }

  ul.lst-kix_list_37-1 {
    list-style-type: none
  }

  ul.lst-kix_list_37-2 {
    list-style-type: none
  }

  ul.lst-kix_list_37-3 {
    list-style-type: none
  }

  .lst-kix_list_1-6>li {
    counter-increment: lst-ctn-kix_list_1-6
  }

  .lst-kix_list_13-4>li:before {
    content: ""counter(lst-ctn-kix_list_13-4, lower-latin) ". "
  }

  .lst-kix_list_34-5>li:before {
    content: " "
  }

  ol.lst-kix_list_26-5.start {
    counter-reset: lst-ctn-kix_list_26-5 0
  }

  ol.lst-kix_list_38-6.start {
    counter-reset: lst-ctn-kix_list_38-6 0
  }

  .lst-kix_list_33-5>li:before {
    content: " "
  }

  ol.lst-kix_list_19-3.start {
    counter-reset: lst-ctn-kix_list_19-3 -1
  }

  .lst-kix_list_2-0>li:before {
    content: ""counter(lst-ctn-kix_list_2-0, decimal) ".   "
  }

  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0
  }

  .lst-kix_list_4-5>li {
    counter-increment: lst-ctn-kix_list_4-5
  }

  ul.lst-kix_list_37-4 {
    list-style-type: none
  }

  ol.lst-kix_list_9-8.start {
    counter-reset: lst-ctn-kix_list_9-8 0
  }

  .lst-kix_list_1-8>li:before {
    content: " "
  }

  ul.lst-kix_list_37-5 {
    list-style-type: none
  }

  ul.lst-kix_list_37-6 {
    list-style-type: none
  }

  ul.lst-kix_list_37-7 {
    list-style-type: none
  }

  ul.lst-kix_list_37-8 {
    list-style-type: none
  }

  .lst-kix_list_34-1>li:before {
    content: " "
  }

  .lst-kix_list_12-8>li:before {
    content: ""counter(lst-ctn-kix_list_12-8, lower-roman) ". "
  }

  .lst-kix_list_8-2>li {
    counter-increment: lst-ctn-kix_list_8-2
  }

  .lst-kix_list_19-0>li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_17-7.start {
    counter-reset: lst-ctn-kix_list_17-7 0
  }

  ol.lst-kix_list_12-6.start {
    counter-reset: lst-ctn-kix_list_12-6 0
  }

  .lst-kix_list_23-8>li {
    counter-increment: lst-ctn-kix_list_23-8
  }

  .lst-kix_list_8-1>li {
    counter-increment: lst-ctn-kix_list_8-1
  }

  ol.lst-kix_list_8-2.start {
    counter-reset: lst-ctn-kix_list_8-2 0
  }

  ol.lst-kix_list_26-1.start {
    counter-reset: lst-ctn-kix_list_26-1 0
  }

  .lst-kix_list_19-2>li:before {
    content: " "
  }

  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0
  }

  ol.lst-kix_list_21-0.start {
    counter-reset: lst-ctn-kix_list_21-0 0
  }

  .lst-kix_list_7-0>li {
    counter-increment: lst-ctn-kix_list_7-0
  }

  .lst-kix_list_2-3>li {
    counter-increment: lst-ctn-kix_list_2-3
  }

  ol.lst-kix_list_19-8.start {
    counter-reset: lst-ctn-kix_list_19-8 -1
  }

  ol.lst-kix_list_41-7 {
    list-style-type: none
  }

  ol.lst-kix_list_41-8 {
    list-style-type: none
  }

  ol.lst-kix_list_41-5 {
    list-style-type: none
  }

  ol.lst-kix_list_41-6 {
    list-style-type: none
  }

  .lst-kix_list_1-2>li {
    counter-increment: lst-ctn-kix_list_1-2
  }

  ol.lst-kix_list_41-3 {
    list-style-type: none
  }

  ol.lst-kix_list_41-4 {
    list-style-type: none
  }

  .lst-kix_list_19-8>li:before {
    content: " "
  }

  ol.lst-kix_list_41-1 {
    list-style-type: none
  }

  ol.lst-kix_list_14-7.start {
    counter-reset: lst-ctn-kix_list_14-7 0
  }

  ol.lst-kix_list_41-2 {
    list-style-type: none
  }

  ol.lst-kix_list_20-8.start {
    counter-reset: lst-ctn-kix_list_20-8 0
  }

  ol.lst-kix_list_41-0 {
    list-style-type: none
  }

  .lst-kix_list_19-5>li:before {
    content: " "
  }

  .lst-kix_list_19-7>li:before {
    content: " "
  }

  .lst-kix_list_9-2>li {
    counter-increment: lst-ctn-kix_list_9-2
  }

  ol.lst-kix_list_23-8.start {
    counter-reset: lst-ctn-kix_list_23-8 0
  }

  .lst-kix_list_24-5>li {
    counter-increment: lst-ctn-kix_list_24-5
  }

  .lst-kix_list_37-7>li:before {
    content: " "
  }

  ol.lst-kix_list_17-2.start {
    counter-reset: lst-ctn-kix_list_17-2 0
  }

  .lst-kix_list_13-2>li {
    counter-increment: lst-ctn-kix_list_13-2
  }

  ol.lst-kix_list_21-5.start {
    counter-reset: lst-ctn-kix_list_21-5 0
  }

  .lst-kix_list_38-0>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) ". "
  }

  .lst-kix_list_19-7>li {
    counter-increment: lst-ctn-kix_list_19-7
  }

  .lst-kix_list_38-1>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) ". "
  }

  .lst-kix_list_14-3>li {
    counter-increment: lst-ctn-kix_list_14-3
  }

  ol.lst-kix_list_15-6.start {
    counter-reset: lst-ctn-kix_list_15-6 0
  }

  .lst-kix_list_37-2>li:before {
    content: " "
  }

  .lst-kix_list_37-4>li:before {
    content: " "
  }

  .lst-kix_list_25-6>li {
    counter-increment: lst-ctn-kix_list_25-6
  }

  .lst-kix_list_37-5>li:before {
    content: " "
  }

  ul.lst-kix_list_31-2 {
    list-style-type: none
  }

  .lst-kix_list_12-1>li {
    counter-increment: lst-ctn-kix_list_12-1
  }

  ul.lst-kix_list_31-3 {
    list-style-type: none
  }

  ul.lst-kix_list_31-4 {
    list-style-type: none
  }

  ul.lst-kix_list_31-5 {
    list-style-type: none
  }

  ul.lst-kix_list_31-6 {
    list-style-type: none
  }

  ul.lst-kix_list_31-7 {
    list-style-type: none
  }

  ul.lst-kix_list_31-8 {
    list-style-type: none
  }

  .lst-kix_list_18-1>li:before {
    content: " "
  }

  .lst-kix_list_38-8>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) "."counter(lst-ctn-kix_list_38-3, decimal) "."counter(lst-ctn-kix_list_38-4, decimal) "."counter(lst-ctn-kix_list_38-5, decimal) "."counter(lst-ctn-kix_list_38-6, decimal) "."counter(lst-ctn-kix_list_38-7, decimal) "."counter(lst-ctn-kix_list_38-8, decimal) ". "
  }

  ul.lst-kix_list_31-0 {
    list-style-type: none
  }

  .lst-kix_list_40-3>li {
    counter-increment: lst-ctn-kix_list_40-3
  }

  ul.lst-kix_list_31-1 {
    list-style-type: none
  }

  ol.lst-kix_list_25-4.start {
    counter-reset: lst-ctn-kix_list_25-4 0
  }

  .lst-kix_list_38-3>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) "."counter(lst-ctn-kix_list_38-3, decimal) ". "
  }

  .lst-kix_list_23-4>li {
    counter-increment: lst-ctn-kix_list_23-4
  }

  ol.lst-kix_list_23-1.start {
    counter-reset: lst-ctn-kix_list_23-1 0
  }

  .lst-kix_list_38-6>li:before {
    content: ""counter(lst-ctn-kix_list_38-0, decimal) "."counter(lst-ctn-kix_list_38-1, decimal) "."counter(lst-ctn-kix_list_38-2, decimal) "."counter(lst-ctn-kix_list_38-3, decimal) "."counter(lst-ctn-kix_list_38-4, decimal) "."counter(lst-ctn-kix_list_38-5, decimal) "."counter(lst-ctn-kix_list_38-6, decimal) ". "
  }

  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0
  }

  .lst-kix_list_23-1>li {
    counter-increment: lst-ctn-kix_list_23-1
  }

  ol.lst-kix_list_1-3 {
    list-style-type: none
  }

  ol.lst-kix_list_1-4 {
    list-style-type: none
  }

  .lst-kix_list_2-7>li:before {
    content: ""counter(lst-ctn-kix_list_2-7, lower-latin) ". "
  }

  .lst-kix_list_2-7>li {
    counter-increment: lst-ctn-kix_list_2-7
  }

  ol.lst-kix_list_1-5 {
    list-style-type: none
  }

  .lst-kix_list_24-2>li {
    counter-increment: lst-ctn-kix_list_24-2
  }

  ol.lst-kix_list_1-6 {
    list-style-type: none
  }

  .lst-kix_list_41-8>li {
    counter-increment: lst-ctn-kix_list_41-8
  }

  ol.lst-kix_list_1-0 {
    list-style-type: none
  }

  .lst-kix_list_2-5>li:before {
    content: ""counter(lst-ctn-kix_list_2-5, lower-roman) ". "
  }

  ol.lst-kix_list_1-1 {
    list-style-type: none
  }

  ol.lst-kix_list_1-2 {
    list-style-type: none
  }

  ol.lst-kix_list_17-0.start {
    counter-reset: lst-ctn-kix_list_17-0 0
  }

  .lst-kix_list_27-5>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) "."counter(lst-ctn-kix_list_27-3, decimal) "."counter(lst-ctn-kix_list_27-4, decimal) "."counter(lst-ctn-kix_list_27-5, decimal) ". "
  }

  ol.lst-kix_list_10-3.start {
    counter-reset: lst-ctn-kix_list_10-3 0
  }

  .lst-kix_list_22-3>li {
    counter-increment: lst-ctn-kix_list_22-3
  }

  .lst-kix_list_18-6>li:before {
    content: " "
  }

  .lst-kix_list_39-5>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) "."counter(lst-ctn-kix_list_39-3, decimal) "."counter(lst-ctn-kix_list_39-4, decimal) "."counter(lst-ctn-kix_list_39-5, decimal) ". "
  }

  .lst-kix_list_39-7>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) "."counter(lst-ctn-kix_list_39-3, decimal) "."counter(lst-ctn-kix_list_39-4, decimal) "."counter(lst-ctn-kix_list_39-5, decimal) "."counter(lst-ctn-kix_list_39-6, decimal) "."counter(lst-ctn-kix_list_39-7, decimal) ". "
  }

  .lst-kix_list_14-6>li {
    counter-increment: lst-ctn-kix_list_14-6
  }

  .lst-kix_list_10-1>li:before {
    content: ""counter(lst-ctn-kix_list_10-1, lower-latin) ". "
  }

  .lst-kix_list_18-4>li:before {
    content: " "
  }

  .lst-kix_list_7-7>li {
    counter-increment: lst-ctn-kix_list_7-7
  }

  ol.lst-kix_list_15-1.start {
    counter-reset: lst-ctn-kix_list_15-1 0
  }

  ol.lst-kix_list_15-4.start {
    counter-reset: lst-ctn-kix_list_15-4 0
  }

  ol.lst-kix_list_1-7 {
    list-style-type: none
  }

  ol.lst-kix_list_1-8 {
    list-style-type: none
  }

  .lst-kix_list_36-6>li:before {
    content: " "
  }

  ol.lst-kix_list_39-2.start {
    counter-reset: lst-ctn-kix_list_39-2 0
  }

  .lst-kix_list_10-3>li:before {
    content: ""counter(lst-ctn-kix_list_10-3, decimal) ". "
  }

  .lst-kix_list_15-4>li {
    counter-increment: lst-ctn-kix_list_15-4
  }

  .lst-kix_list_2-6>li {
    counter-increment: lst-ctn-kix_list_2-6
  }

  ol.lst-kix_list_7-3.start {
    counter-reset: lst-ctn-kix_list_7-3 0
  }

  .lst-kix_list_36-0>li:before {
    content: ""counter(lst-ctn-kix_list_36-0, decimal) ". "
  }

  .lst-kix_list_36-8>li:before {
    content: " "
  }

  ol.lst-kix_list_40-2.start {
    counter-reset: lst-ctn-kix_list_40-2 0
  }

  .lst-kix_list_34-0>li {
    counter-increment: lst-ctn-kix_list_34-0
  }

  .lst-kix_list_26-4>li {
    counter-increment: lst-ctn-kix_list_26-4
  }

  ol.lst-kix_list_5-7.start {
    counter-reset: lst-ctn-kix_list_5-7 0
  }

  .lst-kix_list_20-8>li:before {
    content: ""counter(lst-ctn-kix_list_20-8, lower-roman) ". "
  }

  .lst-kix_list_39-1>li {
    counter-increment: lst-ctn-kix_list_39-1
  }

  .lst-kix_list_3-4>li {
    counter-increment: lst-ctn-kix_list_3-4
  }

  .lst-kix_list_29-7>li:before {
    content: " "
  }

  .lst-kix_list_29-5>li:before {
    content: " "
  }

  .lst-kix_list_20-0>li:before {
    content: "Article "counter(lst-ctn-kix_list_20-0, upper-roman) ". "
  }

  .lst-kix_list_9-6>li:before {
    content: ""counter(lst-ctn-kix_list_9-6, decimal) ". "
  }

  ol.lst-kix_list_10-7 {
    list-style-type: none
  }

  ol.lst-kix_list_10-8 {
    list-style-type: none
  }

  .lst-kix_list_9-4>li:before {
    content: ""counter(lst-ctn-kix_list_9-4, lower-latin) ". "
  }

  ol.lst-kix_list_10-3 {
    list-style-type: none
  }

  ol.lst-kix_list_10-4 {
    list-style-type: none
  }

  ol.lst-kix_list_10-5 {
    list-style-type: none
  }

  ol.lst-kix_list_10-6 {
    list-style-type: none
  }

  .lst-kix_list_20-6>li:before {
    content: ""counter(lst-ctn-kix_list_20-6, lower-roman) ") "
  }

  .lst-kix_list_23-0>li {
    counter-increment: lst-ctn-kix_list_23-0
  }

  ol.lst-kix_list_10-0 {
    list-style-type: none
  }

  ol.lst-kix_list_10-1 {
    list-style-type: none
  }

  ol.lst-kix_list_10-2 {
    list-style-type: none
  }

  ol.lst-kix_list_12-1.start {
    counter-reset: lst-ctn-kix_list_12-1 0
  }

  .lst-kix_list_11-5>li:before {
    content: ""counter(lst-ctn-kix_list_11-5, lower-roman) ". "
  }

  ol.lst-kix_list_21-2.start {
    counter-reset: lst-ctn-kix_list_21-2 0
  }

  .lst-kix_list_20-6>li {
    counter-increment: lst-ctn-kix_list_20-6
  }

  .lst-kix_list_1-1>li:before {
    content: "("counter(lst-ctn-kix_list_1-1, lower-latin) ") "
  }

  .lst-kix_list_11-7>li:before {
    content: ""counter(lst-ctn-kix_list_11-7, lower-latin) ". "
  }

  .lst-kix_list_8-5>li {
    counter-increment: lst-ctn-kix_list_8-5
  }

  ol.lst-kix_list_25-7.start {
    counter-reset: lst-ctn-kix_list_25-7 0
  }

  ul.lst-kix_list_33-1 {
    list-style-type: none
  }

  .lst-kix_list_1-3>li:before {
    content: "("counter(lst-ctn-kix_list_1-3, upper-latin) ") "
  }

  ul.lst-kix_list_33-2 {
    list-style-type: none
  }

  ul.lst-kix_list_33-3 {
    list-style-type: none
  }

  ul.lst-kix_list_33-4 {
    list-style-type: none
  }

  ul.lst-kix_list_33-5 {
    list-style-type: none
  }

  ul.lst-kix_list_33-6 {
    list-style-type: none
  }

  .lst-kix_list_28-3>li:before {
    content: " "
  }

  ol.lst-kix_list_10-5.start {
    counter-reset: lst-ctn-kix_list_10-5 0
  }

  ul.lst-kix_list_33-7 {
    list-style-type: none
  }

  ol.lst-kix_list_21-8 {
    list-style-type: none
  }

  ol.lst-kix_list_21-7 {
    list-style-type: none
  }

  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0
  }

  ol.lst-kix_list_21-4 {
    list-style-type: none
  }

  ol.lst-kix_list_21-3 {
    list-style-type: none
  }

  ol.lst-kix_list_21-6 {
    list-style-type: none
  }

  .lst-kix_list_14-7>li {
    counter-increment: lst-ctn-kix_list_14-7
  }

  ol.lst-kix_list_21-5 {
    list-style-type: none
  }

  ol.lst-kix_list_21-0 {
    list-style-type: none
  }

  ol.lst-kix_list_7-5.start {
    counter-reset: lst-ctn-kix_list_7-5 0
  }

  ol.lst-kix_list_21-2 {
    list-style-type: none
  }

  ol.lst-kix_list_21-1 {
    list-style-type: none
  }

  ol.lst-kix_list_40-4.start {
    counter-reset: lst-ctn-kix_list_40-4 0
  }

  .lst-kix_list_27-7>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) "."counter(lst-ctn-kix_list_27-3, decimal) "."counter(lst-ctn-kix_list_27-4, decimal) "."counter(lst-ctn-kix_list_27-5, decimal) "."counter(lst-ctn-kix_list_27-6, decimal) "."counter(lst-ctn-kix_list_27-7, decimal) ". "
  }

  .lst-kix_list_25-7>li {
    counter-increment: lst-ctn-kix_list_25-7
  }

  ul.lst-kix_list_33-8 {
    list-style-type: none
  }

  ol.lst-kix_list_39-4.start {
    counter-reset: lst-ctn-kix_list_39-4 0
  }

  .lst-kix_list_28-1>li:before {
    content: " "
  }

  ol.lst-kix_list_19-6 {
    list-style-type: none
  }

  .lst-kix_list_35-2>li:before {
    content: " "
  }

  ol.lst-kix_list_19-7 {
    list-style-type: none
  }

  .lst-kix_list_3-1>li {
    counter-increment: lst-ctn-kix_list_3-1
  }

  ol.lst-kix_list_19-8 {
    list-style-type: none
  }

  .lst-kix_list_30-3>li:before {
    content: " "
  }

  ol.lst-kix_list_19-2 {
    list-style-type: none
  }

  ol.lst-kix_list_34-0.start {
    counter-reset: lst-ctn-kix_list_34-0 0
  }

  .lst-kix_list_26-8>li {
    counter-increment: lst-ctn-kix_list_26-8
  }

  ol.lst-kix_list_19-3 {
    list-style-type: none
  }

  ol.lst-kix_list_19-4 {
    list-style-type: none
  }

  .lst-kix_list_30-8>li:before {
    content: " "
  }

  ol.lst-kix_list_19-5 {
    list-style-type: none
  }

  .lst-kix_list_35-7>li:before {
    content: " "
  }

  ol.lst-kix_list_39-1.start {
    counter-reset: lst-ctn-kix_list_39-1 0
  }

  ol.lst-kix_list_7-7.start {
    counter-reset: lst-ctn-kix_list_7-7 0
  }

  .lst-kix_list_3-1>li:before {
    content: "Section "counter(lst-ctn-kix_list_3-0, upper-roman) "."counter(lst-ctn-kix_list_3-1, decimal-leading-zero) " "
  }

  .lst-kix_list_26-6>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) "."counter(lst-ctn-kix_list_26-3, decimal) "."counter(lst-ctn-kix_list_26-4, decimal) "."counter(lst-ctn-kix_list_26-5, decimal) "."counter(lst-ctn-kix_list_26-6, decimal) ". "
  }

  .lst-kix_list_14-0>li {
    counter-increment: lst-ctn-kix_list_14-0
  }

  ol.lst-kix_list_17-4.start {
    counter-reset: lst-ctn-kix_list_17-4 0
  }

  .lst-kix_list_8-2>li:before {
    content: "[("counter(lst-ctn-kix_list_8-2, lower-latin) ") "
  }

  .lst-kix_list_12-0>li {
    counter-increment: lst-ctn-kix_list_12-0
  }

  ol.lst-kix_list_12-3.start {
    counter-reset: lst-ctn-kix_list_12-3 0
  }

  ol.lst-kix_list_40-0.start {
    counter-reset: lst-ctn-kix_list_40-0 0
  }

  .lst-kix_list_21-2>li:before {
    content: "("counter(lst-ctn-kix_list_21-2, lower-latin) ") "
  }

  .lst-kix_list_8-5>li:before {
    content: " "
  }

  .lst-kix_list_2-0>li {
    counter-increment: lst-ctn-kix_list_2-0
  }

  .lst-kix_list_15-1>li {
    counter-increment: lst-ctn-kix_list_15-1
  }

  .lst-kix_list_26-3>li:before {
    content: ""counter(lst-ctn-kix_list_26-0, decimal) "."counter(lst-ctn-kix_list_26-1, decimal) "."counter(lst-ctn-kix_list_26-2, decimal) "."counter(lst-ctn-kix_list_26-3, decimal) ". "
  }

  .lst-kix_list_3-6>li:before {
    content: " "
  }

  .lst-kix_list_21-7>li:before {
    content: ""counter(lst-ctn-kix_list_21-7, lower-latin) ". "
  }

  ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 0
  }

  .lst-kix_list_11-2>li:before {
    content: ""counter(lst-ctn-kix_list_11-2, lower-roman) ". "
  }

  .lst-kix_list_39-4>li {
    counter-increment: lst-ctn-kix_list_39-4
  }

  .lst-kix_list_40-4>li {
    counter-increment: lst-ctn-kix_list_40-4
  }

  ol.lst-kix_list_12-4.start {
    counter-reset: lst-ctn-kix_list_12-4 0
  }

  .lst-kix_list_16-6>li:before {
    content: ""counter(lst-ctn-kix_list_16-6, decimal) ". "
  }

  ol.lst-kix_list_10-1.start {
    counter-reset: lst-ctn-kix_list_10-1 0
  }

  .lst-kix_list_25-2>li:before {
    content: ""counter(lst-ctn-kix_list_25-0, decimal) "."counter(lst-ctn-kix_list_25-1, decimal) "."counter(lst-ctn-kix_list_25-2, decimal) ". "
  }

  .lst-kix_list_18-2>li {
    counter-increment: lst-ctn-kix_list_18-2
  }

  ol.lst-kix_list_5-6.start {
    counter-reset: lst-ctn-kix_list_5-6 0
  }

  .lst-kix_list_22-6>li {
    counter-increment: lst-ctn-kix_list_22-6
  }

  .lst-kix_list_16-1>li:before {
    content: ""counter(lst-ctn-kix_list_16-1, lower-latin) ". "
  }

  .lst-kix_list_7-3>li {
    counter-increment: lst-ctn-kix_list_7-3
  }

  ol.lst-kix_list_39-0.start {
    counter-reset: lst-ctn-kix_list_39-0 0
  }

  .lst-kix_list_25-0>li {
    counter-increment: lst-ctn-kix_list_25-0
  }

  .lst-kix_list_19-3>li {
    counter-increment: lst-ctn-kix_list_19-3
  }

  ol.lst-kix_list_7-8.start {
    counter-reset: lst-ctn-kix_list_7-8 0
  }

  .lst-kix_list_12-4>li {
    counter-increment: lst-ctn-kix_list_12-4
  }

  .lst-kix_list_23-7>li {
    counter-increment: lst-ctn-kix_list_23-7
  }

  ol.lst-kix_list_10-2.start {
    counter-reset: lst-ctn-kix_list_10-2 0
  }

  .lst-kix_list_39-2>li:before {
    content: ""counter(lst-ctn-kix_list_39-0, decimal) "."counter(lst-ctn-kix_list_39-1, decimal) "."counter(lst-ctn-kix_list_39-2, decimal) ". "
  }

  .lst-kix_list_12-7>li {
    counter-increment: lst-ctn-kix_list_12-7
  }

  .lst-kix_list_40-0>li {
    counter-increment: lst-ctn-kix_list_40-0
  }

  .lst-kix_list_17-2>li:before {
    content: ""counter(lst-ctn-kix_list_17-2, lower-roman) ". "
  }

  ol.lst-kix_list_5-5.start {
    counter-reset: lst-ctn-kix_list_5-5 0
  }

  .lst-kix_list_30-0>li:before {
    content: "\0025cf  "
  }

  ol.lst-kix_list_17-3.start {
    counter-reset: lst-ctn-kix_list_17-3 0
  }

  ol.lst-kix_list_40-1.start {
    counter-reset: lst-ctn-kix_list_40-1 0
  }

  .lst-kix_list_17-5>li:before {
    content: ""counter(lst-ctn-kix_list_17-5, lower-roman) ". "
  }

  .lst-kix_list_6-2>li {
    counter-increment: lst-ctn-kix_list_6-2
  }

  .lst-kix_list_27-2>li:before {
    content: ""counter(lst-ctn-kix_list_27-0, decimal) "."counter(lst-ctn-kix_list_27-1, decimal) "."counter(lst-ctn-kix_list_27-2, decimal) ". "
  }

  .lst-kix_list_22-3>li:before {
    content: ""counter(lst-ctn-kix_list_22-0, decimal) "."counter(lst-ctn-kix_list_22-1, decimal) "."counter(lst-ctn-kix_list_22-2, decimal) "."counter(lst-ctn-kix_list_22-3, decimal) ". "
  }

  .lst-kix_list_7-1>li:before {
    content: "[("counter(lst-ctn-kix_list_7-1, lower-latin) ") "
  }

  .lst-kix_list_13-5>li {
    counter-increment: lst-ctn-kix_list_13-5
  }

  .lst-kix_list_9-6>li {
    counter-increment: lst-ctn-kix_list_9-6
  }

  ol.lst-kix_list_23-3.start {
    counter-reset: lst-ctn-kix_list_23-3 0
  }

  ol.lst-kix_list_5-4.start {
    counter-reset: lst-ctn-kix_list_5-4 0
  }

  ol.lst-kix_list_5-1.start {
    counter-reset: lst-ctn-kix_list_5-1 0
  }

  .lst-kix_list_20-3>li {
    counter-increment: lst-ctn-kix_list_20-3
  }

  .lst-kix_list_25-3>li {
    counter-increment: lst-ctn-kix_list_25-3
  }

  .lst-kix_list_16-6>li {
    counter-increment: lst-ctn-kix_list_16-6
  }

  .lst-kix_list_11-6>li {
    counter-increment: lst-ctn-kix_list_11-6
  }

  .lst-kix_list_31-7>li:before {
    content: " "
  }

  ol.lst-kix_list_10-0.start {
    counter-reset: lst-ctn-kix_list_10-0 0
  }

  ol.lst-kix_list_17-8.start {
    counter-reset: lst-ctn-kix_list_17-8 0
  }

  .lst-kix_list_3-8>li {
    counter-increment: lst-ctn-kix_list_3-8
  }

  .lst-kix_list_4-6>li {
    counter-increment: lst-ctn-kix_list_4-6
  }

  .lst-kix_list_1-5>li {
    counter-increment: lst-ctn-kix_list_1-5
  }

  ol.lst-kix_list_17-5.start {
    counter-reset: lst-ctn-kix_list_17-5 0
  }

  .lst-kix_list_4-2>li:before {
    content: "("counter(lst-ctn-kix_list_4-2, lower-latin) ") "
  }

  ol.lst-kix_list_23-6.start {
    counter-reset: lst-ctn-kix_list_23-6 0
  }

  .lst-kix_list_17-4>li {
    counter-increment: lst-ctn-kix_list_17-4
  }

  .lst-kix_list_15-2>li:before {
    content: "("counter(lst-ctn-kix_list_15-2, lower-roman) ") "
  }

  .lst-kix_list_36-3>li:before {
    content: " "
  }

  .lst-kix_list_26-1>li {
    counter-increment: lst-ctn-kix_list_26-1
  }

  .lst-kix_list_10-8>li {
    counter-increment: lst-ctn-kix_list_10-8
  }

  .lst-kix_list_9-1>li:before {
    content: ""counter(lst-ctn-kix_list_9-1, lower-latin) ". "
  }

  .lst-kix_list_10-6>li:before {
    content: ""counter(lst-ctn-kix_list_10-6, decimal) ". "
  }

  .lst-kix_list_40-7>li {
    counter-increment: lst-ctn-kix_list_40-7
  }

  ol.lst-kix_list_12-7.start {
    counter-reset: lst-ctn-kix_list_12-7 0
  }

  .lst-kix_list_15-8>li {
    counter-increment: lst-ctn-kix_list_15-8
  }

  .lst-kix_list_40-8>li:before {
    content: ""counter(lst-ctn-kix_list_40-8, lower-roman) ". "
  }

  .lst-kix_list_41-5>li {
    counter-increment: lst-ctn-kix_list_41-5
  }

  .lst-kix_list_41-4>li:before {
    content: ""counter(lst-ctn-kix_list_41-0, decimal) "."counter(lst-ctn-kix_list_41-1, decimal) "."counter(lst-ctn-kix_list_41-2, decimal) "."counter(lst-ctn-kix_list_41-3, decimal) "."counter(lst-ctn-kix_list_41-4, decimal) ". "
  }

  ol.lst-kix_list_12-8.start {
    counter-reset: lst-ctn-kix_list_12-8 0
  }

  .lst-kix_list_20-3>li:before {
    content: "("counter(lst-ctn-kix_list_20-3, lower-roman) ") "
  }

  .lst-kix_list_27-6>li {
    counter-increment: lst-ctn-kix_list_27-6
  }

  .lst-kix_list_29-2>li:before {
    content: " "
  }

  ol.lst-kix_list_23-5.start {
    counter-reset: lst-ctn-kix_list_23-5 0
  }

  .lst-kix_list_21-1>li {
    counter-increment: lst-ctn-kix_list_21-1
  }

  .lst-kix_list_10-1>li {
    counter-increment: lst-ctn-kix_list_10-1
  }

  .lst-kix_list_8-8>li {
    counter-increment: lst-ctn-kix_list_8-8
  }

  ol.lst-kix_list_17-6.start {
    counter-reset: lst-ctn-kix_list_17-6 0
  }

  .lst-kix_list_28-6>li:before {
    content: " "
  }

  .lst-kix_list_1-6>li:before {
    content: " "
  }

  .lst-kix_list_33-7>li:before {
    content: " "
  }

  .lst-kix_list_40-0>li:before {
    content: ""counter(lst-ctn-kix_list_40-0, decimal) ") "
  }

  .lst-kix_list_12-6>li:before {
    content: ""counter(lst-ctn-kix_list_12-6, decimal) ". "
  }

  ol.lst-kix_list_23-4.start {
    counter-reset: lst-ctn-kix_list_23-4 0
  }

  .lst-kix_list_34-3>li:before {
    content: " "
  }

  .lst-kix_list_2-2>li:before {
    content: ""counter(lst-ctn-kix_list_2-2, lower-roman) ". "
  }

  .lst-kix_list_13-2>li:before {
    content: ""counter(lst-ctn-kix_list_13-2, lower-roman) ". "
  }

  ol.lst-kix_list_5-2.start {
    counter-reset: lst-ctn-kix_list_5-2 0
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td,
  table th {
    padding: 0
  }

  .c6 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 700;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c15 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c2 {
    margin-left: 18pt;
    padding-top: 6pt;
    padding-left: 0pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c11 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: italic
  }

  .c13 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: italic
  }

  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c18 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c1 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c4 {
    padding-top: 6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 12pt
  }

  .c16 {
    padding-top: 6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center;
    height: 12pt
  }

  .c17 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
  }

  .c8 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
  }

  .c5 {
    padding-top: 6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c14 {
    padding-top: 6pt;
    padding-bottom: 12pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c10 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
  }

  .c19 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
  }

  .c12 {
    border: 1px solid black;
    margin: 5px
  }

  .c9 {
    color: inherit;
    text-decoration: inherit
  }

  .c7 {
    padding: 0;
    margin: 0
  }

  .c3 {
    background-color: #ffff00
  }

  .title {
    padding-top: 6pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
  }

  h1 {
    padding-top: 12pt;
    color: #000000;
    border-bottom-color: #000000;
    font-size: 16pt;
    padding-bottom: 1pt;
    line-height: 1.0;
    page-break-after: avoid;
    border-bottom-width: 0.5pt;
    font-family: "Arial";
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 13pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 10pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Cambria";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }
}
